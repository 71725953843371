import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";

import {
    useGetEmailDistributionListQuery,
    useCreateEmailDistributionListMutation,
    useUpdateEmailDistributionListMutation
} from "../../../services/mip";
import ArrayOfStrings from "../../../components/Common/ArrayOfStrings";

export default function EmailDistributionListsForm(props) {
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);
    
    const newMode = params.listIdentifier.toLowerCase() === "new";
    const { 
        data: emailDistributionListsData,
        error: emailDistributionListsError,
        isFetching: emailDistributionListsIsFetching
    } = useGetEmailDistributionListQuery(params.listIdentifier, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const [createEmailDistributionLists, createEmailDistributionListsState] = useCreateEmailDistributionListMutation();
    const postEmailDistributionLists = async () => {
        setSubmittingToAPI(true);
        setInvalidEmailDistributionListsOptions(undefined);
        if (emailDistributionListsName.length === 0) {
            setInvalidEmailDistributionListsOptions("Please provide a name.");
        } else {
            const objectToPost = {
                EmailAddresses: emailAddresses,
                Name: emailDistributionListsName
            };
            try {
                await createEmailDistributionLists(objectToPost).unwrap();
                navigate("/settings/email-distribution-lists");
            } catch (err) {
                if (err.data?.message) {
                    setInvalidEmailDistributionListsOptions(err.data.message);
                } else if (err.data?.Message) {
                    setInvalidEmailDistributionListsOptions(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setInvalidEmailDistributionListsOptions(err.value.error.data.Message);
                } else {
                    setInvalidEmailDistributionListsOptions(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    const [updateEmailDistributionLists] = useUpdateEmailDistributionListMutation();
    const confirmUpdate = async (identifier) => {
        setInvalidEmailDistributionListsOptions(undefined);
        const objectToPost = {
            EmailAddresses: emailAddresses,
            Name: emailDistributionListsName
        };
        try {
            await updateEmailDistributionLists({id: identifier, body: objectToPost}).unwrap();
            setConfirmUpdateModalDisplayed(null);
            navigate("/settings/email-distribution-lists");
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [emailDistributionListsName, setEmailDistributionListsName] = useState(newMode ? "" : undefined);
    const [emailAddresses, setEmailAddresses] = useState(newMode ? [] : undefined);
    const [invalidEmailDistributionListsOptions, setInvalidEmailDistributionListsOptions] = useState();
    const [confirmUpdateModalDisplayed, setConfirmUpdateModalDisplayed] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    useLayoutEffect(() => {
        if (emailDistributionListsData?.Name && emailDistributionListsName === undefined) {
            setEmailDistributionListsName(emailDistributionListsData.Name);
        }
        if (emailDistributionListsData?.EmailAddresses && emailAddresses === undefined) {
            setEmailAddresses(emailDistributionListsData.EmailAddresses);
        }
    }, [params.listIdentifier, emailDistributionListsData, emailDistributionListsName, emailAddresses]);

    const pageTitle = newMode
        ? "Create Email Distribution List"
        : "Edit Email Distribution List";

    useEffect(() => {
        document.title = `Settings - ${  pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (emailDistributionListsError && emailDistributionListsError.status === 401) {
            window.location.href="/login";
        }
    }, [emailDistributionListsError]);

    if (!newMode && !emailDistributionListsIsFetching && !emailDistributionListsData) {
        return <NotFound message="Email Distribution List Not Found" />;
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="email-distribution-lists" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1">
                            <FontAwesomeIcon icon={faCog}/> {pageTitle}
                        </div>
                    </div>
                    {
                        emailDistributionListsError
                            ? <ErrorTile message={emailDistributionListsError} />
                            : emailDistributionListsIsFetching
                                ? <LoadingSpinner text={"Loading Email Distribution List Data"} />
                                : !newMode && !emailDistributionListsData
                                    ?   <div>Map not found</div>
                                    :   <>
                                            <div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="emailDistributionListsName">
                                                        Name
                                                    </label>
                                                    <input type="text" id="emailDistributionListsName" name="emailDistributionListsName" value={emailDistributionListsName ?? ""} onChange={(e) => setEmailDistributionListsName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                </div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="emailDistributionListsType">
                                                        Email Addresses
                                                    </label> 
                                                    <ArrayOfStrings
                                                        arrayOfStrings={emailAddresses}
                                                        setArrayOfStrings={setEmailAddresses}
                                                    />
                                                </div>
                                                {
                                                    invalidEmailDistributionListsOptions 
                                                        ? <ErrorTile message={invalidEmailDistributionListsOptions} />
                                                        : createEmailDistributionListsState && createEmailDistributionListsState.isError
                                                            ? <ErrorTile message={createEmailDistributionListsState.error?.data?.Message} />
                                                            : null
                                                }
                                                <div className="flex justify-evenly">
                                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/email-distribution-lists")}>Back to Email Distribution Lists</button>
                                                    {
                                                        newMode
                                                            ?   <button data-test-id="add-email-distribution-list-button" disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postEmailDistributionLists}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add Email Distribution List"}</button>
                                                            :   <button data-test-id="update-email-distribution-list-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => setConfirmUpdateModalDisplayed(params.listIdentifier)}>Update Email Distribution List</button>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </>
                    }
                </div>
                {
                    emailDistributionListsData && confirmUpdateModalDisplayed
                        ?   <ConfirmModal 
                                title="Confirm Update of Email Distribution List"
                                submitButtonTitle="Update"
                                identifier={emailDistributionListsData.Identifier}
                                confirmationMessage={`Are you sure you want to update the "${ emailDistributionListsData.Name }" Email Distribution List?`}
                                successMessage="Email Distribution List updated successfully."
                                failureMessage="Email Distribution List failed to update:"
                                closeModal={() => setConfirmUpdateModalDisplayed(undefined)}
                                confirmFunction={confirmUpdate}
                            /> 
                        :   null
                }
            </div>
        </div>
        </>
    );
}