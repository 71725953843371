import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import ConfirmModal from "../../components/Common/ConfirmModal";
import QueueManualProcessModal from "../../components/Common/QueueManualProcessModal";
import NotFound from "../../components/Common/NotFound";
import ErrorTile from "../../components/Common/ErrorTile";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import JobHistoryRow from "../../components/ManualProcesses/JobHistoryRow";

import {
    useGetJobHistoryQuery,
    useQueueManualProcessMutation,
    useDeleteManualProcessMutation,
    useGetManualProcessesQuery,
    useGetProvisionedProcessesQuery
 } from "../../services/mip";
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import {
     faBoltLightning,
     faHourglass,
     faPencil,
     faTrash
 } from "@fortawesome/free-solid-svg-icons";

export default function Execute(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasExecutePrivilege = privilege.includes("ManualExecute") || privilege.includes("Deploy") || privilege.includes("All");
    const hasDeployPrivilege = privilege.includes("Deploy") || privilege.includes("All");
    const hasPrivilege = hasExecutePrivilege || hasDeployPrivilege;

    const [jobHistoryStorage, setJobHistoryStorage] = useLocalStorage("jobHistory", {});

    const {
        data: manualProcessData,
        error: manualProcessAPIError,
        isFetching: manualProcessDataFetching,
    } = useGetManualProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: jobHistoryData,
        error: jobHistoryAPIError,
        isFetching: jobHistoryDataFetching
    } = useGetJobHistoryQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const [confirmDeleteManualProcessDisplayed, setConfirmDeleteManualProcessDisplayed] = useState(null);
    const [queueManualProcessModalDisplayed, setQueueManualProcessModalDisplayed] = useState(null);

    const [deleteManualProcessMutation] = useDeleteManualProcessMutation();
    const deleteManualProcess = async (manualProcessIdentifier) => {
        try {
            await deleteManualProcessMutation(manualProcessIdentifier).unwrap();
            setConfirmDeleteManualProcessDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [queueManualProcessMutation] = useQueueManualProcessMutation();
    const queueManualProcess = async (manualProcessIdentifier, body) => {
        try {
            await queueManualProcessMutation({id: manualProcessIdentifier, body}).unwrap();
            setQueueManualProcessModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const sortManualProcesses = (a, b) => {
        if (a.Name.toUpperCase() < b.Name.toUpperCase()) {
            return -1;
        } else if (a.Name.toUpperCase() > b.Name.toUpperCase()) {
            return 1;
        } else {
            return 0;
        }
    };

    const processByIdentifier = {};
    if (provisionedProcessData && Array.isArray(provisionedProcessData)) {
        for (const process of provisionedProcessData) {
            processByIdentifier[process.Identifier] = process;
        }
    }

    const manualProcessByIdentifier = {};
    if (manualProcessData && Array.isArray(manualProcessData)) {
        for (const process of manualProcessData) {
            manualProcessByIdentifier[process.Identifier] = process;
        }
    }

    const jobStatusByIdentifier = {};
    if (jobHistoryData && Array.isArray(jobHistoryData)) {
        for (const jobInfo of jobHistoryData) {
            jobStatusByIdentifier[jobInfo.Identifier] = jobInfo;
        }
    }

    let sortedManualProcessData = [];
    if (manualProcessData !== null && manualProcessData !== undefined && Array.isArray(manualProcessData) && manualProcessData.length !== 0) {
        sortedManualProcessData = [...manualProcessData].sort(sortManualProcesses);
    }

    useEffect(() => {
        let redirectToLogin = false;
        if (manualProcessAPIError && manualProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (provisionedProcessAPIError && provisionedProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (jobHistoryAPIError && jobHistoryAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        manualProcessAPIError,
        provisionedProcessAPIError,
        jobHistoryAPIError
    ]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        document.title = "Execute";
    }, []);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    const gridStyle = hasDeployPrivilege
        ? "minmax(10rem, auto) minmax(20rem, auto) 7rem minmax(10rem, auto) min-content"
        : "minmax(10rem, auto) min-content";

    return (
        <>
        <UserInformationBanner />
        <div className="mx-auto bg-white rounded-lg p-4 mb-4">
        {
            manualProcessAPIError || provisionedProcessAPIError
                ? <ErrorTile message={manualProcessAPIError || provisionedProcessAPIError} />
                : manualProcessDataFetching || provisionedProcessDataFetching
                    ?   <LoadingSpinner text="Loading Processes to Execute" />
                    :   <>
                            <div className="text-2xl pb-4"><FontAwesomeIcon icon={faBoltLightning} /> Execute</div>
                            {
                                sortedManualProcessData.length === 0
                                    ?   <div className="text-center">No manual processes.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: gridStyle}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                            {
                                                hasDeployPrivilege
                                                    ?   <>
                                                            <div className="text-center font-bold px-2 border">Provisioned Process</div>
                                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Verb</div>
                                                            <div className="text-center font-bold px-2 border">Path</div>
                                                        </>
                                                    : null
                                            }
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            {
                                                sortedManualProcessData.map((item, index) => (
                                                    <div data-test-id="manual-process" className="contents group" key={`manual-process-${ index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100">{item.Name}</div>
                                                        {
                                                            hasDeployPrivilege
                                                                ?   <>
                                                                        <div className="p-2 border group-odd:bg-gray-100">{processByIdentifier[item.ProvisionedProcessIdentifier].Name}</div>
                                                                        <div className="p-2 border group-odd:bg-gray-100 whitespace-nowrap">{item.HttpVerb}</div>
                                                                        <div className="p-2 border group-odd:bg-gray-100">{item.ApiPath}</div>
                                                                    </>
                                                                : null
                                                        }
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            {
                                                                hasExecutePrivilege
                                                                    ? processByIdentifier[item.ProvisionedProcessIdentifier].IsDeployed === true
                                                                        ? <button title="Execute" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setQueueManualProcessModalDisplayed(item.Identifier)}><FontAwesomeIcon icon={faBoltLightning} /></button>
                                                                        : <button disabled={true} title="Process Not Deployed" className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setQueueManualProcessModalDisplayed(item.Identifier)}><FontAwesomeIcon icon={faBoltLightning} /></button>
                                                                    : null
                                                            }
                                                            {
                                                                hasDeployPrivilege
                                                                    ?   <>
                                                                            <button title="Edit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => navigate(`/manualprocess/${ item.Identifier }/edit`)}><FontAwesomeIcon icon={faPencil} /></button>
                                                                            <button title="Delete" className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteManualProcessDisplayed(item.Identifier)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                        </>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                            }
                        </>
        }
        {
            manualProcessAPIError || provisionedProcessAPIError || jobHistoryAPIError
                ? <ErrorTile message={manualProcessAPIError || provisionedProcessAPIError || jobHistoryAPIError} />
                : manualProcessDataFetching || provisionedProcessDataFetching || jobHistoryDataFetching
                    ?   <LoadingSpinner text="Loading Job History" margin="mx-auto my-4" />
                    :   <>
                            <div className="text-2xl py-4"><FontAwesomeIcon icon={faHourglass} /> Job History</div>
                            {
                                Array.isArray(jobHistoryData.Data) 
                                    ?   jobHistoryData.Data.length === 0
                                        ?   <div className="text-center">No job history.</div>
                                        :   <div className="grid" style={{gridTemplateColumns: "repeat(3, auto) 7rem" }}>
                                                <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                                <div className="text-center font-bold px-2 border whitespace-nowrap">Start</div>
                                                <div className="text-center font-bold px-2 border whitespace-nowrap">Completed</div>
                                                <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                                {
                                                    jobHistoryData.Data.map((job, index) => (
                                                        <JobHistoryRow
                                                            key={index}
                                                            job={job}
                                                            manualProcessByIdentifier={manualProcessByIdentifier}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        : null
                            }
                        </>
        }
        </div>
        {
            manualProcessData !== null && confirmDeleteManualProcessDisplayed !== null
                ? manualProcessData.map((item, index) => (
                    confirmDeleteManualProcessDisplayed === item.Identifier 
                        ? <ConfirmModal 
                                key={index} 
                                title={`Confirm Delete of ${ item.Name }`}
                                submitButtonTitle="Delete"
                                identifier={item.Identifier}
                                confirmationMessage={`Are you sure you want to delete ${ item.Name  }? This cannot be undone.`} 
                                showWarning={true}
                                successMessage="Manual Process deleted successfully."
                                failureMessage="Manaul Process failed to delete:"
                                closeModal={() => setConfirmDeleteManualProcessDisplayed(null)}
                                confirmFunction={deleteManualProcess}
                            /> 
                        : null
                    ))
                : null
        }
        {
            manualProcessData !== null && queueManualProcessModalDisplayed !== null
                ? manualProcessData.map((item, index) => (
                    queueManualProcessModalDisplayed === item.Identifier 
                        ? <QueueManualProcessModal 
                                key={index} 
                                title={`Execute ${ item.Name }`}
                                submitButtonTitle="Execute"
                                identifier={item.Identifier}
                                manualProcess={item}
                                successMessage="Process executed successfully."
                                failureMessage="Process Failed to Execute"
                                closeModal={() => setQueueManualProcessModalDisplayed(null)}
                                queueProcessFunction={queueManualProcess}
                            />
                        : null
                    ))
                : null
        }
        </>
    );
}