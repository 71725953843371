import { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";

import { useGetApiKeyValueMutation } from "../../../services/mip";

export default function ApiKeysValueModal(props) {  
    const navigate = useNavigate();
    
    const [getApiKeyValue, {
        data: apiKeyValueData, 
        error: apiKeyValueError, 
        isLoading: apiKeyValueLoading
    }] = useGetApiKeyValueMutation();

    useLayoutEffect(() => {
        getApiKeyValue(props.identifier);
    }, [getApiKeyValue, props.identifier]);

    useEffect(() => {
        if (apiKeyValueError && apiKeyValueError.status === 401) {
            navigate("/login");
        }
    }, [navigate, apiKeyValueError]);
    

    return (
        <div className="opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-500 opacity-95"></div>
            <div className="fixed w-full h-full z-50 overflow-y-auto">
                <div className="container mx-auto w-1/3 h-auto text-left m-4 bg-white rounded-lg">
                    <div className="bg-purple-800 text-white text-2xl rounded-t-lg ">
                        <div className="flex">
                            <div className="flex-1 p-2">{props.title}</div>
                            <div className="flex-initial p-2"><FontAwesomeIcon data-test-id="api-key-value-modal-close-link" className="hover:cursor-pointer" icon={faTimesCircle} onClick={props.closeModal} /></div>
                        </div>
                    </div>
                    { 
                        apiKeyValueError
                            ? <ErrorTile message={apiKeyValueError} />
                            : apiKeyValueLoading
                                ? <LoadingSpinner width="w-full" text={"Loading API Key Value"} />
                                : !apiKeyValueData || !apiKeyValueData.hasOwnProperty("Data")
                                    ?   <div className="text-center p-4">Unable to load API Key value</div>
                                    :   <div className="text-center p-4">{apiKeyValueData.Data.Value} <FontAwesomeIcon onClick={() => {navigator.clipboard.writeText(apiKeyValueData.Data.Value).then(() => alert("Copied API Key value to clipboard."));}} className="text-lg px-2" icon={faCopy} /></div>
                    }
                </div>
            </div>
        </div>
    );
}