import ExportDataSelectorField from "../ExportDataSelectorField";
import ExportDataSelectorArrayInfo from "../ExportDataSelectorArrayInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function ExportDataSelectorFieldContainer(props) {   

    const numberOfFields = props.mappingData.fields.length;

    return (
        <div className="shadow border rounded p-4">
            <div data-test-id="export-data-selector-container-div">
            {
                props.mappingData.fields.length === 0
                    ? <div className="text-center">Use the button below to add a column to choose data to export.</div>
                    : props.mappingData.fields.map((field, index) => (
                            <ExportDataSelectorField
                                key={index}
                                index={index}
                                field={field}
                                fieldEntityValue={props.entityValueByName[field.entityProperty]}
                                numberOfFields={numberOfFields}
                                onDeleteClick={props.onDeleteClick}
                                onDownButtonClick={props.onDownButtonClick}
                                onUpButtonClick={props.onUpButtonClick}
                                setFieldValue={props.setFieldValue}
                                entityPropertyOptions={props.entityPropertyOptions}
                            />
                        ))
            }
            </div>
            <div className="flex justify-evenly p-4">
                <button data-test-id="export-data-selector-add-column-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={props.onAddFieldClick}><FontAwesomeIcon icon={faPlus} /> Add Column</button>
            </div>
            {
                props.showArrayInfo && Object.keys(props.mappingData.arrayData).length > 0
                    ? <ExportDataSelectorArrayInfo 
                        mappingData={props.mappingData} 
                        setArrayDataValue={props.setArrayDataValue}
                      />
                    : null 
            }
        </div>
        
    );
}