import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";
import { useGetExternalDataQuery } from "../../../services/mip";

export default function GeneralDataSelectorFieldTimeZoneSelector(props) {

    const { 
        data, 
        error, 
        isFetching, 
        refetch
    } = useGetExternalDataQuery("/settings/timezones");

    const options = [<option key="" value="">None</option>];

    if (data !== null && data !== undefined) {
        for (const timeZone of data) {
            options.push(<option key={timeZone.Id} value={timeZone.Id}>{`${ timeZone.DisplayName  } - ${  timeZone.Id }`}</option>);
        }
    }

    // Import and Export use different setFieldValue signatures so this calls the right one
    const setFieldValue = (timeZoneId) => {
        if (props.property !== undefined) { // Import
            props.setFieldValue(props.property.Name, props.index, props.formValueKey, timeZoneId);
        } else if (props.field !== undefined) { // Export
            props.setFieldValue(props.index, props.formValueKey, timeZoneId);
        }
    };

    const currentValue = props.property ? props.property.userEnteredData[props.index][props.formValueKey] : props.field[props.formValueKey];

    return (
        <>
        {
            error
                ?   <div>
                        <ErrorTile message={error} />
                        <div className="flex justify-evenly">
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={refetch}><FontAwesomeIcon icon={faRedo} /></button>
                        </div>
                    </div>
                : isFetching
                    ?   <LoadingSpinner text="Loading Time Zones" />
                    :   <select name={props.htmlFieldName} id={props.htmlFieldName} value={currentValue} onChange={(e) => {setFieldValue(e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                            {options}
                        </select>
        }
        </>
    );
}