import { useMemo, useReducer } from "react";
import ProvisionableItemDataCollector from "../ProvisionableItemDataCollector";
import {
    getInitialStateFieldObject,
    getFieldType,
    getAllFieldsInProvisionableItemForms
} from "../../../midgard.js";

export default function ProvisionableItemInitialStateSetup(props) {

    const formsToDisplay = useMemo(() => {
        if (props.availableProvisionableItemWhenEditing && ["edit", "view"].includes(props.provisionMode)) {
            return props.availableProvisionableItemWhenEditing.Forms;
        } else if (props.provisionableItem) {
            return props.provisionableItem.Forms;
        }
        return [];
    }, [
        props.availableProvisionableItemWhenEditing,
        props.provisionMode,
        props.provisionableItem
    ]);

    const allFields = getAllFieldsInProvisionableItemForms(formsToDisplay);

    const setInitialFormState = () => {
        const initialState = {};
        for (const fieldIdentifier of Object.keys(allFields)) {
            initialState[fieldIdentifier] = getInitialStateFieldObject(allFields[fieldIdentifier], allFields, props.provisionableItem.Arguments);
        }

        // add formConditions to all fields
        for (const form of formsToDisplay) {
            for (const field of form.Fields) {
                initialState[field.Parameter.Identifier].formConditions = form.Conditions;
            }
        }

        // disable all related fields for FileLayoutSelector that have externalData
        for (const form of formsToDisplay) {
            for (const field of form.Fields) {
                const fieldType = getFieldType(field);
                if (fieldType === "FileLayoutSelector" && initialState[field.Parameter.Identifier].externalData !== null) {
                    for (const relatedFieldParameterIdentifier of Object.values(field.ConvertToJsonApiQueryStringParameters)) {
                        initialState[relatedFieldParameterIdentifier].disabled = true;
                    }    
                }
            }
        }

        return initialState;
    };

    const formStateReducer = (state, action) => {
        const stateCopy = {...state};
        switch (action.type) {
            case "deletePreviousValue":
                delete stateCopy[action.payload.fieldIdentifier].previousValue;
                break;
            case "setExternalData":
                stateCopy[action.payload.fieldIdentifier].externalData = action.payload.data;
                // TODO: go over the things to reset when this changes and reset them.
                break;
            case "setIsFetching":
                stateCopy[action.payload.fieldIdentifier].isFetching = action.payload.data;
                break;
            case "setFormValue":
                stateCopy[action.payload.fieldIdentifier].touched = true;
                stateCopy[action.payload.fieldIdentifier].value = action.payload.data;
                break;
            case "disableRelatedFields":
                if (Array.isArray(action.payload)) {
                    for (const parameterIdentifer of action.payload) {
                        stateCopy[parameterIdentifer].disabled = true;
                    }
                }
                break;
            case "enableRelatedFields":
                if (Array.isArray(action.payload)) {
                    for (const parameterIdentifer of action.payload) {
                        stateCopy[parameterIdentifer].disabled = false;
                    }
                }
                break;
            default:
                throw new Error("unknown action.type in modalStateReducer");
        }
        return stateCopy;
    };

    // having useReducer be the last thing we do just in case anything else is slow
    const [formState, formStateDispatch] = useReducer(formStateReducer, {}, setInitialFormState);

    return (
        <ProvisionableItemDataCollector
            provisionableItem={props.provisionableItem}
            provisionMode={props.provisionMode}
            provisionableItemType={props.provisionableItemType}
            formsToDisplay={formsToDisplay}
            formState={formState}
            formStateDispatch={formStateDispatch}
            availableProvisionableItemWhenEditing={props.availableProvisionableItemWhenEditing}
        />
    );
}