import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { 
    useGetOrderReservationWindowsQuery,
    useCreateOrderReservationWindowMutation,
    useUpdateOrderReservationWindowMutation
} from "../../../services/mip";

export default function OrderReservationWindowsForm(props) {
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const newMode = params.windowId.toLowerCase() === "new";
    const daysOfWeekValues = ["All", "WeekDays", "WeekendDays", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"];
    const [openTime, setOpenTime] = useState(newMode ? "00:00:00" : undefined);
    const [closeTime, setCloseTime] = useState(newMode ? "00:00:00" : undefined);
    const [startTime, setStartTime] = useState(newMode ? "00:00:00" : undefined);
    const [endTime, setEndTime] = useState(newMode ? "00:00:00" : undefined);
    const [daysOfWeek, setDaysOfWeek] = useState(newMode ? ["All"] : undefined);
    const [validationErrors, setValidationErrors] = useState(undefined);
    const [confirmUpdateModalDisplayed, setConfirmUpdateModalDisplayed] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const setMultieSelectDaysOfWeek = (options) => {
        const selectedOptions = [];
        for (const option of options) {
            if (option.selected === true) {
                selectedOptions.push(option.value);
            }
        }
        setDaysOfWeek(selectedOptions);
    };

    const dayOfWeekOptions = [];
    for (const dow of daysOfWeekValues) {
        dayOfWeekOptions.push(<option key={dow} value={dow}>{dow}</option>);
    }

    const { 
        data: orderReservationWindowsData,
        error: orderReservationWindowsError,
        isFetching: orderReservationWindowsIsFetching
    } = useGetOrderReservationWindowsQuery(null, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const getValidationErrors = () => {
        const errors = [];
        if (daysOfWeek.length < 1) {
            errors.push("Please provide at least one day of the week.");
        }

        const timeRegularExpression = /^([0-9]{2}):([0-9]{2}):([0-9]{2})(\.[0-9]{7})?$/;
        const startTimeMatch = startTime.match(timeRegularExpression);
        if (startTimeMatch === null) {
            errors.push("Please provide a Start Time in HH:MM:SS format.");
        } else if (parseInt(startTimeMatch[1]) > 23 || parseInt(startTimeMatch[2]) > 59 || parseInt(startTimeMatch[3]) > 59) {
            errors.push("Please provide a valid Start Time.");
        }

        const endTimeMatch = endTime.match(timeRegularExpression);
        if (endTimeMatch === null) {
            errors.push("Please provide an End Time in HH:MM:SS format.");
        } else if (parseInt(endTimeMatch[1]) > 23 || parseInt(endTimeMatch[2]) > 59 || parseInt(endTimeMatch[3]) > 59) {
            errors.push("Please provide a valid End Time.");
        }

        const openTimeMatch = openTime.match(timeRegularExpression);
        if (openTimeMatch === null) {
            errors.push("Please provide an Open Time in HH:MM:SS format.");
        } else if (parseInt(openTimeMatch[1]) > 23 || parseInt(openTimeMatch[2]) > 59 || parseInt(openTimeMatch[3]) > 59) {
            errors.push("Please provide a valid Open Time.");
        }

        const closeTimeMatch = closeTime.match(timeRegularExpression);
        if (closeTimeMatch === null) {
            errors.push("Please provide an Close Time in HH:MM:SS format.");
        } else if (parseInt(closeTimeMatch[1]) > 23 || parseInt(closeTimeMatch[2]) > 59 || parseInt(closeTimeMatch[3]) > 59) {
            errors.push("Please provide a valid Close Time.");
        }

        if (errors.length === 0) {
            const startTimeInSeconds = parseInt(startTimeMatch[1]) * 60 * 60 + parseInt(startTimeMatch[2] * 60 + parseInt(startTimeMatch[3]));
            const endTimeInSeconds = parseInt(endTimeMatch[1]) * 60 * 60 + parseInt(endTimeMatch[2] * 60 + parseInt(endTimeMatch[3]));
            const openTimeInSeconds = parseInt(openTimeMatch[1]) * 60 * 60 + parseInt(openTimeMatch[2] * 60 + parseInt(openTimeMatch[3]));
            const closeTimeInSeconds = parseInt(closeTimeMatch[1]) * 60 * 60 + parseInt(closeTimeMatch[2] * 60 + parseInt(closeTimeMatch[3]));

            if (startTimeInSeconds < openTimeInSeconds || startTimeInSeconds > closeTimeInSeconds) {
                errors.push("Start Time must be within Open/Close time.");
            }
            if (endTimeInSeconds < openTimeInSeconds || endTimeInSeconds > closeTimeInSeconds) {
                errors.push("End Time must be within Open/Close time.");
            }
        }

        return errors;
    };

    const [createOrderReservationWindow, createOrderReservationWindowState] = useCreateOrderReservationWindowMutation();
    const postOrderReservationWindow = async () => {
        setSubmittingToAPI(true);
        setValidationErrors(undefined);
        
        const errors = getValidationErrors();
        if (errors.length > 0) {
            setValidationErrors(errors);
        } else {
            const objectToPost = {
                DaysOfWeek: daysOfWeek.join(","),
                EndTime: endTime,
                StartTime: startTime,
                OpenTime: openTime,
                CloseTime: closeTime
            };
            try {
                await createOrderReservationWindow(objectToPost).unwrap();
                navigate("/settings/order-reservation-windows");
            } catch (err) {
                setValidationErrors(err);
            }
        }
        setSubmittingToAPI(false);
    };

    const validateAndShowConfirmModal = (windowId) => {
        setValidationErrors(undefined);
        const errors = getValidationErrors();
        if (errors.length > 0) {
            setValidationErrors(errors);
        } else {
            setConfirmUpdateModalDisplayed(windowId);
        }
    };

    const [updateOrderReservationWindow] = useUpdateOrderReservationWindowMutation();
    const confirmUpdate = async (windowId) => {
        setValidationErrors(undefined);
        const objectToPost = {
            Identifier: windowId,
            DaysOfWeek: daysOfWeek.join(","),
            EndTime: endTime,
            StartTime: startTime,
            OpenTime: openTime,
            CloseTime: closeTime
        };
        try {
            await updateOrderReservationWindow({id: windowId, body: objectToPost}).unwrap();
            setConfirmUpdateModalDisplayed(null);
            navigate("/settings/order-reservation-windows");
            return [true, null];
        } catch (err) {
            return [false, err];
        }
    };

    useLayoutEffect(() => {
        let orderReservationWindowObject;
        if (Array.isArray(orderReservationWindowsData?.Data)) {
            for (const orderReservationWindow of orderReservationWindowsData.Data) {
                if (orderReservationWindow.Identifier === params.windowId) {
                    orderReservationWindowObject = orderReservationWindow;
                    break;
                }
            }
            // set defaults on edit
            if (orderReservationWindowObject) {
                if (startTime === undefined) {
                    setStartTime(orderReservationWindowObject.StartTime);
                }
                if (endTime === undefined) {
                    setEndTime(orderReservationWindowObject.EndTime);
                }
                if (openTime === undefined) {
                    setOpenTime(orderReservationWindowObject.OpenTime);
                }
                if (closeTime === undefined) {
                    setCloseTime(orderReservationWindowObject.CloseTime);
                }
                if (daysOfWeek === undefined) {
                    let previousDaysOfWeek = [];
                    if (orderReservationWindowObject.DaysOfWeek.includes(",")) {
                        previousDaysOfWeek = orderReservationWindowObject.DaysOfWeek.replace(" ", "").split(",");
                    } else {
                        previousDaysOfWeek = [orderReservationWindowObject.DaysOfWeek];
                    }
                    setDaysOfWeek(previousDaysOfWeek);
                }
            }
        }
    }, [params.windowId, orderReservationWindowsData, startTime, endTime, openTime, closeTime, daysOfWeek]);

    const pageTitle = newMode ? "Create Reservation Window" : "Edit Reservation Window";

    useEffect(() => {
        document.title = `Settings - ${ pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (orderReservationWindowsError && orderReservationWindowsError.status === 401) {
            window.location.href="/login";
        }
    }, [orderReservationWindowsError]);

    if (!newMode && Array.isArray(orderReservationWindowsData?.Data)) {
        let foundInData = false;
        for (const orderReservationWindow of orderReservationWindowsData.Data) {
            if (orderReservationWindow.Identifier === params.windowId) {
                foundInData = true;
                break;
            }
        }
        if (!foundInData) {
            return <NotFound message="Window Not Found" />;
        }
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="order-reservation-windows" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1">
                            <FontAwesomeIcon icon={faCog}/> {pageTitle}
                        </div>
                    </div>
                    {
                        orderReservationWindowsError
                            ? <ErrorTile message={orderReservationWindowsError} />
                            : orderReservationWindowsIsFetching
                                ? <LoadingSpinner text={"Loading Windows"} />
                                : !newMode && (!orderReservationWindowsData || !orderReservationWindowsData.hasOwnProperty("Data") || !Array.isArray(orderReservationWindowsData.Data) || orderReservationWindowsData.Data.length === 0)
                                    ?   <div>Window not found</div>
                                    :   <>
                                            <div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="daysOfWeek">
                                                        Days Of Week
                                                    </label>
                                                    <select size="7" multiple="multiple" id="daysOfWeek" name="daysOfWeek" value={daysOfWeek} onChange={(e) => {setMultieSelectDaysOfWeek(e.target.options);}} className="flex-1 shadow border rounded w-full text-grey-800 text-base focus:shadow-outline">{dayOfWeekOptions}</select>
                                                </div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="openTime">
                                                        Open Time
                                                    </label>
                                                    <input type="text" id="openTime" name="openTime" value={openTime ?? ""} onChange={(e) => setOpenTime(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                </div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="closeTime">
                                                        Close Time
                                                    </label>
                                                    <input type="text" id="closeTime" name="closeTime" value={closeTime ?? ""} onChange={(e) => setCloseTime(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                </div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="startTime">
                                                        Start Time
                                                    </label>
                                                    <input type="text" id="startTime" name="startTime" value={startTime ?? ""} onChange={(e) => setStartTime(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                </div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="endTime">
                                                        End Time
                                                    </label>
                                                    <input type="text" id="endTime" name="endTime" value={endTime ?? ""} onChange={(e) => setEndTime(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                </div>
                                                {
                                                    validationErrors
                                                        ? <ErrorTile message={validationErrors} />
                                                        : createOrderReservationWindowState && createOrderReservationWindowState.isError
                                                            ? <ErrorTile message={createOrderReservationWindowState.error?.data?.Message} />
                                                            : null
                                                }
                                                <div className="flex justify-evenly">
                                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/order-reservation-windows")}>Back to Windows</button>
                                                    {
                                                        newMode
                                                            ?   <button data-test-id="add-order-reservation-window-button" disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postOrderReservationWindow}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add Window"}</button>
                                                            :   <button data-test-id="update-order-reservation-window-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => validateAndShowConfirmModal(params.windowId)}>Update Window</button>
                                                    }                                                    
                                                </div>
                                            </div>
                                        </>
                    }
                </div>
                {
                    orderReservationWindowsData?.Data && Array.isArray(orderReservationWindowsData.Data) && confirmUpdateModalDisplayed
                        ? orderReservationWindowsData.Data.map((orderWindow, index) => (
                            confirmUpdateModalDisplayed === orderWindow.Identifier 
                                ?   <ConfirmModal 
                                        key={index} 
                                        title={`Confirm Update of ${ orderWindow.StartTime } - ${ orderWindow.EndTime } Window`}
                                        submitButtonTitle="Update"
                                        identifier={orderWindow.Identifier}
                                        confirmationMessage={`Are you sure you want to update ${ orderWindow.StartTime } - ${ orderWindow.EndTime }?`} 
                                        successMessage="Window updated successfully."
                                        failureMessage="Window failed to update:"
                                        closeModal={setConfirmUpdateModalDisplayed}
                                        confirmFunction={confirmUpdate}
                                    /> 
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}