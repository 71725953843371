import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faLink, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import {
    useGetUrlSubstitutionsQuery,
    useExtendUrlSubstitutionMutation,
    useDeleteUrlSubstitutionMutation
} from "../../../services/mip";

export default function UrlSubstitutions(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("UrlSubstitutions") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - URL Substitutions";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: urlSubstitutionsData,
        error: urlSubstitutionsError,
        isFetching: urlSubstitutionsIsFetching
    } = useGetUrlSubstitutionsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const [deleteUrlSubstitution] = useDeleteUrlSubstitutionMutation();
    const confirmDelete = async (key) => {
        try {
            await deleteUrlSubstitution(encodeURIComponent(key)).unwrap();
            setConfirmDeleteModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [confirmDeleteModalDisplayed, setConfirmDeleteModalDisplayed] = useState();

    const [extendUrlSubstitution] = useExtendUrlSubstitutionMutation();
    const confirmExtend = async (key) => {
        try {
            await extendUrlSubstitution(encodeURIComponent(key)).unwrap();
            setConfirmExtendModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [confirmExtendModalDisplayed, setConfirmExtendModalDisplayed] = useState();

    useEffect(() => {
        if (urlSubstitutionsError && urlSubstitutionsError.status === 401) {
            window.location.href="/login";
        }
    }, [urlSubstitutionsError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="url-substitutions" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faLink} /> URL Substitutions</div>
                        <div className="flex-initial text-right">
                            <button data-test-id="url-substitutions-plus-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/settings/url-substitutions/new");}}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>    
                    </div>
                    {
                        urlSubstitutionsError
                            ? <ErrorTile message={urlSubstitutionsError} />
                            : urlSubstitutionsIsFetching
                                ? <LoadingSpinner text={"Loading URL Substitutions"} />
                                : !urlSubstitutionsData || !urlSubstitutionsData.hasOwnProperty("Data") || !Array.isArray(urlSubstitutionsData.Data) || urlSubstitutionsData.Data.length === 0
                                    ?   <div className="text-center">No url substitutions configured.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "7rem repeat(2, auto) repeat(3, min-content)"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Key</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Original URL</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Substitute URL</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">TTL Hours</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Created</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            {
                                                urlSubstitutionsData.Data.map((urlSubstitution, index) => (
                                                    <div className="contents group" key={`url-subtitution-${  index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100">{urlSubstitution.Key}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{urlSubstitution.Original}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{urlSubstitution.Substitute}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{urlSubstitution.TTLHours}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100 whitespace-nowrap">{urlSubstitution.Timestamp.substring(0,19)}</div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            <button title="Extend Duaion" id={`extend-${ urlSubstitution.Key }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmExtendModalDisplayed(urlSubstitution.Key)}><FontAwesomeIcon className="w-auto" icon={faClock} /></button>
                                                            <button title="Delete" id={`delete-${ urlSubstitution.Key }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteModalDisplayed(urlSubstitution.Key)}><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                    }
                </div>
                {
                    urlSubstitutionsData?.Data && Array.isArray(urlSubstitutionsData.Data) && confirmDeleteModalDisplayed !== undefined
                        ? urlSubstitutionsData.Data.map((urlSubstitution, index) => (
                            confirmDeleteModalDisplayed === urlSubstitution.Key 
                                ?   <ConfirmModal 
                                        key={index} 
                                        title={`Confirm Delete of ${ urlSubstitution.Key } Substitution`}
                                        submitButtonTitle="Delete"
                                        identifier={urlSubstitution.Key}
                                        confirmationMessage={`Are you sure you want to delete the ${ urlSubstitution.Key } Substitution?`} 
                                        successMessage="URL Substitution successfully deleted."
                                        failureMessage="URL Substitution failed to delete:"
                                        closeModal={setConfirmDeleteModalDisplayed}
                                        confirmFunction={confirmDelete}
                                    /> 
                                : null
                        ))
                        : null
                }
                {
                    urlSubstitutionsData?.Data && Array.isArray(urlSubstitutionsData.Data) && confirmExtendModalDisplayed !== undefined
                        ? urlSubstitutionsData.Data.map((urlSubstitution, index) => (
                            confirmExtendModalDisplayed === urlSubstitution.Key 
                                ?   <ConfirmModal 
                                        key={index} 
                                        title={`Confirm Extension of ${ urlSubstitution.Key } Substitution`}
                                        submitButtonTitle="Extend"
                                        identifier={urlSubstitution.Key}
                                        confirmationMessage={`Are you sure you want to extend the ${ urlSubstitution.Key } Substitution?`} 
                                        successMessage="URL Substitution successfully extended."
                                        failureMessage="URL Substitution failed to extend:"
                                        closeModal={setConfirmExtendModalDisplayed}
                                        confirmFunction={confirmExtend}
                                    /> 
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}