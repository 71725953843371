import { useLayoutEffect } from "react";
import { MathOperations, RoundOperations } from "../../../midgard.js";

export default function GeneralDataSelectorCalculationsInfoField(props) {  

    useLayoutEffect(() => {
        // TODO: What if the source (other than "Constant") isn't in the available sources anymore?
        // that would be a use layout effect to set it, eh?
    }, []);

    const htmlFiledName = `${ props.htmlFieldName  }-${  props.index }`;

    return (
        <>
            <div className="flex p-1">
                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`mathOperation-${  htmlFiledName }`}>
                    Math Fn.
                </label>
                <select name={`mathOperation-${  htmlFiledName }`} id={`mathOperation-${  htmlFiledName }`} value={props.calculation.mathOperation} onChange={(e) => {props.setFieldValue(props.index, "mathOperation", e.target.value);}} className="flex-1 shadow border rounded w-1/4 py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                    <option value="">None</option>
                    {Object.keys(MathOperations).map((key) => <option key={key} value={key}>{MathOperations[key].Label}</option>)}
                </select>
                <select name={`mathSource-${  htmlFiledName }`} id={`mathSource-${  htmlFiledName }`} value={props.calculation.source} onChange={(e) => {props.setFieldValue(props.index, "source", e.target.value);}} className="flex-1 shadow border rounded w-1/4 py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                    <option value="Constant">Constant</option>
                    {props.sourceOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                </select>
                {
                    props.calculation.source === "Constant"
                        ?   <input name={`mathOperand-${  htmlFiledName }`} id={`mathOperand-${  htmlFiledName }`} type='number' required="required" value={props.calculation.mathOperand} onChange={(e) => {props.setFieldValue(props.index, "mathOperand", e.target.value);}} className="flex-1 shadow appearance-none border rounded w-1/4 py-2 pl-3 pr-6 text-grey-800 text-right" placeholder="Operand" />
                        :   <div className="flex-1 shadow appearance-none border rounded w-1/4 py-2 pl-3 pr-6 text-grey-800 text-right">N/A</div>
                }
                <select name={`roundOperation-${  htmlFiledName }`} id={`roundOperation-${  htmlFiledName }`} value={props.calculation.roundOperation} onChange={(e) => {props.setFieldValue(props.index, "roundOperation", e.target.value);}} className="flex-1 shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                    <option value="">None</option>
                    {RoundOperations.map((operation) => <option key={operation} value={operation}>{operation}</option>)}
                </select>
            </div>
        </>
    );
}