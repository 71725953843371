import FilesTableRow from "../FilesTableRow/index.js";
import {
    formatOffset
} from "../../../midgard.js";

export default function Files(props) {   
    return (
    <div className="grid mb-2" style={{gridTemplateColumns: "repeat(3, auto) min-content"}}>
        <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
        <div className="text-center font-bold px-2 border whitespace-nowrap">Last Modified {formatOffset(new Date().getTimezoneOffset())}</div>
        <div className="text-center font-bold px-2 border whitespace-nowrap">Size</div>
        <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
        {
            props.filesData.map((file, index) => 
                <FilesTableRow
                    key={file.ObjectKey}
                    allowDeleteFolder={props.allowDeleteFolder}
                    setConfirmDeleteFolderModalDisplayed={props.setConfirmDeleteFolderModalDisplayed}        
                    setConfirmDeleteFileModalDisplayed={props.setConfirmDeleteFileModalDisplayed}
                    prefixLength={props.prefixLength}
                    index={index}
                    file={file}
                />
            )
        }
    </div>
    );
}