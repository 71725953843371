import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectPrivilege } from "../../../features/auth/authSlice";
import { getSettingsLinks } from "../../../midgard";

export default function SettingsLeftNav(props) {
    const privilege = useSelector(selectPrivilege);
    const settingsLinks = getSettingsLinks(privilege);

    const links = [];
    let index = 0;
    for (const link of settingsLinks) {
        const classes = ["block", "border", "px-3", "py-2", "outline-none", "focus:outline-none]"];
        if (index === 0) {
            classes.push("rounded-t-lg");
        }
        if (link.key === props.selected) {
            classes.push("border-l-8 border-t-0 border-r-0 border-purple-300 hover:cursor-auto");
        } else {
            classes.push("hover:bg-purple-300 hover:text-black");
        }
        links.push(<Link key={link.key} to={link.path} title={link.title} className={classes.join(" ")}>{link.title}</Link>);
        index++;
    }

    return (
        <div className="print:hidden border rounded-lg">{links}</div>
    );
}