import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";

export default function QueueManualProcessModalPlaceholderValue(props) {   

    const {placeholderValueIndex, placeholderValue, placeholderValuesDispatch} = props;

    return (
        <div className="contents group">
            <div className="p-2 border group-odd:bg-gray-100">
                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`${ placeholderValue.key }-${ placeholderValueIndex }`}>
                    {placeholderValue.placeholderSetting.DisplayName}
                </label>
                {
                    !placeholderValue.placeholderSetting.Required
                        ? <FontAwesomeIcon icon={faTrash} className="hover:cursor-pointer text-red-500 hover:text-red-700 ml-1 text-sm" onClick={() => placeholderValuesDispatch({type: "deletePlaceholder", payload: {index: placeholderValueIndex}})} />
                        : null
                }
            </div>
            <div className="p-2 border group-odd:bg-gray-100">
                <input type="text" id={`${ placeholderValue.key }-${ placeholderValueIndex }`} name={`${ placeholderValue.key }-${ placeholderValueIndex }`} value={placeholderValue.value} onChange={(e) => placeholderValuesDispatch({type: "setPlaceholderValue", payload: {index: placeholderValueIndex, value: e.target.value}})} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800" />
            </div>
        </div>
    );
}