import { useEffect, useState } from "react";
import ProvisionableItemForm from "../ProvisionableItemForm";
import ProvisionableItemReviewPage from "../ProvisionableItemReviewPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHatWizard } from "@fortawesome/free-solid-svg-icons";
import { checkConditions } from "../../../midgard.js";

export default function ProvisionableItemFormContainer(props) {
    
    const numberOfForms = props.formsToDisplay ? props.formsToDisplay.length - 1 : 0;

    // controls what screen/form and how previous/next buttons work.
    const [formNumber, setFormNumber] = useState(props.provisionMode === "view" ? props.formsToDisplay.length : 0);
    const [showSlowDownWarning, setShowSlowDownWarning] = useState(false);

    const handleNextButton = () => {
        let somethingIsFetching = false;
        for (const field of props.formsToDisplay[formNumber].Fields) {
            if (props.formState[field.Parameter.Identifier].hasApi && checkConditions(field.Conditions, props.formState) && props.formState[field.Parameter.Identifier].isFetching) {
                somethingIsFetching = true;
                break;
            }
        }
        if (somethingIsFetching) {
            setShowSlowDownWarning(true);
        } else {
            setShowSlowDownWarning(false);
            const currentFormNumber = formNumber;
            let nextPage = currentFormNumber + 1;
            let nextPageFound = false;
            for (let i = nextPage; i <= numberOfForms; i++)
            {
                if (!nextPageFound && checkConditions(props.formsToDisplay[i].Conditions, props.formState)) {
                    nextPage = i;
                    nextPageFound = true;
                }
            }
            if (nextPageFound) {
                setFormNumber(nextPage);
            } else {
                // numberOfForms + 1 is the "Review page"
                setFormNumber(numberOfForms + 1);
            }
            window.scrollTo(0, 0);
        }
    };

    const handlePreviousButton = () => {
        setShowSlowDownWarning(false);
        const currentFormNumber = formNumber;
        let nextPage = currentFormNumber - 1;
        if (currentFormNumber > numberOfForms) {
            // if we are beyond numberOfForms we are on the review page.
            // set the "next" page to review to the last form.
            nextPage = numberOfForms;
        }
        let nextPageFound = false;
        for (let i = nextPage; i >= 0; i--)
        {
            if (!nextPageFound && checkConditions(props.formsToDisplay[i].Conditions, props.formState)) {
                nextPage = i;
                nextPageFound = true;
            }
        }
        if (nextPageFound) {
            setFormNumber(nextPage);
        } else {
            setFormNumber(0);
        }
        window.scrollTo(0, 0);
    };

    // loop through the forms and create a left-nav-progress-bar
    // this requires formState so happens after above useReducer
    const progressBarItems = [];
    if (props.provisionMode !== "view") {
        for (const index in props.formsToDisplay) {
            const form = props.formsToDisplay[index];
            if (checkConditions(form.Conditions, props.formState)) {
                const classes = ["block", "border", "px-3", "py-2", "outline-none", "focus:outline-none]"];
                if (index === 0) {
                    classes.push("rounded-t-lg");
                }
                if (parseInt(index) < formNumber) {
                    classes.push("hover:cursor-pointer hover:bg-purple-300 hover:text-black");
                    progressBarItems.push(<div key={form.Description} className={classes.join(" ")} onClick={() => setFormNumber(parseInt(index))}>{form.Description}</div>);
                } else {
                    if (parseInt(index) === formNumber) {
                        classes.push("border-l-8 border-t-0 border-r-0 border-b-0 border-purple-300");
                    } else {
                        classes.push("hover:cursor-not-allowed");
                    }
                    progressBarItems.push(<div title="Use next button to move forward." key={form.Description} className={classes.join(" ")}>{form.Description}</div>);
                }
            }
        }
    }
    if (formNumber > numberOfForms) {
        // review page selected
        progressBarItems.push(<div key="Review" className="block border px-3 py-2 outline-none focus:outline-none border-l-8 border-t-0 border-r-0 border-b-0 border-purple-300">Review</div>);
    } else {
        // review page not selected
        progressBarItems.push(<div key="Review" className="block border px-3 py-2 outline-none focus:outline-none hover:cursor-not-allowed">Review</div>);
    }

    let pageTitle;
    switch (props.provisionMode) {
        case "edit":
        case "new":
            pageTitle = `Provision ${ props.provisionableItem.Name }`;
            break;
        case "view":
        default:
            pageTitle = props.provisionableItem.Name;
            break;
    }

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    return (
        <div className="p-4 print:p-0 grid md:grid-cols-5 grid-cols-1 print:grid-cols-1 gap-4">
            <div className="print:hidden border rounded-lg">{progressBarItems}</div>
            <div className="col-span-4 pl-4 print:p-0">
                <div className="text-2xl mb-4 print:m-0 print:hidden">
                    <FontAwesomeIcon className="print:hidden mr-2" icon={faHatWizard} />{pageTitle}
                </div>
                <div>
                    {
                        props.formsToDisplay !== null 
                            ? props.formsToDisplay.map((form, index) => (
                                formNumber === index 
                                    ?   <ProvisionableItemForm 
                                            key={index} 
                                            formToShow={formNumber} 
                                            formIndex={index} 
                                            form={form}
                                            formState={props.formState}
                                            formStateDispatch={props.formStateDispatch}
                                            onNextButtonClick={handleNextButton}
                                            onPreviousButtonClick={handlePreviousButton}
                                            showSlowDownWarning={showSlowDownWarning}
                                            identifierToExcludeFromLinkedQuery={props.provisionMode !== "new" ? props.provisionableItem.Identifier : null} // used in linked process query
                                            provisionMode={props.provisionMode}
                                            provisionableItemType={props.provisionableItemType}
                                        /> 
                                    : null
                            ))
                            : null
                    }
                    {
                        formNumber > numberOfForms
                            ?   <ProvisionableItemReviewPage
                                    forms={props.formsToDisplay}
                                    formState={props.formState}
                                    provisionMode={props.provisionMode}
                                    provisionableItem={props.provisionableItem}
                                    provisionableItemType={props.provisionableItemType}
                                    onPreviousButtonClick={handlePreviousButton}
                                    postItemIdentifier={props.provisionMode !== "new" ? props.provisionableItem.ProvisionableItemIdentifier : props.provisionableItem.Identifier}
                                    putItemIdentifier={props.provisionableItem.Identifier}
                                    isDeployed={props.provisionableItem.IsDeployed === true}
                                    singleInstanceOnly={props.availableProvisionableItemWhenEditing?.SingleInstanceOnly === true}
                                    identifierToExcludeFromLinkedQuery={props.provisionMode !== "new" ? props.provisionableItem.Identifier : null} // used in linked process query
                                    availableProvisionableItemWhenEditing={props.availableProvisionableItemWhenEditing}
                                /> 
                            : null
                    }
                    {
                        process.env.REACT_APP_ENV === "test" 
                            &&  <div className="text-right mt-10 p-4 print:hidden">
                                    <button onClick={() => {navigator.clipboard.writeText(JSON.stringify(props.provisionableItem));}} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 mx-2 rounded" type="button">copy provisionableItem</button>
                                    <button onClick={() => {navigator.clipboard.writeText(JSON.stringify(props.formState));}} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 mx-2 rounded" type="button">copy formState</button>
                                </div>
                    }
                </div>
            </div>
        </div>
    );
}
