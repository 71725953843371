import { useEffect, useLayoutEffect, useMemo, useState, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faPlus, faQuestionCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";

import {
    useGetProvisionedProcessesQuery,
    useGetEmailDistributionListsQuery,
    useGetSettingsQuery,
    useGetTaskResultActionQuery,
    useCreateTaskResultActionMutation,
    useUpdateTaskResultActionMutation,
} from "../../../services/mip";
import TaskResultActionsFormCondition from "../../../components/Settings/TaskResultActionsFormCondition";

export default function TaskResultActionsForm(props) {
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);
    
    const newMode = params.taskResultActionIdentifier.toLowerCase() === "new";
    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const { 
        data: taskResultActionData,
        error: taskResultActionError,
        isFetching: taskResultActionIsFetching
    } = useGetTaskResultActionQuery(params.taskResultActionIdentifier, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const { 
        data: settingsData,
        error: settingsError,
        isFetching: settingsIsFetching
    } = useGetSettingsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const { 
        data: emailDistributionListsData,
        error: emailDistributionListsError,
        isFetching: emailDistributionListsIsFetching
    } = useGetEmailDistributionListsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const taskResultActionTypes = {
            PostPutEmailTaskResultAction: "Send Email",
            CopyFileTaskResultAction: "Copy File",
            QueueProcessTaskResultAction: "Queue Process"
    };

    const taskResultActionTypeToTypeString = {
        PostPutEmailTaskResultAction: "MIP.Provisioning.TaskResultActions.EmailTaskResultAction, Provisioning",
        CopyFileTaskResultAction: "MIP.Provisioning.TaskResultActions.CopyFileTaskResultAction, Provisioning",
        QueueProcessTaskResultAction: "MIP.Provisioning.TaskResultActions.QueueProcessTaskResultAction, Provisioning"
    };

    const createObjectToPost = () => {
        let objectToPost;
        const baseObject = {
            $type: taskResultActionTypeToTypeString[taskResultActionType],
            ProvisionedProcessIdentifier: provisionedProcess
        };
        if (taskResultActionType === "PostPutEmailTaskResultAction") {
            objectToPost = {
                ...baseObject,
                ...{
                    SendWithoutAttachmentIfNotExists: emailIncludeAttachment && attachmentFilename && attachmentFilename !== "" && sendWithoutAttachmentIfNotExists === true,
                    AttachmentFilename: emailIncludeAttachment && attachmentFilename && attachmentFilename !== "" ? attachmentFilename : null,
                    AttachmentProcessFilenameKey: emailIncludeAttachment && attachmentFilename && attachmentProcessFilenameKey ? attachmentProcessFilenameKey : null,
                    ConvertAttachmentFromJsonToCsv: emailIncludeAttachment && attachmentFilename && attachmentProcessFilenameKey && convertAttachmentFromJsonToCsv === true,
                    FilterExtendedRecordsWhereNotComplete: emailIncludeAttachment && attachmentFilename && attachmentProcessFilenameKey && emailFilterExtendedRecordsWhereNotComplete === true,
                    Body: emailBody,
                    BodyIsHtml: emailBodyIsHtml,
                    Subject: emailSubject,
                    EmailDistributionListIdentifier: emailDistributionListIdentifier,
                    SESFromAddressOverride: fromAddress === "Default" ? null : fromAddress
                }
            };
        } else if (taskResultActionType === "CopyFileTaskResultAction") {
            objectToPost = {
                ...baseObject,
                ...{
                    ConvertFromJsonToCsv: copyFileConvertFromJsonToCsv === true,
                    DestinationObjectKey: copyFileDestinationObjectKey,
                    FilterExtendedRecordsWhereNotComplete: copyFileFilterExtendedRecordsWhereNotComplete === true,
                    ProcessFilenameKey: copyFileProcessFilenameKey
                }
            };
        } else if (taskResultActionType === "QueueProcessTaskResultAction") {
            objectToPost = {
                ...baseObject,
                ...{
                    ProvisionedProcessIdentifierToQueue: provisionedProcessIdentifierToQueue
                }
            };
        }

        const conditionToPost = [];
        for (const condition of conditionsState) {
            conditionToPost.push({
                MetadataKey: condition.propertyName === "Metadata" ? condition.metadataKey : null,
                Not: condition.not,
                PropertyName: condition.propertyName,
                TruthyValue: condition.truthyValue
            });
        }
        if (conditionToPost.length > 0) {
            objectToPost.Conditions = conditionToPost;
        }
        return objectToPost;
    };

    const [createTaskResultActions, createTaskResultActionsState] = useCreateTaskResultActionMutation();
    const postTaskResultActions = async () => {
        setSubmittingToAPI(true);
        setInvalidTaskResultActionsOptions(undefined);

        const errors = getValidationErrors();
        if (errors.length > 0) {
            setInvalidTaskResultActionsOptions(errors);
        } else {
            try {
                await createTaskResultActions(createObjectToPost()).unwrap();
                navigate("/settings/task-result-actions");
            } catch (err) {
                if (err.data?.message) {
                    setInvalidTaskResultActionsOptions(err.data.message);
                } else if (err.data?.Message) {
                    setInvalidTaskResultActionsOptions(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setInvalidTaskResultActionsOptions(err.value.error.data.Message);
                } else {
                    setInvalidTaskResultActionsOptions(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    const [updateTaskResultActions] = useUpdateTaskResultActionMutation();
    const confirmUpdate = async (identifier) => {
        setInvalidTaskResultActionsOptions(undefined);
        try {
            await updateTaskResultActions({id: identifier, body: createObjectToPost()}).unwrap();
            setConfirmUpdateModalDisplayed(null);
            navigate("/settings/task-result-actions");
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    // fields that can be updated
    const [provisionedProcess, setProvisionedProcess] = useState(undefined);
    const [taskResultActionType, setTaskResultActionType] = useState(newMode ? "PostPutEmailTaskResultAction" : undefined);
    const [emailDistributionListIdentifier, setEmailDistributionListIdentifier] = useState(undefined);
    const [fromAddress, setFromAddress] = useState(undefined);
    const [emailSubject, setEmailSubject] = useState(newMode ? "" : undefined);
    const [emailBody, setEmailBody] = useState(newMode ? "" : undefined);
    const [emailBodyIsHtml, setEmailBodyIsHtml] = useState(newMode ? false : undefined);
    const [emailIncludeAttachment, setEmailIncludeAttachment] = useState(newMode ? false : undefined);
    const [sendWithoutAttachmentIfNotExists, setSendWithoutAttachmentIfNotExists] = useState(newMode ? false : undefined);
    const [attachmentFilename, setAttachmentFilename] = useState(newMode ? "" : undefined);
    const [attachmentProcessFilenameKey, setAttachmentProcessFilenameKey] = useState(undefined);
    const [convertAttachmentFromJsonToCsv, setConvertAttachmentFromJsonToCsv] = useState(newMode ? false : undefined);
    const [emailFilterExtendedRecordsWhereNotComplete, setEmailFilterExtendedRecordsWhereNotComplete] = useState(newMode ? false : undefined);
    const [showSubjectHint, setShowSubjectHint] = useState(false);
    const [showBodyHint, setShowBodyHint] = useState(false);
    const [provisionedProcessIdentifierToQueue, setProvisionedProcessIdentifierToQueue] = useState(undefined);
    const [copyFileFilterExtendedRecordsWhereNotComplete, setCopyFileFilterExtendedRecordsWhereNotComplete] = useState(newMode ? false : undefined);
    const [copyFileConvertFromJsonToCsv, setCopyFileConvertFromJsonToCsv] = useState(newMode ? false : undefined);
    const [copyFileDestinationObjectKey, setCopyFileDestinationObjectKey] = useState(newMode ? "" : undefined);
    const [copyFileProcessFilenameKey, setCopyFileProcessFilenameKey] = useState(undefined);

    // form behavior
    const [invalidTaskResultActionsOptions, setInvalidTaskResultActionsOptions] = useState();
    const [confirmUpdateModalDisplayed, setConfirmUpdateModalDisplayed] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const processesByProcessId = useMemo(() => {
        const processes = [];
        if (Array.isArray(provisionedProcessData)) {
            for (const process of provisionedProcessData) {
                processes[process.Identifier] = process;
            }
        }
        return processes;
    }, [provisionedProcessData]);

    const selectedProcess = provisionedProcess && processesByProcessId[provisionedProcess] ? processesByProcessId[provisionedProcess] : undefined;

    const conditionStateReducer = (state, action) => {
        const stateCopy = state.map((obj) => ({...obj}));
        switch (action.type) {
            case "addCondition":
                stateCopy.push({
                    propertyName: "Success",
                    metadataKey: "",
                    not: false,
                    truthyValue: "False"
                });
                break;
            case "setConditionValue":
                stateCopy[action.payload.index][action.payload.property] = action.payload.value;
                if (action.payload.property === "propertyName") {
                    if (["Success","HasFailedRecords"].includes(action.payload.value)) {
                        stateCopy[action.payload.index]["truthyValue"] = stateCopy[action.payload.index]["truthyValue"] === "True" ? "True" : "False";
                    } else if (action.payload.value === "TaskIdentifier") {
                        const validTaskIdentifiers = Object.values(selectedProcess.TaskIdentifiers);
                        if (!validTaskIdentifiers.includes(stateCopy[action.payload.index]["truthyValue"])) {
                            const [ firstTaskIdentifier ] = validTaskIdentifiers;
                            stateCopy[action.payload.index]["truthyValue"] = firstTaskIdentifier;
                        }
                    } else {
                        stateCopy[action.payload.index]["truthyValue"] = "";
                    }
                }
                break;
            case "deleteCondition":
                stateCopy.splice(action.payload.index, 1);
                break;
            case "setInitialConditions":
                // when in dev mode the useEffect for this gets called twice
                // so this is needed to only do it once.
                if (stateCopy.length === 0) {
                    for (const condition of action.payload) {
                        stateCopy.push({
                            propertyName: condition.PropertyName,
                            metadataKey: condition.MetadataKey ?? "",
                            not: condition.Not === true,
                            truthyValue: condition.TruthyValue
                        });
                    }
                }
                break;
            default:
                throw new Error("unknown action.type in conditionStateReducer");
        }
        return stateCopy;
    };

    const [conditionsState, conditionsStateDispatch] = useReducer(conditionStateReducer, []);

    useLayoutEffect(() => {
        if (provisionedProcessData && settingsData && emailDistributionListsData && taskResultActionData) {
            if (provisionedProcess === undefined) {
                if (
                    !Object.keys(processesByProcessId).includes(taskResultActionData.ProvisionedProcessIdentifier) &&
                    Array.isArray(provisionedProcessData) &&
                    provisionedProcessData.length > 0
                ) {
                    // this is here to support copying task result actions from other customers or if the 
                    // chosen process somehow doesn't exist any more.
                    setProvisionedProcess(provisionedProcessData[0].Identifier);
                } else {
                    setProvisionedProcess(taskResultActionData.ProvisionedProcessIdentifier);
                }               
            }
            if (taskResultActionType === undefined) {
                if (taskResultActionData.$type === "MIP.Provisioning.TaskResultActions.EmailTaskResultAction, Provisioning") {
                    setTaskResultActionType("PostPutEmailTaskResultAction");
                    if (Array.isArray(settingsData?.SESFromAddresses) && fromAddress === undefined) {
                        const sesEmailAddresses = settingsData.SESFromAddresses.map((address) => address.Value);
                        if (taskResultActionData.SESFromAddressOverride === null || taskResultActionData.SESFromAddressOverride === undefined || !sesEmailAddresses.includes(taskResultActionData.SESFromAddressOverride)) {
                            setFromAddress("Default");
                        } else {
                            setFromAddress(taskResultActionData.SESFromAddressOverride);
                        }
                    }
                    if (Array.isArray(emailDistributionListsData?.Data) && emailDistributionListIdentifier === undefined) {
                        const emailDistributionListIdentifiers = emailDistributionListsData.Data.map((list) => list.Identifier);
                        if (!emailDistributionListIdentifiers.includes(taskResultActionData.EmailDistributionListIdentifier)) {
                            setEmailDistributionListIdentifier(emailDistributionListIdentifiers[0]);
                        } else {
                            setEmailDistributionListIdentifier(taskResultActionData.EmailDistributionListIdentifier);
                        }
                    }
                    if (emailSubject === undefined) {
                        setEmailSubject(taskResultActionData.Subject ?? "");
                    }
                    if (emailBody === undefined) {
                        setEmailBody(taskResultActionData.Body ?? "");
                    }
                    if (emailBodyIsHtml === undefined) {
                        setEmailBodyIsHtml(taskResultActionData.BodyIsHtml === true);
                    }
                    if (emailIncludeAttachment === undefined) {
                        setEmailIncludeAttachment(taskResultActionData.AttachmentFilename && taskResultActionData.AttachmentFilename !== "");
                    }
                    if (sendWithoutAttachmentIfNotExists === undefined) {
                        setSendWithoutAttachmentIfNotExists(taskResultActionData.SendWithoutAttachmentIfNotExists === true);
                    }
                    if (attachmentFilename === undefined) {
                        setAttachmentFilename(taskResultActionData.AttachmentFilename ?? "");
                    }
                    if (attachmentProcessFilenameKey === undefined) {
                        setAttachmentProcessFilenameKey(taskResultActionData.AttachmentProcessFilenameKey ?? undefined);
                    }
                    if (convertAttachmentFromJsonToCsv === undefined) {
                        setConvertAttachmentFromJsonToCsv(taskResultActionData.ConvertAttachmentFromJsonToCsv === true);
                    }
                    if (emailFilterExtendedRecordsWhereNotComplete === undefined) {
                        setEmailFilterExtendedRecordsWhereNotComplete(taskResultActionData.FilterExtendedRecordsWhereNotComplete === true);
                    }
                } else if (taskResultActionData.$type === "MIP.Provisioning.TaskResultActions.CopyFileTaskResultAction, Provisioning") {
                    setTaskResultActionType("CopyFileTaskResultAction");
                    if (copyFileFilterExtendedRecordsWhereNotComplete === undefined) {
                        setCopyFileFilterExtendedRecordsWhereNotComplete(taskResultActionData.FilterExtendedRecordsWhereNotComplete === true);
                    }
                    if (copyFileConvertFromJsonToCsv === undefined) {
                        setCopyFileConvertFromJsonToCsv(taskResultActionData.ConvertFromJsonToCsv === true);
                    }
                    if (copyFileDestinationObjectKey === undefined) {
                        setCopyFileDestinationObjectKey(taskResultActionData.DestinationObjectKey ?? "");
                    }
                    if (copyFileProcessFilenameKey === undefined) {
                        setCopyFileProcessFilenameKey(taskResultActionData.ProcessFilenameKey ?? "");
                    }
                } else if (taskResultActionData.$type === "MIP.Provisioning.TaskResultActions.QueueProcessTaskResultAction, Provisioning") {
                    setTaskResultActionType("QueueProcessTaskResultAction");
                    if (provisionedProcessIdentifierToQueue === undefined) {
                        setProvisionedProcessIdentifierToQueue(taskResultActionData.ProvisionedProcessIdentifierToQueue ?? "");
                    }
                }
            }
            if (conditionsState.length === 0 && taskResultActionData.Conditions) {
                conditionsStateDispatch({type: "setInitialConditions", payload: taskResultActionData.Conditions});
            }
        }
    }, [
        params.taskResultActionIdentifier,
        taskResultActionType,
        provisionedProcessData,
        emailDistributionListsData,
        settingsData,
        provisionedProcess,
        processesByProcessId,
        emailDistributionListIdentifier,
        taskResultActionData,
        fromAddress,
        emailSubject,
        emailBody,
        emailBodyIsHtml,
        emailIncludeAttachment,
        convertAttachmentFromJsonToCsv,
        emailFilterExtendedRecordsWhereNotComplete,
        sendWithoutAttachmentIfNotExists,
        attachmentFilename,
        attachmentProcessFilenameKey,
        conditionsState,
        copyFileConvertFromJsonToCsv,
        copyFileFilterExtendedRecordsWhereNotComplete,
        copyFileDestinationObjectKey,
        copyFileProcessFilenameKey,
        provisionedProcessIdentifierToQueue
    ]);

    useLayoutEffect(() => {
        // this is setup to only run once the provisionedProcess and taskResultAction type
        // have been setup. I.e. not on the first run of an edit/new page.
        // This is here to catch if it was not part of the previously edited task action
        // or if the selected option is no longer there because the process changed.
        if (provisionedProcess && taskResultActionType && processesByProcessId[provisionedProcess]) {
            const allProcessFileNameKeys = [...Object.keys(processesByProcessId[provisionedProcess].ProcessFileObjectKeys), "InvalidFile"];
            if (attachmentProcessFilenameKey === undefined || !allProcessFileNameKeys.includes(attachmentProcessFilenameKey)) {
                const [ firstProcessKey ] = allProcessFileNameKeys;
                setAttachmentProcessFilenameKey(firstProcessKey);
            }
        }
    }, [
        provisionedProcess,
        taskResultActionType,
        processesByProcessId,
        attachmentProcessFilenameKey
    ]);

    useLayoutEffect(() => {
        if (params.taskResultActionIdentifier.toLowerCase() === "new" && Array.isArray(emailDistributionListsData?.Data) && emailDistributionListsData.Data.length !== 0 && emailDistributionListIdentifier === undefined) {
            setEmailDistributionListIdentifier(emailDistributionListsData.Data[0].Identifier);
        }
    }, [params.taskResultActionIdentifier, emailDistributionListsData, emailDistributionListIdentifier]);

    useLayoutEffect(() => {
        if (params.taskResultActionIdentifier.toLowerCase() === "new" && settingsData && fromAddress === undefined) {
            setFromAddress("Default");
        }
    }, [params.taskResultActionIdentifier, settingsData, fromAddress]);

    useLayoutEffect(() => {
        if (params.taskResultActionIdentifier.toLowerCase() === "new" && Array.isArray(provisionedProcessData) && provisionedProcessData.length > 0) {
            if (provisionedProcess === undefined) {
                setProvisionedProcess(provisionedProcessData[0].Identifier);
            }
            if (provisionedProcess) {
                const allProcessFileNameKeys = [...Object.keys(processesByProcessId[provisionedProcess].ProcessFileObjectKeys), "InvalidFile"];
                if (attachmentProcessFilenameKey === undefined) {
                    const [ firstProcessKey ] = allProcessFileNameKeys;
                    setAttachmentProcessFilenameKey(firstProcessKey);
                }
                if (copyFileProcessFilenameKey === undefined) {
                    const [ firstProcessKey ] = allProcessFileNameKeys;
                    setCopyFileProcessFilenameKey(firstProcessKey);
                }
                if (provisionedProcessIdentifierToQueue === undefined) {
                    setProvisionedProcessIdentifierToQueue(processesByProcessId[provisionedProcess].Identifier);
                }
            }
        }
    }, [
        params.taskResultActionIdentifier,
        provisionedProcessData,
        provisionedProcess,
        processesByProcessId,
        attachmentProcessFilenameKey,
        copyFileProcessFilenameKey,
        provisionedProcessIdentifierToQueue
    ]);

    const pageTitle = newMode
        ? "Create Task Result Action"
        : "Edit Task Result Action";

    useEffect(() => {
        document.title = `Settings - ${  pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (
                (taskResultActionError && taskResultActionError.status === 401) || 
                (settingsError && settingsError.status === 401) || 
                (emailDistributionListsError && emailDistributionListsError.status === 401) ||
                (provisionedProcessAPIError && provisionedProcessAPIError.status === 401)
            ) {
            window.location.href="/login";
        }

    }, [taskResultActionError, settingsError, emailDistributionListsError, provisionedProcessAPIError]);

    const getValidationErrors = () => {
        const errors = [];
        if (taskResultActionType === "PostPutEmailTaskResultAction") {
            if (emailBody === "") {
                errors.push("Please add valid email body.");
            }
            if (emailSubject === "") {
                errors.push("Please add valid email subject.");
            }
            if (emailIncludeAttachment && attachmentFilename === "") {
                errors.push("Attachment filename is required when including an attachment.");
            }
        } else if (taskResultActionType === "CopyFileTaskResultAction") {
            if (copyFileDestinationObjectKey === "") {
                errors.push("Please add valid destination object key.");
            }
            if (!copyFileDestinationObjectKey.startsWith("sftp/") && !copyFileDestinationObjectKey.startsWith("mip/temp/")) {
                errors.push("Destination object key must start with sftp/ or mip/temp/.");
            }
        } else if (taskResultActionType === "QueueProcessTaskResultAction") {
            if (provisionedProcess === provisionedProcessIdentifierToQueue) {
                errors.push("Really? A process queueing itself. Did you forget we get charged by the CPU cycle?");
            }
        }
        for (const conditionIndex in conditionsState) {
            if (conditionsState[conditionIndex].propertyName === "Metadata" && conditionsState[conditionIndex].metadataKey === "") {
                errors.push(`Check the metadata key value of condition #${ parseInt(conditionIndex) + 1 }`);
            }
        }
        return errors;
    };

    const validateAndShowConfirmModal = (windowId) => {
        setInvalidTaskResultActionsOptions(undefined);
        const errors = getValidationErrors();
        if (errors.length > 0) {
            setInvalidTaskResultActionsOptions(errors);
        } else {
            setConfirmUpdateModalDisplayed(windowId);
        }
    };

    if (!newMode && !taskResultActionIsFetching && !taskResultActionData) {
        return <NotFound message="Task Result Action Not Found" />;
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="task-result-actions" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1">
                            <FontAwesomeIcon icon={faCog}/> {pageTitle}
                        </div>
                    </div>
                    {
                        provisionedProcessAPIError || taskResultActionError || settingsError || emailDistributionListsError
                            ? <ErrorTile message={provisionedProcessAPIError || taskResultActionError || settingsError || emailDistributionListsError} />
                            : provisionedProcessDataFetching || settingsIsFetching || taskResultActionIsFetching || emailDistributionListsIsFetching
                                ? <LoadingSpinner text={"Loading Task Result Action Data"} />
                                : !newMode && !taskResultActionData
                                    ?   <div>Task result action not found</div>
                                    :   Array.isArray(provisionedProcessData) && provisionedProcessData.length === 0
                                        ? <div>Task Result Actions require a Process</div>
                                        :   <>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="provisionedProcess">
                                                        Process
                                                    </label>
                                                    <select id="provisionedProcess" name="provisionedProcess" value={provisionedProcess} onChange={(e) => setProvisionedProcess(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                        {
                                                            provisionedProcessData.map((process) => <option value={process.Identifier} key={process.Identifier}>{process.Name}</option>)
                                                        }
                                                    </select>
                                                </div>
                                                <div className="my-3">
                                                    <span className="block text-grey-800 text-sm font-bold align-middle mb-2">
                                                        Conditions
                                                    </span>
                                                    <div>
                                                        {
                                                            conditionsState && conditionsState.length > 0
                                                                ? conditionsState.map((condition, index) => <TaskResultActionsFormCondition key={index} conditionIndex={index} condition={condition} conditionsStateDispatch={conditionsStateDispatch} process={selectedProcess} settingsData={settingsData} />)
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="mb-4 flex justify-end text-sm">
                                                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => {conditionsStateDispatch({type: "addCondition", payload: undefined});}}><FontAwesomeIcon icon={faPlus} /> Add a Condition</button>
                                                    </div>
                                                </div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="taskResultActionType">
                                                        Task Result Action Type
                                                    </label>
                                                    <select id="taskResultActionType" name="taskResultActionType" value={taskResultActionType} onChange={(e) => setTaskResultActionType(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                        {
                                                            Object.keys(taskResultActionTypes).map((typeKey) => <option value={typeKey} key={typeKey}>{taskResultActionTypes[typeKey]}</option>)
                                                        }
                                                    </select>
                                                </div>
                                                {
                                                    taskResultActionType === "PostPutEmailTaskResultAction"
                                                        ?   <>
                                                                <div>
                                                                    <div className="my-3">
                                                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="fromAddress">
                                                                            From Address
                                                                        </label>
                                                                        <select id="fromAddress" name="fromAddress" value={fromAddress} onChange={(e) => setFromAddress(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                                            <option key="Default" value="Default">Default</option>
                                                                            {
                                                                                settingsData && settingsData.SESFromAddresses.map((possibleAddress, index) => <option value={possibleAddress.Value} key={index}>{possibleAddress.Key}</option>)
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className="my-3">
                                                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="emailDistributionListIdentifier">
                                                                            Email Distribution List (Recipient)
                                                                        </label>
                                                                        <select id="emailDistributionListIdentifier" name="emailDistributionListIdentifier" value={emailDistributionListIdentifier} onChange={(e) => setEmailDistributionListIdentifier(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                                            {
                                                                                emailDistributionListsData && emailDistributionListsData.Data.map((distributionList, index) => <option value={distributionList.Identifier} key={index}>{distributionList.Name}</option>)
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    <div className="my-3">
                                                                        <div className="mb-2">
                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="emailSubject">
                                                                                Subject
                                                                            </label>
                                                                            <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowSubjectHint(!showSubjectHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>
                                                                        </div>
                                                                        {
                                                                            showSubjectHint && <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{"Placeholders may be used for {{EndTime}}, {{ErrorMessage}}, {{ProvisionedProcessIdentifier}}, {{StartTime}}, {{Success}}, {{TaskChainInstance}}, {{TaskIdentifier}}, or any Metadata Key: {{BusinessUnitEntityKey}}, {{BusinessUnitIdentifier}}, {{DailyPassIdentifiers}}, {{ExternalEndpoint}}, {{ExternalErrorMessage}}, {{Identifiers}}, {{InvalidFile}}, {{MissingIdentifiers}}, {{MobileDeviceEntityKeys}}, {{NewIdentifiers}}, {{OrderedDailyPassIdentifiers}}, {{OrderIdentifiers}}, {{RecurringOrderIdentifiers}}, {{RegionEntityKey}}, {{RegionIdentifier}}, {{RetrieveType}}, {{RouteIdentifiers}}, {{RoutingSessionDates}}, {{RoutingSessionDescriptions}}, {{RoutingSessionEntityKeys}}, {{RoutingSessionModes}}, {{StatusCode}}, {{UnassignedOrderGroupEntityKeys}}."}</div>
                                                                        }
                                                                        <input type="text" id="emailSubject" name="emailSubject" value={emailSubject ?? ""} onChange={(e) => setEmailSubject(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                                    </div>
                                                                    <div className="my-3">
                                                                        <div className="mb-2">
                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="emailBody">
                                                                                Body
                                                                            </label>
                                                                            <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowBodyHint(!showBodyHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>
                                                                        </div>
                                                                        {
                                                                            showBodyHint && <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{"Placeholders may be used for {{EndTime}}, {{ErrorMessage}}, {{ProvisionedProcessIdentifier}}, {{StartTime}}, {{Success}}, {{TaskChainInstance}}, {{TaskIdentifier}}, or any Metadata Key: {{BusinessUnitEntityKey}}, {{BusinessUnitIdentifier}}, {{DailyPassIdentifiers}}, {{ExternalEndpoint}}, {{ExternalErrorMessage}}, {{Identifiers}}, {{InvalidFile}}, {{MissingIdentifiers}}, {{MobileDeviceEntityKeys}}, {{NewIdentifiers}}, {{OrderedDailyPassIdentifiers}}, {{OrderIdentifiers}}, {{RecurringOrderIdentifiers}}, {{RegionEntityKey}}, {{RegionIdentifier}}, {{RetrieveType}}, {{RouteIdentifiers}}, {{RoutingSessionDates}}, {{RoutingSessionDescriptions}}, {{RoutingSessionEntityKeys}}, {{RoutingSessionModes}}, {{StatusCode}}, {{UnassignedOrderGroupEntityKeys}}."}</div>
                                                                        }
                                                                        <textarea id="emailBody" name="emailBody" value={emailBody ?? ""} onChange={(e) => setEmailBody(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3 h-32"></textarea>
                                                                        <div className="mb-2">
                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="emailBodyIsHtml">
                                                                                <input type="checkbox" value={emailBodyIsHtml || false} name="emailBodyIsHtml" id="emailBodyIsHtml" checked={emailBodyIsHtml ? "checked" : ""} onChange={(e) => {setEmailBodyIsHtml(e.target.checked);}} className="mr-2 align-middle" />
                                                                                <span className="text-grey-800 mb-4 align-middle">Body is HTML</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="my-3">
                                                                        <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="emailIncludeAttachment">
                                                                            <input type="checkbox" value={emailIncludeAttachment || false} name="emailIncludeAttachment" id="emailIncludeAttachment" checked={emailIncludeAttachment ? "checked" : ""} onChange={(e) => {setEmailIncludeAttachment(e.target.checked);}} className="mr-2 align-middle" />
                                                                            <span className="text-grey-800 mb-4 align-middle">Include an Attachment</span>
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        emailIncludeAttachment === true
                                                                            ?   <>
                                                                                    <div className="my-3">
                                                                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="attachmentProcessFilenameKey">
                                                                                            Attachment File
                                                                                        </label>
                                                                                        <select id={`attachmentProcessFilenameKey-${ props.index }`} name={`attachmentProcessFilenameKey-${ props.index }`} value={attachmentProcessFilenameKey} onChange={(e) => setAttachmentProcessFilenameKey(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                                                            {
                                                                                                selectedProcess?.ProcessFileObjectKeys && [...Object.keys(selectedProcess.ProcessFileObjectKeys), "InvalidFile"].sort().map((key) => <option value={key} key={key}>{key}</option>)
                                                                                            }
                                                                                        </select>
                                                                                        <div className="mb-2">
                                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="convertAttachmentFromJsonToCsv">
                                                                                                <input type="checkbox" value={convertAttachmentFromJsonToCsv || false} name="convertAttachmentFromJsonToCsv" id="convertAttachmentFromJsonToCsv" checked={convertAttachmentFromJsonToCsv ? "checked" : ""} onChange={(e) => {setConvertAttachmentFromJsonToCsv(e.target.checked);}} className="mr-2 align-middle" />
                                                                                                <span className="text-grey-800 mb-4 align-middle">Convert Attachment from JSON to CSV</span>
                                                                                                {
                                                                                                    attachmentProcessFilenameKey && convertAttachmentFromJsonToCsv && !selectedProcess?.ProcessFileObjectKeys[attachmentProcessFilenameKey]?.endsWith(".json")
                                                                                                        ?   <span className="text-orange-500 pl-4">Double check object key is JSON.</span>
                                                                                                        : null
                                                                                                }
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="mb-2">
                                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="sendWithoutAttachmentIfNotExists">
                                                                                                <input type="checkbox" value={sendWithoutAttachmentIfNotExists || false} name="sendWithoutAttachmentIfNotExists" id="sendWithoutAttachmentIfNotExists" checked={sendWithoutAttachmentIfNotExists ? "checked" : ""} onChange={(e) => {setSendWithoutAttachmentIfNotExists(e.target.checked);}} className="mr-2 align-middle" />
                                                                                                <span className="text-grey-800 mb-4 align-middle">Send Without Attachment If Not Exists</span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="mb-2">
                                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="emailFilterExtendedRecordsWhereNotComplete">
                                                                                                <input type="checkbox" value={emailFilterExtendedRecordsWhereNotComplete || false} name="emailFilterExtendedRecordsWhereNotComplete" id="emailFilterExtendedRecordsWhereNotComplete" checked={emailFilterExtendedRecordsWhereNotComplete ? "checked" : ""} onChange={(e) => {setEmailFilterExtendedRecordsWhereNotComplete(e.target.checked);}} className="mr-2 align-middle" />
                                                                                                <span className="text-grey-800 mb-4 align-middle">Filter Extended Records Where Not Complete</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="my-3">
                                                                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="attachmentFilename">
                                                                                            Attachment Filename
                                                                                        </label>
                                                                                        <input type="text" id="attachmentFilename" name="attachmentFilename" value={attachmentFilename ?? ""} onChange={(e) => setAttachmentFilename(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                                                    </div>
                                                                                </>
                                                                            : null
                                                                    }
                                                                </div>
                                                            </>
                                                        : null
                                                    }
                                                    {
                                                        taskResultActionType === "CopyFileTaskResultAction"
                                                            ?   <>
                                                                    <div>
                                                                        <div className="my-3">
                                                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="copyFileProcessFilenameKey">
                                                                                Process Filename Key
                                                                            </label>
                                                                            <select id="copyFileProcessFilenameKey" name="copyFileProcessFilenameKey" value={copyFileProcessFilenameKey} onChange={(e) => setCopyFileProcessFilenameKey(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                                                {
                                                                                    selectedProcess?.ProcessFileObjectKeys && Object.keys(selectedProcess.ProcessFileObjectKeys).map((key) => <option value={key} key={key}>{key}</option>)
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="my-3">
                                                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="copyFileDestinationObjectKey">
                                                                                Destination Object Key
                                                                            </label>
                                                                            <input type="text" id="copyFileDestinationObjectKey" name="copyFileDestinationObjectKey" value={copyFileDestinationObjectKey ?? ""} onChange={(e) => setCopyFileDestinationObjectKey(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                                        </div>
                                                                        <div className="my-3">
                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="copyFileConvertFromJsonToCsv">
                                                                                <input type="checkbox" value={copyFileConvertFromJsonToCsv || false} name="copyFileConvertFromJsonToCsv" id="copyFileConvertFromJsonToCsv" checked={copyFileConvertFromJsonToCsv ? "checked" : ""} onChange={(e) => {setCopyFileConvertFromJsonToCsv(e.target.checked);}} className="mr-2 align-middle" />
                                                                                <span className="text-grey-800 mb-4 align-middle">Convert from JSON to CSV</span>
                                                                            </label>
                                                                        </div>
                                                                        <div className="my-3">
                                                                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="copyFileFilterExtendedRecordsWhereNotComplete">
                                                                                <input type="checkbox" value={copyFileFilterExtendedRecordsWhereNotComplete || false} name="copyFileFilterExtendedRecordsWhereNotComplete" id="copyFileFilterExtendedRecordsWhereNotComplete" checked={copyFileFilterExtendedRecordsWhereNotComplete ? "checked" : ""} onChange={(e) => {setCopyFileFilterExtendedRecordsWhereNotComplete(e.target.checked);}} className="mr-2 align-middle" />
                                                                                <span className="text-grey-800 mb-4 align-middle">Filter Extended Records where not Complete</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            : null
                                                    }
                                                    {
                                                        taskResultActionType === "QueueProcessTaskResultAction"
                                                            ?   <>
                                                                    <div>
                                                                        <div className="my-3">
                                                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="provisionedProcessIdentifierToQueue">
                                                                                Process to Queue
                                                                            </label>
                                                                            <select id="provisionedProcessIdentifierToQueue" name="provisionedProcessIdentifierToQueue" value={provisionedProcessIdentifierToQueue} onChange={(e) => setProvisionedProcessIdentifierToQueue(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                                            {
                                                                                provisionedProcessData.map((process) => (
                                                                                        !process.IsChainLink && <option value={process.Identifier} key={process.Identifier}>{process.Name}</option>
                                                                                ))
                                                                            }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            : null
                                                    }
                                                    {
                                                        invalidTaskResultActionsOptions 
                                                            ? <ErrorTile message={invalidTaskResultActionsOptions} />
                                                            : createTaskResultActionsState && createTaskResultActionsState.isError
                                                                ? <ErrorTile message={createTaskResultActionsState.error?.data?.Message} />
                                                                : null
                                                    }
                                                    <div className="flex justify-evenly">
                                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/task-result-actions")}>Back to Task Result Actions</button>
                                                        {
                                                            newMode
                                                                ?   <button data-test-id="add-task-result-action-button" disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postTaskResultActions}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add Task Result Action"}</button>
                                                                :   <>
                                                                        <button data-test-id="provision-as-new-task-result-action-button" disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postTaskResultActions}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Provision as New Task Result Action"}</button>
                                                                        <button data-test-id="update-task-result-action-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => validateAndShowConfirmModal(params.taskResultActionIdentifier)}>Update Task Result Action</button>
                                                                    </>
                                                        }
                                                    </div>
                                                </> 
                    }
                </div>
                {
                    taskResultActionData && confirmUpdateModalDisplayed
                        ?   <ConfirmModal 
                                title="Confirm Update of Task Result Action"
                                submitButtonTitle="Update"
                                identifier={taskResultActionData.Identifier}
                                confirmationMessage="Are you sure you want to update this Task Result Action?"
                                successMessage="Task Result Action updated successfully."
                                failureMessage="Task Result Action failed to update:"
                                closeModal={() => setConfirmUpdateModalDisplayed(undefined)}
                                confirmFunction={confirmUpdate}
                            /> 
                        :   null
                }
            </div>
        </div>
        </>
    );
}