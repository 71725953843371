import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../../Common/ErrorTile";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ProvsionedProcessesTableRow from "../ProvisionedProcessesTableRow";

export default function AvailableProcessesTable(props) {
    
    const allProcesses = Array.isArray(props.data) ? props.data : [];
    const processesByIdentifier = {};
    for (const process of allProcesses) {
        processesByIdentifier[process.Identifier] = process;
    }

    // we will eventually re-arrange this and/or filter it to not show linked process
    const dataToDisplay = [];
    const processesDisplayed = [];

    const getDataToDisplayRecursively = (process, dataToDisplay, processesDisplayed) => {
        dataToDisplay.push(process);
        processesDisplayed.push(process.Identifier);
        // if it has a chained process identifier and that process exists, then add it
        if (process.ChainedProcessIdentifier && processesByIdentifier[process.ChainedProcessIdentifier] && processesByIdentifier[process.ChainedProcessIdentifier].IsChainLink) {
            // add the child here.
            const processCopy = {...processesByIdentifier[process.ChainedProcessIdentifier]};
            processCopy.ParentIsDeployed = process.IsDeployed;
            getDataToDisplayRecursively(processCopy, dataToDisplay, processesDisplayed);
        }
    };

    // loop over the processes the first time. If they are not a chain link
    // add them, and also keep track of which process are linked to.
    const processesThatAreLinkedTo = [];
    for (const process of allProcesses) {
        if (!process.IsChainLink) {
            getDataToDisplayRecursively(process, dataToDisplay, processesDisplayed);
        }
        if (process.ChainedProcessIdentifier !== "00000000-0000-0000-0000-000000000000") {
            processesThatAreLinkedTo.push(process.ChainedProcessIdentifier);
        }
    }

    // now go back through any that are not displayed and also are not linked to.
    // this will catch the top of chains that aren't finished.
    for (const process of allProcesses) {
        if (!processesDisplayed.includes(process.Identifier) && !processesThatAreLinkedTo.includes(process.Identifier)) {
            const processCopy = {...process};
            processCopy.IsNotLinked = true;
            getDataToDisplayRecursively(processCopy, dataToDisplay, processesDisplayed);
        }
    }

    // finally, are there any left that are not displayed yet.
    // this is because if we set a process to linked but haven't linked it yet it won't show up.
    for (const process of allProcesses) {
        if (!processesDisplayed.includes(process.Identifier)) {
            const processCopy = {...process};
            processCopy.IsNotLinked = true;
            dataToDisplay.push(processCopy);
        }
    }

    let highlight = false;
    const toggleHighlight = () => {
        highlight = !highlight;
        return highlight;
    };

    return (
        <div className="mx-auto bg-white rounded-lg p-4 mb-4">
        {
            props.error
                ? <ErrorTile message={props.error} />
                : props.fetching
                    ?   <LoadingSpinner text="Loading Provisioned Processes" />
                    :   <>
                            <div className="text-2xl pb-4"><FontAwesomeIcon icon={faServer} /> Provisioned Processes</div>
                            {
                                dataToDisplay.length === 0
                                    ?   <div className="text-center">No provisioned processes.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "minmax(20rem, auto) auto repeat(2, min-content)"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Type</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Deployed</div>
                                            <div data-test-id="provisioned-processes" className="contents">
                                            {
                                                dataToDisplay.map((item, index) => (
                                                    <div data-test-id="provisioned-process" className="contents" key={`provisioned-process-${ index }`}>
                                                        <ProvsionedProcessesTableRow
                                                            key={index}
                                                            item={item}
                                                            highlight={!props.chainedProcesses.includes(item.Identifier) ? toggleHighlight() : highlight}
                                                            deprovisionModal={props.deprovisionModal}
                                                            deployModal={props.deployModal}
                                                            undeployModal={props.undeployModal}
                                                            queueModal={props.queueModal}
                                                            queueAPIModal={props.queueAPIModal}
                                                            createManualProcessModal={props.createManualProcessModal}
                                                            chainedProcesses={props.chainedProcesses}
                                                            hasAdminPrivilege={props.hasAdminPrivilege}
                                                            hasLogsAccess={props.hasLogsAccess}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                            }
                        </>
        }
        </div>
    );
}
