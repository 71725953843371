import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { formatDateAsISO } from "../../../midgard.js";
import LogsTableRowDetailsRow from "../LogsTableRowDetailsRow";

export default function LogsTableRow(props) {

    const [expandMessage, setExpandMessage] = useState(false);

    const timestamp = props.useUTC
        ? props.entry.Timestamp
        : formatDateAsISO(new Date(`${ props.entry.Timestamp }Z`));
    const [timestampDatePart, timestampTimePart] = timestamp.split("T");

    return (
        <div className="contents group">
            <div className="p-1 border group-odd:bg-gray-100">
                <div>{timestampDatePart}</div>
                <div>{timestampTimePart.substring(0,8)}</div>
            </div>
            <div className="p-1 border group-odd:bg-gray-100">{props.entry.LogLevel.toUpperCase()}</div>
            <div className="p-1 border group-odd:bg-gray-100">
                <div>
                    <LogsTableRowDetailsRow
                        longName="Lambda Function Name"
                        shortName="Fn"
                        fullValue={props.entry.LambdaFunctionName}
                        displayValue={props.entry.LambdaFunctionName.replace("MIP-Test-", "").replace("MIP-", "")}
                        filterFunction={props.setLambdaFnFilter}
                    />
                    {
                        props.entry.TaskIdentifier !== null
                            ?   <LogsTableRowDetailsRow
                                    longName="Task Identifier"
                                    shortName="TI"
                                    fullValue={props.entry.TaskIdentifier}
                                    displayValue={props.entry.TaskIdentifier}
                                    filterFunction={props.setTaskIdentifierFilter}
                                />
                            : null
                    }
                    {
                        props.entry.TaskChainInstance !== null
                            ?   <LogsTableRowDetailsRow
                                    longName="Task Chain Instance"
                                    shortName="TCI"
                                    fullValue={props.entry.TaskChainInstance}
                                    displayValue={props.entry.TaskChainInstance}
                                    filterFunction={props.setTaskChainInstanceFilter}
                                />
                            :   null
                    }
                    {
                        props.entry.LambdaRequestIdentifier !== null
                            ?   <LogsTableRowDetailsRow
                                    longName="Lambda Request Identifier"
                                    shortName="LRI"
                                    fullValue={props.entry.LambdaRequestIdentifier}
                                    displayValue={props.entry.LambdaRequestIdentifier}
                                    filterFunction={props.setLambdaRequestIdentifierFilter}
                                    queryByLambdaRequestIdentifier={props.queryByLambdaRequestIdentifier}
                                />
                            :   null
                    }
                    
                    {
                        props.entry.CognitoEmail !== ""
                            ? <div>Em: {props.entry.CognitoEmail}</div>
                            : null
                    }
                    
                </div>
            </div>
            <div className="p-1 border group-odd:bg-gray-100">
                {
                    props.expandAll || expandMessage || props.entry.Message.length < 200
                        ?   <div>{props.entry.Message}</div>
                        :   <div className="flex">
                                <div className="flex-l">{ props.entry.Message.substring(0,Math.min(props.entry.Message.length, 200)) }</div>
                                <div className="hover:cursor-pointer"><FontAwesomeIcon icon={faExpandAlt} onClick={() => setExpandMessage(true)} /></div>
                            </div>
                }
            </div>
        </div>
    );
}