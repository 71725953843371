import ProvisionableItemDataApplicator from "../ProvisionableItemDataApplicator";
import { checkConditions, getFieldType, geValuesApiUrl } from "../../../midgard.js";
import { usePrefetch } from "../../../services/mip";
import { useLayoutEffect, useMemo } from "react";


export default function ProvisionableItemDataCollector(props) {

    const prefetchExternalData = usePrefetch("getExternalData");

    // this loop is just to find the DataSelector if there is one.
    // there will only ever be one by convention
    let dataSelectorField;
    for (const form of props.formsToDisplay) {
        if (dataSelectorField) {
            continue;
        }
        for (const field of form.Fields) {
            if (dataSelectorField) {
                continue;
            }
            if (checkConditions(field.Conditions, props.formState)) {
                const hasApi = field.GetValuesApi !== undefined && field.GetValuesApi !== null && field.GetValuesApi !== "";
                if (hasApi) {
                    const externalDataApiUrl = geValuesApiUrl(field, props.formState, "edit", props.provisionableItem.Identifier);
                    if (externalDataApiUrl !== null) {
                        const fieldType = getFieldType(field);
                        if (fieldType === "Import.DataSelector" || fieldType === "DataSelector") {
                            dataSelectorField = field;
                        }
                    }
                }
            }
        }
    }


    const urlsToPrefetch = useMemo(() => {
        const urls = [];
        for (const form of props.formsToDisplay) {
            for (const field of form.Fields) {
                if (checkConditions(field.Conditions, props.formState)) {
                    const hasApi = field.GetValuesApi !== undefined && field.GetValuesApi !== null && field.GetValuesApi !== "";
                    if (hasApi) {
                        const externalDataApiUrl = geValuesApiUrl(field, props.formState, "edit", props.provisionableItem.Identifier);
                        if (externalDataApiUrl !== null) {
                            const fieldType = getFieldType(field);
                            // we don't load DataSelectors because that is handled in the DataApplicator
                            // at the next level down.
                            if (["edit", "view"].includes(props.provisionMode) && fieldType !== "Import.DataSelector" && fieldType !== "DataSelector") {
                                urls.push(externalDataApiUrl);
                            }
                        }
                    }
                }
            }
        }
        return urls;
    }, [props.formsToDisplay, props.formState, props.provisionMode, props.provisionableItem]);

    useLayoutEffect(() => {
        for (const externalDataApiUrl of urlsToPrefetch) {
            prefetchExternalData(externalDataApiUrl, true);
        }
    }, [urlsToPrefetch, prefetchExternalData]);

    return (
        <ProvisionableItemDataApplicator
            provisionableItem={props.provisionableItem}
            provisionMode={props.provisionMode}
            provisionableItemType={props.provisionableItemType}
            formsToDisplay={props.formsToDisplay}
            formState={props.formState}
            formStateDispatch={props.formStateDispatch}
            dataSelectorField={dataSelectorField}
            availableProvisionableItemWhenEditing={props.availableProvisionableItemWhenEditing}
        />
    );



}