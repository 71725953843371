import { JsonType } from "../../../midgard.js";
import { useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faArrowUp, faArrowDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select, { createFilter } from "react-select";
import GeneralDataSelectorFieldTimeZoneSelector from "../GeneralDataSelectorFieldTimeZoneSelector";
import GeneralDataSelectorFieldDataMapSelector from "../GeneralDataSelectorFieldDataMapSelector";
import GeneralDataSelectorStringConcatInfo from "../GeneralDataSelectorStringConcatInfo";
import GeneralDataSelectorCalculationsInfo from "../GeneralDataSelectorCalculationsInfo";
import { useGetSettingsQuery } from "../../../services/mip";
import GeneralDataSelectorFormatStringControl from "../GeneralDataSelectorFormatStringControl";

export default function ExportDataSelectorField(props) {   

    const fieldNumber = props.index + 1;
    const upDisabled = props.index === 0;
    const downDisabled = props.index + 1 === props.numberOfFields;

    const [expandAdditionalOptions, setExpandAdditionalOptions] = useState(props.field.formatOption !== "None" || props.field.applyCalculation || props.field.applyStringConcatenations || props.field.useDataMaps ? true : false);

    // I don't know why we need this here but not in the Import.DataSelectorField.
    useLayoutEffect(() => {
        if (!expandAdditionalOptions && (props.field.formatOption !== "None" || props.field.applyCalculation || props.field.useDataMaps)) {
            setExpandAdditionalOptions(true);
        }
    }, [props.field.formatOption, props.field.applyCalculation, props.field.useDataMaps, expandAdditionalOptions]);

    const columnsInDataFile = [];
    for (const selectOption of props.entityPropertyOptions) {
        columnsInDataFile.push(selectOption.value);
    }

    const {
        data: settingsData
    } = useGetSettingsQuery(null);
    const hasDataMaps = settingsData && settingsData.DataMaps && Array.isArray(settingsData.DataMaps) && settingsData.DataMaps.length > 0;
    const dataMaps = hasDataMaps ? settingsData.DataMaps : undefined;

    const propertyKeyOptions = [];
    if (props.fieldEntityValue && Array.isArray(props.fieldEntityValue.PropertyKeyOptions)) {
        for (const propertyKey of props.fieldEntityValue.PropertyKeyOptions) {
            propertyKeyOptions.push(<option key={propertyKey.Value} value={propertyKey.Value}>{propertyKey.Key}</option>);
        }
    }

    // if we have property key options and don't have a default, set it to the first one in the list.
    useLayoutEffect(() => {
        if (props.fieldEntityValue && Array.isArray(props.fieldEntityValue.PropertyKeyOptions) && props.fieldEntityValue.PropertyKeyOptions.length > 0 && props.field.customPropertyKey === "") {
            props.setFieldValue(props.index, "customPropertyKey", props.fieldEntityValue.PropertyKeyOptions[0].Value);
        }
    }, [props]);

    return (
        <div className="mappedColumn shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t">
                <div className="flex-1 text-xl p-2 font-bold">Column {fieldNumber}</div>
                <div>
                {
                        upDisabled
                            ? null
                            : <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {props.onUpButtonClick(props.index);}}><FontAwesomeIcon icon={faArrowUp} /></button>
                    }
                    {
                        downDisabled
                            ? null
                            : <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {props.onDownButtonClick(props.index);}}><FontAwesomeIcon icon={faArrowDown} /></button>
                    }
                    <button className="bg-red-700 hover:bg-red-900 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {props.onDeleteClick(props.index);}}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>
            <div className="m-4">
                <div className="flex p-1">
                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`source-${  props.index }`}>
                        Source
                    </label>
                    <select name={`source-${ props.index }`} id={`source-${ props.index }`} value={props.field.source} onChange={(e) => {props.setFieldValue(props.index, "source", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                        <option value="Entity">Entity Property</option>
                        <option value="Constant">Constant Value</option>
                    </select>
                </div>
                {
                    props.field.source === "Entity"
                        ?   <>
                                <div className="flex p-1">
                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`entityProperty-${  props.index }`}>
                                        Property
                                    </label>
                                    <Select className="flex-1" id={`export-data-selector-select-field-${ props.index }`} filterOption={createFilter({ ignoreAccents: false })} isSearchable={true} name={`entityProperty-${ props.index }`} inputId={`entityProperty-${ props.index }`} value={{value: props.field.entityProperty, label: props.field.entityProperty}} options={props.entityPropertyOptions} defaultValue={props.entityPropertyOptions[0]} onChange={(e) => props.setFieldValue(props.index, "entityProperty", e.value)} />
                                </div>
                                {
                                    props.fieldEntityValue?.PropertyKeyOptions
                                        ?   <>
                                                <div className="flex p-1">
                                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`customPropertyKey-${  props.index }`}>
                                                        Property Key
                                                    </label>
                                                    <select name={`customPropertyKey-${ props.index }`} id={`customPropertyKey-${ props.index }`} value={props.field.customPropertyKey} onChange={(e) => {props.setFieldValue(props.index, "customPropertyKey", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                        {propertyKeyOptions}
                                                    </select>
                                                </div>
                                            </>
                                        : null
                                }
                                <div className="flex p-1">
                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`destinationPropertyOverride-${  props.index }`}>
                                        Destination
                                    </label>
                                    <input name={`destinationPropertyOverride-${  props.index }`} id={`destinationPropertyOverride-${  props.index }`} type='text' value={props.field.destinationPropertyOverride} onChange={(e) => {props.setFieldValue(props.index, "destinationPropertyOverride", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" placeholder="Optional" />
                                </div>
                            </>
                        : null
                }
                {
                    props.field.source === "Constant"
                        ?   <>
                            <div className="flex p-1">
                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`constantValue-${ props.index }`}>
                                    Value
                                </label>
                                <input name={`constantValue-${ props.index }`} id={`constantValue-${ props.index }`} type='text' value={props.field.constantValue} onChange={(e) => {props.setFieldValue(props.index, "constantValue", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                            </div>
                            <div className="flex p-1">
                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`destinationProperty-${  props.index }`}>
                                    Destination
                                </label>
                                <input name={`destinationProperty-${  props.index }`} id={`destinationProperty-${  props.index }`} type='text' value={props.field.destinationProperty} onChange={(e) => {props.setFieldValue(props.index, "destinationProperty", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" placeholder="Required" />
                            </div>
                            </>
                        : null
                }
                <div className="m-1 text-right">
                    <span className="text-sm text-grey-200 hover:cursor-pointer" onClick={() => setExpandAdditionalOptions(!expandAdditionalOptions)}>
                    {
                        expandAdditionalOptions 
                            ? <span data-test-id={`additionalOptions-${  props.index }`}>Additional Options <FontAwesomeIcon icon={faCaretDown} /></span>
                            : <span data-test-id={`additionalOptions-${  props.index }`}>Additional Options <FontAwesomeIcon icon={faCaretRight} /></span>
                    }
                    </span>
                </div>
                {
                    expandAdditionalOptions
                        ?   <>
                                <div className="flex p-1">
                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatOption-${ props.index }`}>
                                        Format Option
                                    </label>
                                    <select name={`formatOption-${  props.index }`} id={`formatOption-${  props.index }`} value={props.field.formatOption} onChange={(e) => {props.setFieldValue(props.index, "formatOption", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                        <option value="None">None</option>
                                        <option value="Format">Format</option>
                                        <option value="Parse">Parse</option>
                                        <option value="ParseThenFormat">Parse then Format</option>
                                    </select>
                                </div>
                                {
                                    props.field.formatOption !== "None"
                                        ?   <div className="flex p-1">
                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatTypeCode-${ props.index }`}>
                                                    Data Type
                                                </label>
                                                {
                                                    props.fieldEntityValue
                                                        ?   <select disabled={true} name={`formatTypeCode-${ props.index }`} id={`formatTypeCode-${ props.index }`} value={props.fieldEntityValue.JsonType} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                                {
                                                                    JsonType.map((jsonType) => <option value={jsonType} key={jsonType}>{jsonType}</option>)
                                                                }
                                                            </select>
                                                        :   <select name={`formatTypeCode-${ props.index }`} id={`formatTypeCode-${ props.index }`} value={props.field.formatTypeCode} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                                {
                                                                    JsonType.map((jsonType) => <option value={jsonType} key={jsonType}>{jsonType}</option>)
                                                                }
                                                            </select>
                                                }
                                            </div>
                                        : null
                                }
                                {   /* Parse String only appears on DateTimes */
                                    (props.fieldEntityValue?.JsonType === "DateTime" || props.field.formatTypeCode === "DateTime") && ["Parse", "ParseThenFormat"].includes(props.field.formatOption)
                                        ?   <>
                                                <div className="flex p-1">
                                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`parseString-${  props.index }`}>
                                                        Parse String
                                                    </label>
                                                    <input name={`parseString-${ props.index }`} id={`parseString-${ props.index }`} type='text' value={props.field.parseString} onChange={(e) => {props.setFieldValue(props.index, "parseString", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                                </div>
                                            </>
                                        : null
                                }
                                {   /* The format string (non-control version) is shown for datetimes and double */
                                    (["DateTime", "Double"].includes(props.fieldEntityValue?.JsonType) || ["DateTime", "Double"].includes(props.field.formatTypeCode)) && ["Format", "ParseThenFormat"].includes(props.field.formatOption)
                                        ?   <>
                                                <div className="flex p-1">
                                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatString-${  props.index }`}>
                                                        Format String
                                                    </label>
                                                    <input name={`formatString-${  props.index }`} id={`formatString-${  props.index }`} type='text' value={props.field.formatString} onChange={(e) => {props.setFieldValue(props.index, "formatString", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                                </div>
                                            </>
                                        :   null
                                }
                                {   /* The Format Control only Appears for Strings */
                                    (props.fieldEntityValue?.JsonType === "String" || props.field.formatTypeCode === "String") && ["Format", "ParseThenFormat"].includes(props.field.formatOption)
                                        ?   <div className="flex p-1">
                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatString-${  props.index }`}>
                                                    Format String
                                                </label>
                                                <GeneralDataSelectorFormatStringControl
                                                    key={`formatStringControl-${ props.index }`}
                                                    index={props.index}
                                                    field={props.field}
                                                    setFieldValue={props.setFieldValue}
                                                />
                                            </div>
                                        : null
                                }
                                {
                                    props.field.formatOption !== "None" && (props.fieldEntityValue?.JsonType === "DateTime" || props.field.formatTypeCode === "DateTime")
                                        ?   <>
                                                <div className="flex p-1">
                                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`sourceTimeZoneId-${ props.index }`}>
                                                        Source TZ
                                                    </label>
                                                    <GeneralDataSelectorFieldTimeZoneSelector
                                                        key={`sourceTimeZoneId-${ props.index }`}
                                                        formValueKey="sourceTimeZoneId"
                                                        htmlFieldName={`sourceTimeZoneId-${ props.index }`}
                                                        index={props.index}
                                                        field={props.field}
                                                        setFieldValue={props.setFieldValue}
                                                    />
                                                </div>
                                                <div className="flex p-1">
                                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`destinationTimeZoneId-${ props.index }`}>
                                                        Destination TZ
                                                    </label>
                                                    <GeneralDataSelectorFieldTimeZoneSelector
                                                        key={`destinationTimeZoneId-${ props.index }`}
                                                        formValueKey="destinationTimeZoneId"
                                                        htmlFieldName={`destinationTimeZoneId-${ props.index }`}
                                                        index={props.index}
                                                        field={props.field}
                                                        setFieldValue={props.setFieldValue}
                                                    />
                                                </div>
                                            </>
                                        : null
                                }
                                <div className="m-1 mb-2 pl-2">
                                    <label className="align-middle" htmlFor={`applyStringConcatenations-${  props.index }`}>
                                        <input type="checkbox" name={`applyStringConcatenations-${  props.index }`} id={`applyStringConcatenations-${  props.index }`} value={props.field.applyStringConcatenations || false} checked={props.field.applyStringConcatenations ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.index, "applyStringConcatenations", e.target.checked);}} className="mr-2 align-middle" />
                                        <span className="text-grey-800 align-middle">Concatenate Strings</span>
                                    </label>
                                </div>
                                {
                                    props.field.applyStringConcatenations
                                        ?   <GeneralDataSelectorStringConcatInfo
                                                key={`applyStringConcatenations-${ props.index }`}
                                                field={props.field}
                                                index={props.index}
                                                setFieldValue={props.setFieldValue}
                                                htmlFieldName={props.index}
                                                concatenations={props.field.concatenations}
                                                sourceOptions={columnsInDataFile}
                                            />
                                        :   null
                                }
                                <div className="m-1 mb-2 pl-2">
                                    <label className="align-middle" htmlFor={`applyCalculation-${  props.index }`}>
                                        <input type="checkbox" name={`applyCalculation-${  props.index }`} id={`applyCalculation-${  props.index }`} value={props.field.applyCalculation || false} checked={props.field.applyCalculation ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.index, "applyCalculation", e.target.checked);}} className="mr-2 align-middle" />
                                        <span className="text-grey-800 align-middle">Apply Calculation</span>
                                    </label>
                                </div>
                                {
                                    props.field.applyCalculation
                                        ?   <GeneralDataSelectorCalculationsInfo
                                                key={`applyCalculation-${  props.index }`}
                                                field={props.field}
                                                index={props.index}
                                                setFieldValue={props.setFieldValue}
                                                htmlFieldName={props.index}
                                                calculations={props.field.calculations}
                                                sourceOptions={columnsInDataFile}
                                            />
                                        :   null
                                }
                                {
                                    hasDataMaps
                                        ?   <>
                                                <div className="m-1 mb-2 pl-2">
                                                    <label className="align-middle" htmlFor={`useDataMaps-${  props.index }`}>
                                                        <input type="checkbox" name={`useDataMaps-${  props.index }`} id={`useDataMaps-${  props.index }`} value={props.field.useDataMaps || false} checked={props.field.useDataMaps ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.index, "useDataMaps", e.target.checked);}} className="mr-2 align-middle" />
                                                        <span className="text-grey-800 align-middle">Use Data Maps</span>
                                                    </label>
                                                </div>
                                            </>
                                        : null
                                }
                                {
                                    props.field.useDataMaps && dataMaps
                                        ?   <div className="flex p-1">
                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`dataMapName-${  props.index }`}>
                                                    Data Map
                                                </label>
                                                <GeneralDataSelectorFieldDataMapSelector 
                                                    key={`dataMapName-${  props.index }`}
                                                    index={props.index}
                                                    field={props.field}
                                                    setFieldValue={props.setFieldValue}
                                                    dataMaps={dataMaps}
                                                />
                                            </div>
                                        : null
                                }
                            </>
                        : null // end expandAdditionalOptions
                    }
            </div>
        </div>
    );
}