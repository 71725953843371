import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege,
    selectLoginSecondsLeft
} from "../../../features/auth/authSlice";
import { useGetSettingsQuery } from "../../../services/mip";
import ErrorTile from "../ErrorTile";
import LoadingSpinner from "../LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning, faCopy } from "@fortawesome/free-solid-svg-icons";

export default function UserInformationBanner(props) {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const loginSecondsLeft = useSelector(selectLoginSecondsLeft);
    const userIsAllowedToViewSettings = privilege.includes("ProvisionProcesses") || privilege.includes("All");
    const userIsAdmin = privilege.includes("Admin") || privilege.includes("All");
    const {
        data: settingsData,
        error: settingsError,
        isFetching: settingsIsFetching
    } = useGetSettingsQuery(undefined, { skip: !isLoggedIn || !userIsAllowedToViewSettings });

    useEffect(() => {
        if (settingsError && settingsError.status === 401) {
            window.location.href="/login";
        }
    }, [settingsError]);

    const formatTimeLeft = (timeLeftInSeconds) => {
        const minutes = Math.floor(timeLeftInSeconds / 60);
        const seconds = timeLeftInSeconds % 60;
        if (timeLeftInSeconds > 1) {
            return `Session expires in ${ minutes }:${ (`00${  seconds }`).slice(-2) }`;
        } else {
            try {
                Auth.signOut();
                setTimeout(() => {window.location.href = "/login";}, 1000);
            } catch (err) {
                console.error(err.message);
            }
            return "Session expired";
        }
    };

    const sessionExpirationTime = loginSecondsLeft ? new Date(new Date().getTime() + (loginSecondsLeft * 1000)) : undefined;

    if (!userIsAllowedToViewSettings) {
        return null;
    }

    return (
        <>
        {
            settingsError
                ? <ErrorTile message={settingsError.data?.Message ?? "Unknown Error"} />
                : settingsIsFetching 
                    ? <LoadingSpinner text="Loading Customer Settings" width="w-full" />
                    : settingsData !== null && settingsData !== undefined
                        ?   <div className="print:hidden mx-auto bg-black text-white text-center rounded-b-xl w-min px-4 pb-1 whitespace-nowrap">
                                <div title={sessionExpirationTime && `Session expires ${ sessionExpirationTime }`}>
                                    <span data-test-id="customer-name">{settingsData.CustomerName}</span> 
                                    {
                                        userIsAdmin
                                            ? <FontAwesomeIcon className="hover:cursor-pointer text-gray-600 ml-2" icon={faCopy} onClick={() => {navigator.clipboard.writeText(settingsData.CustomerIdentifier).then(() => alert(`Copied Customer Identifier ${ settingsData.CustomerIdentifier } to clipboard.`));}} />
                                            : null
                                    }
                                </div>    
                                {
                                    loginSecondsLeft !== undefined && loginSecondsLeft < (60 * 10)
                                        ? <div className="text-orange-300"><FontAwesomeIcon icon={faWarning} /><div className="min-w-[180px] inline-block mx-2">{formatTimeLeft(loginSecondsLeft)}</div><FontAwesomeIcon icon={faWarning} /></div>
                                        : null
                                }
                            </div>
                        : null
        }
        </>
    );
}