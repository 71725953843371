import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    // selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBoltLightning,
    faChainBroken,
    faClock,
    faCopy,
    faEye,
    faGear,
    faGlobe,
    faInfoCircle,
    faLink,
    faPencil,
    faToggleOff,
    faToggleOn,
    faTrash,
    faWarning,
    faMagnifyingGlassChart,
    faUserGear
} from "@fortawesome/free-solid-svg-icons";

export default function ProvsionedProcessesTableRow(props) {
    const navigate = useNavigate();
    const privilege = useSelector(selectPrivilege);
    const userIsAllowedToDeploy = privilege.includes("Deploy") || privilege.includes("All");
    const [showDetails, setShowDetails] = useState(false);

    let type = "Unknown";
    if (props.item.ScheduleExpression !== null && props.item.ScheduleExpression === "Unscheduled") {
        type = <><FontAwesomeIcon icon={faGear} /> {props.item.ScheduleExpression}</>;
    } else if (props.item.ScheduleExpression !== null && props.item.ScheduleExpression !== undefined && props.item.ScheduleExpression !== "") {
        type = <><FontAwesomeIcon icon={faClock} /> {props.item.ScheduleExpression}</>;
    } else if (props.item.HttpVerbs !== null && props.item.HttpVerbs !== undefined && Array.isArray(props.item.HttpVerbs) && props.item.HttpVerbs.length !== 0) {
        type = <><FontAwesomeIcon title={props.item.HttpPathPrefix} icon={faGlobe} /> {props.item.HttpVerbs.join(", ")}</>;
    } else if (props.item.IsNotLinked) {
        type = <><FontAwesomeIcon icon={faChainBroken} /> Unlinked Chain</>;
    } else if (props.item.IsChainLink) {
        type = <><FontAwesomeIcon icon={faLink} /> Chain Link</>;
    }
    
    const details = [];
    if (props.item.DisplayProperties) {
        for (const propertyKey of Object.keys(props.item.DisplayProperties)) {
            details.push(
                <li className="text-sm" key={propertyKey}><span className="font-bold">{propertyKey}</span>: {props.item.DisplayProperties[propertyKey]}</li>
            );
        }
    }

    const nameClasses = ["flex", "p-2"];
    if (props.item.IsChainLink && !props.item.IsNotLinked) {
        nameClasses.push("pl-4");
    }

    return (
        <>
            <div className={`px-2 border ${ props.highlight && "bg-gray-100" }`}>
                <div className={nameClasses.join(" ")}>
                    { 
                        props.item.IsChainLink && !props.item.IsNotLinked
                            ? <div className="flex-initial ml-4 mr-2"><FontAwesomeIcon icon={faLink} /></div>
                            : null
                    }
                    <div className="flex-1">
                        <div className="flex">
                            <div  className="flex-1" data-test-id="provisioned-process-name">{props.item.Name}</div>
                            {
                                details.length > 0
                                    ? <div><button onClick={() => setShowDetails(!showDetails)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm mx-2"><FontAwesomeIcon icon={faInfoCircle} /></button></div>
                                    : null
                            }
                        </div>
                        {
                            showDetails
                                ? <ul className="list-disc ml-8">{details}</ul>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className={`p-2 border whitespace-nowrap ${ props.highlight && "bg-gray-100" }`}>{type}</div>
            <div className={`px-2 border flex justify-evenly items-start ${ props.highlight && "bg-gray-100" }`}>
                {
                    props.hasAdminPrivilege !== true
                        ? <button disabled={true} title="Insufficient privileges." data-test-id={`provisioned-provisionable-item-run-${ props.item.Identifier }`} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faBoltLightning} /></button>
                        : (props.chainedProcesses && props.chainedProcesses.includes(props.item.Identifier))
                            ? <button disabled={true} title="Only Stand Alone and Head of Chain processes can be run." data-test-id={`provisioned-provisionable-item-run-${ props.item.Identifier }`} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faBoltLightning} /></button>
                            : !props.item.IsDeployed
                                ? <button disabled={true} title="Can only run deployed processes." data-test-id={`provisioned-provisionable-item-run-${ props.item.Identifier }`} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faBoltLightning} /></button>
                                : props.item.HttpVerbs && props.item.HttpVerbs !== ""
                                    ? <button title="Queue Process for Execution" data-test-id={`provisioned-provisionable-item-run-${ props.item.Identifier }`} onClick={() => props.queueAPIModal(props.item.Identifier)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faBoltLightning} /></button>
                                    : <button title="Queue Process for Execution" data-test-id={`provisioned-provisionable-item-run-${ props.item.Identifier }`} onClick={() => props.queueModal(props.item.Identifier)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faBoltLightning} /></button>
                }
                <button title="Copy Process Identifier to Clipboard" onClick={() => {navigator.clipboard.writeText(props.item.Identifier).then(() => alert(`Copied identifier: ${ props.item.Identifier }`));}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2"><FontAwesomeIcon icon={faCopy} /></button>
                {
                    props.hasLogsAccess
                        ? <button title="Navigate to Process Tracking Page" data-test-id={`provisioned-provisionable-item-track-${ props.item.Identifier }`} onClick={() => navigate(`/processtracking/search?processIdentifier=${ props.item.Identifier }`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faMagnifyingGlassChart} /></button>
                        : null
                }
                <button title="View" data-test-id={`provisioned-provisionable-item-view-${ props.item.Identifier }`} onClick={() => navigate(`/provisionableitem/process/view/${ props.item.Identifier }`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faEye} /></button>
                {
                    props.hasAdminPrivilege !== true
                        ? <button disabled={true} title="Insufficient Privileges" data-test-id={`provisioned-provisionable-item-crate-manual-process-${ props.item.Identifier }`} onClick={() => props.createManualProcessModal(props.item.Identifier)} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faUserGear} /></button>
                        : (props.chainedProcesses && props.chainedProcesses.includes(props.item.Identifier))
                            ? <button disabled={true} title="Only stand alone and manual processes can be used as manual processes." data-test-id={`provisioned-provisionable-item-crate-manual-process-${ props.item.Identifier }`} onClick={() => props.createManualProcessModal(props.item.Identifier)} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faUserGear} /></button>
                            : props.item.HttpVerbs && props.item.HttpVerbs !== ""
                                ? <button title="Create Manual Process" data-test-id={`provisioned-provisionable-item-crate-manual-process-${ props.item.Identifier }`} onClick={() => props.createManualProcessModal(props.item.Identifier)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faUserGear} /></button>
                                : <button disabled={true} title="Currently only API Processes support manaul process creation." data-test-id={`provisioned-provisionable-item-crate-manual-process-${ props.item.Identifier }`} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faUserGear} /></button>
                }
                <button title="Edit" data-test-id={`provisioned-provisionable-item-edit-${ props.item.Identifier }`} onClick={() => navigate(`/provisionableitem/process/edit/${ props.item.Identifier }`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faPencil} /></button>
                {
                    (props.chainedProcesses && props.chainedProcesses.includes(props.item.Identifier))
                        ? <button disabled={true} title="Only Stand Alone and Head of Chain processes can be deprovisioned." data-test-id={`provisioned-provisionable-item-deprovision-${ props.item.Identifier }`} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faTrash} /></button>
                        : props.item.IsDeployed
                            ? <button disabled={true} title="Cannot deprovision deployed process." data-test-id={`provisioned-provisionable-item-deprovision-${ props.item.Identifier }`} className="bg-gray-400 cursor-not-allowed text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faTrash} /></button>
                            : <button title="Deprovision" data-test-id={`provisioned-provisionable-item-deprovision-${ props.item.Identifier }`} onClick={() => props.deprovisionModal(props.item.Identifier)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faTrash} /></button>
                }
            </div>
            <div className={`px-2 border flex justify-evenly items-start ${ props.highlight && "bg-gray-100" }`}>
            {
                props.item.UpdateRequiredBeforeDeploy === true
                    ? <FontAwesomeIcon title="Update required before deployment." className="text-orange-400 text-3xl mt-2" icon={faWarning} />
                    : props.item.IsChainLink
                        ? props.item.ParentIsDeployed
                            ? <button disabled={true} title="Only Stand Alone and Head of Chain processes can be deployed." data-test-id={`provisioned-provisionable-item-undeploy-${ props.item.Identifier }`} className="text-green-700 opacity-40 cursor-not-allowed font-bold py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOn} /></button>
                            : <button disabled={true} title="Only Stand Alone and Head of Chain processes can be deployed." data-test-id={`provisioned-provisionable-item-undeploy-${ props.item.Identifier }`} className="text-gray-400 cursor-not-allowed font-bold py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOff} /></button>
                        : props.item.IsDeployed === true
                            ? !userIsAllowedToDeploy
                                ? <button  disabled={true} title="Deploy unavailable" data-test-id={`provisioned-provisionable-item-undeploy-${ props.item.Identifier }`} className="text-green-700 opacity-40 cursor-not-allowed font-bold py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOn} /></button>
                                : <button data-test-id={`provisioned-provisionable-item-undeploy-${ props.item.Identifier }`} onClick={() => props.undeployModal(props.item.Identifier)} className="hover:text-red-700 text-green-700 font-bold py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOn} /></button>
                            : !userIsAllowedToDeploy
                                ? <button disabled={true} title="Deploy unavailable" data-test-id={`provisioned-provisionable-item-undeploy-${ props.item.Identifier }`} className="text-gray-400 cursor-not-allowed font-bold py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOff} /></button>
                                : props.item.IsDeployed !== undefined && <button data-test-id={`provisioned-provisionable-item-deploy-${ props.item.Identifier }`} onClick={() => props.deployModal(props.item.Identifier)} className="hover:text-green-700 py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOff} /></button>
            }
            </div>
        </>
    );
}
