import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import ErrorTile from "../../../components/Common/ErrorTile";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import NotFound from "../../../components/Common/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNetworkWired, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
    useGetExternalEntitiesSystemQuery,
    useCreateExternalEntitiesSystemsMutation,
    useUpdateExternalEntitiesSystemsMutation
} from "../../../services/mip";

export default function ExternalEntitiesSystemsForm(props) {   
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const newMode = params.systemId.toLowerCase() === "new";
    const { 
        data: externalEntitiesSystemData,
        error: externalEntitiesSystemError,
        isFetching: externalEntitiesSystemIsFetching
    } = useGetExternalEntitiesSystemQuery(params.systemId, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const [addSystemError, setAddSystemError] = useState();
    const [systemName, setSystemName] = useState("");
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    useLayoutEffect(() => {
        if (!newMode && externalEntitiesSystemData) {
            setSystemName(externalEntitiesSystemData.Name ?? "");
        }
    }, [newMode, externalEntitiesSystemData]);

    const [createExternalEntitiesSystem] = useCreateExternalEntitiesSystemsMutation();
    const postExternalEntitiesSystem = async () => {
        setSubmittingToAPI(true);
        setAddSystemError(undefined);
        const objectToPost = {
            Name: systemName
        };
        try {
            await createExternalEntitiesSystem(objectToPost).unwrap();
            navigate("/settings/external-entities/systems");
        } catch (err) {
            if (err.data?.message) {
                setAddSystemError(err.data.message);
            } else if (err.data?.Message) {
                setAddSystemError(err.data.Message);
            } else if (err.value?.error?.data?.Message) {
                setAddSystemError(err.value.error.data.Message);
            } else {
                setAddSystemError(err.toString());
            }
        }
        setSubmittingToAPI(false);
    };

    const [updateExternalEntitiesSystem] = useUpdateExternalEntitiesSystemsMutation();
    const patchExternalEntitiesSystem = async (systemId) => {
        setSubmittingToAPI(true);
        setAddSystemError(undefined);
        const objectToPost = {
            id: systemId,
            body: {
                Name: systemName
            }
        };
        try {
            await updateExternalEntitiesSystem(objectToPost).unwrap();
            navigate("/settings/external-entities/systems");
        } catch (err) {
            if (err.data?.message) {
                setAddSystemError(err.data.message);
            } else if (err.data?.Message) {
                setAddSystemError(err.data.Message);
            } else if (err.value?.error?.data?.Message) {
                setAddSystemError(err.value.error.data.Message);
            } else {
                setAddSystemError(err.toString());
            }
        }
        setSubmittingToAPI(false);
    };

    const pageTitle = newMode
        ? "Create External Entities System"
        : "Edit External Entities System";

    useEffect(() => {
        document.title = `Settings - ${  pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (externalEntitiesSystemError && externalEntitiesSystemError.status === 401) {
            window.location.href="/login";
        }
    }, [externalEntitiesSystemError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="external-entities" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                {
                    externalEntitiesSystemError
                        ? <ErrorTile message={externalEntitiesSystemError} />
                        : externalEntitiesSystemIsFetching
                            ?   <LoadingSpinner text={"Loading Data"} />
                            :   <>
                                    <div className="text-2xl p-1 pb-4">
                                        <FontAwesomeIcon icon={faNetworkWired} /> {pageTitle}
                                    </div>
                                    {
                                        addSystemError && <ErrorTile message={addSystemError} />
                                    }
                                    <div>
                                        <div className="my-3">
                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="systemName">
                                                Name
                                            </label>
                                            <input type="text" id="systemName" name="systemName" value={systemName ?? ""} onChange={(e) => setSystemName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                        </div>
                                        <div className="flex justify-evenly">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/external-entities/systems")}>Back to External Entities Systems</button>
                                            {
                                                newMode
                                                    ? <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postExternalEntitiesSystem}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add System"}</button>
                                                    : <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => patchExternalEntitiesSystem(params.systemId)}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update System"}</button>
                                            }
                                        </div>
                                    </div>
                                </>
                }
                </div>
            </div>
        </div>
        </>
    );
}