import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";

export default function ArrayOfKeyValuePairs(props) {

    const [keyValueToAdd, setKeyValueToAdd] = useState({Key: "", Value: ""});

    const deleteAtIndex = (index) => {
        const arrayCopy = [...props.arrayOfKeyValuePairs];
        arrayCopy.splice(index, 1);
        props.setArrayOfKeyValuePairs(arrayCopy);
    };

    const addKeyValuePair = () => {
        const arrayCopy = [...props.arrayOfKeyValuePairs];
        arrayCopy.push(keyValueToAdd);
        props.setArrayOfKeyValuePairs(arrayCopy);
        setKeyValueToAdd({Key: "", Value: ""});
    };
    
    return (
        <div className="grid" style={{gridTemplateColumns: "auto auto 7rem"}}>
            <div className="p-2 border text-center font-bold">Key</div>
            <div className="p-2 border text-center font-bold">Value</div>
            <div className="p-2 border text-center font-bold"></div>
            {
                Array.isArray(props.arrayOfKeyValuePairs)
                    ? props.arrayOfKeyValuePairs.map((kvp, index) => (
                        <div className="contents group" key={`string-${ index }`}>
                            <div className="p-2 border group-odd:bg-gray-100">{kvp.Key}</div>
                            <div className="p-2 border group-odd:bg-gray-100">{kvp.Value}</div>
                            <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                <button id={`delete-${ index }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => deleteAtIndex(index)}><FontAwesomeIcon icon={faTrash} /></button>
                            </div>
                        </div>
                      ))
                    : null
            }
            <div className="contents group" key="add">
                <div className="p-2 border">
                    <input id="keyToAdd" name="keyToAdd" value={keyValueToAdd.Key} onChange={(e) => setKeyValueToAdd(Object.assign({}, keyValueToAdd, {Key: e.target.value}))} className="shadow appearance-none border rounded w-full py-1 pl-3 text-grey-800" />
                </div>
                <div className="p-2 border">
                    <input id="valueToAdd" name="valueToAdd" value={keyValueToAdd.Value} onChange={(e) => setKeyValueToAdd(Object.assign({}, keyValueToAdd, {Value: e.target.value}))} className="shadow appearance-none border rounded w-full py-1 pl-3 text-grey-800" />
                </div>
                <div className="px-2 border flex justify-evenly items-start">
                    <button id="add-kvp" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm mt-3 mx-2" type="button" onClick={() => addKeyValuePair()}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
        </div>
    );
}