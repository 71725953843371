import ExternalEntitiesConstantItems from "../ExternalEntitiesConstantItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function ExternalEntitiesConstantItemsContainer(props) {   

    return (
        <div className="shadow border rounded p-4">
            <div data-test-id="export-data-selector-container-div">
            {
                props.constantItems.length === 0
                    ? <div className="text-center">Use the button below to add a Constant Item.</div>
                    : props.constantItems.map((constantItem, index) => (
                            <ExternalEntitiesConstantItems
                                key={index}
                                index={index}
                                constantItem={constantItem}
                                constantItemsDispatch={props.constantItemsDispatch}
                            />
                        ))
            }
            </div>
            <div className="mb-4 flex justify-end text-sm">
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => {props.constantItemsDispatch({type: "addConstantItem"});}}><FontAwesomeIcon icon={faPlus} /> Add Constant Item</button>
            </div>
        </div>
        
    );
}