import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import ConfirmModal from "../../components/Common/ConfirmModal";
import UploadFilesModal from "../../components/Common/UploadFilesModal";
import NotFound from "../../components/Common/NotFound";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import FilesTable from "../../components/Files/FilesTable/index.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRedo,
    faFolder,
    faFileUpload
} from "@fortawesome/free-solid-svg-icons";
import {
    midgardFetch
} from "../../services/mip";
import FilesPaging from "../../components/Files/FilesPaging/index.js";

// I have always defined these inside the component, but it looks like best practices are to not do that so it doesn't have
// to be recreated on every render. Interesting difference between the other code so noting it here for my future reference.
const fileObjectsReducer = (state, action) => {
    switch (action.type) {
        case "storeResults":
            const updatedCached = action.clearCache
                ?   action.objects
                :   [
                        ...state.objects,
                        ...action.objects
                    ];
            return {
                objects: updatedCached,
                nextToken: action.nextToken
            };
        case "removeDeletedObject":
            return {
                objects: state.objects.filter((object) => object.ObjectKey !== action.objectKey),
                nextToken: state.nextToken
            };
        case "clearCache":
            return {
                pages: [],
                nextToken: undefined
            };
        default:
            return state;
    }
};

export default function Files(props) {   
    const navigate = useNavigate();
    const location = useLocation();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("FileManagement") || privilege.includes("All");
    const isAdmin = privilege.includes("Admin") || privilege.includes("All");
    const queryParams = new URLSearchParams(location.search);
    const prefixParam = queryParams.get("prefix");
    const isFirstLoad = useRef(true);
    const prefixRef = useRef(prefixParam === null || prefixParam === undefined || prefixParam === "/" ? "" : prefixParam);

    const [confirmDeleteFileModalDisplayed, setConfirmDeleteFileModalDisplayed] = useState();
    const [confirmDeleteFolderModalDisplayed, setConfirmDeleteFolderModalDisplayed] = useState();
    const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);
    const [pagingStart, setPagingStart] = useState(0);
    const [pageSize, setPageSize] = useState(300);
    const [isFetching, setIsFetching] = useState(false);
    const [getFilesAPIError, setGetFilesAPIError] = useState();

    const [cachedResults, dispatch] = useReducer(fileObjectsReducer, {
        objects: [],
        nextToken: undefined
    });

    const lastRowShown = pagingStart+pageSize < cachedResults.objects.length ? pagingStart+pageSize : cachedResults.objects.length;

    const fetchFiles = useCallback((prefix, clearCache, pageSize, nextToken) => {
        setIsFetching(true);
        setGetFilesAPIError(undefined);

        const urlParameters = [];
        urlParameters.push(`objectKeyPrefix=${ encodeURIComponent(prefix) }`);
        urlParameters.push(`pageSize=${ encodeURIComponent(pageSize) }`);
        if (nextToken) {
            urlParameters.push(`pageToken=${ encodeURIComponent(nextToken) }`);
        }

        midgardFetch("files", "GET", undefined, undefined, urlParameters)
            .then(async (response) => {
                const responseData = await response.json();
                if (!response.ok) {
                    setGetFilesAPIError(responseData?.Data?.Message ?? responseData);
                } else {
                    process.env.REACT_APP_ENV === "test" && console.log("fetchFiles result", responseData);
                    dispatch({
                        type: "storeResults",
                        clearCache,
                        objects: responseData.Data.Page,
                        nextToken: responseData.Data.NextToken
                    });
                }
            }).catch((error) => {
                setGetFilesAPIError(error);
            }).finally(() => {
                setIsFetching(false);
            });
    }, []);

    const loadMoreUsingNextToken = () => {
        setPagingStart(pagingStart + pageSize);
        fetchFiles(prefixRef.current === "/" ? "" : prefixRef.current, false, pageSize, cachedResults.nextToken);
    };

    const reloadFiles = () => {
        setPagingStart(0);
        fetchFiles(prefixRef.current === "/" ? "" : prefixRef.current, true, pageSize);
    };

    useEffect(() => {
        if (isFirstLoad.current || prefixParam !== prefixRef.current) {
            isFirstLoad.current = false;
            setPagingStart(0);
            prefixRef.current = prefixParam === null || prefixParam === undefined || prefixParam === "/" ? "" : prefixParam;
            fetchFiles(prefixRef.current, true, pageSize);
        }
    }, [isFirstLoad, fetchFiles, prefixParam, pageSize, prefixRef]);

    const closeModalAndRefresh = (refresh) => {
        if (refresh) {
            setPagingStart(0);
            fetchFiles(prefixRef.current, true, pageSize);
        }
        setShowUploadFilesModal(false);
    };

    const confirmDeleteFile = async (objectKey) => {
        try {
            await midgardFetch(`files/${ encodeURIComponent(objectKey) }`, "DELETE").then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(err.Message);
                    }).catch(() => {
                        throw new Error(`${ response.status } ${ response.statusText }`);
                    });
                }
            });
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
        // remove this object from the cache
        dispatch({
            type: "removeDeletedObject",
            objectKey
        });
        setConfirmDeleteFileModalDisplayed(null);
        return [true, null];
    };

    const confirmDeleteFolder = async (folder) => {
        try {
            await midgardFetch(`files?folder=${ encodeURIComponent(folder) }`, "DELETE").then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(err.Message);
                    }).catch(() => {
                        throw new Error(`${ response.status } ${ response.statusText }`);
                    });
                }
            });
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
        // remove this object from the cache
        dispatch({
            type: "removeDeletedObject",
            objectKey: folder
        });
        setConfirmDeleteFolderModalDisplayed(null);
        return [true, null];
    };

    useEffect(() => {
        document.title = "Files";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const switchPageSize = (newPageSize) => {
        setPageSize(newPageSize);
        setPagingStart(0);
        fetchFiles(prefixRef.current, true, newPageSize);
    };

    const prefixParts = 
        prefixRef.current === "/"
            ? [""]
            : prefixRef.current.split("/");
    const prefixLinks = [];
    prefixLinks.push(<div key="root" className="inline"><span className="text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => navigate("/files")}>root</span>/&nbsp;</div>);
    for (let i = 0; i < prefixParts.length; i++) {
        if (prefixParts[i] !== "") {
            const path = `${ prefixParts.slice(0, i+1).join("/") }/`;
            prefixLinks.push(<div key={path} className="inline"><span className="text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => navigate(`/files?prefix=${ path }`)}>{ prefixParts[i] }</span>/&nbsp;</div>);
        }
    }
  
    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4">
            <div className="mx-auto mb-10 bg-white rounded-lg">
                <div className="text-2xl pb-4 flex">
                    <div className="flex-1 p-1"><FontAwesomeIcon icon={faFolder} /> Files - {prefixRef.current === "" ? "/" : prefixRef.current } </div>
                </div>
                {
                    getFilesAPIError
                        ? <ErrorTile message={getFilesAPIError} />
                        : isFetching
                            ? <LoadingSpinner text={"Loading Files"} />
                            :   <>
                                    <div className="flex w-full mb-2">
                                        <div className="flex-grow w-4/5">
                                            { prefixLinks }
                                        </div>
                                        <div className="flex-shrink">
                                            <div className="flex flex-nowrap gap-2">
                                                <button title="Refresh" className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded" type="button" onClick={() => reloadFiles()}><FontAwesomeIcon icon={faRedo} /></button>
                                                <select title="Page Size" id="pageSize" name="pageSize" value={pageSize} onChange={(e) => switchPageSize(parseInt(e.target.value))} className="shadow border rounded p-1 text-grey-800 text-base focus:shadow-outline">
                                                    <option key="10" value="10">10</option>
                                                    <option key="100" value="100">100</option>
                                                    <option key="300" value="300">300</option>
                                                    <option key="500" value="500">500</option>
                                                    <option key="1000" value="1000">1000</option>
                                                </select>
                                                <button title="Upload Files" className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded" type="button" onClick={() => setShowUploadFilesModal(true)}><FontAwesomeIcon icon={faFileUpload} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <FilesPaging 
                                        objects={cachedResults.objects}
                                        nextToken={cachedResults.nextToken}
                                        lastRowShown={lastRowShown}
                                        pagingStart={pagingStart}
                                        pageSize={pageSize}
                                        setPagingStart={setPagingStart}
                                        loadMoreUsingNextToken={loadMoreUsingNextToken}
                                    />
                                    {
                                        cachedResults.objects.length === 0
                                            ?   <div className="text-center mb-2">No files found.</div>
                                            :   <FilesTable
                                                    filesData={cachedResults.objects.slice(pagingStart, lastRowShown)}
                                                    prefixLength={prefixRef.current === "/" ? 0 : prefixRef.current.length}
                                                    allowDeleteFolder={isAdmin}
                                                    setConfirmDeleteFolderModalDisplayed={setConfirmDeleteFolderModalDisplayed}
                                                    setConfirmDeleteFileModalDisplayed={setConfirmDeleteFileModalDisplayed}
                                                />
                                    }
                                    <FilesPaging 
                                        objects={cachedResults.objects}
                                        nextToken={cachedResults.nextToken}
                                        lastRowShown={lastRowShown}
                                        pagingStart={pagingStart}
                                        pageSize={pageSize}
                                        setPagingStart={setPagingStart}
                                        loadMoreUsingNextToken={loadMoreUsingNextToken}
                                    />
                                </>
                }
            </div>
            {
                cachedResults.objects && confirmDeleteFileModalDisplayed !== undefined
                    ? cachedResults.objects.map((file, index) => (
                        confirmDeleteFileModalDisplayed === file.ObjectKey
                            ?   <ConfirmModal
                                    key={index}
                                    title={"Are you sure you want to delete this file?"}
                                    submitButtonTitle="Delete"
                                    identifier={file.ObjectKey}
                                    confirmationMessage={`Are you sure you want to delete ${ file.ObjectKey  }?`} 
                                    failureMessage="File failed to delete:"
                                    closeModal={setConfirmDeleteFileModalDisplayed}
                                    confirmFunction={confirmDeleteFile}
                                />
                            : null
                    ))
                    : null
            }
            {
                cachedResults.objects && confirmDeleteFolderModalDisplayed !== undefined
                    ? cachedResults.objects.map((file, index) => (
                        confirmDeleteFolderModalDisplayed === file.ObjectKey
                            ?   <ConfirmModal
                                    key={index}
                                    title={"Are you sure you want to delete this folder?"}
                                    submitButtonTitle="Delete"
                                    identifier={file.ObjectKey}
                                    confirmationMessage={`Are you sure you want to delete ${ file.ObjectKey  } and all of its contents?`} 
                                    failureMessage="File failed to delete:"
                                    closeModal={setConfirmDeleteFolderModalDisplayed}
                                    confirmFunction={confirmDeleteFolder}
                                />
                            : null
                    ))
                    : null
            }
            {
                showUploadFilesModal && <UploadFilesModal
                                            prefix={prefixRef.current === "/" ? "" : prefixRef.current}
                                            closeModal={closeModalAndRefresh}
                                        />
            }
        </div>
        </>
    );
}