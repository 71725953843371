import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp, faArrowAltCircleDown, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function GeneralDataSelectorFormatStringControl(props) {

    let htmlFieldName = `formatStringFunctions-${ props.index }`;
    if (props.property !== undefined) {
        htmlFieldName = `formatStringFunctions-${ props.htmlFieldName }`;
    }

    const currentValue = props.property ? props.property.userEnteredData[props.index].formatStringFunctions : props.field.formatStringFunctions;

    const possibleFormatTypes = [
        {
            FormatType: "Insert",
            StartIndex: "0",
            Value: ""
        },
        {
            FormatType: "JsonSerialize",
            IgnoreNullAndDefaultValues: false,
            TypeNameHandling: "None"
        },
        {
            FormatType: "PadLeft",
            PaddingChar: "",
            TotalWidth: ""
        },
        {
            FormatType: "PadRight",
            PaddingChar: "",
            TotalWidth: ""
        },
        {
            FormatType: "Pluralize"
        },
        {
            FormatType: "Replace",
            NewValue: "",
            OldValue: ""
        },
        {
            FormatType: "SplitLeft",
            SplitOn: ""
        },
        {
            FormatType: "SplitRight",
            SplitOn: ""
        },
        {
            FormatType: "StripLineBreaks",
            ReplacementValue: ""
        },
        {
            FormatType: "Substring",
            Length: "",
            StartIndex: "0"
        },
        {
            FormatType: "ToLower"
        },
        {
            FormatType: "ToLowerCamelCase"
        },
        {
            FormatType: "ToSpacedCamelCase"
        },
        {
            FormatType: "ToUpper"
        },
        {
            FormatType: "Trim"
        }
    ];

    const formatTypes = possibleFormatTypes.reduce((accumulator, formatType) => {
        accumulator.push(formatType.FormatType);
        return accumulator;
    }, []);

    // this is the passed down setFieldValue from the control. It has a different format
    // if we are in export or import. In this control this is called when a format option is
    // added or removed from the formatStrings array.
    const setFieldValue = (formatStringFunctions) => {
        if (props.property !== undefined) { // Import
            props.setFieldValue(props.property.Name, props.index, "formatStringFunctions", formatStringFunctions);
        } else if (props.field !== undefined) { // Export
            props.setFieldValue(props.index, "formatStringFunctions", formatStringFunctions);
        }
    };

    const addFormatStringOption = () => {
        const stateCopy = [...currentValue];
        stateCopy.push({
            FormatType: "Replace",
            Value: "",
            NewValue: "",
            OldValue: "",
            SplitOn: "",
            Length: "",
            StartIndex: "0",
            IgnoreNullAndDefaultValues: false,
            TypeNameHandling: "None",
            ReplacementValue: ""
        });
        setFieldValue(stateCopy);
    };

    const moveFormatStringIndexUp = (index) => {
        const stateCopy = [...currentValue];
        const removedElements = stateCopy.splice(index, 1);
        stateCopy.splice(index - 1, 0, removedElements[0]);
        setFieldValue(stateCopy);
    };

    const moveFormatStringIndexDown = (index) => {
        const stateCopy = [...currentValue];
        const removedElements = stateCopy.splice(index, 1);
        stateCopy.splice(index + 1, 0, removedElements[0]);
        setFieldValue(stateCopy);
    };
    
    const deleteFormatStringAtIndex = (index) => {
        const stateCopy = [...currentValue];
        stateCopy.splice(index, 1);
        setFieldValue(stateCopy);
    };

    const setFormatStringProperty = (index, propertyName, value) => {
        const stateCopy = [...currentValue];
        stateCopy[index][propertyName] = value;
        setFieldValue(stateCopy);
    };

    const fieldValidationMessages = [];
    let optionIndex = 1;
    if (currentValue.length === 0) {
        fieldValidationMessages.push("One or more format string functions are required.");
    }
    for (const option of currentValue) {
        if (["PadRight", "PadLeft"].includes(option.FormatType)) {
            if (!option.PaddingChar || option.PaddingChar.length !== 1) {
                fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } has an invalid Padding Char value.`);
            }
            if (!Number.isInteger(parseInt(option.TotalWidth))) {
                fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } requires an integer for Total Width.`);
            }
        }
        if (["SplitRight", "SplitLeft"].includes(option.FormatType) && option.SplitOn === "") {
            fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } requires a Split On value.`);
        }
        if (option.FormatType === "Replace" && option.OldValue === "") {
            fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } requires a Old Value value.`);
        }
        if (option.FormatType === "Insert") {
            if (option.Value === "") {
                fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } requires a Value value.`);
            }
            if (!Number.isInteger(parseInt(option.StartIndex))) {
                fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } requires a Numeric StartIndex.`);
            }
        }

        if (option.FormatType === "Substring") {
            if (!Number.isInteger(parseInt(option.StartIndex))) {
                fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } requires an integer for Start Index.`);
            }
            if (option.Length !== "" && !Number.isInteger(parseInt(option.Length))) {
                fieldValidationMessages.push(`${ option.FormatType } at position ${ optionIndex } requires an integer for Length if provided.`);
            }
        }
        optionIndex++;
    }

    return (
        <div className="flex-1 shadow border rounded p-2">
            {
                currentValue.length !== 0
                    ?   <>
                            {
                                currentValue.map((option, index) => (
                                    <div key={index} className="hover:bg-gray-200 flex">
                                        <div className="flex-1">
                                            <select name={`${ htmlFieldName }-formatType-${ index }`} id={`${ htmlFieldName }-formatType-${ index }`} value={option.FormatType} onChange={(e) => {setFormatStringProperty(index, "FormatType", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                {
                                                    formatTypes.map((optionValue) => (<option key={optionValue} value={optionValue}>{optionValue}</option>))
                                                }
                                            </select>
                                            {
                                                ["PadRight", "PadLeft"].includes(option.FormatType)
                                                    ?   <>
                                                            <input data-test-id={`${ htmlFieldName }-totalWidth-${ index }`} key={`totalWidth-${ index }`} value={option.TotalWidth} onChange={(e) => setFormatStringProperty(index, "TotalWidth", e.target.value)} placeholder="Total Width" className={`flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline ${ option.TotalWidth === "" ? "border-orange-500" : "" }`} />
                                                            <input data-test-id={`${ htmlFieldName }-paddingChar-${ index }`} key={`paddingChar-${ index }`} value={option.PaddingChar} onChange={(e) => setFormatStringProperty(index, "PaddingChar", e.target.value)} placeholder="Padding Char" className={`flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline ${ option.PaddingChar?.length !== 1 ? "border-orange-500" : "" }`} />
                                                        </>
                                                    :   null
                                            }
                                            {
                                                ["SplitRight", "SplitLeft"].includes(option.FormatType)
                                                    ?   <input data-test-id={`${ htmlFieldName }-splitOn-${ index }`} key={`splitOn-${ index }`} value={option.SplitOn} onChange={(e) => setFormatStringProperty(index, "SplitOn", e.target.value)} placeholder="Split On" className={`flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline ${ option.SplitOn === "" ? "border-orange-500" : "" }`} />
                                                    :   null
                                            }
                                            {
                                                option.FormatType === "Replace"
                                                    ?   <>
                                                            <input data-test-id={`${ htmlFieldName }-oldValue-${ index }`} key={`oldValue-${ index }`} value={option.OldValue} onChange={(e) => setFormatStringProperty(index, "OldValue", e.target.value)} placeholder="Old Value" className={`flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline ${ option.OldValue === "" ? "border-orange-500" : "" }`} />
                                                            <input data-test-id={`${ htmlFieldName }-newValue-${ index }`} key={`newValue-${ index }`} value={option.NewValue} onChange={(e) => setFormatStringProperty(index, "NewValue", e.target.value)} placeholder="New Value" className="flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline" />
                                                        </>
                                                    :   null
                                            }
                                            {
                                                option.FormatType === "Insert"
                                                    ?   <>
                                                            <input data-test-id={`${ htmlFieldName }-startIndex-${ index }`} key={`startIndex-${ index }`} value={option.StartIndex} onChange={(e) => setFormatStringProperty(index, "StartIndex", e.target.value)} placeholder="Start Index" className={`flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline ${ option.StartIndex === "" ? "border-orange-500" : "" }`} />
                                                            <input data-test-id={`${ htmlFieldName }-value-${ index }`} key={`value-${ index }`} value={option.Value} onChange={(e) => setFormatStringProperty(index, "Value", e.target.value)} placeholder="Value" className={`flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline ${ option.Value === "" ? "border-orange-500" : "" }`} />
                                                        </>
                                                    :   null
                                            }
                                            {
                                                option.FormatType === "Substring"
                                                    ?   <>
                                                            <input data-test-id={`${ htmlFieldName }-startIndex-${ index }`} key={`startIndex-${ index }`} value={option.StartIndex} onChange={(e) => setFormatStringProperty(index, "StartIndex", e.target.value)} placeholder="Start Index" className={`flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline ${ option.StartIndex === "" ? "border-orange-500" : "" }`} />
                                                            <input data-test-id={`${ htmlFieldName }-length-${ index }`} key={`length-${ index }`} value={option.Length} onChange={(e) => setFormatStringProperty(index, "Length", e.target.value)} placeholder="Length" className="flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline" />
                                                        </>
                                                    :   null
                                            }
                                            {
                                                option.FormatType === "StripLineBreaks"
                                                    ?   <input data-test-id={`${ htmlFieldName }-replacementValue-${ index }`} key={`replacementValue-${ index }`} value={option.ReplacementValue} onChange={(e) => setFormatStringProperty(index, "ReplacementValue", e.target.value)} placeholder="Replacement Value" className="flex-1 shadow border rounded py-2 ml-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline" />
                                                    :   null
                                            }
                                            {
                                                option.FormatType === "JsonSerialize"
                                                    ?   <>
                                                            <select title="Type Name Handling" name={`${ htmlFieldName }-typeNameHandling-${ index }`} id={`${ htmlFieldName }-typeNameHandling-${ index }`} value={option.TypeNameHandling} onChange={(e) => {setFormatStringProperty(index, "TypeNameHandling", e.target.value);}} className="flex-1 shadow border rounded ml-2 py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                                {
                                                                    ["None", "Objects", "Arrays", "All", "Auto"].map((optionValue) => (<option key={optionValue} value={optionValue}>{`Type Name Handling: ${ optionValue }`}</option>))
                                                                }
                                                            </select>
                                                            <label className="flex-1 ml-4 text-grey-800 text-sm font-bold align-middle" htmlFor={`${ htmlFieldName }-ignoreNullAndDefaultValues-${ index }`}>
                                                                <input type="checkbox" value={option.IgnoreNullAndDefaultValues} name={`${ htmlFieldName }-ignoreNullAndDefaultValues-${ index }`} id={`${ htmlFieldName }-ignoreNullAndDefaultValues-${ index }`} checked={option.IgnoreNullAndDefaultValues ? "checked" : ""} onChange={(e) => {setFormatStringProperty(index, "IgnoreNullAndDefaultValues", e.target.checked);}} className="mr-2 align-middle" />
                                                                <span className="text-grey-800 mb-4 align-middle">Ignore Null/Defaults</span>
                                                            </label>
                                                        </>
                                                    :   null
                                            }
                                        </div>
                                            <div className="shrink text-right pt-2">
                                                { index !== 0 && <button onClick={() => moveFormatStringIndexUp(index)} className="bg-blue-500 hover:bg-blue-700 text-white px-1 rounded text-sm mx-1" type="button"><FontAwesomeIcon icon={faArrowAltCircleUp} /></button> }
                                                { index + 1 !== currentValue.length && <button onClick={() => moveFormatStringIndexDown(index)} className="bg-blue-500 hover:bg-blue-700 text-white px-1 rounded text-sm mx-1" type="button"><FontAwesomeIcon icon={faArrowAltCircleDown} /></button> }
                                                <button onClick={() => deleteFormatStringAtIndex(index)} className="bg-blue-500 hover:bg-blue-700 text-white px-1 rounded text-sm mx-1" type="button"><FontAwesomeIcon icon={faTrash} /></button>
                                            </div>
                                    </div>
                                ))
                            }
                        </>
                    : <div>Add a format string option using the link below.</div>
            }
            <div className="my-2 text-right">
                <div data-test-id={`${ htmlFieldName }-add-format-string-option`} className="inline text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => {addFormatStringOption();}}>Add format string option</div>
            </div>
            {
                fieldValidationMessages.length !== 0
                    ?   <div className="ml-4 text-orange-400">
                            {
                                fieldValidationMessages.map((option, index) => (<div key={index}>{option}</div>))
                            }
                        </div>
                    : null
            }
        </div>
    );
}