import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorTile from "../../../components/Common/ErrorTile";
import DownloadFileButton from "../../../components/Common/DownloadFileButton/index.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
    formatDateAsISO,
    formatFileSizeAsHumanReadable
} from "../../../midgard.js";

export default function Files(props) {   
    const navigate = useNavigate();
    const [downloadErrorMessage, setDownloadErrorMessage] = useState();

    return (
    <div className="contents group" key={`file-${ props.index }`}>  
        {
            props.file.ObjectKey?.endsWith("/")
                ?   <>
                        <div className="p-2 border col-span-3 group-odd:bg-gray-100">
                            <div className="inline text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => navigate(`/files?prefix=${ props.file.ObjectKey }`)}>{ props.file.ObjectKey.substring(props.prefixLength) }</div>
                        </div>
                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                        {
                            props.allowDeleteFolder
                                ? <button title="Delete" id={`delete-${ props.file.ObjectKey }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => props.setConfirmDeleteFolderModalDisplayed(props.file.ObjectKey)}><FontAwesomeIcon icon={faTrash} /></button>
                                : null
                        }
                        </div>
                    </>
                :   <>
                        <div className="p-2 border group-odd:bg-gray-100">
                            {props.file.ObjectKey.substring(props.prefixLength)}
                            {
                                downloadErrorMessage
                                    ? <ErrorTile message={downloadErrorMessage} />
                                    : null
                            }
                        </div>
                        <div className="p-2 border group-odd:bg-gray-100">{formatDateAsISO(new Date(`${ props.file.LastModified }`), false, " ", false)}</div>
                        <div className="p-2 border group-odd:bg-gray-100">{formatFileSizeAsHumanReadable(props.file.SizeInBytes)}</div>
                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                            <DownloadFileButton
                                filename={props.file.ObjectKey}
                                setErrorMessage={setDownloadErrorMessage}
                            />
                            <button title="Delete" id={`delete-${ props.file.ObjectKey }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => props.setConfirmDeleteFileModalDisplayed(props.file.ObjectKey)}><FontAwesomeIcon icon={faTrash} /></button>
                        </div>
                    </>
        }
    </div>
    );
}