import { useState } from "react";
import { Field } from "formik";
import DataSelector from "../DataSelector";
import ExportDataSelector from "../ExportDataSelector";
import ImportDataSelector from "../ImportDataSelector";
import FixedWidthColumnsSelector from "../FixedWidthColumnsSelector";
import FileLayoutSelector from "../FileLayoutSelector";
import ProvisionableItemFormSelectField from "../ProvisionableItemFormSelectField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faUndo } from "@fortawesome/free-solid-svg-icons";
import { getFieldType } from "../../../midgard.js";

export default function ProvisionableItemFormField(props) {

    const placeholderValue = props.field.Placeholder ? props.field.Placeholder : "";
    const hideLabel = props.field.HideLabel;
    const identifier = props.field.Parameter.Identifier;
    const tooltip = props.field.Parameter.Hint;
    const label = props.field.Parameter.Name;

    const [showHint, setShowHint] = useState(false);

    const fieldType = getFieldType(props.field);

    if (fieldType === "TextField") {
        const classes = ["shadow", "appearance-none", "border", "rounded", "w-full", "py-2", "pl-3", "pr-6", "text-grey-800", "mb-3"];
        if (props.hasError) {
            classes.push("border-red-500");
        }
        let customFormikField = <Field disabled={props.formState[identifier].disabled === true} name={identifier} id={identifier} type='text' placeholder={placeholderValue} className={classes.join(" ")} onBlur={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: e.target.id, data: e.target.value}})} />;
        if (props.field.TextType === "Password") {
            customFormikField = <Field disabled={props.formState[identifier].disabled === true} name={identifier} id={identifier} type='password' placeholder={placeholderValue} className={classes.join(" ")} onBlur={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: e.target.id, data: e.target.value}})} />;
        } else if (props.field.TextType === "TextArea") {
            classes.push("h-32");
            customFormikField = <Field disabled={props.formState[identifier].disabled === true} name={identifier} id={identifier} as='textarea' placeholder={placeholderValue} className={classes.join(" ")} onBlur={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: e.target.id, data: e.target.value}})} />;
        }
        return (
            <div className="my-2">
                <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                    {!hideLabel && 
                        <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={identifier}>
                            {label}
                        </label>
                    }
                    {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                </div>
                {
                    tooltip && showHint
                        ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                        :   null
                }
                {props.hasError && props.errorMessage && <div data-test-id={`error-msg-for-${  identifier }`} className="text-red-600 text-sm font-bold ml-4">{props.errorMessage }</div>}
                {customFormikField}
            </div>
        );
    } else if (fieldType === "ConstantField") {
        const hidden = props.field.Hidden;
        if (hidden) {
            return null;
        }
        else {
            return (
                <div className="my-2">
                    <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                        {!hideLabel && 
                            <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={identifier}>
                                {label}
                            </label>
                        }
                        {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                    </div>
                    {
                        tooltip && showHint
                            ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                            :   null
                    }
                    <div className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3">
                        {props.formState[identifier]?.value ?? "Constant value not set."}
                    </div>
                </div>
            );
        }
    } else if (fieldType === "CheckboxField") {
        return (
            <div className="mt-2 mb-4">
                <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                    {!hideLabel && 
                        <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={identifier}>
                            {label}
                        </label>
                    }
                    {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                </div>
                {
                    tooltip && showHint
                        ?   <div><div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div></div>
                        :   null
                }
                <label className="align-middle ml-4" htmlFor={identifier}>
                    <Field type="checkbox" disabled={props.formState[identifier].disabled === true} name={identifier} id={identifier} className="mr-2 align-middle" onChange={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: e.target.id, data: e.target.checked ? true : false}})} />
                    <span className="text-grey-800 text-sm mb-4 align-middle">{props.field.Text}</span>
                </label> 
                {props.hasError && props.errorMessage && <div data-test-id={`error-msg-for-${  identifier }`} className="text-red-600 text-sm font-bold ml-4">{props.errorMessage }</div>}
            </div>
        );   
    } else if (fieldType === "SelectField") {
        return <ProvisionableItemFormSelectField
            field={props.field}
            disabled={props.formState[identifier].disabled === true}
            hasError={props.hasError}
            errorMessage={props.errorMessage}
            formState={props.formState}
            formStateDispatch={props.formStateDispatch}
            identifierToExcludeFromLinkedQuery={props.identifierToExcludeFromLinkedQuery} // used in linked process query
            provisionMode={props.provisionMode}
        />;
    } else if (fieldType === "Export.DataSelector") {
        return (
            <div className="my-2">
                <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                    {!hideLabel ? <label className="text-grey-800 text-sm font-bold mb-2" htmlFor={identifier}>{label}</label> : null}
                    {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                </div>
                {
                    tooltip && showHint
                        ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                        :   null
                }
                <ExportDataSelector 
                    field={props.field} 
                    formState={props.formState}
                    formStateDispatch={props.formStateDispatch}
                />
            </div>
        );
    } else if (fieldType === "FileSelectorField") {
        let previouslySelectedFilename = null;
        if (props.formState[identifier].value !== null) {
            previouslySelectedFilename = props.formState[identifier].value[0].name;
        }
        return (
            <div className="my-2">
                <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                    {!hideLabel ? <label className="text-grey-800 text-sm font-bold mb-2" htmlFor={identifier}>{label}</label> : null}
                    {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                </div>
                {
                    tooltip && showHint
                        ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                        :   null
                }
                {
                    previouslySelectedFilename === null 
                        ?   <input type="file" name={identifier} id={identifier} onChange={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: e.target.id, data: e.target.files}})} accepttypes={props.field.AcceptTypes} allowmultiple={props.field.AllowMultiple ? "allowmultipe" : undefined}  className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                        :   <div>
                                <input type="text" name={`${ identifier  }file`} id={`${ identifier  }file`} disabled="disabled" className="inline w-11/12 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800 mb-3" value={previouslySelectedFilename} />
                                <button disabled={props.formState[identifier].disabled === true} data-test-id={`reset-file-upload-${  identifier }`} className="inline bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-1/12" type="button" onClick={() => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: identifier, data: null}})}><FontAwesomeIcon icon={faUndo} /></button>
                            </div>
                }
                
            </div>          
        );
    } else if (fieldType === "FileLayoutSelector") {
        return (
            <div className="my-2">
                <FileLayoutSelector 
                    field={props.field} 
                    formState={props.formState}
                    formStateDispatch={props.formStateDispatch}
                />
            </div>
        );
    } else if (fieldType === "DataSelector") {
        return (
            <div className="my-2">
                <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                    {!hideLabel ? <label className="text-grey-800 text-sm font-bold mb-2" htmlFor={identifier}>{label}</label> : null}
                    {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                </div>
                {
                    tooltip && showHint
                        ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                        :   null
                }
                <DataSelector 
                    field={props.field}
                    formState={props.formState}
                    formStateDispatch={props.formStateDispatch}
                />
            </div>
        );
    } else if (fieldType === "Import.DataSelector") {
        return (
            <div className="my-2">
                <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                    {!hideLabel ? <label className="text-grey-800 text-sm font-bold mb-2" htmlFor={identifier}>{label}</label> : null}
                    {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                </div>
                {
                    tooltip && showHint
                        ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                        :   null
                }
                <ImportDataSelector 
                    field={props.field}
                    formState={props.formState}
                    formStateDispatch={props.formStateDispatch}
                />
            </div>
        );
    } else if (fieldType === "FixedWidthColumnsSelector") {
        return (
            <div className="my-2">
                <div className={!hideLabel || tooltip ? "mb-4" : ""}>
                    {!hideLabel ? <label className="text-grey-800 text-sm font-bold mb-2" htmlFor={identifier}>{label}</label> : null}
                    {tooltip && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
                </div>
                {
                    tooltip && showHint
                        ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                        :   null
                }
                <FixedWidthColumnsSelector 
                    field={props.field}
                    disabled={props.formState[identifier].disabled === true} 
                    formState={props.formState}
                    formStateDispatch={props.formStateDispatch}
                    mappingData={props.formState[props.field.Parameter.Identifier].value} 
                />
            </div>
        );

    } else {
        console.error("Unknown Field Type:", fieldType, props.field);
        return (
            <div className="bg-black text-green-400 rounded-lg p-4 mb-4">
                <pre>-- Unknown Field Type `{fieldType}`</pre>
                <pre>{JSON.stringify(props.field, null, " ")}</pre>
            </div>
        );
    }
}
