import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import ConfirmModal from  "../../../components/Common/ConfirmModal";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import AdminLeftNav from "../../../components/Admin/AdminLeftNav";
import {
    useGetCustomersQuery,
    useGetUsersQuery,
    useToggleUserMutation,
    useResendUserConfirmationMutation
} from "../../../services/mip";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faPencil,
    faToggleOff,
    faToggleOn,
    faPlus,
    faSpinner,
    faUsers,
    faWarning,
    faSortDown,
    faSortUp,
    faSort
} from "@fortawesome/free-solid-svg-icons";

export default function Users(props) {   
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Admin") || privilege.includes("All");

    const [confirmDisableModalDisplayed, setConfirmDisableModalDisplayed] = useState();
    const [confirmEnableModalDisplayed, setConfirmEnableModalDisplayed] = useState();
    const [resendConfirmationModallDisplayed, setResendConfirmationModallDisplayed] = useState();
    const [sortedProperty, setSortedProperty] = useState({property: "Username", direction: 1});

    useEffect(() => {
        document.title = "Administration - Users";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: userData,
        error: userError,
        isFetching: userIsFetching
    } = useGetUsersQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const { 
        data: customerData,
        error: customerError,
        isFetching: customerIsFetching
    } = useGetCustomersQuery(undefined, { skip: !isLoggedIn || !hasPrivilege});

    const toggleSort = (property) => {
        if (property !== sortedProperty.property) {
            setSortedProperty({property, direction: 1});
        } else {
            setSortedProperty({property, direction: sortedProperty.direction * -1});
        }
    };

    // expanding the default user array with named properties to make sorting easier.
    const allUsers = [];
    if (Array.isArray(userData?.Data) && Array.isArray(customerData?.Data)) {
        const customerNameById = {};
        for (const customer of customerData.Data) {
            customerNameById[customer.Identifier] = customer.Name;
        }
        for (const user of userData.Data) {
            allUsers.push({
                ...user,
                companyName: customerNameById[user.Profile.CustomerIdentifier] ? customerNameById[user.Profile.CustomerIdentifier] : ""
            });
        }
    }

    const sortedUsers = [...allUsers];
    sortedUsers.sort((a, b) => {
        if (a[sortedProperty.property] < b[sortedProperty.property]) {
          return -1 * sortedProperty.direction;
        }
        if (a[sortedProperty.property] > b[sortedProperty.property]) {
          return 1 * sortedProperty.direction;
        }
        return 0;
      });


    const [toggleUserEnabled] = useToggleUserMutation();
    const disableUser = async (username) => {
        try {
            await toggleUserEnabled({username, enabled: false}).unwrap();
            setConfirmDisableModalDisplayed(undefined);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };
    const enableUser = async (username) => {
        try {
            await toggleUserEnabled({username, enabled: true}).unwrap();
            setConfirmEnableModalDisplayed(undefined);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [resendConfirmationFunction] = useResendUserConfirmationMutation();
    const resendConfirmation = async (username) => {
        try {
            await resendConfirmationFunction(username).unwrap();
            setResendConfirmationModallDisplayed(undefined);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    useEffect(() => {
        if (userError && userError.status === 401) {
            window.location.href="/login";
        }
    }, [userError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <AdminLeftNav selected="users" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faUsers} /> Users</div>
                        <div className="flex-initial text-right">
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/admin/users/new");}}><FontAwesomeIcon className="w-auto" icon={faPlus} /></button>
                        </div>
                    </div>                    
                    {
                        userError
                            ? <ErrorTile message={userError} />
                            : userIsFetching
                                ? <LoadingSpinner text={"Loading Users"} />
                                : !Array.isArray(allUsers) || allUsers.length === 0
                                    ?   <div className="text-center">No users loaded. Something has gone very wrong.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "repeat(5, auto) repeat(2, min-content)"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap hover:cursor-pointer" onClick={() => toggleSort("Username")}>User {sortedProperty.property === "Username" ? sortedProperty.direction === 1 ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon className="text-gray-300" icon={faSort} />}</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap hover:cursor-pointer" onClick={() => toggleSort("companyName")}>Company {sortedProperty.property === "companyName" ? sortedProperty.direction === 1 ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon className="text-gray-300" icon={faSort} />}</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap hover:cursor-pointer" onClick={() => toggleSort("Status")}>Status {sortedProperty.property === "Status" ? sortedProperty.direction === 1 ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon className="text-gray-300" icon={faSort} />}</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Group(s)</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Privilege</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap hover:cursor-pointer" onClick={() => toggleSort("Enabled")}>Enabled {sortedProperty.property === "Enabled" ? sortedProperty.direction === 1 ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon className="text-gray-300" icon={faSort} />}</div>
                                            {
                                                sortedUsers.map((user, index) => (
                                                    <div className="contents group" key={`user-${ index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100 whitespace-nowrap">
                                                            {user.Username}
                                                        </div>
                                                        <div className="p-2 border group-odd:bg-gray-100">
                                                            {
                                                                customerError
                                                                    ? <span><FontAwesomeIcon className="text-orange-400" icon={faWarning} /> Error</span>
                                                                    : customerIsFetching
                                                                        ? <FontAwesomeIcon className="spinner" icon={faSpinner} />
                                                                        : user.companyName === ""
                                                                            ? <span><FontAwesomeIcon className="text-orange-400" icon={faWarning} /> Not Found</span>
                                                                            : user.companyName
                                                            }
                                                        </div>
                                                        <div className="p-2 border group-odd:bg-gray-100">
                                                            {user.Status}
                                                        </div>
                                                        <div className="p-2 border group-odd:bg-gray-100">
                                                            <ul>
                                                            {
                                                                Array.isArray(user.Profile?.CustomerGroups)
                                                                    ?   <>
                                                                        {
                                                                            user.Profile.CustomerGroups.map((group, groupIndex) => (
                                                                                <li key={groupIndex}>{group.Key}</li>
                                                                            ))
                                                                        }
                                                                        </> 
                                                                    : <li>N/A</li>
                                                            }
                                                            </ul>
                                                        </div>
                                                        <div className="p-2 border group-odd:bg-gray-100">
                                                            {user.Profile?.Privilege ?? "N/A"}
                                                        </div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            <button title="Resend Confirmation Email" disabled={!user.Enabled || user.Status !== "FORCE_CHANGE_PASSWORD"} className={`${ user.Enabled && user.Status === "FORCE_CHANGE_PASSWORD" ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-500" } text-white font-bold py-1 px-4 rounded text-sm m-2`} type="button" onClick={() => setResendConfirmationModallDisplayed(user.Username)}><FontAwesomeIcon icon={faEnvelope} /></button>
                                                            <button title="Edit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => navigate(`/admin/users/${ user.Username }`)}><FontAwesomeIcon icon={faPencil} /></button>
                                                        </div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            {
                                                                user.Enabled
                                                                    ? <button data-test-id={`toggle-user-disabled-${ user.Username }`} onClick={() => setConfirmDisableModalDisplayed(user.Username)} className="hover:text-red-700 text-green-700 font-bold py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOn} /></button>
                                                                    : <button data-test-id={`toggle-user-enabled-${ user.Username }`} onClick={() => setConfirmEnableModalDisplayed(user.Username)} className="hover:text-green-700 py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOff} /></button>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                    }
                </div>
            </div>
            {
                userData?.Data && Array.isArray(userData.Data) && confirmDisableModalDisplayed !== undefined
                    ? userData.Data.filter((allUser) => allUser.Enabled).map((user, index) => (
                        confirmDisableModalDisplayed === user.Username 
                            ? <ConfirmModal 
                                    key={index} 
                                    title={`Confirm Disable of ${ user.Username }`}
                                    submitButtonTitle="Disable"
                                    identifier={user.Username}
                                    confirmationMessage={`Are you sure you want to disable ${ user.Username }?`} 
                                    successMessage="User disabled."
                                    failureMessage="User failed to disable:"
                                    closeModal={() => setConfirmDisableModalDisplayed(null)}
                                    confirmFunction={disableUser}
                                /> 
                            : null
                      ))
                    : null
            }
            {
                userData?.Data && Array.isArray(userData.Data) && confirmEnableModalDisplayed !== undefined
                    ? userData.Data.filter((allUser) => !allUser.Enabled).map((user, index) => (
                        confirmEnableModalDisplayed === user.Username 
                            ? <ConfirmModal 
                                    key={index} 
                                    title={`Confirm Enable of ${ user.Username }`}
                                    submitButtonTitle="Enable"
                                    identifier={user.Username}
                                    confirmationMessage={`Are you sure you want to enable ${ user.Username }?`} 
                                    successMessage="User enabled."
                                    failureMessage="User failed to enable:"
                                    closeModal={() => setConfirmEnableModalDisplayed(null)}
                                    confirmFunction={enableUser}
                                /> 
                            : null
                      ))
                    : null
            }
            {
                userData?.Data && Array.isArray(userData.Data) && resendConfirmationModallDisplayed !== undefined
                    ? userData.Data.filter((allUser) => allUser.Enabled).map((user, index) => (
                        resendConfirmationModallDisplayed === user.Username 
                            ? <ConfirmModal 
                                    key={index} 
                                    title={`Confirm Resend of Email Confirmation for ${ user.Username }`}
                                    submitButtonTitle="Resend Confirmation"
                                    identifier={user.Username}
                                    confirmationMessage={`Are you sure you want to resend the email confirmation for ${ user.Username }?`} 
                                    successMessage="Email confirmation resent."
                                    failureMessage="Email confirmation email failed."
                                    closeModal={() => setResendConfirmationModallDisplayed(null)}
                                    confirmFunction={resendConfirmation}
                                /> 
                            : null
                      ))
                    : null
            }
        </div>
        </>
    );
}