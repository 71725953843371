import {
    useMemo,
    useState
} from "react";
import ErrorTile from "../../Common/ErrorTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faSpinner, faUndo, faDownload } from "@fortawesome/free-solid-svg-icons";
import "./spinner.css";
import { geValuesApiUrl, flattenConvertJsonData } from "../../../midgard.js";
import {useConvertToJsonMutation } from "../../../services/mip";

export default function FileLayoutSelector(props) {

    const [convertToJsonAPIError, setConvertToJsonAPIError] = useState(null);
    const [loadingDataFromAPI, setLoadingDataFromAPI] = useState(false);
    const [showHint, setShowHint] = useState(false);

    // NOTE: This function does not have a useLayuoutEffect to work on
    //       previousValue. That is done in ProvisionableItemIndex during
    //       the initial setState function.

    const getBase64FileData = async (file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (ev) => {
                resolve(ev.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    const [postConvertToJson] = useConvertToJsonMutation();
    const handleUploadFile = async () => {
        setConvertToJsonAPIError(null);
        setLoadingDataFromAPI(true);

        const validationMessages = [];
        if (props.formState[props.field.Parameter.Identifier].value === null) {
            validationMessages.push("Select a file from your machine to upload.");
        } else if (props.formState[props.field.Parameter.Identifier].value[0].size > 1024*1024*5) {
            validationMessages.push("That's a lot of sample data. Please use a smaller sample file (less than 5MB).");
        }

        const sourceTypeIdentifier = props.field.SourceFileTypeProvisioningIdentifier;
        const sourceFileTypeValue = props.formState[sourceTypeIdentifier].value;
        const queryStringParameters = [];

        for (const parameter of Object.keys(props.field.ConvertToJsonApiQueryStringParameters)) {
            const parameterIdentifier = props.field.ConvertToJsonApiQueryStringParameters[parameter];
            if (sourceFileTypeValue === "FixedWidth" && parameter === "fixedWidthColumnsSelector") {
                // I have stored these as strings, the API needs them as integers, so recreate the object
                const columnsToPost = [];
                let columnIndex = 0;
                for (const columnData of props.formState[parameterIdentifier].value) {
                    let valid = true;
                    const columnNumber = columnIndex + 1;
                    if (columnData.StartIndex === "" || isNaN(columnData.StartIndex)) {
                        validationMessages.push(`StartIndex of column ${  columnNumber  } is not a number.`);
                        valid = false;
                    }

                    if (columnData.Length === "" || isNaN(columnData.Length)) {
                        validationMessages.push(`Length of column ${  columnNumber  } is not a number.`);
                        valid = false;
                    }

                    if (valid) {
                        columnsToPost.push({
                            StartIndex: parseInt(columnData.StartIndex),
                            Length: parseInt(columnData.Length),
                            Justification: columnData.Justification !== "Right" ? "Left" : "Right"
                        });
                    }
                    columnIndex++;
                }
                queryStringParameters.push(`${ parameter }=${ encodeURIComponent(JSON.stringify(columnsToPost)) }`);
            } else if (parameterIdentifier !== null && parameterIdentifier !== undefined && props.formState[parameterIdentifier] !== null && props.formState[parameterIdentifier] !== undefined) {
                queryStringParameters.push(`${ parameter }=${ encodeURIComponent(props.formState[parameterIdentifier].value) }`);
            }
        }

        if (validationMessages.length > 0) {
            setConvertToJsonAPIError(validationMessages.join(" "));
            setLoadingDataFromAPI(false);
        } else {
            try {
                const fileContents = await getBase64FileData(props.formState[props.field.Parameter.Identifier].value[0]);
                const url = geValuesApiUrl(props.field, props.formState, "new", null, queryStringParameters);
                const response = await postConvertToJson({url, body: fileContents});
                if (response.error) {
                    setLoadingDataFromAPI(false);
                    setConvertToJsonAPIError(response.error);
                } else {
                    //props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: true}});
                    props.formStateDispatch({type: "setExternalData", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: JSON.parse(response.data.Data)}});
                    props.formStateDispatch({type: "disableRelatedFields", payload: Object.values(props.field.ConvertToJsonApiQueryStringParameters)});
                }
                setLoadingDataFromAPI(false); 
            } catch (ex) {
                setLoadingDataFromAPI(false);
                setConvertToJsonAPIError(ex.toString());
            }
        }
    };

    const handleClearSampleDataButton = () => {
        props.formStateDispatch({type: "setExternalData", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: null }});
        props.formStateDispatch({type: "deletePreviousValue", payload: { fieldIdentifier: props.field.Parameter.Identifier }});
        //props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: null }});
        props.formStateDispatch({type: "enableRelatedFields", payload: Object.values(props.field.ConvertToJsonApiQueryStringParameters)});
    };

    let uploadedFileTable = null;
    const fileDataDisplayRows = [];

    if (props.formState[props.field.Parameter.Identifier].externalData !== null) {
        const sourceData = {};
        for (let rowIndex = 0; rowIndex < Math.min(3, props.formState[props.field.Parameter.Identifier].externalData.length); rowIndex++) {
            const flattenedData = flattenConvertJsonData(null, props.formState[props.field.Parameter.Identifier].externalData[rowIndex]);
            for (const columnName of Object.keys(flattenedData)) {
                if (!Object.keys(sourceData).includes(columnName)) {
                    sourceData[columnName] = [];
                }
                sourceData[columnName].push(flattenedData[columnName]);
            }
        }
        let rowIndex = 0;
        for (const columnName of Object.keys(sourceData)) {
            const sampleDivs = [];
            let sampleDataIndex = 0;
            for (let sampleData of sourceData[columnName]) {
                if (sampleData === "") {
                    sampleData = <>&nbsp;</>;
                }
                sampleDivs.push(<div key={sampleDataIndex}>{sampleData}</div>);
                sampleDataIndex++;
            }
            fileDataDisplayRows.push(<tr key={rowIndex} className="border odd:bg-gray-100"><th className="align-top p-2 text-left">{columnName}</th><td className="p-2 w-3/4 break-words text-left">{sampleDivs}</td></tr>);
            rowIndex++;
        }
        uploadedFileTable = <table className="border w-full table-auto"><thead><tr><th className="p-2 border">Column</th><th className="p-2">Extracted Sample Data</th></tr></thead><tbody data-test-id="convert-to-json-rows">{fileDataDisplayRows}</tbody></table>;
    }

    let previouslySelectedFilename = null;
    if (props.formState[props.field.Parameter.Identifier].value !== null) {
        previouslySelectedFilename = props.formState[props.field.Parameter.Identifier].value[0].name;
    }


    const jsonSourceFileIsArray = useMemo(() => {
        if (Object.keys(props.formState).includes(props.field.ConvertToJsonApiQueryStringParameters.sourceFileType) && Object.keys(props.formState).includes(props.field.ConvertToJsonApiQueryStringParameters.jsonSourceFileIsArray)) {
            const sourceFileType = props.formState[props.field.ConvertToJsonApiQueryStringParameters.sourceFileType].value;
            if (["JSON", "XML"].includes(sourceFileType)) {
                return props.formState[props.field.ConvertToJsonApiQueryStringParameters.jsonSourceFileIsArray].value === true;
            }
        }
        return true;
    }, [
        props.field,
        props.formState
    ]);

    const externalDataBlob = useMemo(() => {
        if (props.formState[props.field.Parameter.Identifier].externalData !== null) {
            const { externalData } = props.formState[props.field.Parameter.Identifier];
            const json = JSON.stringify(jsonSourceFileIsArray ? externalData : externalData[0]);
            return new Blob([json], {type: "application/json"});
        }
        return null;
    }, [
        jsonSourceFileIsArray,
        props.formState,
        props.field
    ]);

    return (
        <>
        <div className="my-2">
            <div className={!props.field.HideLabel || props.field.Parameter.Hint ? "mb-4" : ""}>
                {!props.field.HideLabel ? <label className="text-grey-800 text-sm font-bold mb-2" htmlFor={props.field.Parameter.Identifier}>{props.field.Parameter.Name}</label> : null}
                {props.field.Parameter.Hint && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
            </div>
            {
                props.field.Parameter.Hint && showHint
                    ?   <div className="inline-block text-sm mb-4 -mt-2 rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                    :   null
            }
            {
                previouslySelectedFilename === null 
                    ?   <input type="file" name={props.field.Parameter.Identifier} id={props.field.Parameter.Identifier} onChange={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: e.target.id, data: e.target.files}})} accepttypes={props.field.AcceptTypes} allowmultiple={props.field.AllowMultiple ? "allowmultipe" : undefined}  className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                    :   <div>
                            <input type="text" name={`${ props.field.Parameter.Identifier }file`} id={`${ props.field.Parameter.Identifier  }file`} disabled="disabled" className="inline w-11/12 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800 mb-3" value={previouslySelectedFilename} />
                            <button disabled={props.formState[props.field.Parameter.Identifier].externalData !== null} data-test-id={`reset-file-upload-${ props.field.Parameter.Identifier }`} className="inline bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-1/12" type="button" onClick={() => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: null}})}><FontAwesomeIcon icon={faUndo} /></button>
                        </div>
            }
        </div>
        {
            props.formState[props.field.Parameter.Identifier].externalData !== null
                ?   <>
                        <div className="mt-8 mb-4">
                            <div className="text-center">{uploadedFileTable}</div>
                            <div className="flex justify-evenly mt-4">
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={handleClearSampleDataButton}>Clear Uploaded Data</button>
                                <a href={URL.createObjectURL(externalDataBlob)} download="convertedFile.json" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><FontAwesomeIcon icon={faDownload} /> Download as JSON</a>
                            </div>
                        </div>
                    </>
                :   <>
                        { convertToJsonAPIError && <ErrorTile message={convertToJsonAPIError} /> }
                        <div className="flex justify-evenly">
                            <button disabled={loadingDataFromAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={handleUploadFile}>{loadingDataFromAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Upload"}</button>
                        </div>
                    </>
        }
        </>
    );
}