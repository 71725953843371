import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSkull, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

import { 
    useGetKillSettingsQuery,
    useCreateKillTCIorKillAllMutation,
    useDeleteKillSettingsMutation,
    useGetProvisionedProcessesQuery
} from "../../../services/mip";

export default function KillTasks(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Admin") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - Kill Tasks";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: getKillTasksSettingsData,
        error: getKillTasksSettingsError,
        isFetching: getKillTasksSettingsIsFetching,
        refetch: refetchKillTasksData
    } = useGetKillSettingsQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const [successMessage, setSuccessMessage] = useState(null);
    const [validationMessage, setValidationMessage] = useState(null);
    const [submittingToAPI, setSubmittingToAPI] = useState(false);
    const [tciToAdd, setTciToAdd] = useState("");

    const processNameByIdentifier = {};
    if (provisionedProcessData && Array.isArray(provisionedProcessData)) {
        for (const process of provisionedProcessData) {
            processNameByIdentifier[process.Identifier] = process.Name;
        }
    }

    const [addTciToKillFileOrCreateKillAllFile] = useCreateKillTCIorKillAllMutation();
    const createKillAllOrAddTCI = async (useTci = false) => {
        setSubmittingToAPI(true);
        setValidationMessage(null);
        setSuccessMessage(null);
        try {
            await addTciToKillFileOrCreateKillAllFile(useTci ? tciToAdd : undefined).unwrap();
            setSuccessMessage(useTci ? `${ tciToAdd } Added to TCI Kill List`: "Kill All File Created");
            if (useTci) {
                setTciToAdd("");
            }
            refetchKillTasksData();
        } catch (err) {
            setValidationMessage(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
        }
        setSubmittingToAPI(false);
    };

    const [deleteKillProcesses] = useDeleteKillSettingsMutation();
    const removeKillProcesses = async (identifier, isTci = false) => {
        setSubmittingToAPI(true);
        setValidationMessage(null);
        setSuccessMessage(null);
        try {
            await deleteKillProcesses(identifier).unwrap();
            setSuccessMessage(
                identifier
                    ? isTci
                        ? `${ identifier } Removed From TCI List`
                        : `${ processNameByIdentifier[identifier] } Removed from Process List`
                    : "Kill All File Deleted"
            );
            refetchKillTasksData();
        } catch (err) {
            setValidationMessage(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
        }
        setSubmittingToAPI(false);
    };

    useEffect(() => {
        if ((getKillTasksSettingsError && getKillTasksSettingsError.status === 401) || (provisionedProcessAPIError && provisionedProcessAPIError === 401)) {
            window.location.href="/login";
        }
    }, [getKillTasksSettingsError, provisionedProcessAPIError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="kill-tasks" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    {
                        validationMessage
                            ? <ErrorTile message={validationMessage} />
                            : null
                    }
                    {
                        successMessage
                            ? <div className="border text-center border-green-500 rounded-lg mt-2 mb-4 p-4 bg-green-200">{successMessage}</div>
                            : null
                    }
                    {
                        getKillTasksSettingsError || provisionedProcessAPIError
                            ? <ErrorTile message={getKillTasksSettingsError || provisionedProcessAPIError} />
                            : getKillTasksSettingsIsFetching || provisionedProcessDataFetching
                                ?   <LoadingSpinner text={"Loading Kill Settings Data"} />
                                :   <>
                                    <div className="text-2xl pb-4 flex">
                                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faSkull} /> Task Chains to Kill</div>
                                    </div>
                                    {
                                        getKillTasksSettingsData && provisionedProcessData && Array.isArray(getKillTasksSettingsData.Data?.TaskChainInstances) && getKillTasksSettingsData.Data.TaskChainInstances.length === 0
                                            ? <div className="px-6">No TCIs in kill list.</div>
                                            :   <div data-test-id="kill-tci-files" className="grid" style={{gridTemplateColumns: "auto min-content"}}>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">TCI</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                                    {
                                                        getKillTasksSettingsData.Data.TaskChainInstances.map((taskChainIdentifier, index) => (
                                                            <div className="contents group" key={`tci-${ index }`}>
                                                                <div className="p-2 border group-odd:bg-gray-100">{taskChainIdentifier}</div>
                                                                <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                                    <button title="Delete" id={`delete-tci-${ taskChainIdentifier }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => removeKillProcesses(taskChainIdentifier, true)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                    }
                                    <div className="p-2 flex">
                                        <input id="tciToAdd" name="tciToAdd" value={tciToAdd} onChange={(e) => setTciToAdd(e.target.value)} className="shadow appearance-none border rounded flex-1 py-1 pl-3 text-grey-800" placeholder="TCI to Kill" />
                                        <div className="">
                                            <button id="add-tci" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded mt-1 mx-2" type="button" onClick={() => createKillAllOrAddTCI(true)}><FontAwesomeIcon icon={faPlus} /></button>
                                        </div>
                                    </div>
                                    <div className="text-2xl mt-4 pb-4 flex">
                                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faSkull} /> Processes to Kill</div>
                                    </div>
                                    {
                                        getKillTasksSettingsData && provisionedProcessData && Array.isArray(getKillTasksSettingsData.Data?.ProvisionedProcessIdentifiers) && Array.isArray(provisionedProcessData) && getKillTasksSettingsData.Data.ProvisionedProcessIdentifiers.length === 0
                                            ? <div className="px-6">No processes in kill list.</div>
                                            :   <div data-test-id="kill-process-files" className="grid" style={{gridTemplateColumns: "auto min-content"}}>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Process</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                                    {
                                                        getKillTasksSettingsData.Data.ProvisionedProcessIdentifiers.map((processIdentifier, index) => (
                                                            <div className="contents group" key={`process-${ index }`}>
                                                                <div className="p-2 border group-odd:bg-gray-100">{processNameByIdentifier[processIdentifier]}</div>
                                                                <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                                    <button title="Delete" id={`delete-process-${ processIdentifier }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => removeKillProcesses(processIdentifier)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                    }
                                    <div className="text-2xl mt-4 pb-4 flex">
                                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faSkull} /> Kill All</div>
                                    </div>
                                    {
                                        getKillTasksSettingsData
                                            ?   <>
                                                    <div className="px-6">{getKillTasksSettingsData.Data.All === false ? "No kill all file" : "Kill all file present"}. </div>
                                                    <div className="flex justify-evenly">
                                                        {
                                                            getKillTasksSettingsData.Data.All === false
                                                                ? <button disabled={submittingToAPI} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => createKillAllOrAddTCI(false)}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Create Kill All File"}</button>
                                                                : <button disabled={submittingToAPI} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => {removeKillProcesses(undefined);}}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Remove Kill All File"}</button>
                                                        }
                                                    </div>
                                                </>
                                            : null
                                    }
                                    </>
                    }
                </div>
            </div>
        </div>
        </>
    );
}