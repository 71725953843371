

export default function ExportDataSelectorArrayInfoField(props) {
    return (
        <div className="shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t">
                <div className="text-xl p-2 font-bold">{props.arrayIdentifier}</div>
            </div>
            <div className="m-4">
                <div className="mb-4">
                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`${ props.arrayIdentifier  }VerticalOrHorizontal`}>
                        Array Flatten Type
                    </label>
                </div>
                <select name={`${ props.arrayIdentifier  }VerticalOrHorizontal`} id={`${ props.arrayIdentifier  }VerticalOrHorizontal`} value={props.mappingData.arrayData[props.arrayIdentifier].VerticalOrHorizontal} onChange={(e) => {props.setArrayDataValue(props.arrayIdentifier, "VerticalOrHorizontal", e.target.value);}} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                    <option value="vertical">Vertical</option>
                    <option value="horizontal">Horizontal</option>
                </select>
            </div>
            {
                props.mappingData.arrayData[props.arrayIdentifier].VerticalOrHorizontal === "horizontal"
                    ?   <>
                            <div className="m-4">
                                <div className="mb-4">
                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`${ props.arrayIdentifier  }NumberOfColumns`}>
                                        Number of Horizontal Fields
                                    </label>
                                </div>
                                <input name={`${ props.arrayIdentifier  }NumberOfColumns`} id={`${ props.arrayIdentifier  }NumberOfColumns`} type='number' min="1" value={props.mappingData.arrayData[props.arrayIdentifier].NumberOfColumns} onChange={(e) => {props.setArrayDataValue(props.arrayIdentifier, "NumberOfColumns", e.target.value);}} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                            </div>
                        </>
                    : null
            }
        </div>
    );
}