import { JsonType } from "../../../midgard.js";
import { useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faTrash, faWarning } from "@fortawesome/free-solid-svg-icons";
import GeneralDataSelectorFieldDataMapSelector from "../GeneralDataSelectorFieldDataMapSelector";
import GeneralDataSelectorCalculationsInfo from "../GeneralDataSelectorCalculationsInfo";
import GeneralDataSelectorStringConcatInfo from "../GeneralDataSelectorStringConcatInfo";
import GeneralDataSelectorFieldTimeZoneSelector from "../GeneralDataSelectorFieldTimeZoneSelector";
import { useGetSettingsQuery } from "../../../services/mip";
import GeneralDataSelectorFormatStringControl from "../GeneralDataSelectorFormatStringControl";

export default function DataSelectorField(props) {

    const deleteDisabled = props.property.IsRequired;

    // cypress doesn't do well with periods in the field names because
    // they are CSS selector jargon so we replace them with -'s to make tests
    // possible. It does mean we have to remember this in the testing.
    // go ahead and add the index here too
    const htmlFieldName = `${ props.property.Name.replaceAll(".", "-").replaceAll("[]", "").replaceAll("$", "")  }-${  props.index }`;

    const [expandAdditionalOptions, setExpandAdditionalOptions] = useState(props.userEnteredData.formatOption !== "None" || props.userEnteredData.lookupCacheFilename !== "" || props.userEnteredData.useDataMaps || props.userEnteredData.applyCalculation || props.userEnteredData.applyStringConcatenations || props.userEnteredData.lookupEntityKeyByIdentifier || props.userEnteredData.useLookupEntityKeyFallback || props.userEnteredData.urlSubstitution);
    const {
        data: settingsData
    } = useGetSettingsQuery(null);
    const hasDataMaps = settingsData && settingsData.DataMaps && Array.isArray(settingsData.DataMaps) && settingsData.DataMaps.length > 0;
    const dataMaps = hasDataMaps ? settingsData.DataMaps : undefined;

    const sourceOptions = []; // all source options including static, lookup, etc.
    const fileOptions = [];  // just the options from the file.

    if (Array.isArray(props.columnsInDataFile)) {
        props.columnsInDataFile.forEach((column, index) => {
            fileOptions.push(<option key={index} value={index}>{column}</option>);
        });
    }

    const currentlySelectedIndex = parseInt(props.userEnteredData.dataFileLocation);
    const currentlySelectedColumnName = props.columnsInDataFile[currentlySelectedIndex];

    if (props.property.AllowConstantValueOnImport) {
        sourceOptions.push(<option key="Constant" value="Constant">Constant Value</option>);
    }

    if (fileOptions.length !== 0) {
        sourceOptions.push(<option key="DataFile" value="DataFile">Data File</option>);
    }

    if (props.property.HasRegionDefault) {
        sourceOptions.push(<option key="RegionDefault" value="RegionDefault">Region Default</option>);
    }

    const toggleSourceField = (propertyName, sourceValue) => {
        if (!["DataFile", "Constant"].includes(sourceValue)) {
            setExpandAdditionalOptions(false);
        }
        props.setFieldValue(propertyName, props.index, "source", sourceValue);
    };

    const propertyKeyOptions = [];
    if (props.property.PropertyKeyOptions) {
        for (const propertyKey of props.property.PropertyKeyOptions) {
            propertyKeyOptions.push(<option key={propertyKey.Value} value={propertyKey.Value}>{propertyKey.Key}</option>);
        }
    }

   // when using a fixed layout file, the first data file location may not be index 0.
    // we instantiate all new files with dataFileLocation: "0"
    // this checks to see if dataFileLocation doesn't exist in the options and if that's the case resets it.
    useLayoutEffect(() => {
        const fixedWidthColumnIndexes = [];
        if (Array.isArray(props.columnsInDataFile)) {
            props.columnsInDataFile.forEach((column, index) => {
                if (!props.fixedWidthUnmappedIndexes.includes(props.columnsInDataFile.indexOf(column))) {
                    fixedWidthColumnIndexes.push(index.toString());
                }
            });
        }
        if (!fixedWidthColumnIndexes.includes(props.userEnteredData.dataFileLocation)) {
            const [ firstIndex ] = fixedWidthColumnIndexes;
            props.setFieldValue(props.property.Name, props.index, "dataFileLocation", firstIndex);
        }
    }, [props]);

    // if we have property key options and don't have a default, set it to the first one in the list.
    // if we do have one, but it's not in the list, also set it to the first one in the list.
    useLayoutEffect(() => {
        if (Array.isArray(props.property.PropertyKeyOptions) && props.property.PropertyKeyOptions.length > 0) {
            const propertyKeyValues = props.property.PropertyKeyOptions.map((p) => p.Value);
            if (!propertyKeyValues.includes(props.userEnteredData.customPropertyKey)) {
                props.setFieldValue(props.property.Name, props.index, "customPropertyKey", props.property.PropertyKeyOptions[0].Value);
            }
        }
    }, [props]);

    // determine if I should show a duplicate mapping warning
    let countPropertyKeyAlreadyMapped = 0;
    if (props.customPropertyKeysAlreadyMapped.hasOwnProperty(props.property.Name)) {
        for (const alreadyMappedKey of props.customPropertyKeysAlreadyMapped[props.property.Name]) {
            if (props.userEnteredData.customPropertyKey !== "" && props.userEnteredData.customPropertyKey === alreadyMappedKey) {
                countPropertyKeyAlreadyMapped++;
            }
        }
    }
    const showCustomPropertyDuplicateWarning = countPropertyKeyAlreadyMapped > 1;
    const showCustomPropertyChangedWarning = props.userEnteredData.previousCustomPropertyKey !== null && props.userEnteredData.previousCustomPropertyKey !== undefined && props.userEnteredData.previousCustomPropertyKey !== props.userEnteredData.customPropertyKey;

    return (
        <>
        <div className="mappedProperty shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t">
                <div data-test-id="importDataSelectorFieldTargetName" className="flex-1 text-xl p-2 font-bold">{props.property.Name}</div>
                <div>
                    {
                        deleteDisabled 
                            ? null
                            : <button className="bg-red-700 hover:bg-red-900 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {props.onDeleteClick(props.property.Name, props.index);}}><FontAwesomeIcon icon={faTrash} /></button>
                    }
                </div>
            </div>
            <div className={props.userEnteredData.missingLinkedPropertyAdded === true || showCustomPropertyChangedWarning || showCustomPropertyDuplicateWarning ? "bg-orange-200 p-4" : "m-4"}>
                {
                    props.userEnteredData.missingLinkedPropertyAdded === true
                        ? <div className="pl-4 text-orange-500 text-center"><FontAwesomeIcon icon={faWarning} /> This field was added because it was a missing linked property. <FontAwesomeIcon icon={faWarning} /></div>
                        : null
                }
                {
                    showCustomPropertyDuplicateWarning
                        ? <div className="pl-4 text-orange-500 text-center"><FontAwesomeIcon icon={faWarning} /> This key is mapped more than once. <FontAwesomeIcon icon={faWarning} /></div>
                        : null
                }
                {
                    showCustomPropertyChangedWarning
                        ? <div className="pl-4 text-orange-500 text-center"><FontAwesomeIcon icon={faWarning} /> This key is no longer mapped to its original value of '{ props.userEnteredData.previousCustomPropertyKey }'. <FontAwesomeIcon icon={faWarning} /></div>
                        : null
                }
                {
                    props.property.PropertyKeyOptions
                        ?   <div className="flex p-1">
                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`customPropertyKey-${  htmlFieldName }`}>
                                    Property Key
                                </label>
                                <select name={`customPropertyKey-${  htmlFieldName }`} id={`customPropertyKey-${  htmlFieldName }`} value={props.userEnteredData.customPropertyKey} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "customPropertyKey", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                    {propertyKeyOptions}
                                </select>
                            </div>
                        : null
                }
                <div className="flex p-1">
                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`source-${ htmlFieldName }`}>
                        Source
                    </label>
                    <select name={`source-${ htmlFieldName }`} id={`source-${ htmlFieldName }`} value={props.userEnteredData.source} onChange={(e) => {toggleSourceField(props.property.Name, e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                        {sourceOptions}
                    </select>
                </div>
                {
                    props.userEnteredData.source === "Constant"
                        ?   <div className="flex p-1">
                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`constantValue-${  htmlFieldName }`}>
                                    Value
                                </label>
                                {
                                    Array.isArray(props.property.ValidConstantValuesOnImport)
                                        ? props.property.AllowMultipleValidConstantValuesOnImport === true
                                            ?   <select multiple="multiple" name={`constantValue-${ htmlFieldName }`} id={`constantValue-${ htmlFieldName }`} value={props.userEnteredData.constantValue} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "constantValue", e.target.options);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                    {
                                                        props.property.ValidConstantValuesOnImport.map((optionValue, index) => (
                                                            <option key={`constantValue-${ htmlFieldName }-${ index }`} value={optionValue.Value}>{optionValue.Key}</option>
                                                        ))
                                                    }
                                                </select>
                                            :   <select name={`constantValue-${ htmlFieldName }`} id={`constantValue-${ htmlFieldName }`} value={props.userEnteredData.constantValue} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "constantValue", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                    {
                                                        props.property.ValidConstantValuesOnImport.map((optionValue, index) => (
                                                            <option key={`constantValue-${ htmlFieldName }-${ index }`} value={optionValue.Value}>{optionValue.Key}</option>
                                                        ))
                                                    }
                                                </select>
                                        : <input name={`constantValue-${  htmlFieldName }`} id={`constantValue-${  htmlFieldName }`} type='text' value={props.userEnteredData.constantValue} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "constantValue", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                }
                            </div>
                        : null
                }
                {
                    props.userEnteredData.source === "DataFile"
                        ?   <div className="flex p-1">
                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`dataFileLocation-${  htmlFieldName }`}>
                                    File Location
                                </label>
                                <select name={`dataFileLocation-${  htmlFieldName }`} id={`dataFileLocation-${  htmlFieldName }`} value={props.userEnteredData.dataFileLocation} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "dataFileLocation", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                    {fileOptions}
                                </select>
                            </div>
                        : null
                }
                {
                    // This checks to see if the source is an array but the destination is not
                    // and also checks to see if the source has more arrays than the destination
                    // in both cases concatDelim is required
                    props.userEnteredData.source === "DataFile" && currentlySelectedColumnName && ((currentlySelectedColumnName.split("[]").length > props.property.Name.split("[]").length) || (!props.property.Name.endsWith("[]") && currentlySelectedColumnName.endsWith("[]")))
                        ?   <>
                                <div className="flex p-1">
                                    <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`concatenateDelimiter-${  htmlFieldName }`}>
                                        Delimiter
                                    </label>
                                    <input name={`concatenateDelimiter-${  htmlFieldName }`} id={`concatenateDelimiter-${  htmlFieldName }`} required="required" value={props.userEnteredData.concatenateDelimiter} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "concatenateDelimiter", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                </div>
                            </>
                        : null
                }
                {
                    expandAdditionalOptions || ["Constant", "DataFile"].includes(props.userEnteredData.source)
                        ?   <div className="m-1 text-right">
                                <span className="text-sm text-grey-200 hover:cursor-pointer" onClick={() => setExpandAdditionalOptions(!expandAdditionalOptions)}>
                                {
                                    expandAdditionalOptions 
                                        ? <span data-test-id={`additionalOptions-${  htmlFieldName }`}>Additional Options <FontAwesomeIcon icon={faCaretDown} /></span>
                                        : <span data-test-id={`additionalOptions-${  htmlFieldName }`}>Additional Options <FontAwesomeIcon icon={faCaretRight} /></span>
                                }
                                </span>
                            </div>
                        : null
                }
                {
                    expandAdditionalOptions 
                        ?   <>
                            {
                                ["Constant", "DataFile"].includes(props.userEnteredData.source) && currentlySelectedColumnName && !currentlySelectedColumnName.endsWith("[]")
                                    ?   <>
                                            <div className="flex p-1">
                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatOption-${  htmlFieldName }`}>
                                                    Format Option
                                                </label>
                                                <select name={`formatOption-${  htmlFieldName }`} id={`formatOption-${  htmlFieldName }`} value={props.userEnteredData.formatOption} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "formatOption", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                    <option value="None">None</option>
                                                    <option value="Format">Format</option>
                                                    <option value="Parse">Parse</option>
                                                    <option value="ParseThenFormat">Parse then Format</option>
                                                </select>
                                            </div>
                                            {
                                                props.userEnteredData.formatOption !== "None"
                                                    ?   <>
                                                            <div className="flex p-1">
                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatTypeCode-${  htmlFieldName }`}>
                                                                    Data Type
                                                                </label>
                                                                <select name={`formatTypeCode-${ htmlFieldName }`} id={`formatTypeCode-${ htmlFieldName }`} value={props.userEnteredData.formatTypeCode} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "formatTypeCode", e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                                                {
                                                                    JsonType.map((jsonType) => <option value={jsonType} key={jsonType}>{jsonType}</option>)
                                                                }
                                                                </select>
                                                            </div>
                                                            {   /* Parse String only appears on DateTimes */
                                                                props.userEnteredData.formatTypeCode === "DateTime" && ["Parse", "ParseThenFormat"].includes(props.userEnteredData.formatOption)
                                                                    ?   <>
                                                                            <div className="flex p-1">
                                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`parseString-${  htmlFieldName }`}>
                                                                                    Parse String
                                                                                </label>
                                                                                <input name={`parseString-${ htmlFieldName }`} id={`parseString-${ htmlFieldName }`} type='text' value={props.userEnteredData.parseString} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "parseString", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                                                            </div>
                                                                        </>
                                                                    : null
                                                            }
                                                            {   /* The format string (non-control version) is shown for datetimes and double */
                                                                ["DateTime", "Double"].includes(props.userEnteredData.formatTypeCode) && ["Format", "ParseThenFormat"].includes(props.userEnteredData.formatOption)
                                                                    ?   <>
                                                                            <div className="flex p-1">
                                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatString-${ htmlFieldName }`}>
                                                                                    Format String
                                                                                </label>
                                                                                <input name={`formatString-${ htmlFieldName }`} id={`formatString-${ htmlFieldName }`} type='text' value={props.userEnteredData.formatString} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "formatString", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                                                            </div>
                                                                        </>
                                                                    : null
                                                            }
                                                            {   /* The format control is only shown for strings */
                                                                props.userEnteredData.formatTypeCode === "String" && ["Format", "ParseThenFormat"].includes(props.userEnteredData.formatOption)
                                                                    ?   <>
                                                                            <div className="flex p-1">
                                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`formatString-${ htmlFieldName }`}>
                                                                                    Format String
                                                                                </label>
                                                                                <GeneralDataSelectorFormatStringControl
                                                                                    key={`formatStringControl-${ htmlFieldName }`}
                                                                                    htmlFieldName={htmlFieldName}
                                                                                    index={props.index}
                                                                                    property={props.property}
                                                                                    setFieldValue={props.setFieldValue}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    : null
                                                            }
                                                            {
                                                                props.userEnteredData.formatTypeCode === "DateTime"
                                                                    ?   <>
                                                                            <div className="flex p-1">
                                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`sourceTimeZoneId-${ htmlFieldName }`}>
                                                                                    Source TZ
                                                                                </label>
                                                                                <GeneralDataSelectorFieldTimeZoneSelector
                                                                                    key={`sourceTimeZoneId-${ htmlFieldName }`}
                                                                                    formValueKey="sourceTimeZoneId"
                                                                                    htmlFieldName={`sourceTimeZoneId-${ htmlFieldName }`}
                                                                                    index={props.index}
                                                                                    property={props.property}
                                                                                    setFieldValue={props.setFieldValue}
                                                                                />
                                                                            </div>
                                                                            <div className="flex p-1">
                                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`destinationTimeZoneId-${ htmlFieldName }`}>
                                                                                    Destination TZ
                                                                                </label>
                                                                                <GeneralDataSelectorFieldTimeZoneSelector
                                                                                    key={`destinationTimeZoneId-${ htmlFieldName }`}
                                                                                    formValueKey="destinationTimeZoneId"
                                                                                    htmlFieldName={`destinationTimeZoneId-${ htmlFieldName }`}
                                                                                    index={props.index}
                                                                                    property={props.property}
                                                                                    setFieldValue={props.setFieldValue}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    : null
                                                            }
                                                        </>
                                                    : null
                                            }
                                            {
                                                hasDataMaps
                                                    ?   <div className="m-1 mb-2 pl-2">
                                                            <label className="align-middle" htmlFor={`useDataMaps-${ htmlFieldName }`}>
                                                                <input type="checkbox" value={props.userEnteredData.useDataMaps || false} name={`useDataMaps-${  htmlFieldName }`} id={`useDataMaps-${  htmlFieldName }`} checked={props.userEnteredData.useDataMaps ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "useDataMaps", e.target.checked);}} className="mr-2 align-middle" />
                                                                <span className="text-grey-800 mb-4 align-middle">Use Data Map</span>
                                                            </label>
                                                        </div>
                                                    : null
                                            }
                                            {
                                                props.userEnteredData.useDataMaps && dataMaps
                                                    ?   <>
                                                            <div className="flex p-1">
                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`dataMapName-${  htmlFieldName }`}>
                                                                    Data Map
                                                                </label>
                                                                <GeneralDataSelectorFieldDataMapSelector 
                                                                    key={`dataMapName-${ htmlFieldName }`}
                                                                    htmlFieldName={htmlFieldName}
                                                                    property={props.property}
                                                                    index={props.index}
                                                                    setFieldValue={props.setFieldValue}
                                                                    dataMaps={dataMaps}
                                                                />
                                                            </div>
                                                        </>
                                                    : null
                                            }
                                            <div className="m-1 mb-2 pl-2">
                                                <label className="align-middle" htmlFor={`applyStringConcatenations-${  htmlFieldName }`}>
                                                    <input type="checkbox" value={props.userEnteredData.applyStringConcatenations || false} name={`applyStringConcatenations-${  htmlFieldName }`} id={`applyStringConcatenations-${  htmlFieldName }`} checked={props.userEnteredData.applyStringConcatenations ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "applyStringConcatenations", e.target.checked);}} className="mr-2 align-middle" />
                                                    <span className="text-grey-800 align-middle">Concatenate Strings</span>
                                                </label>
                                            </div>
                                            {
                                                props.userEnteredData.applyStringConcatenations
                                                    ?   <GeneralDataSelectorStringConcatInfo
                                                            key={`applyStringConcatenations-${ htmlFieldName }`}
                                                            property={props.property}
                                                            propertyIndex={props.index}
                                                            setFieldValue={props.setFieldValue}
                                                            htmlFieldName={htmlFieldName}
                                                            concatenations={props.userEnteredData.concatenations}
                                                            sourceOptions={props.columnsInDataFile}
                                                        />
                                                    :   null
                                            }
                                        </>
                                    : null // end DataFile || Lookup
                            }
                            <div className="m-1 mb-2 pl-2">
                                <label className="align-middle" htmlFor={`applyCalculation-${  htmlFieldName }`}>
                                    <input type="checkbox" value={props.userEnteredData.applyCalculation || false} name={`applyCalculation-${  htmlFieldName }`} id={`applyCalculation-${  htmlFieldName }`} checked={props.userEnteredData.applyCalculation ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "applyCalculation", e.target.checked);}} className="mr-2 align-middle" />
                                    <span className="text-grey-800 align-middle">Apply Calculation</span>
                                </label>
                            </div>
                            {
                                props.userEnteredData.applyCalculation
                                    ?   <GeneralDataSelectorCalculationsInfo
                                            key={`applyCalculation-${ htmlFieldName }`}
                                            property={props.property}
                                            propertyIndex={props.index}
                                            setFieldValue={props.setFieldValue}
                                            htmlFieldName={htmlFieldName}
                                            calculations={props.userEnteredData.calculations}
                                            sourceOptions={props.columnsInDataFile}
                                        />
                                    :   null
                            }
                            {
                                props.property.AllowIdentifierLookup === true
                                    ?   <div className="m-1 mb-2 pl-2">
                                            <label className="align-middle" htmlFor={`lookupEntityKeyByIdentifier-${  htmlFieldName }`}>
                                                <input type="checkbox" value={props.userEnteredData.lookupEntityKeyByIdentifier || false} name={`lookupEntityKeyByIdentifier-${  htmlFieldName }`} id={`lookupEntityKeyByIdentifier-${  htmlFieldName }`} checked={props.userEnteredData.lookupEntityKeyByIdentifier ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "lookupEntityKeyByIdentifier", e.target.checked);}} className="mr-2 align-middle" />
                                                <span className="text-grey-800 align-middle">Lookup Entity Key by Identifier</span>
                                            </label>
                                        </div>
                                    :   null
                            }
                            {   // only show the lookup fall back if it's a lookup
                                props.userEnteredData.lookupEntityKeyByIdentifier
                                    ?   <>
                                            <div className="flex p-1">
                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`lookupCacheFilename-${ htmlFieldName }`}>
                                                    Cache File
                                                </label>
                                                <input name={`lookupCacheFilename-${ htmlFieldName }`} id={`lookupCacheFilename-${ htmlFieldName }`} type='text' value={props.userEnteredData.lookupCacheFilename} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "lookupCacheFilename", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                            </div>
                                            {
                                                props.property.AllowEntityKeyFallback === true
                                                    ?   <>
                                                            <div className="m-1 mb-2 pl-2">
                                                                <label className="align-middle" htmlFor={`useLookupEntityKeyFallback-${  htmlFieldName }`}>
                                                                    <input type="checkbox" value={props.userEnteredData.useLookupEntityKeyFallback || false} name={`useLookupEntityKeyFallback-${  htmlFieldName }`} id={`useLookupEntityKeyFallback-${  htmlFieldName }`} checked={props.userEnteredData.useLookupEntityKeyFallback ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "useLookupEntityKeyFallback", e.target.checked);}} className="mr-2 align-middle" />
                                                                    <span className="text-grey-800 align-middle">Use Entity Key Fallback</span>
                                                                </label>
                                                            </div>
                                                            {
                                                                props.userEnteredData.useLookupEntityKeyFallback
                                                                    ?   <>
                                                                            <div className="flex p-1">
                                                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`lookupEntityKeyFallback-${  htmlFieldName }`}>
                                                                                    Fallback EK
                                                                                </label>
                                                                                <input name={`lookupEntityKeyFallback-${  htmlFieldName }`} id={`lookupEntityKeyFallback-${  htmlFieldName }`} type='number' required="required" value={props.userEnteredData.lookupEntityKeyFallback} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "lookupEntityKeyFallback", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                                                            </div>
                                                                        </>
                                                                    : null
                                                            }
                                                        </>
                                                    :   null
                                            }
                                        </>
                                    : null
                            }
                            {
                                props.property.AllowUrlSubstitution === true
                                    ?   <div className="m-1 mb-2 pl-2">
                                            <label className="align-middle" htmlFor={`urlSubstitution-${  htmlFieldName }`}>
                                                <input type="checkbox" value={props.userEnteredData.urlSubstitution || false} name={`urlSubstitution-${  htmlFieldName }`} id={`urlSubstitution-${  htmlFieldName }`} checked={props.userEnteredData.urlSubstitution ? "checked" : ""} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "urlSubstitution", e.target.checked);}} className="mr-2 align-middle" />
                                                <span className="text-grey-800 align-middle">Substitute URLs with bxtr.link</span>
                                            </label>
                                        </div>
                                    :   null
                            }
                            {   // only show the lookup fall back if it's a lookup
                                props.userEnteredData.urlSubstitution
                                    ?   <>
                                            <div className="flex p-1">
                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`urlSubstitutionTTL-${ htmlFieldName }`}>
                                                    TTL Hours
                                                </label>
                                                <input name={`urlSubstitutionTTL-${ htmlFieldName }`} id={`urlSubstitutionTTL-${ htmlFieldName }`} type="number" min="0" step="0.01"  value={props.userEnteredData.urlSubstitutionTTL} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "urlSubstitutionTTL", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                            </div>
                                            <div className="flex p-1">
                                                <label className="w-32 inline-block p-2 text-grey-800 font-bold align-middle" htmlFor={`urlSubstitutionKeyLength-${ htmlFieldName }`}>
                                                    Key Length
                                                </label>
                                                <input name={`urlSubstitutionKeyLength-${ htmlFieldName }`} id={`urlSubstitutionKeyLength-${ htmlFieldName }`} type="number" min="0" step="1"  value={props.userEnteredData.urlSubstitutionKeyLength} onChange={(e) => {props.setFieldValue(props.property.Name, props.index, "urlSubstitutionKeyLength", e.target.value);}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" />
                                            </div>
                                        </>
                                    : null
                            }
                            </>
                        : null // end expandAdditionalOptions
                }
            </div>
        </div>
        {
            props.showAddAdditionalFieldOption
                ?   <div className="mb-4 text-right">
                        <div className="inline text-blue-500 hover:text-blue-700 hover:underline hover:cursor-pointer" onClick={() => {props.onAddFieldClick(props.property.Name);}}>Add another {props.property.Name}</div>
                    </div>
                :   null
        }
        </>
    );
}