import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import AdminLeftNav from "../../../components/Admin/AdminLeftNav";
import {
    useGetProfileQuery,
    useGetCustomersQuery
} from "../../../services/mip";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faIdCard, faPencil, faPlus, faStar } from "@fortawesome/free-solid-svg-icons";

export default function Customers(props) {   
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ManageCustomers") || privilege.includes("All");
    const canAddCustomer = privilege.includes("CreateCustomer") || privilege.includes("All");

    useEffect(() => {
        document.title = "Administration - Customers";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: profileData,
        error: profileError,
        isFetching: profileIsFetching
    } = useGetProfileQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: customersData,
        error: customersError,
        isFetching: customersIsFetching
    } = useGetCustomersQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const somethingFetching = profileIsFetching || customersIsFetching;
    const firstError = profileError || customersError;

    useEffect(() => {
        let redirectToLogin = false;
        if (profileError && profileError.status === 401) {
            redirectToLogin = true;
        }
        if (customersError && customersError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        profileError,
        customersError
    ]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <AdminLeftNav selected="customers" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faIdCard} /> Customers</div>
                        {
                            canAddCustomer
                                ?   <div className="flex-initial text-right">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/admin/customers/new");}}><FontAwesomeIcon className="w-auto" icon={faPlus} /></button>
                                    </div>
                                : null
                        }
                    </div>
                    {
                        firstError
                            ? <ErrorTile message={firstError} />
                            : somethingFetching || !customersData || !customersData.Data || !Array.isArray(customersData.Data)
                                ? <LoadingSpinner text={"Loading Customers"} />
                                : customersData.Data.length === 0
                                    ?   <div className="text-center">No customers loaded? Something has gone very wrong.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "auto auto min-content"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Customer</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Group</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            {
                                                customersData.Data.map((customer, index) => (
                                                    <div className="contents group" key={`customer-${  index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100">
                                                            {
                                                                customer.Identifier === profileData.Data.CustomerIdentifier
                                                                    ? <FontAwesomeIcon icon={faStar} className="mr-2" />
                                                                    : null
                                                            }
                                                            {customer.Name}
                                                        </div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{customer.GroupDisplayName}</div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            <button title="Copy Customer Identifier" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigator.clipboard.writeText(customer.Identifier).then(() => alert(`Copied identifier: ${  customer.Identifier }`));}}><FontAwesomeIcon icon={faCopy} /></button>
                                                            <button title="Edit" disabled={!canAddCustomer} className={`${ canAddCustomer ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-400 cursor-not-allowed" } text-white font-bold py-1 px-4 rounded text-sm m-2`} type="button" onClick={() => {navigate(`/admin/customers/${ customer.Identifier }`);}}><FontAwesomeIcon icon={faPencil} /></button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    );
}