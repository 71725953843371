export default function ManualProcessPlaceholderSetting(props) {

    const {placeholderKey, placeholderSetting, placeholderStateDispatch} = props;

    return (
        <>
        <div className="shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t text-xl p-2 font-bold">{placeholderKey}</div>
            <div className="p-3">
                <div className="my-3">
                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor={`DisplayName-${ placeholderKey }`}>
                        Display Name
                    </label>
                    <input type="text" id={`DisplayName-${ placeholderKey }`} name={`DisplayName-${ placeholderKey }`} value={placeholderSetting.DisplayName ?? ""} onChange={(e) => placeholderStateDispatch({type: "setPlaceholderValue", payload: {key: placeholderKey, property: "DisplayName", value: e.target.value}})} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                </div>
                <div className="my-3">
                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`AllowMultiple-${ placeholderKey }`}>
                        <input type="checkbox" disabled={placeholderSetting.DisableAllowMultiple} value={placeholderSetting.AllowMultiple} name={`AllowMultiple-${ placeholderKey }`} id={`AllowMultiple-${ placeholderKey }`} checked={placeholderSetting.AllowMultiple ? "checked" : ""} onChange={(e) => {placeholderStateDispatch({type: "setPlaceholderValue", payload: {key: placeholderKey, property: "AllowMultiple", value: e.target.checked}});}} className="mr-2 align-middle" />
                        <span className="text-grey-800 mb-4 align-middle">Allow Multiple</span>
                    </label>
                </div>
                <div className="my-3">
                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`Required-${ placeholderKey }`}>
                        <input type="checkbox" disabled={placeholderSetting.DisableRequired} value={placeholderSetting.Required} name={`Required-${ placeholderKey }`} id={`Required-${ placeholderKey }`} checked={placeholderSetting.Required ? "checked" : ""} onChange={(e) => {placeholderStateDispatch({type: "setPlaceholderValue", payload: {key: placeholderKey, property: "Required", value: e.target.checked}});}} className="mr-2 align-middle" />
                        <span className="text-grey-800 mb-4 align-middle">Required</span>
                    </label>
                </div>
                <div className="my-3">
                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor={`ConstantValue-${ placeholderKey }`}>
                        Constant Value
                    </label>
                    <input type="text" id={`ConstantValue-${ placeholderKey }`} name={`ConstantValue-${ placeholderKey }`} value={placeholderSetting.ConstantValue ?? ""} onChange={(e) => placeholderStateDispatch({type: "setPlaceholderValue", payload: {key: placeholderKey, property: "ConstantValue", value: e.target.value}})} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                </div>
            </div>
        </div>
        </>
    );
}