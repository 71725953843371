import { useLayoutEffect, useMemo, useState } from "react";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning, faQuestionCircle, faRedo } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";
import { geValuesApiUrl } from "../../../midgard.js";
import { useGetExternalDataQuery } from "../../../services/mip";

export default function ProvisionableItemFormSelectField(props) {
    const hasApi = props.field.GetValuesApi !== undefined && props.field.GetValuesApi !== null && props.field.GetValuesApi !== "";
    const externalDataApiUrl = geValuesApiUrl(props.field, props.formState, props.provisionMode, props.identifierToExcludeFromLinkedQuery);
    const { 
        data: selectFieldApiData,
        error: apiErrorMessage, 
        isFetching: isLoadingFromAPI, 
        refetch: fetchValuesFromAPI
    } = useGetExternalDataQuery(externalDataApiUrl, { skip: !hasApi, refetchOnMountOrArgChange: props.field.GetValuesApiForceRefetch === true ? true : false });

    const apiKeyValues = useMemo(() => {
        const kvpFromApi = [];
        if (hasApi && selectFieldApiData !== undefined && selectFieldApiData !== null && typeof(selectFieldApiData) === "object") {
            // adding data from API to optionKeyValues
            let dataToParse = null;
            if (props.field.GetValuesApiResultSet) {
                if (Object.keys(selectFieldApiData).includes(props.field.GetValuesApiResultSet)) {
                    dataToParse = selectFieldApiData[props.field.GetValuesApiResultSet];
                } else {
                    console.error(`Field ${ props.field.Parameter.Identifier }: Could not find GetValuesApiResultSet ${ props.field.GetValuesApiResultSet } in results from ${ props.field.GetValuesApi }`, selectFieldApiData);
                }
            } else if (Array.isArray(selectFieldApiData)) {
                dataToParse = selectFieldApiData;
            }
            if (dataToParse !== null) {
                for (const dataObject of dataToParse) {
                    kvpFromApi.push({
                        Key: dataObject[props.field.GetValuesApiResultKey].toString(),
                        Value: dataObject[props.field.GetValuesApiResultValue].toString()
                    });
                }
            }
        }
        return kvpFromApi;
    }, [hasApi, selectFieldApiData, props.field.GetValuesApiResultSet, props.field.GetValuesApiResultKey, props.field.GetValuesApiResultValue, props.field.Parameter.Identifier, props.field.GetValuesApi]);
    const optionKeyValues = useMemo(() => [...props.field.Values, ...apiKeyValues], [props.field.Values, apiKeyValues]);

    const [showHint, setShowHint] = useState(false);
    const [missingValueWarning, setMissingValueWarning] = useState();

    // on load, and if the data element changes
    // set default values
    const {formStateDispatch} = props;
    const currentlySelectedValue = props.formState[props.field.Parameter.Identifier].value;
    useLayoutEffect(() => {
        // allValues is used to get or check the default
        const allValues = [];
        for (const kvp of optionKeyValues) {
            allValues.push(kvp.Value);
        }

        // if we do not already have a value for this field we need to set a default.
        // if we do have a value, we need to see if it's still in the list. The use case here
        // is if a previously selected value from an API is no longer in the list.

        if (hasApi && selectFieldApiData === undefined) {
            // do nothing if we have an API and data is not set yet.
            // this will run again after we get the data and at that point
            // things will get set correcrtly.
            return;
        }
        
        if (currentlySelectedValue === null) {
            // if the field doesn't have a value in the formValues
            // set its value to the first one. This should not be hit since
            // everything gets a default.
            formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: allValues[0]}});
        } else {
            if (["Select", "VerticalRadio", "HorizontalRadio"].includes(props.field.SelectType)) {
                // if it does have a value, and it's a single select box, check if the values
                // exist in the data, and if not, set the value to another one.
                if (!allValues.includes(currentlySelectedValue)) {
                    setMissingValueWarning(currentlySelectedValue);
                    formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: allValues[0]}});
                }   
            } else {
                // multi-selects can be nullable so we don't need to set a default
                // but we should remove any invalid options.
                const newValues = [];
                const missingValues = [];
                let saveNewValues = false;
                for (const currentValue of currentlySelectedValue) {
                    if (allValues.includes(currentValue)) {
                        newValues.push(currentValue);
                    } else {
                        missingValues.push(currentValue);
                        saveNewValues = true;
                    }
                }
                if (saveNewValues) {
                    setMissingValueWarning(missingValues.join(", "));
                    formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: newValues}});
                }
            }
        }
    }, [selectFieldApiData, formStateDispatch, props.field, currentlySelectedValue, hasApi, optionKeyValues]);

    const thisComponentIsFetching = props.formState[props.field.Parameter.Identifier].isFetching;
    useLayoutEffect(() => {
        if (hasApi && thisComponentIsFetching !== isLoadingFromAPI) {
            formStateDispatch({type: "setIsFetching", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: isLoadingFromAPI}});
        }
    }, [formStateDispatch, isLoadingFromAPI, hasApi, thisComponentIsFetching, props.field.Parameter.Identifier]);

    const handleCheckboxChange = (e) => {
        const dataCopy = [...props.formState[props.field.Parameter.Identifier].value];
        if (e.target.checked) {
            dataCopy.push(e.target.value);
        } else {
            const currentIndex = dataCopy.indexOf(e.target.value);
            dataCopy.splice(currentIndex, 1);
        }
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: dataCopy}});
    };

    const handleMultiSelectChange = (e) => {
        const selectedOptions = [];
        for (const option of e.target.options) {
            if (option.selected) {
                selectedOptions.push(option.value);
            }
        }
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: selectedOptions}});
    };

    let selectElement = null;

    if (props.field.SelectType === "Select")  {
        // Single Select Feld
        const options = [];
        for (const kvp of optionKeyValues) {
            options.push(<option key={kvp.Value} value={kvp.Value}>{kvp.Key}</option>);
        }
        const classes = ["shadow", "border", "rounded", "py-2", "pl-3", "pr-8", "text-grey-800", "mb-3", "text-base", "focus:shadow-outline"];
        if (hasApi) {
            classes.push("w-11/12");
        } else {
            classes.push("w-full");
        }
        if (props.hasError) {
            classes.push("border-red-500");
        }
        selectElement = (
            <>
                {
                    options.length === 0
                        ?   <span className={classes.join(" ")}>No options.</span>
                        :   <Field disabled={props.disabled} name={props.field.Parameter.Identifier} id={props.field.Parameter.Identifier} as="select" className={classes.join(" ")} onChange={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: e.target.value}})} >
                                {options}
                            </Field>
                }
                {
                    hasApi
                        ? <button disabled={props.disabled} data-test-id={`refresh-button-for-${  props.field.Parameter.Identifier }`} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/12" type="button" onClick={fetchValuesFromAPI}><FontAwesomeIcon icon={faRedo} /></button>
                        : null
                }
            </>
        );
    } else if (props.field.SelectType === "VerticalRadio" || props.field.SelectType === "HorizontalRadio")  {
        // Radio Groups, 1 is Vertical and 2 is Horizontal
        const options = [];
        for (const kvp of optionKeyValues) {
            if (props.field.SelectType === "VerticalRadio") {
                // Vertical, includes a div to provide a block without
                // making the label a block
                const classes = [];
                if (!props.field.HideLabel) {
                    classes.push("ml-4");
                } 
                options.push(
                    <div key={kvp.Value}>
                        <label className={classes.join(" ")}>
                            <Field type="radio" disabled={props.disabled} name={props.field.Parameter.Identifier} value={kvp.Value} className="align-middle" onChange={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: e.target.value}})} />
                            <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">{kvp.Key}</span>
                        </label>
                    </div>
                );
            } else {
                // Horizontal, only has ml-on first
                const classes = [];
                classes.push("ml-4");
                if (props.field.HideLabel) {
                    classes.push("first:ml-0");
                }
                options.push(
                    <label key={kvp.Value} className={classes.join(" ")}>
                        <Field type="radio" disabled={props.disabled} name={props.field.Parameter.Identifier} value={kvp.Value} className="align-middle" onChange={(e) => props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: e.target.value}})} />
                        <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">{kvp.Key}</span>
                    </label>
                );
            }
        }
        selectElement = (
            <>
                {
                    options.length === 0
                        ?   <div>No options loaded</div>
                        :   <div role="group" aria-labelledby={!props.field.HideLabel ? `${ props.field.Parameter.Identifier  }-radio-group` : ""}>
                                {options}
                            </div>
                }
                {
                    hasApi 
                        ?   <div className="p-4">
                                <button data-test-id={`refresh-button-for-${  props.field.Parameter.Identifier }`} className="inline bg-green-500 hover:bg-green-700 text-white font-bold rounded w-1/12" type="button" onClick={fetchValuesFromAPI}><FontAwesomeIcon icon={faRedo} /></button> 
                            </div>
                        : null
                }
            </>
        );
    } else if (props.field.SelectType === "MultiSelect")  {
        // Multi Select Field
        const options = [];
        for (const kvp of optionKeyValues) {
            options.push(<option key={kvp.Key} value={kvp.Value}>{kvp.Key}</option>);
        }
        const classes = ["shadow", "border", "rounded", "w-full", "py-2", "pl-3", "pr-8", "text-grey-800", "mb-3", "text-base", "focus:shadow-outline"];
        if (props.hasError) {
            classes.push("border-red-500");
        }
        selectElement = (
            <>
                {
                    options.length === 0
                        ?   <span>No options</span>
                        :   <Field id={props.field.Parameter.Identifier} disabled={props.disabled} name={props.field.Parameter.Identifier} as="select" multiple="multiple" className={classes.join(" ")} onChange={handleMultiSelectChange}>
                                {options}
                            </Field>
                }
                {
                    hasApi 
                        ?   <div className="p-2 text-center">
                                <button data-test-id={`refresh-button-for-${  props.field.Parameter.Identifier }`} className="inline bg-green-500 hover:bg-green-700 text-white font-bold rounded w-1/12" type="button" onClick={fetchValuesFromAPI}><FontAwesomeIcon icon={faRedo} /></button> 
                            </div>
                        : null
                }
            </>
        );
    } else if (props.field.SelectType === "VerticalCheckboxGroup" || props.field.SelectType === "HorizontalCheckboxGroup")  {
        const options = [];
        for (const kvp of optionKeyValues) {
            if (props.field.SelectType === "VerticalCheckboxGroup") {
                // Vertical has a div to be in a block without making the label a block
                const classes = [];
                if (!props.field.HideLabel) {
                    classes.push("ml-4");
                } 
                options.push(
                    <div key={kvp.Value}>
                        <label className={classes.join(" ")}>
                            <Field type="checkbox" disabled={props.disabled} name={props.field.Parameter.Identifier} value={kvp.Value} className="align-middle" onChange={handleCheckboxChange}/>
                            <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">{kvp.Key}</span>
                        </label>
                    </div>
                );
            } else {
                // Horizontal
                const classes = [];
                classes.push("ml-4");
                if (props.field.HideLabel) {
                    classes.push("first:ml-0");
                }
                options.push(
                    <label key={kvp.Value} className={classes.join(" ")}>
                        <Field type="checkbox" disabled={props.disabled} name={props.field.Parameter.Identifier} value={kvp.Value} className="align-middle" onChange={handleCheckboxChange}/>
                        <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">{kvp.Key}</span>
                    </label>
                );
            }
        }
        selectElement = (
            <>
                {
                    options.length === 0
                        ?   <div>No options.</div>
                        :   <div role="group" aria-labelledby={!props.field.HideLabel ? `${ props.field.Parameter.Identifier  }-checkbox-group` : ""}>
                                {options}
                            </div>
                }
                {
                    hasApi 
                        ?   <div className="p-4">
                                <button data-test-id={`refresh-button-for-${  props.field.Parameter.Identifier }`} className="inline bg-green-500 hover:bg-green-700 text-white font-bold rounded w-1/12" type="button" onClick={fetchValuesFromAPI}><FontAwesomeIcon icon={faRedo} /></button> 
                            </div>
                        : null
                }
            </>
        );
    }

    return (
        <div className="my-4">
            <div className={!props.field.HideLabel || props.field.Parameter.Hint ? "mb-4" : ""}>
                {!props.field.HideLabel ?  <label className="text-grey-800 text-sm font-bold" htmlFor={props.field.Parameter.Identifier}>{props.field.Parameter.Name}</label> : null}
                {props.field.Parameter.Hint && <div className="pl-2 text-grey-800 text-sm font-bold align-middle inline" onClick={() => setShowHint(!showHint)}><FontAwesomeIcon icon={faQuestionCircle} /></div>}
            </div>
            {
                props.field.Parameter.Hint && showHint
                    ?   <div className="inline-block text-sm rounded-lg bg-gray-200 px-2 p-1">{props.field.Parameter.Hint}</div>
                    :   null
            }
            {
                props.provisionMode !== "new" && missingValueWarning
                    ?   <div className="text-orange-500 text-sm font-bold"><FontAwesomeIcon icon={faWarning} /> The following values are no longer present in this select list: {missingValueWarning}</div>
                    :   null
            }
            {props.hasError && props.errorMessage && <div data-test-id={`error-msg-for-${ props.field.Parameter.Identifier }`} className="text-red-600 text-sm font-bold ml-4">{props.errorMessage}</div>}
            <div className="my-4">
                {
                    apiErrorMessage
                        ?   <div>
                                <ErrorTile message={apiErrorMessage} />
                                <div className="flex justify-evenly">
                                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={fetchValuesFromAPI}><FontAwesomeIcon icon={faRedo} /></button>
                                </div>
                            </div>
                        : isLoadingFromAPI
                            ?   <LoadingSpinner text="Loading Options" />
                            :   selectElement
                }
            </div>
        </div>
    );
}