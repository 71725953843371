import { useEffect, useLayoutEffect, useState } from "react";
import Select, { createFilter } from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDoorOpen,
    faIdCard,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {
    mipApi,
    useGetProfileQuery,
    useGetCustomersQuery,
    useUpdateCustomerIdentifierMutation
} from "../../../services/mip";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ProfileLeftNav from "../../../components/Profile/ProfileLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";

export default function AssignedCustomer(props) {   
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const canSwitchCustomers = privilege.includes("ManageCustomers") || privilege.includes("All");
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [selectedCustomerIdentifier, setSelectedCustomerIdentifier] = useState();
    const [currentCustomer, setCurrentCustomer] = useState();

    const {
        data: profileData,
        error: profileError,
        isFetching: profileIsFetching
    } = useGetProfileQuery(null, { skip: !isLoggedIn || !canSwitchCustomers });

    const {
        data: customersData,
        error: customersError,
        isFetching: customersIsFetching
    } = useGetCustomersQuery(null, { skip: !isLoggedIn || !canSwitchCustomers });

    const [changeCustomerError, setChangeCustomerError] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const customerOptions = [];
    if (Array.isArray(customersData?.Data) && profileData?.Data) {
        for (const customer of customersData.Data) {
            customerOptions.push({value: customer.Identifier, label: `${ customer.Name } (${ customer.GroupDisplayName })`});
        }
    }

    const [updateCustomerIdentifier] = useUpdateCustomerIdentifierMutation();
    const postCustomer = async (identifier) => {
        setIsRedirecting(true);
        setSubmittingToAPI(true);
        setChangeCustomerError(undefined);
        const objectToPost = {
            CustomerIdentifier: identifier
        };
        try {
            await updateCustomerIdentifier(objectToPost).unwrap();
            dispatch(mipApi.util.resetApiState());
            window.location.href = "/login/redirect";
        } catch (err) {
            setChangeCustomerError(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
            setIsRedirecting(false);
        }
        setSubmittingToAPI(false);
    };

    useEffect(() => {
        let redirectToLogin = false;
        if (profileError && profileError.status === 401) {
            redirectToLogin = true;
        }
        if (customersError && customersError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        profileError,
        customersError
    ]);

    useEffect(() => {
        document.title = "Profile - Change Password";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    useLayoutEffect(() => {
        if (Array.isArray(customersData?.Data) && profileData?.Data && currentCustomer === undefined) {
            for (const customer of customersData.Data) {
                if (customer.Identifier === profileData.Data.CustomerIdentifier) {
                    setCurrentCustomer({
                        value: customer.Identifier,
                        label: `${ customer.Name } (${ customer.GroupDisplayName })`
                    });
                    break;
                }
            }
        }
    }, [customersData, profileData, currentCustomer]);

    useLayoutEffect(() => {
        if (profileData?.Data && selectedCustomerIdentifier === undefined) {
            setSelectedCustomerIdentifier(profileData.Data.CustomerIdentifier);
        }
    }, [profileData, selectedCustomerIdentifier]);

    if (!canSwitchCustomers) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        {
            isRedirecting
                ?   <LoadingSpinner text="Redirecting" margin="mx-auto my-4" />
                :   <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
                        <ProfileLeftNav selected="assigned-customer" />
                        <div className="col-span-4">
                        <div className="mx-auto mb-10 bg-white rounded-lg">
                                <div className="text-2xl p-1 pb-4"><FontAwesomeIcon icon={faIdCard} /> Assigned Customer</div>
                                {
                                    changeCustomerError && <ErrorTile message={changeCustomerError} />
                                }
                                {
                                profileError || customersError
                                    ? <ErrorTile message={profileError || customersError} />
                                    : profileIsFetching || customersIsFetching || selectedCustomerIdentifier === undefined || currentCustomer === undefined
                                        ? <LoadingSpinner text={"Loading Customers"} />
                                        :   <div className="flex">
                                                <Select className="flex-1" filterOption={createFilter({ ignoreAccents: false })} isSearchable={true} options={customerOptions} defaultValue={currentCustomer} onChange={(e) => setSelectedCustomerIdentifier(e.value)} />
                                                <button disabled={submittingToAPI || !selectedCustomerIdentifier} className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded py-1 px-4 ml-1" type="button" onClick={() => postCustomer(selectedCustomerIdentifier)}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <FontAwesomeIcon icon={faDoorOpen} />}</button>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
        }
        </>
    );
}