import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function ImportColumnsSelectorField(props) {

    const fieldLabel = `Column ${  props.index + 1 }`;

    return (
        <div className="shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t">
                <div className="flex-1 text-xl p-2 font-bold">{fieldLabel}</div>
                <div>
                    <button disabled={props.disabled} className="bg-red-700 hover:bg-red-900 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {props.onDeleteClick(props.index);}}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>
            <div className="m-4">
                <div className="mb-4">
                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`StartIndex${ props.index }`}>
                        Start Index
                    </label>
                </div>
                <input disabled={props.disabled} name={`StartIndex${ props.index }`} id={`StartIndex${ props.index }`} type='text' value={props.column.StartIndex} onChange={(e) => {props.addKeyToDataMapping(props.index, "StartIndex", e.target.value);}} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                <div className="mb-4">
                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`Length${ props.index }`}>
                        Field Length
                    </label>
                </div>
                <input disabled={props.disabled} name={`Length${ props.index }`} id={`Length${ props.index }`} type='text' value={props.column.Length} onChange={(e) => {props.addKeyToDataMapping(props.index, "Length", e.target.value);}} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                <div className="mb-4">
                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`Justification${ props.index }`}>
                        Justification
                    </label>
                </div>
                <select disabled={props.disabled} name={`Justification${ props.index }`} id={`Justification${ props.index }`} type='text' value={props.column.Justification} onChange={(e) => {props.addKeyToDataMapping(props.index, "Justification", e.target.value);}} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                    <option value="Left">Left</option>
                    <option value="Right">Right</option>
                </select>
            </div>
        </div>
    );
}