export default function FilesPaging(props) {   
    const {
        objects,
        nextToken,
        pagingStart,
        lastRowShown,
        pageSize,
        setPagingStart,
        loadMoreUsingNextToken
    } = props;

    if (objects.length === 0) {
        return null;
    }

    const isLastPage = ((pagingStart + pageSize) > objects.length) || (((pagingStart + pageSize) === objects.length) && nextToken);
    const nextPage = pagingStart+pageSize;
    const prevPage = Math.max(0, pagingStart-pageSize);

    return (
        <div className="flex w-full mb-2">
            <div className="flex-grow w-4/5">
            {
                pagingStart === 0
                    ? <span className="text-gray-400 mr-2">&lt;</span>
                    : <span className="cursor-pointer hover:underline text-blue-600 mr-2" onClick={() => {setPagingStart(prevPage);}}>&lt;</span>
            }
            {
                objects.map((_, index) => (
                    <>
                    {
                        (index === 0 || index % pageSize === 0) 
                            ? index === pagingStart
                                ? <span key={`filesPagingLink-${ index }-current`} className="font-bold text-gray-600 mr-2">{(index / pageSize)+1}</span>
                                : <span key={`filesPagingLink-${ index }`} className="cursor-pointer hover:underline text-blue-600 mr-2" onClick={() => {setPagingStart(index);}}>{(index / pageSize)+1}</span>
                            : null
                    }
                    </>
                ))
            }
            {
                nextToken
                    ? isLastPage
                        ? <span className="cursor-pointer hover:underline text-blue-600 mr-2" onClick={() => loadMoreUsingNextToken()}>&hellip;</span>
                        : <span className="text-gray-600 mr-2">&hellip;</span>
                    : null
            }
            {
                isLastPage
                    ? nextToken
                        ? <span className="cursor-pointer hover:underline text-blue-600 mr-2" onClick={() => loadMoreUsingNextToken()}>&gt;</span>
                        : <span className="text-gray-400">&gt;</span>
                    : <span className="cursor-pointer hover:underline text-blue-600 mr-2" onClick={() => setPagingStart(nextPage)}>&gt;</span>
            }
            </div>
            <div className="flex-shrink text-right">
                <span className="mr-2">Showing objects {pagingStart+1}-{lastRowShown}</span>
            </div>
        </div>
    );
}