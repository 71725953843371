import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
    faTimesCircle,
    faWarning
} from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../ErrorTile";

export default function CreateManualProcessModal(props) {   

    const [errorMessage, setErrorMessage] = useState("");
    const [submittingToAPI, setSubmittingToAPI] = useState(false);
    
    const [httpVerb, setHttpVerb] = useState(props.process.HttpVerbs[0]);
    const [manualProcessName, setManualProcessName] = useState("");

    const queueProcess = async(identifier) => {
        setSubmittingToAPI(true);
        setErrorMessage(null);
        const [success, msg] = await props.createManualProcessFunction(identifier, httpVerb, manualProcessName);
        if (!success) {
            setErrorMessage(msg);
            setSubmittingToAPI(false);
        }
    };

    return (
        <div className="opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-500 opacity-95"></div>
            <div className="fixed w-full h-full z-50 overflow-y-auto">
                <div className="container mx-auto w-1/2 h-auto text-left m-4 bg-white rounded-lg">
                    <div className="bg-purple-800 text-white text-2xl rounded-t-lg ">
                        <div className="flex">
                            <div className="flex-1 p-2">Create Manual Process</div>
                            <div className="flex-initial p-2"><FontAwesomeIcon className="hover:cursor-pointer" icon={faTimesCircle} onClick={props.closeModal} /></div>
                        </div>
                    </div>
                    {
                        props.showWarning === true
                            ? <div className="text-center m-4"><FontAwesomeIcon icon={faWarning} size="6x" className="text-orange-500" /></div>
                            : null
                    }
                    <div className="p-4">{props.confirmationMessage}</div>
                    { 
                        errorMessage 
                            ? <ErrorTile title={props.failureMessage} message={errorMessage} additionalClasses={["mx-4"]} />
                            : null
                    }
                    <div className="p-2">Create Manual Process from "{props.process.Name}"</div>
                    <div className="p-4">
                        <div className="my-3">
                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="manualProcessName">
                                Manual Process Name
                            </label>
                            <input type="text" id="manualProcessName" name="manualProcessName" value={manualProcessName ?? ""} onChange={(e) => setManualProcessName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                        </div>
                        <div className="my-3">
                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="httpVerb">
                                Verb
                            </label>
                            {
                                Array.isArray(props.process?.HttpVerbs)
                                    ?   <select id="httpVerb" name="httpVerb" value={httpVerb ?? "POST"} onChange={(e) => setHttpVerb(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                            {
                                                props.process.HttpVerbs.map((verb) => <option value={verb} key={verb}>{verb}</option>)
                                            }
                                        </select>
                                    : "POST"
                            }
                        </div>
                    </div>
                    <div className="flex justify-evenly p-4">
                        <button data-test-id={`confirm-queue-process-button-${ props.identifier }`} onClick={() => {queueProcess(props.identifier);}} disabled={submittingToAPI} className={`${ props.showWarning ? "bg-red-500 hover:bg-red-700" : "bg-blue-500 hover:bg-blue-700" } text-white font-bold py-2 px-4 rounded`} type="button">{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : props.submitButtonTitle}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}