import ExternalEntitiesProperties from "../ExternalEntitiesProperties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function ExternalEntitiesPropertiesContainer(props) {   

    const allPropertyNames = props.entityProperties.map((p) => p.name);

    return (
        <div className="shadow border rounded p-4">
            <div data-test-id="export-data-selector-container-div">
            {
                props.entityProperties.length === 0
                    ? <div className="text-center">Use the button below to add an Entity Property.</div>
                    : props.entityProperties.map((entityProperty, index) => (
                            <ExternalEntitiesProperties
                                key={index}
                                index={index}
                                entityProperty={entityProperty}
                                entitiesDispatch={props.entitiesDispatch}
                                allPropertyNames={allPropertyNames}
                            />
                        ))
            }
            </div>
            <div className="mb-4 flex justify-end text-sm">
                <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => {props.entitiesDispatch({type: "addEntitiesProperty"});}}><FontAwesomeIcon icon={faPlus} /> Add Entity Property</button>
            </div>
        </div>
        
    );
}