import { useLayoutEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    selectIsLoggedIn
} from "../../../features/auth/authSlice";
import DownloadFileButton from "../../../components/Common/DownloadFileButton/index.js";
import ErrorTile from "../../Common/ErrorTile";
import {
    useGetJobResultsQuery
} from "../../../services/mip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFolder,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";

export default function JobHistoryRow(props) {

    const { job } = props;
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);

    const [errorMessage, setErrorMessage] = useState();
    const timerRef = useRef(null);

    const {
        data: jobResultsData,
        error: jobResultsAPIError,
        isFetching: jobResultsDataFetching,
        refetch: fetchJobResults,
    } = useGetJobResultsQuery(job.Identifier, { skip: !isLoggedIn || job.CompleteTime || job.TimedOut});

    // TODO: I have no idea if this work as expected
    //       but it seems to finally be the right amount of recurssion to not go forever.
    useLayoutEffect(() => {
        if (jobResultsData && jobResultsData.Data.CompleteTime === null && jobResultsData.Data.TimedOut === false) {
            timerRef.current = setTimeout(() => {
                if (!jobResultsDataFetching && jobResultsData && jobResultsData.Data.CompleteTime === null && jobResultsData.Data.TimedOut === false) {
                    timerRef.current = setTimeout(() => {
                        fetchJobResults();
                    }, 3000);
                }
                fetchJobResults();
            }, 3000);
        }
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [
        jobResultsData,
        fetchJobResults,
        jobResultsDataFetching
    ]);

    let jobCompleteTimeDisplay;
    if (job.CompleteTime) {
        jobCompleteTimeDisplay = job.CompleteTime;
        } else if (jobResultsData && jobResultsData.Data.CompleteTime) {
        jobCompleteTimeDisplay = jobResultsData.Data.CompleteTime;
    } else if (job.TimedOut || (jobResultsData && jobResultsData.Data.TimedOut)) {
        jobCompleteTimeDisplay = "Timed Out";
    } else {
        jobCompleteTimeDisplay = <FontAwesomeIcon className="spinner" icon={faSpinner} />;
    }

    let downloadFileButtonOrLink;
    const resultFilePath = job.ResultFileOrDirectoryObjectKey || jobResultsData?.Data?.ResultFileOrDirectoryObjectKey;
    if (resultFilePath) {
        if (resultFilePath.endsWith("/")) {
            downloadFileButtonOrLink = <button title="View Folder" onClick={() => navigate(`/files?${ encodeURIComponent(resultFilePath) }`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faFolder} /></button>;
        } else {
            downloadFileButtonOrLink = <DownloadFileButton filename={resultFilePath} setErrorMessage={setErrorMessage} />;
        }
    } else if (job.CompleteTime || jobResultsData?.Data?.CompleteTime) {
        downloadFileButtonOrLink = <button title="View Files" onClick={() => navigate("/files")} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faFolder} /></button>;
    }

    return (
        <div data-test-id="job-history" className="contents group">
            <div className="p-2 border group-odd:bg-gray-100 whitespace-nowrap">{props.manualProcessByIdentifier[job.ManualProcessIdentifier].Name}</div>
            <div className="p-2 border group-odd:bg-gray-100 whitespace-nowrap">{job.StartTime}</div>
            <div className="p-2 border group-odd:bg-gray-100 whitespace-nowrap">{jobCompleteTimeDisplay}</div>
            <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">{downloadFileButtonOrLink}</div>
            {
                errorMessage || jobResultsAPIError
                    ? <div className="col-span-3">
                        <ErrorTile message={errorMessage || jobResultsAPIError} />
                    </div>
                    : null
            }
        </div>
    );
}