import { useLayoutEffect } from "react";
import ProvisionableItemFormContainer from "../ProvisionableItemFormContainer";
import { useGetExternalDataQuery } from "../../../services/mip";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";
import { processDataSelectorChanges, geValuesApiUrl } from "../../../midgard.js";

export default function ProvisionableItemDataApplicator(props) {

    // we will skip the API call if this form does not have a DataSelector
    // or if the previousValues have already been processed.
    const skipAPICall = !props.dataSelectorField || !props.formState[props.dataSelectorField.Parameter.Identifier].previousValue;

    let dataSelectorExternalDataUrl;
    if (props.dataSelectorField) {
        dataSelectorExternalDataUrl = geValuesApiUrl(props.dataSelectorField, props.formState);
    }

    const {
        data: entityPropertiesData,
        error: dataSelectorDataAPIError,
        isFetching: dataSelectorDataFetching,
    } = useGetExternalDataQuery(dataSelectorExternalDataUrl, {skip: skipAPICall});

    const {formStateDispatch} = props;
    useLayoutEffect(() => {
        if (!skipAPICall && entityPropertiesData !== null && entityPropertiesData !== undefined) {
            processDataSelectorChanges(formStateDispatch, props.formState, props.dataSelectorField, entityPropertiesData);
        }
    }, [entityPropertiesData, formStateDispatch, props.formState, props.dataSelectorField, skipAPICall]);
    
    return (
        dataSelectorDataAPIError
            ? <ErrorTile message={dataSelectorDataAPIError} />
            : dataSelectorDataFetching
                ?   <LoadingSpinner margin="mx-auto mt-4" text="Loading Data Selector" />
                :   <ProvisionableItemFormContainer
                        provisionableItem={props.provisionableItem}
                        provisionMode={props.provisionMode}
                        provisionableItemType={props.provisionableItemType}
                        formsToDisplay={props.formsToDisplay}
                        formState={props.formState}
                        formStateDispatch={props.formStateDispatch}
                        availableProvisionableItemWhenEditing={props.availableProvisionableItemWhenEditing}
                    />
    );
}
