
export default function ErrorTile(props) {
    let message = "Unknown Error";
    if (typeof(props.message) === "string") {
        // eslint-disable-next-line prefer-destructuring
        message = props.message;
    } else if (Array.isArray(props.message)) {
        message = (
            <ul className="text-left list-disc">
                {
                    props.message.map((message, index) => (
                        <li key={index} className="ml-4">{message}</li>
                    ))
                }
            </ul>
        );
    } else if (typeof(props.message) === "object") {
        message = props.message.data?.Message || props.message.data?.message || props.message?.message || props.message?.Message || props.message.value?.error?.message || props.message.value?.error?.data?.message || props.message.value?.error?.data?.Message || props.message.value?.error?.data?.Data?.Message || JSON.stringify(props.message);
    }

    let classes =["border", "text-center", "border-red-500", "rounded-lg", "mt-2", "mb-4", "p-4", "bg-red-200"];
    if (Array.isArray(props.additionalClasses)) {
        classes = [...classes, ...props.additionalClasses];
    }
    return (
        <div className={classes.join(" ")}>
            {
                props.title
                    ? <div className="font-bold mb-2">{props.title}</div>
                    : null
            }
            <div>{message}</div>
        </div>
    );
}