import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    customerIdentifier: null,
    isLoggedIn: false,
    loginSecondsLeft: undefined,
    privilege: [],
    userInfo: null
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.userInfo = action.payload;
    },
    logout: (state) => {
        state.isLoggedIn = false;
        state.userInfo = null;
        state.customerIdentifier = null;
        state.privilege = [];
    },
    updateLoginSecondsLeft: (state, action) => {
      state.loginSecondsLeft = action.payload;
    },
    updatePrivilege: (state, action) => {
      state.privilege = action.payload;
    },
    updateProfile: (state, action) => {
      state.customerIdentifier = action.payload.CustomerIdentifier;
      state.privilege = action.payload.Privilege?.split(",").map((s) => s.trim());
    }
  },
});

export const {
  login,
  logout,
  updateLoginSecondsLeft,
  updatePrivilege,
  updateProfile
} = authSlice.actions;

export const selectCustomerIdentifier = (state) => state.auth.customerIdentifier;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectLoginSecondsLeft = (state) => state.auth.loginSecondsLeft;
export const selectPrivilege = (state) => state.auth.privilege;
export const selectUserInfo = (state) => state.auth.userInfo;

export default authSlice.reducer;