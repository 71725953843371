import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import ProvisionedResourcesTable from "../../components/Resources/ProvisionedResourcesTable";
import AvailableResourcesTable from "../../components/Resources/AvailableResourcesTable";
import ConfirmModal from "../../components/Common/ConfirmModal";
import NotFound from "../../components/Common/NotFound";
import { 
    useGetResourcesQuery, 
    useGetProvisionedResourcesQuery, 
    useDeprovisionResourceMutation
} from "../../services/mip";

export default function Resources(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionResources") || privilege.includes("All");
    const isAdmin = privilege.includes("Admin") || privilege.includes("All");

    useEffect(() => {
        document.title = "Resources";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: provisionedResourceData,
        error: provisionedResourceAPIError,
        isFetching: provisionedResourceDataFetching
    } = useGetProvisionedResourcesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });
    
    const {
        data: availableResourceData,
        error: availableResourceAPIError,
        isFetching: availableResourceDataFetching
    } = useGetResourcesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });
    
    const [confirmDeprovisionModalDisplayed, setConfirmDeprovisionModalDisplayed] = useState(null);

    const [deprovisionResource] = useDeprovisionResourceMutation();
    const deleteProvisionResource = async (identifier) => {
        try {
            await deprovisionResource(identifier).unwrap();
            setConfirmDeprovisionModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    useEffect(() => {
        let redirectToLogin = false;
        if (provisionedResourceAPIError && provisionedResourceAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (availableResourceAPIError && availableResourceAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        provisionedResourceAPIError,
        availableResourceAPIError
    ]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white">
            <ProvisionedResourcesTable
                error={provisionedResourceAPIError}
                fetching={provisionedResourceDataFetching}
                data={provisionedResourceData}
                deprovisionModal={setConfirmDeprovisionModalDisplayed}
                isAdmin={isAdmin}
            />
            <AvailableResourcesTable 
                error={availableResourceAPIError}
                fetching={availableResourceDataFetching}
                data={availableResourceData}
            />
            {
                provisionedResourceData !== null && confirmDeprovisionModalDisplayed !== null
                    ? provisionedResourceData.map((resource, index) => (
                        confirmDeprovisionModalDisplayed === resource.Identifier 
                            ?   <ConfirmModal 
                                    key={index} 
                                    title={`Confirm Deprovision of ${  resource.Name }`}
                                    submitButtonTitle="Deprovision"
                                    identifier={resource.Identifier}
                                    confirmationMessage={`Are you sure you want to delete ${  resource.Name  }? This cannot be undone.`} 
                                    showWarning={true}
                                    successMessage="Resource deprovisioned successfully."
                                    failureMessage="Resource failed to deprovision:"
                                    closeModal={() => setConfirmDeprovisionModalDisplayed(null)}
                                    confirmFunction={deleteProvisionResource}
                                /> 
                            : null
                      ))
                    : null
            }
        </div>
        </>
    );
}