import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import ErrorTile from "../../../components/Common/ErrorTile";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import NotFound from "../../../components/Common/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faSpinner, faWarning } from "@fortawesome/free-solid-svg-icons";
import {
    useGetUsagePlansQuery,
    useGetApiKeysQuery,
    useAddApiKeyMutation,
    useUpdateApiKeyMutation
} from "../../../services/mip";

export default function ApiKeysForm(props) {   
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionResources") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: usagePlansData,
        error: usagePlansError,
        isFetching: usagePlansIsFetching
    } = useGetUsagePlansQuery(undefined, { skip: !isLoggedIn || !hasPrivilege});

    const newMode = params.apiKeyId.toLowerCase() === "new";
    const { 
        data: apiKeysData,
        error: apiKeysError,
        isFetching: apiKeysIsFetching
    } = useGetApiKeysQuery(null, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const firstError = usagePlansError || apiKeysError;
    const somethingIsFetching = usagePlansIsFetching || apiKeysIsFetching;

    const [addApiKeyError, setApiKeyError] = useState();
    const [description, setDescription] = useState("");
    const [usagePlan, setUsagePlan] = useState();
    const [missingUsagePlan, setMissingUsagePlan] = useState(false);
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    useLayoutEffect(() => {
        if (newMode && Array.isArray(usagePlansData?.Data) && usagePlansData.Data.length !== 0 && !usagePlan) {
            setUsagePlan(usagePlansData.Data[0].Id);
        }
        if (!newMode && Array.isArray(apiKeysData?.Data) && Array.isArray(usagePlansData?.Data)) {
            let apiKeyObject;
            for (const apiKey of apiKeysData.Data) {
                if (apiKey.Id === params.apiKeyId) {
                    apiKeyObject = apiKey;
                    break;
                }
            }
            if (apiKeyObject && usagePlan === undefined) {
                let foundUsagePlan = false;
                for (const usagePlan of usagePlansData.Data) {
                    if (usagePlan.Id === apiKeyObject.UsagePlan.Id) {
                        foundUsagePlan = usagePlan;
                        break;
                    }
                }
                if (!foundUsagePlan) {
                    setMissingUsagePlan(true);
                }
                setUsagePlan(foundUsagePlan ? foundUsagePlan.Id : usagePlansData.Data[0].Id);
            }
            setDescription(apiKeyObject.Description ?? "");
        }
    }, [newMode, params.apiKeyId, usagePlan, usagePlansData, apiKeysData]);

    const [addApiKey] = useAddApiKeyMutation();
    const postApiKey = async () => {
        setSubmittingToAPI(true);
        setApiKeyError(undefined);
        const objectToPost = {
            Description: description,
            UsagePlanId: usagePlan
        };
        try {
            await addApiKey(objectToPost).unwrap();
            navigate("/settings/api-keys");
        } catch (err) {
            if (err.data?.message) {
                setApiKeyError(err.data.message);
            } else if (err.data?.Message) {
                setApiKeyError(err.data.Message);
            } else if (err.value?.error?.data?.Message) {
                setApiKeyError(err.value.error.data.Message);
            } else {
                setApiKeyError(err.toString());
            }
        }
        setSubmittingToAPI(false);
    };

    const [updateApiKey] = useUpdateApiKeyMutation();
    const patchApiKey = async (apiKeyId) => {
        setSubmittingToAPI(true);
        setApiKeyError(undefined);
        const objectToPost = {
            id: apiKeyId,
            body: {
                Description: description,
                UsagePlanId: usagePlan
            }
        };
        try {
            await updateApiKey(objectToPost).unwrap();
            navigate("/settings/api-keys");
        } catch (err) {
            if (err.data?.message) {
                setApiKeyError(err.data.message);
            } else if (err.data?.Message) {
                setApiKeyError(err.data.Message);
            } else if (err.value?.error?.data?.Message) {
                setApiKeyError(err.value.error.data.Message);
            } else {
                setApiKeyError(err.toString());
            }
        }
        setSubmittingToAPI(false);
    };

    let apiKeyName = "";
    let apiKeyFound = false;
    if (Array.isArray(apiKeysData?.Data)) {
        for (const apiKey of apiKeysData.Data) {
            if (apiKey.Id === params.apiKeyId) {
                apiKeyName = apiKey.Name;
                apiKeyFound = true;
                break;
            }
        }
    }

    const pageTitle = newMode
        ? "Create API Key"
        : `Edit API Key ${  apiKeyName }`;

    useEffect(() => {
        document.title = `Settings - ${  pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (usagePlansError && usagePlansError.status === 401) {
            window.location.href="/login";
        }
        if (apiKeysError && apiKeysError.status === 401) {
            window.location.href="/login";
        }
    }, [usagePlansError, apiKeysError]);

    if (!newMode && apiKeysData && Array.isArray(apiKeysData?.Data) && !apiKeyFound) {
        return <NotFound message="API Key Not Found" />;
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="api-keys" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                {
                    firstError
                        ? <ErrorTile message={firstError} />
                        : usagePlansData && Array.isArray(usagePlansData?.Data) && usagePlansData.Data.length === 0
                            ? <ErrorTile message="No usage plans." />
                            : somethingIsFetching
                                ?   <LoadingSpinner text={"Loading Data"} />
                                :   <>
                                        <div className="text-2xl p-1 pb-4">
                                            <FontAwesomeIcon icon={faGear} /> {pageTitle}
                                        </div>
                                        {
                                            addApiKeyError && <ErrorTile message={addApiKeyError} />
                                        }
                                        <div>
                                            <div className="my-3">
                                                <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="description">
                                                    Description
                                                </label>
                                                <input type="text" id="description" name="description" value={description ?? ""} onChange={(e) => setDescription(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                            </div>
                                            <div className="my-3">
                                                {
                                                    missingUsagePlan
                                                        ? <div><FontAwesomeIcon icon={faWarning} /> Previously used usage plan no longer available.</div>
                                                        : null
                                                }
                                                <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="usagePlan">
                                                    Usage Plan
                                                </label>
                                                <select id="usagePlan" name="usagePlan" value={usagePlan} onChange={(e) => setUsagePlan(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                    {
                                                        usagePlansData.Data.map((plan, index) => <option value={plan.Id} key={index}>{plan.Name}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="flex justify-evenly">
                                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/api-keys")}>Back to API Keys</button>
                                                {
                                                    newMode
                                                        ? <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postApiKey}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add API Key"}</button>
                                                        : <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => patchApiKey(params.apiKeyId)}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update API Key"}</button>
                                                }
                                            </div>
                                        </div>
                                    </>
                }
                </div>
            </div>
        </div>
        </>
    );
}