import { configureStore } from "@reduxjs/toolkit";
import { mipApi } from "../services/mip";

import authReducer from "../features/auth/authSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        [mipApi.reducerPath]: mipApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(mipApi.middleware),
});
