import { Auth } from "aws-amplify";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { mipApi } from "../../services/mip";
import baxterLogo from "./baxter-black.png";
import soleraLogo from "./fleet-solutions-omnitracs.jpg";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Login(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        if (window?.location?.href && window.location.href.includes("app.dop.omnitracs.com")) {
            document.title = "Data Orchestration Platform - Login";
        } else {
            document.title = "Baxter Software Solutions - Login";
        }
    }, []);

    const [userEnteredEmail, setUserEnteredEmail] = useState("");
    const [cognitoUser, setCognitoUser] = useState(null);
    const [amplifyErrorMessage, setAmplifyErrorMessage] = useState(null);
    const [formToDisplay, setFormToDisplay] = useState("signIn");
    const [pageTitle, setPageTitle] = useState(<></>);
    const [submitInProgress, setSubmitInProgress] = useState(false);


    useLayoutEffect(() => {
        if (window?.location?.href && window.location.href.includes("app.dop.omnitracs.com"))
        {
            setPageTitle(
                <>
                <div className="flex justify-center -mt-8">
                    <img className="p-2" alt="Solera Fleet Solutions | Omnitracs" src={soleraLogo} />
                </div>
                <h2 className="mt-4 text-gray-800 text-3xl font-semibold">Data Orchestration Platform</h2>
                </>
            );
        } else {
            setPageTitle(
                <>
                <div className="flex justify-center -mt-16">
                    <img className="bg-black p-2 rounded-full" width="192" alt="Baxter, our namesake." src={baxterLogo} />
                </div>
                <h2 className="mt-4 text-gray-800 text-3xl font-semibold">Baxter Software Solutions</h2>
                </>
            );
        }
    }, []);

    const handleSignInFormSubmit = async (values) => {
        setSubmitInProgress(true);
        try {
            setUserEnteredEmail(values.email);
            // here user comes before session;
            const signInUser = await Auth.signIn(values.email, values.password);
            dispatch(mipApi.util.invalidateTags(["Settings"]));
            if (signInUser && signInUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                setCognitoUser(signInUser);
                setFormToDisplay("changePassword");
                setAmplifyErrorMessage(null);
            } else {
                if (!signInUser.attributes || !signInUser.attributes.email_verified) {
                    setAmplifyErrorMessage("Your email must be verified before you are able to use this application. Please contact support to have your email verified.");
                } else {
                    await Auth.currentSession();
                    const userInfo = await Auth.currentUserInfo();
                    localStorage.setItem("loginDateTime", new Date().getTime());
                    dispatch({
                        type: "auth/login",
                        payload: userInfo
                    });
                    navigate("/login/redirect");
                }
            }
        } catch (err) {
            dispatch({type: "auth/logout"});
            setAmplifyErrorMessage(err.message);
        }
        setSubmitInProgress(false);
    };

    const handleForgotPasswordLinkClick = () => {
        setFormToDisplay("resetPassword");
    };

    const SignInForm = () => (
        <Formik
            initialValues={{
                email: userEnteredEmail,
                password: ""
            }}
            onSubmit={handleSignInFormSubmit}
            validationSchema={Yup.object({
                email: Yup.string().email("Invalid email address").required("Required"),
                password: Yup.string().required("Required")
            })}
        >
            <Form id="loginForm">
                <p className="text-gray-600">Sign in to monitor and manage your integrations.</p>
                <div className="my-4">
                    <label htmlFor="email" className="block text-grey-800 text-sm font-bold mb-2">Email</label>
                    <Field name="email" type="email" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="email">{(msg) => <div data-test-id="email-has-error" className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                <div className="my-4">
                    <label htmlFor="password" className="block text-grey-800 text-sm font-bold mb-2">Password</label>
                    <Field name="password" type="password" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="password">{(msg) => <div data-test-id="password-has-error" className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                {amplifyErrorMessage ? <div data-test-id="amplify-has-error" className="text-red-500 my-4">{amplifyErrorMessage}</div> : null }
                <div className="flex items-center justify-between">
                    <button disabled={submitInProgress} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">{ submitInProgress ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Sign In" }</button>
                    <button onClick={handleForgotPasswordLinkClick} className="inline-block align-baseline font-bold text-sm text-gray-700 hover:text-gray-900" type="button">Forgot Password?</button>
                </div>
            </Form>
        </Formik>
    );

    const handleResetPasswordFormSubmit = async (values) => {
        setSubmitInProgress(true);
        try {
            setUserEnteredEmail(values.email);
            await Auth.forgotPassword(values.email);    
            setFormToDisplay("changePasswordWithCode");
            setAmplifyErrorMessage(null);
        } catch (err) {
            setAmplifyErrorMessage(err.message);
        }
        setSubmitInProgress(false);
    };

    const handleReturnToLoginLinkClick = () => {
        setFormToDisplay("signIn");
    };

    const ResetPasswordForm = () => (
        <Formik
            initialValues={{
                email: userEnteredEmail
            }}
            onSubmit={handleResetPasswordFormSubmit}
            validationSchema={Yup.object({
                email: Yup.string().email("Invalid email address").required("Required")
            })}
        >
            <Form>
                <p className="text-gray-600">We will email you a code you can use to reset your password.</p>
                <div className="my-4">
                    <label htmlFor="email" className="block text-grey-800 text-sm font-bold mb-2">Email</label>
                    <Field name="email" type="email" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="email">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                {amplifyErrorMessage ? <div className="text-red-500 my-4">{amplifyErrorMessage}</div> : null }
                <div className="flex items-center justify-between">
                    <button disabled={submitInProgress} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit" >{ submitInProgress ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Send Reset Code" }</button>
                    <button onClick={handleReturnToLoginLinkClick} className="inline-block align-baseline font-bold text-sm text-gray-700 hover:text-gray-900" type="button">Return to Login</button>
                </div>
            </Form>
        </Formik>
    );


    const handleChangePasswordFormSubmit = async (values) => {
        setSubmitInProgress(true);
        try {
            await Auth.completeNewPassword(cognitoUser, values.passwordConfirmation);
            await Auth.signIn(userEnteredEmail, values.passwordConfirmation);
            dispatch(mipApi.util.invalidateTags(["Settings"]));
            await Auth.currentSession();
            const userInfo = await Auth.currentUserInfo();
            dispatch({
                type: "auth/login",
                payload: userInfo
            });
            navigate("/login/redirect");
        } catch (err) {
            setAmplifyErrorMessage(err.message);
        }
        setSubmitInProgress(false);
    };

    const ChangePasswordForm = () => (
        <Formik
            initialValues={{
                password: "",
                passwordConfirmation: ""
            }}
            onSubmit={handleChangePasswordFormSubmit}
            validationSchema={Yup.object({
                password: Yup.string()
                    .required("Required")
                    .min(8, "Must be at least 8 characters")
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/, "Must contain at least one of each: lower case letter, upper case letter, number, special character."),
                passwordConfirmation: Yup.string().required("Required").oneOf([Yup.ref("password"), null], "Passwords must match")
            })}
        >
            <Form>
                <p className="text-red-600 font-semibold">Please create a new password.</p>
                <div className="my-4">
                    <label htmlFor="password" className="block text-grey-800 text-sm font-bold mb-2">Password</label>
                    <Field name="password" type="password" placeholder="********" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="password">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                <div className="my-4">
                    <label htmlFor="passwordConfirmation" className="block text-grey-800 text-sm font-bold mb-2">Confirm Password</label>
                    <Field name="passwordConfirmation" type="password" placeholder="********" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="passwordConfirmation">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                {amplifyErrorMessage ? <div className="text-red-500 my-4">{amplifyErrorMessage}</div> : null }
                <div className="flex items-center justify-between">
                    <button disabled={submitInProgress} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">{ submitInProgress ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Change Password" }</button>
                </div>
            </Form>
        </Formik>
    );

    const handleChangePasswordWithCodeFormSubmit = async (values) => {
        setSubmitInProgress(true);
        try {
            await Auth.forgotPasswordSubmit(userEnteredEmail, values.resetCode, values.passwordConfirmation);
            await Auth.signIn(userEnteredEmail, values.passwordConfirmation);
            dispatch(mipApi.util.invalidateTags(["Settings"]));
            await Auth.currentSession();
            const userInfo = await Auth.currentUserInfo();
            dispatch({
                type: "auth/login",
                payload: userInfo
            });
            navigate("/login/redirect");
        } catch (err) {
            setAmplifyErrorMessage(err.message);
        }
        setSubmitInProgress(false);
    };

    const ChangePasswordWithCodeForm = () => (
        <Formik
            initialValues={{
                resetCode: "",
                password: "",
                passwordConfirmation: ""
            }}
            onSubmit={handleChangePasswordWithCodeFormSubmit}
            validationSchema={Yup.object({
                resetCode: Yup.string().required("Required"),
                password: Yup.string().required("Required"),
                passwordConfirmation: Yup.string().required("Required").oneOf([Yup.ref("password"), null], "Passwords must match")
            })}
        >
            <Form>
                <p className="text-gray-600">Enter a new password along with the code we just emailed you.</p>
                <div className="my-4">
                    <label htmlFor="resetCode" className="block text-grey-800 text-sm font-bold mb-2">Reset Code</label>
                    <Field name="resetCode" type="resetCode" placeholder="" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="resetCode">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                <div className="my-4">
                    <label htmlFor="password" className="block text-grey-800 text-sm font-bold mb-2">Password</label>
                    <Field name="password" type="password" placeholder="********" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="password">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                <div className="my-4">
                    <label htmlFor="passwordConfirmation" className="block text-grey-800 text-sm font-bold mb-2">Confirm Password</label>
                    <Field name="passwordConfirmation" type="password" placeholder="********" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                    <ErrorMessage name="passwordConfirmation">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                </div>
                {amplifyErrorMessage ? <div className="text-red-500 my-4">{amplifyErrorMessage}</div> : null }
                <div className="flex items-center justify-between">
                    <button disabled={submitInProgress} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">{ submitInProgress ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Reset Password" }</button>
                </div>
            </Form>
        </Formik>
    );

    return (
        <div className="max-w-md mx-auto py-4 px-8 bg-white shadow-lg rounded-lg my-20">
            {pageTitle}
            { formToDisplay === "signIn" ? <SignInForm /> : null}
            { formToDisplay === "resetPassword" ? <ResetPasswordForm /> : null}
            { formToDisplay === "changePassword" ? <ChangePasswordForm /> : null}
            { formToDisplay === "changePasswordWithCode" ? <ChangePasswordWithCodeForm /> : null}
        </div>
    );
}