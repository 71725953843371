import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./modalForm.css";
import ProvisionableItemFormField from "../ProvisionableItemFormField";
import { checkConditions, getFieldType } from "../../../midgard.js";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

export default function ProvisionableItemForm(props) { 

    const navigate = useNavigate();

    const calculateFieldSchema = (fieldType, field) => {
        let fieldSchema = null;
        if (fieldType === "CheckboxField") {
            // in our case required == set to true
            fieldSchema = Yup.bool();
            if (field.Parameter.Required) {
                fieldSchema = Yup.bool().oneOf([true], `${ field.Parameter.Name  } must be checked`);
            }
        } else if ((fieldType === "SelectField" && ["MultiSelect", "VerticalCheckboxGroup", "HorizontalCheckboxGroup"].includes(field.SelectType))) {
            // display type of 3,4,5 are muli selects
            // slightly different than a text box since they could deslect all values.
            // this needs the "ensure()"
            fieldSchema = Yup.array();
            if (field.Parameter.Required) {
                fieldSchema = Yup.array().min(1, `${ field.Parameter.Name  } requires at least one option to be selected.`);
            }
        } else {
            // all others are strings
            fieldSchema = Yup.string();
            if (field.Parameter.Required && field.ValidationRegex) {
                fieldSchema = Yup.string()
                    .required(`${ field.Parameter.Name  } is required`)
                    .matches(field.ValidationRegex, `${ field.Parameter.Name  }: ${  field.ValidationErrorMessage }`);
            } else if (field.Parameter.Required) {
                fieldSchema = Yup.string().required(`${ field.Parameter.Name  } is required`);
            } else if (field.ValidationRegex) {
                fieldSchema = Yup.string().matches(field.ValidationRegex, `${ field.Parameter.Name  }: ${  field.ValidationErrorMessage }`);
            }
        }
        return fieldSchema;
    };

    const [
        currentFormValues,
        touchedFields,
        fieldsThatMeetConditions,
        validationFieldIdentifiers,
        formSchema
    ] = useMemo(() => {
        // used to set initial form values
        // it's ok that it has everything.
        const currentFormValues = {};
        // doing "touched" field through useReducer because formik is setup to re-initialize
        // on every change to formstate which clears the touched value.
        const touchedFields = {};
        for (const fieldStateKey of Object.keys(props.formState)) {
            currentFormValues[fieldStateKey] = props.formState[fieldStateKey].value;
            if (props.formState[fieldStateKey].touched) {
                touchedFields[fieldStateKey] = true;
            }
        }

        const fieldsThatMeetConditions = [];
        const formSchemaData = {};
        const validationFieldIdentifiers = []; // holds the IDS fo the special validation fields to be touched later
        // we only add to the formSchemaData if the form isn't in the exclude list. We exclude some special fields.
        const excludeFieldTypesFromSchema = ["DataSelector", "Import.DataSelector", "FileSelectorField", "FixedWidthColumnsSelector"];
        for (const field of props.form.Fields) {
            if (checkConditions(field.Conditions, props.formState)) {
                fieldsThatMeetConditions.push(field);
                const fieldType = getFieldType(field);
                if (fieldType === "Export.DataSelector") {
                    formSchemaData[`export-data-selector-fields-mapped-${ field.Parameter.Identifier }`] = Yup.number().integer().min(1, "You must select at least one field to continue.");
                    validationFieldIdentifiers.push(`export-data-selector-fields-mapped-${ field.Parameter.Identifier }`);
                    currentFormValues[`export-data-selector-fields-mapped-${ field.Parameter.Identifier }`] = props.formState[field.Parameter.Identifier].value?.fields?.length ?? 0;
                } else if (fieldType === "FileLayoutSelector") {
                    formSchemaData[`import-file-layout-selector-fields-mapped-${ field.Parameter.Identifier }`] = Yup.bool().oneOf([true], "You must upload a sample file to continue.");
                    validationFieldIdentifiers.push(`import-file-layout-selector-fields-mapped-${ field.Parameter.Identifier }`);
                    currentFormValues[`import-file-layout-selector-fields-mapped-${ field.Parameter.Identifier }`] = props.formState[field.Parameter.Identifier].externalData !== null;
                } else if (!excludeFieldTypesFromSchema.includes(fieldType)) {
                    formSchemaData[field.Parameter.Identifier] = calculateFieldSchema(fieldType, field);
                }
            }
        }
        return [
            currentFormValues,
            touchedFields,
            fieldsThatMeetConditions,
            validationFieldIdentifiers,
            Yup.object().shape(formSchemaData)
        ];
    }, [props.form.Fields, props.formState]);

    const hasBothTouchedAndErrors = (errors, touched) => {
        for (const t of Object.keys(touched)) {
            if (touched[t] && Object.keys(errors).includes(t)) {
                return true;
            }
        }
        return false;
    };

    const touchFormFields = () => {
        for (const field of fieldsThatMeetConditions) {
            touchedFields[field.Parameter.Identifier] = true;
        }
        for (const identifer of validationFieldIdentifiers) {
            touchedFields[identifer] = true;
        }
    };

    const handleCancelButton = () => {
        if (props.provisionableItemType === "process") {
            navigate("/processes");
        } else {
            // props.provisionableItemType === resource
            navigate("/resources");
        }
        window.scrollTo(0,0);
    };


    return (
        <>
        <Formik
            enableReinitialize={true} // so that formState values are applied
            initialValues={currentFormValues}
            onSubmit={props.onNextButtonClick}
            validationSchema={formSchema}
            validateOnMount={true} // so errors will be populated after reinitialize
        >
            {({errors, handleSubmit}) => (<Form>
                {
                    props.form.Description && <>
                        <div className="my-4 text-center">{props.form.Description}</div>
                        <div className="midgardGradient w-5/6 mx-auto"></div>
                    </>
                }
                {Array.isArray(fieldsThatMeetConditions) && fieldsThatMeetConditions.map((field) => (
                    <ProvisionableItemFormField 
                        key={field.Parameter.Identifier}
                        field={field} 
                        hasError={Object.keys(touchedFields).includes(field.Parameter.Identifier) && Object.keys(errors).includes(field.Parameter.Identifier)} 
                        errorMessage={Object.keys(errors).includes(field.Parameter.Identifier) ? errors[field.Parameter.Identifier] : null}
                        onFormValueChange={props.onFormValueChange} 
                        formState={props.formState}
                        formStateDispatch={props.formStateDispatch}
                        identifierToExcludeFromLinkedQuery={props.identifierToExcludeFromLinkedQuery} // used in linked process query
                        provisionMode={props.provisionMode}
                    />
                ))}
                <div className="midgardGradient mt-8 w-5/6 mx-auto"></div>
                {hasBothTouchedAndErrors(errors, touchedFields) ? 
                    <div data-test-id='form-errors' className="border border-red-500 rounded-lg mt-4 mb-4 p-4 bg-red-200">
                        <div className="">Please correct the following errors in the form above before continuing.</div>
                        <ul className="ml-8 my-2 list-disc">
                            {Object.keys(errors).map((key, index) => (
                                touchedFields[key] && <li key={index} className="text-sm">{errors[key]}</li>
                            ))}
                        </ul>
                    </div> : null
                }
                {
                    props.showSlowDownWarning
                        ? <div className="text-orange-500 text-center text-sm font-bold mt-4 mb-2"><FontAwesomeIcon icon={faWarning} /> We're gonna need you to slow down there meow. Let those API calls finish before moving on.</div>
                        : null
                }
                <div className="flex justify-evenly mt-4">
                    <button onClick={() => handleCancelButton()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">Cancel</button>
                    {props.formToShow !== 0 && <button onClick={props.onPreviousButtonClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">Back</button>}
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={(e) => {touchFormFields(); handleSubmit();}}>Next</button>
                </div>
            </Form>)}
        </Formik>
        </>
    );
}