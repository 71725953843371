import {
    useState,
    useLayoutEffect
} from "react";

export default function GeneralDataSelectorFieldDataMapSelector(props) {  

    const [highlightDataMapMissing, setHighlightDataMapMissing] = useState();

    // Import and Export use different setFieldValue signatures so this calls the right one
    const setFieldValue = (dataMap) => {
        if (props.property !== undefined) { // Import
            props.setFieldValue(props.property.Name, props.index, "dataMapName", dataMap);
        } else if (props.field !== undefined) { // Export
            props.setFieldValue(props.index, "dataMapName", dataMap);
        }
    };

    useLayoutEffect(() => {
        if (Array.isArray(props.dataMaps) && props.dataMaps.length !== 0) {
            if (props.property) {
                // props.property means we are in an Import
                if (props.property.userEnteredData[props.index].dataMapName === "") {
                    props.setFieldValue(props.property.Name, props.index, "dataMapName", props.dataMaps[0]);
                } else if (!props.dataMaps.includes(props.property.userEnteredData[props.index].dataMapName)) {
                    setHighlightDataMapMissing(true);
                } else if (highlightDataMapMissing === true) {
                    setHighlightDataMapMissing(false);
                }
            } else if (props.field) {
                // props.field means we are in an Export
                if (props.field.dataMapName === "") {
                    props.setFieldValue(props.index, "dataMapName", props.dataMaps[0]);
                } else if (!props.dataMaps.includes(props.field.dataMapName)) {
                    setHighlightDataMapMissing(true);
                } else if (highlightDataMapMissing === true) {
                    setHighlightDataMapMissing(false);
                }
            }
        }
    }, [props, highlightDataMapMissing]);

    let htmlFieldName = `dataMapName-${  props.index }`;
    if (props.property !== undefined) {
        htmlFieldName = `dataMapName-${  props.htmlFieldName }`;
    }
    const currentValue = props.property ? props.property.userEnteredData[props.index].dataMapName : props.field.dataMapName;

    return (
        <select name={htmlFieldName} id={htmlFieldName} value={currentValue} onChange={(e) => {setFieldValue(e.target.value);}} className={`${ highlightDataMapMissing === true ? "bg-orange-200" : "" } flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline`}>
            {props.dataMaps.map((mapName) => <option key={mapName} value={mapName}>{mapName}</option>)}
        </select>
    );
}