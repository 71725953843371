import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { 
    useCreateUrlSubstitutionMutation
} from "../../../services/mip";

export default function OrderReservationWindowsForm(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("UrlSubstitutions") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const [original, setOriginal] = useState("");
    const [ttlHours, setTtlHours] = useState("48");
    const [keyLength, setKeyLength] = useState("");
    const [validationErrors, setValidationErrors] = useState(undefined);
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const getValidationErrors = () => {
        const errors = [];
        if (original.length < 10) {
            errors.push("Please enter a plausible URL");
        }
        if (Number.isNaN(parseFloat(ttlHours))) {
            errors.push("Please enter a valid TTL");
        }
        if (keyLength !== "" && Number.isNaN(parseInt(keyLength))) {
            errors.push("Please enter a valid Key Length");
        }
        return errors;
    };

    const [createUrlSubstitution, createUrlSubstitutionState] = useCreateUrlSubstitutionMutation();
    const postOrderReservationWindow = async () => {
        setSubmittingToAPI(true);
        setValidationErrors(undefined);
        
        const errors = getValidationErrors();
        if (errors.length > 0) {
            setValidationErrors(errors);
        } else {
            const objectToPost = {
                Original: original,
                TTLHours: parseFloat(ttlHours),
                KeyLength: keyLength !== "" ? parseInt(keyLength) : null
            };
            try {
                await createUrlSubstitution(objectToPost).unwrap();
                navigate("/settings/url-substitutions");
            } catch (err) {
                setValidationErrors(err);
            }
        }
        setSubmittingToAPI(false);
    };

    useEffect(() => {
        document.title = "Settings - Create URL Substitution";
    }, []);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="url-substitutions" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1">
                            <FontAwesomeIcon icon={faLink}/> Create URL Substitution
                        </div>
                    </div>
                        <div>
                            <div className="my-3">
                                <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="original">
                                    Original URL
                                </label>
                                <input type="text" id="original" name="original" value={original ?? ""} onChange={(e) => setOriginal(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                            </div>
                            <div className="my-3">
                                <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="ttlHours">
                                    TTL Hours
                                </label>
                                <input type="number" min="0" step="0.01" id="ttlHours" name="ttlHours" value={ttlHours ?? ""} onChange={(e) => setTtlHours(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                            </div>
                            <div className="my-3">
                                <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="keyLength">
                                    Key Length
                                </label>
                                <input type="number" min="0" step="1" id="keyLength" name="keyLength" value={keyLength ?? ""} onChange={(e) => setKeyLength(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                            </div>
                            {
                                validationErrors
                                    ? <ErrorTile message={validationErrors} />
                                    : createUrlSubstitutionState && createUrlSubstitutionState.isError
                                        ? <ErrorTile message={createUrlSubstitutionState.error?.data?.Message} />
                                        : null
                            }
                            <div className="flex justify-evenly">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/url-substitutions")}>Back to URL Substitutions</button>
                                <button data-test-id="add-url-substitution-button" disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postOrderReservationWindow}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add URL Substitution"}</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        </>
    );
}