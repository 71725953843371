import FixedWidthColumnsSelectorField from "../FixedWidthColumnsSelectorField";

export default function FixedWidthColumnsSelector(props) {

    const getCurrentMaxIndex = () => {
        let nextCharacter = 0;
        for (const column of props.mappingData) {
            const thisColumnEnds = parseInt(column.StartIndex) + parseInt(column.Length);
            if (thisColumnEnds > nextCharacter) {
                nextCharacter = thisColumnEnds;
            }
        }
        return nextCharacter;
    };

    const handleAddField = () => {
        const fixedWidthColumnCopy = [...props.mappingData];
        fixedWidthColumnCopy.push({
            StartIndex: getCurrentMaxIndex().toString(),
            Length: "1",
            Justification: "Left"
        });
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: fixedWidthColumnCopy}});
    };

    const handleDeleteButton = (index) => {
        const fixedWidthColumnCopy = [...props.mappingData];
        fixedWidthColumnCopy.splice(index, 1);
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: fixedWidthColumnCopy}});
    };

    const addKeyToDataMapping = (index, key, value) => {
        const fixedWidthColumnCopy = [...props.mappingData];
        const indexCopy = Object.assign({}, fixedWidthColumnCopy[index], {[key]: value});
        fixedWidthColumnCopy[index] = indexCopy;
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: fixedWidthColumnCopy}});
    };

    return (
        <>
            <div className="shadow border rounded p-4">
                <div data-test-id="import-column-selector-columns">
                {
                    Array.isArray(props.mappingData) && props.mappingData.length !== 0
                        ? props.mappingData.map((column, index) => (
                            <FixedWidthColumnsSelectorField 
                                key={index} 
                                index={index} 
                                column={column}
                                disabled={props.disabled}
                                numberOfFields={props.mappingData.length}
                                onDeleteClick={handleDeleteButton}
                                addKeyToDataMapping={addKeyToDataMapping}
                            />
                            ))
                        : null
                }
                </div>
                <div className="text-center">
                    <button disabled={props.disabled} data-test-id="import-column-selector-add-column-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={handleAddField}>Add Column</button>
                </div>
            </div>
        </>
    );
}