import { JsonType } from "../../../midgard.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function ExternalEntitiesConstantItems(props) {

    return (
        <div className="mappedColumn shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t">
                <div className="flex-1 text-xl p-2 font-bold">{props.constantItem.constantItemKey}</div>
                <div>
                    <button className="bg-red-700 hover:bg-red-900 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {props.constantItemsDispatch({type: "deleteConstantItem", payload: {index: props.index}});}}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>
            <div className="grid gap-2 m-4" style={{gridTemplateColumns: "auto 1fr"}}>
                <label className="p-2 text-grey-800 font-bold align-middle" htmlFor={`constantItemKey-${ props.index }`}>
                    Key
                </label>
                <input name={`constantItemKey-${ props.index }`} id={`constantItemKey-${ props.index }`} type='text' value={props.constantItem.constantItemKey} onChange={(e) => {props.constantItemsDispatch({type: "setConstantItemValue", payload: {index: props.index, property: "constantItemKey", value: e.target.value}});}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" placeholder="Required" />
                <div className="p-2 text-grey-800 font-bold align-middle">
                    Value
                </div>
                <div className="flex gap-2">
                    <select name={`constantItemValueType-${ props.index }`} id={`constantItemValueType-${ props.index }`} value={props.constantItem.constantItemValueType} onChange={(e) => {props.constantItemsDispatch({type: "setConstantItemValue", payload: {index: props.index, property: "constantItemValueType", value: e.target.value}});}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                        {
                            JsonType.map((jsonType) => <option value={jsonType} key={jsonType}>{jsonType}</option>)
                        }
                    </select>
                    <input name={`constantItemValue-${ props.index }`} id={`constantItemValue-${ props.index }`} type='text' value={props.constantItem.constantItemValue} onChange={(e) => {props.constantItemsDispatch({type: "setConstantItemValue", payload: {index: props.index, property: "constantItemValue", value: e.target.value}});}} className="flex-1 shadow appearance-none border rounded py-2 pl-3 pr-6 text-grey-800" placeholder="Required" />
                </div>
            </div>
        </div>
    );
}