import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import NotFound from "../../components/Common/NotFound";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import ProvisionableItemInitialStateSetup from "../../components/ProvisionableItem/ProvisionableItemInitialStateSetup";

import { 
    useGetProcessQuery, 
    useGetProvisionedProcessQuery,
    useGetResourceQuery, 
    useGetProvisionedResourceQuery
 } from "../../services/mip";

export default function ProvisionableItemIndex(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const userCanViewResources = privilege.includes("ProvisionResources") || privilege.includes("All");

    // useEffect that sets document title is down in the ProvisionableItemFormContainer

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    // scroll to top on first render
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        provisionableItemType,
        provisionMode,
        identifier: itemIdentifier
    } = useParams();

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessQuery(itemIdentifier, {skip: !isLoggedIn || provisionableItemType.toLocaleLowerCase() === "resource" || provisionMode.toLocaleLowerCase() === "new"});

    const {
        data: newAvailableProcessData,
        error: newAvailableProcessAPIError,
        isFetching: newAvailableProcessDataFetching,
    } = useGetProcessQuery(itemIdentifier, {skip: !isLoggedIn || provisionableItemType.toLocaleLowerCase() === "resource" || provisionMode.toLocaleLowerCase() !== "new"});

    const {
        data: provisionedResourceData,
        error: provisionedResourceAPIError,
        isFetching: provisionedResourceDataFetching
    } = useGetProvisionedResourceQuery(itemIdentifier, {skip: !isLoggedIn || !userCanViewResources || provisionableItemType.toLocaleLowerCase() === "process" || provisionMode.toLocaleLowerCase() === "new"});
    
    const {
        data: newAvailableResourceData,
        error: newAvailableResourceAPIError,
        isFetching: newAvailableResourceDataFetching
    } = useGetResourceQuery(itemIdentifier, {skip: !isLoggedIn || !userCanViewResources || provisionableItemType.toLocaleLowerCase() === "process" || provisionMode.toLocaleLowerCase() !== "new"});

    const provisionableItem = useMemo(() => {
        if (newAvailableProcessData && provisionableItemType.toLocaleLowerCase() === "process" && provisionMode.toLocaleLowerCase() === "new") {
            return newAvailableProcessData;
        } else if (provisionedProcessData && provisionableItemType.toLocaleLowerCase() === "process" && provisionMode.toLocaleLowerCase() !== "new") {
            return provisionedProcessData;
        } else if (newAvailableResourceData && provisionableItemType.toLocaleLowerCase() === "resource" && provisionMode.toLocaleLowerCase() === "new") {
            return newAvailableResourceData;
        } else if (provisionedResourceData && provisionableItemType.toLocaleLowerCase() === "resource" && provisionMode.toLocaleLowerCase() !== "new") {
            return provisionedResourceData;
        }
    }, [
        provisionMode,
        provisionableItemType,
        newAvailableProcessData,
        newAvailableResourceData,
        provisionedProcessData,
        provisionedResourceData
    ]);

    const {
        data: existingAvailableProcessData,
        error: existingAvailableProcessAPIError,
        isFetching: existingAvailableProcessDataFetching,
    } = useGetProcessQuery(provisionableItem?.ProvisionableItemIdentifier, {skip: !isLoggedIn || !provisionableItem?.ProvisionableItemIdentifier || provisionableItemType.toLocaleLowerCase() === "resource" || provisionMode.toLocaleLowerCase() === "new"});

    const {
        data: existingAvailableResourceData,
        error: existingAvailableResourceAPIError,
        isFetching: existingAvailableResourceDataFetching,
    } = useGetResourceQuery(provisionableItem?.ProvisionableItemIdentifier, {skip: !isLoggedIn || !provisionableItem?.ProvisionableItemIdentifier || provisionableItemType.toLocaleLowerCase() === "process" || provisionMode.toLocaleLowerCase() === "new"});

    useEffect(() => {
        let redirectToLogin = false;
        if (provisionedProcessAPIError && provisionedProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (provisionedResourceAPIError && provisionedResourceAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (newAvailableProcessAPIError && newAvailableProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (newAvailableResourceAPIError && newAvailableResourceAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (existingAvailableProcessAPIError && existingAvailableProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (existingAvailableResourceAPIError && existingAvailableResourceAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        provisionedProcessAPIError,
        newAvailableProcessAPIError,
        provisionedResourceAPIError,
        newAvailableResourceAPIError,
        existingAvailableProcessAPIError,
        existingAvailableResourceAPIError
    ]);

    const availableProvisionableItemWhenEditing = useMemo(() => {
        if (existingAvailableProcessData && provisionableItemType.toLocaleLowerCase() === "process" && provisionMode.toLocaleLowerCase() !== "new") {
            return existingAvailableProcessData;
        } else if (existingAvailableResourceData && provisionableItemType.toLocaleLowerCase() === "resource" && provisionMode.toLocaleLowerCase() !== "new") {
            return existingAvailableResourceData;
        }
    }, [
        provisionableItemType,
        provisionMode,
        existingAvailableProcessData,
        existingAvailableResourceData
    ]);

    const somethingIsFetching = provisionedProcessDataFetching || newAvailableProcessDataFetching || provisionedResourceDataFetching || newAvailableResourceDataFetching || existingAvailableResourceDataFetching || existingAvailableProcessDataFetching;
    const firstError = provisionedProcessAPIError || newAvailableProcessAPIError || provisionedResourceAPIError || newAvailableResourceAPIError || existingAvailableProcessAPIError || existingAvailableResourceAPIError;

    return (
        <>
        <UserInformationBanner />
        {
            firstError
                ? <ErrorTile message={firstError} />
                : somethingIsFetching
                    ? <LoadingSpinner margin="mx-auto mt-4" text="Loading Form" />
                    : !provisionableItem
                        ?   <NotFound message="Item Not Found" />
                        :   <ProvisionableItemInitialStateSetup
                                provisionableItem={provisionableItem}
                                provisionMode={provisionMode.toLowerCase()}
                                provisionableItemType={provisionableItemType.toLowerCase()}
                                availableProvisionableItemWhenEditing={availableProvisionableItemWhenEditing}
                            />
        }
        </>
    );
}