import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import NotFound from "../../components/Common/NotFound";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import LogsTable from "../../components/Logs/LogsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretUp,
    faCaretRight,
    faSpinner,
    faArrowRotateBackward,
    faArrowRotateForward,
    faPager,
    faClock
} from "@fortawesome/free-solid-svg-icons";
import { midgardFetch } from "../../services/mip";
import { formatDateAsISO } from "../../midgard.js";

// I have always defined these inside the component, but it looks like best practices are to not do that so it doesn't have
// to be recreated on every render. Interesting difference between the other code so noting it here for my future reference.
const logEntryReducer = (state, action) => {
    switch (action.type) {
        case "storeResults":
            const initialKeyCount = action.clearCache ? 0 : Object.keys(state.logEntries).length;
            const newLogEntriesByIdentifier = action.entries.reduce((acc, item) => ({ ...acc, [item.Identifier]: item }), {});
            const newLogEntries = action.clearCache
                ?   newLogEntriesByIdentifier
                :   {
                        ...state.logEntries,
                        ...newLogEntriesByIdentifier
                    };
            const updatedKeyCount = Object.keys(newLogEntries).length;
            const allTimeStamps = Object.values(newLogEntries).map((logEntry) => new Date(`${ logEntry.Timestamp }Z`));
            const youngestTimestamp = allTimeStamps.length === 0 ? new Date() : new Date(Math.max.apply(null, allTimeStamps)).toISOString();
            const oldestTimestamp = allTimeStamps.length === 0 ? new Date() : new Date(Math.min.apply(null, allTimeStamps)).toISOString();
            return {
                lastLoadCount: updatedKeyCount - initialKeyCount,
                totalLogEntries: newLogEntries.length,
                logEntries: newLogEntries,
                lastQueryDetails: action.lastQueryDetails,
                youngestTimestamp,
                oldestTimestamp
            };
        default:
            return state;
    }
};


export default function Logs(props) {   
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Logs") || privilege.includes("All");

    const [referenceTimestampInputValue, setReferenceTimestampInputValue] = useState(formatDateAsISO(new Date()));
    const [pageSize, setPageSize] = useState(500);
    const [pageIndex, setPageIndex] = useState(0);
    const [expandOptions, setExpandOptions] = useState(true);
    const [useUTC, setUseUTC] = useState(false);
    const [expandAllLogs, setExpandAllLogs] = useState(false);
    const [globalLogs, setGlobalLogs] = useState(false);
    const [lambdaRequestIdentifier, setLambdaRequestIdentifier] = useState("");
    const [cachedLogEntries, dispatch] = useReducer(logEntryReducer, {
        lastLoadCount: undefined,
        totalLogEntries: 0,
        logEntries: [],
        oldestTimestamp: undefined,
        youngestTimestamp: undefined
    });
    const [isResetting, setIsResetting] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [logApiError, setLogApiError] = useState();
    const [timestampValidationError, setTimestampValidationError] = useState();
    const [hideLoadButtons, setHideLoadButtons] = useState(false);

    const offset = (new Date().getTimezoneOffset()) / -60;

    useEffect(() => {
        document.title = "Administration - Logs";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const switchPageSize = (newPageSize) => {
        setPageSize(parseInt(newPageSize));
        if (pageIndex !== 0) {
            setPageIndex(0);
        }
    };

    const updateTimestampToNow = () => {
        if (useUTC) {
            setReferenceTimestampInputValue((new Date()).toISOString());
        } else {
            setReferenceTimestampInputValue(formatDateAsISO(new Date()));
        }
    };

    const toggleUseUTC = (value) => {
        const currentTimeStampValue = referenceTimestampInputValue;
        if (value) { 
            // if we are setting UTC to true then referenceTimeStamp is currently in local
            // this converts it back to UTC
            setReferenceTimestampInputValue(new Date(currentTimeStampValue).toISOString());
        } else {
            // if we are going from UTC to Local...
            setReferenceTimestampInputValue(formatDateAsISO(new Date(currentTimeStampValue)));
        }
        setUseUTC(value);
    };

    const fetchLogs = async (globalLogs, lambdaRequestIdentifier, pageSize, pageToken, orderByDescending, clearCache) => {
        setIsFetching(true);
        setLogApiError(undefined);

        const urlParameters = [];
        if (lambdaRequestIdentifier !== "") {
            urlParameters.push(`lambdaRequestIdentifier=${ encodeURIComponent(lambdaRequestIdentifier) }`);
        } else if (globalLogs === true) {
            urlParameters.push("globalLogs=true");
        }
        urlParameters.push(`pageSize=${ encodeURIComponent(pageSize) }`);
        urlParameters.push(`pageToken=${ encodeURIComponent(pageToken) }`);
        urlParameters.push(`orderByDescending=${ encodeURIComponent(orderByDescending) }`);
        urlParameters.push("extend=true");

        midgardFetch("logs", "GET", undefined, undefined, urlParameters)
            .then(async (response) => {
                const responseData = await response.json();
                if (!response.ok) {
                    setLogApiError(responseData?.Data?.Message ?? responseData);
                } else {
                    process.env.REACT_APP_ENV === "test" && console.log("fetchLogs result", responseData);
                    dispatch({
                        type: "storeResults",
                        clearCache,
                        entries: responseData.Data.Page,
                        lastQueryDetails: 
                            lambdaRequestIdentifier !== ""
                                ? `entries related to LRI ${ lambdaRequestIdentifier }`
                                : `${ clearCache ? "initial" : "additional" } entries ${ orderByDescending ? "prior to" : "after" } ${ new Date(pageToken) }`
                    });
                }
            }).catch((error) => {
                setLogApiError(error);
            }).finally(() => {
                setIsFetching(false);
            });
    };

    const fetchInitialLogs = async (lookBackwards) => {
        setIsResetting(true);
        setHideLoadButtons(false);
        setTimestampValidationError(undefined);
        setLambdaRequestIdentifier("");
        let referenceTimestampToSend = undefined;
        try {
            referenceTimestampToSend = useUTC ? referenceTimestampInputValue : new Date(referenceTimestampInputValue).toISOString();
        } catch (ex) {
            setTimestampValidationError(ex);
        }
        if (referenceTimestampToSend) {
            await fetchLogs(globalLogs, "", pageSize, referenceTimestampToSend, lookBackwards, true);
        }
        setIsResetting(false);
    };

    const fetchAdditionalLogs = async (lookBackwards) => {
        const pageToken = lookBackwards ? cachedLogEntries.oldestTimestamp : cachedLogEntries.youngestTimestamp;
        await fetchLogs(globalLogs, "", pageSize, pageToken, lookBackwards, false);
    };

    const queryByLambdaRequestIdentifier = async (lriGuid) => {
        setIsResetting(true);
        setHideLoadButtons(true);
        setPageIndex(0);
        if (typeof(lriGuid) === "string") {
            setLambdaRequestIdentifier(lriGuid);
        }
        setTimestampValidationError(undefined);
        await fetchLogs(false, typeof(lriGuid) === "string" ? lriGuid : lambdaRequestIdentifier, pageSize, undefined, false, true);
        setIsResetting(false);
    };

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white">
            <div className="p-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faPager} /> Logs</div>
                    </div>
                    <div className="border p-2">
                        {
                            expandOptions
                                ?   <div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="initialTimestamp">
                                                    Reference Timestamp
                                                </label>
                                            </div>
                                            <input id="initialTimestamp" type="text" value={referenceTimestampInputValue} onChange={(e) => setReferenceTimestampInputValue(e.target.value)} className="shadow appearance-none border rounded w-11/12 py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                            <button onClick={updateTimestampToNow} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/12"><FontAwesomeIcon icon={faClock} /> Now</button>
                                        </div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="lambdaRequestIdentifier">
                                                    Lambda Request Identifier
                                                </label>
                                            </div>
                                            <input type="text" value={lambdaRequestIdentifier} onChange={(e) => setLambdaRequestIdentifier(e.target.value)} className="shadow appearance-none border rounded w-11/12 py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                            <button onClick={queryByLambdaRequestIdentifier} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/12">Query</button>
                                        </div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="globalLogs">
                                                    Log Type
                                                </label>
                                            </div>
                                            <label className="ml-4">
                                                <input type="radio" name="globalLogs" id="globalLogs" value="customer" checked={!globalLogs} className="align-middle" onChange={(e) => setGlobalLogs(false)} />
                                                <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">Customer</span>
                                            </label>
                                            <label className="ml-4">
                                                <input type="radio" name="globalLogs" id="globalLogs" value="global" checked={globalLogs} className="align-middle" onChange={(e) => setGlobalLogs(true)} />
                                                <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">Global</span>
                                            </label>
                                        </div>
                                        <div className="my-2">
                                                <div className="mb-4">
                                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="pageSize">
                                                        Page Size
                                                    </label>
                                                </div>
                                                <select id="pageSize" name="pageSize" value={pageSize} onChange={(e) => switchPageSize(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                    <option key="100" value="100">100</option>
                                                    <option key="500" value="500">500</option>
                                                    <option key="1000" value="1000">1000</option>
                                                </select>
                                            </div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="globalLogs">
                                                    Additional Options
                                                </label>
                                            </div>
                                            <div className="m-1 mb-2 pl-2">
                                                <label className="align-middle" htmlFor="useUTC">
                                                    <input type="checkbox" name="useUTC" id="useUTC" value={useUTC} checked={useUTC ? "checked" : ""} onChange={(e) => {toggleUseUTC(e.target.checked);}} className="mr-2 align-middle" />
                                                    <span className="text-grey-800 align-middle">Use UTC</span>
                                                </label>
                                            </div>
                                            <div className="m-1 mb-2 pl-2">
                                                <label className="align-middle" htmlFor="expandAllLogs">
                                                    <input type="checkbox" name="expandAllLogs" id="expandAllLogs" value={expandAllLogs} checked={expandAllLogs ? "checked" : ""} onChange={(e) => {setExpandAllLogs(e.target.checked);}} className="mr-2 align-middle" />
                                                    <span className="text-grey-800 align-middle">Expand All</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flex justify-evenly">
                                            <button data-test-id="load-prior-to-reference-button" disabled={isFetching} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => fetchInitialLogs(true)}>{isFetching ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span><FontAwesomeIcon icon={faArrowRotateBackward} /> Load Prior to Reference</span>}</button>
                                            <button data-test-id="load-after-reference-button" disabled={isFetching} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => fetchInitialLogs(false)}>{isFetching ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span><FontAwesomeIcon icon={faArrowRotateForward} /> Load After Reference</span>}</button>
                                        </div>
                                    </div>
                                : null
                        }
                        <div className="flex-initial text-right text-sm hover:cursor-pointer" onClick={() => setExpandOptions(!expandOptions)}>Log Options {expandOptions ? <FontAwesomeIcon icon={faCaretUp}  className="w-3" /> : <FontAwesomeIcon icon={faCaretRight} className="w-3" />}</div>
                    </div>
                    { 
                        timestampValidationError 
                            ? <ErrorTile message={timestampValidationError} />
                            : null
                    }
                    { 
                        logApiError 
                            ? <ErrorTile message={logApiError} />
                            : null
                    }
                    {
                        isResetting
                            ? <LoadingSpinner text="Fetching logs" />
                            : Object.keys(cachedLogEntries.logEntries).length === 0
                                ?   <>
                                        <div data-test-id="no-log-entries-found" className="text-sm text-center pt-4">
                                            {
                                                cachedLogEntries.lastLoadCount === 0 ? `No ${ cachedLogEntries.lastQueryDetails }.` : "Use the form above to query logs."
                                            }
                                        </div>
                                    </>
                                :   <div className="pt-4">
                                        <LogsTable
                                            cachedLogEntries={cachedLogEntries}
                                            offset={offset}
                                            useUTC={useUTC}
                                            expandAll={expandAllLogs}
                                            hideLoadButtons={hideLoadButtons}
                                            queryByLambdaRequestIdentifier={queryByLambdaRequestIdentifier}
                                            isFetching={isFetching}
                                            pageSize={pageSize}
                                            pageIndex={pageIndex}
                                            setPageIndex={setPageIndex}
                                            fetchAdditionalLogs={fetchAdditionalLogs}
                                        />
                                    </div>
                    }
                </div>
            </div>
        </div>
        </>
    );
}