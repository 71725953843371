import { useLayoutEffect, useMemo, useState } from "react";
import ImportDataSelectorField from "../ImportDataSelectorField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Select, { createFilter } from "react-select";

// could put this here to avoid the eslint warnings, but don't understand what it's doing
//const selectOptions = [];
export default function ImportDataSelectorFieldContainer(props) {   

    const properties = props.formState[props.field.Parameter.Identifier].value;
    const allPropertyValueTargets = properties.map((p) => p.Name);
    const targetsAlreadyMapped = properties.filter((p) => p.displayIndex !== -1).map((p) => p.Name);
    const dataFromFileAlreadyMapped = useMemo(() => {
        const columnNamesThatAreMapped = [];
        for (const property of properties) {
            if (property.displayIndex !== -1) {
                for (const userEnteredData of property.userEnteredData) {
                    if (userEnteredData.source === "DataFile") {
                        columnNamesThatAreMapped.push(props.columnsInDataFile[parseInt(userEnteredData.dataFileLocation)]);
                    }
                    if (userEnteredData.applyStringConcatenations === true && Array.isArray(userEnteredData.concatenations)) {
                        for (const concatenation of userEnteredData.concatenations) {
                            if (concatenation.source !== "Constant") {
                                columnNamesThatAreMapped.push(concatenation.source);
                            }
                        }
                    }
                    if (userEnteredData.applyCalculation === true && Array.isArray(userEnteredData.calculations)) {
                        for (const calculation of userEnteredData.calculations) {
                            if (calculation.source !== "Constant") {
                                columnNamesThatAreMapped.push(calculation.source);
                            }
                        }
                    }
                }
            }
        }
        return columnNamesThatAreMapped;
    }, [properties, props.columnsInDataFile]);

    const dataFromFileNotAlreadyMapped = props.columnsInDataFile.filter((column) => !dataFromFileAlreadyMapped.includes(column) && !props.fixedWidthUnmappedIndexes.includes(props.columnsInDataFile.indexOf(column)));

    const selectOptions = useMemo(() => {
        const options = [];
        for (const propertyName of allPropertyValueTargets.sort()) {
            if (!targetsAlreadyMapped.includes(propertyName)) {
                options.push({value: propertyName, label: propertyName});
            }
        }
        return options;
    }, [targetsAlreadyMapped, allPropertyValueTargets]);

    const [propertyToAdd, setPropertyToAdd] = useState(selectOptions.length > 0 ? selectOptions[0].value : null);
    
    // this useLayoutEffect makes sure that the removed element (after it was added so it was removed from the list)
    // is no longer the selected one.
    useLayoutEffect(() => {
        const availableOptionNames = selectOptions.map((o) => o.value);
        if (selectOptions.length > 0 && !availableOptionNames.includes(propertyToAdd)) {
            setPropertyToAdd(selectOptions[0].value);
        }
    }, [selectOptions, propertyToAdd]);

    const propertiesToDisplay = properties.filter((property) => property.displayIndex !== -1);
    // this will result in IsRequired alphabetically and then DisplayIndex alphabetically.
    propertiesToDisplay.sort((a, b) => {
        if (a.IsRequired && !b.IsRequired) {
            return -1;
        }
        if (b.IsRequired && !a.IsRequired) {
            return 1;
        }
        if (a.displayIndex < b.displayIndex) {
            return -1;
        }
        if (a.displayIndex > b.displayIndex) {
            return 1;
        }
        // stackoverflow gem for doing alphabetical sorting.
        return a.Name.localeCompare(b.Name);
    });

    const customPropertyKeysAlreadyMapped = {};
    for (const property of propertiesToDisplay) {
        if (Array.isArray(property.userEnteredData) && property.userEnteredData.length > 0) {
            for (const userEnteredData of property.userEnteredData) {
                if (userEnteredData.customPropertyKey !== undefined && userEnteredData.customPropertyKey !== null && userEnteredData.customPropertyKey !== "") {
                    if (!customPropertyKeysAlreadyMapped.hasOwnProperty(property.Name)) {
                        customPropertyKeysAlreadyMapped[property.Name] = [];
                    }
                    customPropertyKeysAlreadyMapped[property.Name].push(userEnteredData.customPropertyKey);
                }
            }
        }
    }

    const importDataSelectorFields = [];
    for (const property of propertiesToDisplay) {
        if (Array.isArray(property.userEnteredData) && property.userEnteredData.length > 0) {
            property.userEnteredData.forEach((data, index) => {
                importDataSelectorFields.push(
                    <ImportDataSelectorField
                        key={`${ property.Name  }-${  index }`}
                        property={property}
                        userEnteredData={data}
                        customPropertyKeysAlreadyMapped={customPropertyKeysAlreadyMapped}
                        index={index}
                        onDeleteClick={props.onDeleteClick}
                        onAddFieldClick = {props.onAddFieldClick}
                        showAddAdditionalFieldOption={property.AllowMultipleMappingOnImport && property.userEnteredData.length === index + 1}
                        setFieldValue={props.setFieldValue}
                        columnsInDataFile={props.columnsInDataFile}
                        fixedWidthUnmappedIndexes={props.fixedWidthUnmappedIndexes}
                    />
                );
            });
        }
    }

    return (
        <>
        <div className="shadow border rounded p-4">
            <div data-test-id="import-data-selector-container-div">
            {
                properties.length === 0
                    ? <div className="text-center">Select a destination and then use the "+" (plus) button to add it.</div>
                    : importDataSelectorFields
            }
            </div>
            {
                propertyToAdd && selectOptions.length > 0 
                    ? <>
                        <div className="text-center">Select a destination field and then use the "+" (plus) button to add it to the fields to map.</div>
                        <div className="flex justify-evenly p-4">
                            <Select id="import-data-selector-select-field" filterOption={createFilter({ ignoreAccents: false })} isSearchable={true} name="propertyToAdd" inputId="propertyToAdd" value={{value: propertyToAdd, label: propertyToAdd}} options={selectOptions} defaultValue={selectOptions[0]} onChange={(e) => setPropertyToAdd(e.value)} className="w-11/12" />
                            <button data-test-id="import-data-selector-add-field-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-0 px-4 rounded" type="button" onClick={() => {props.onAddFieldClick(propertyToAdd);}}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                      </>
                    : <div className="text-center">All options have been mapped.</div>
            }
        </div>
        <div className="my-4">
            {
                dataFromFileNotAlreadyMapped.length === 0
                    ?   <div className="mb-2">All columns from your data file have been mapped to an entity property.</div>
                    :   <>
                            <div className="mb-2">The following columns are present in your uploaded file but have not yet been mapped to a destination:</div>
                            <ul className="list-disc list-inside ml-4">{dataFromFileNotAlreadyMapped.map((column, index) => (<li key={index}>{column}</li>))}</ul>
                        </>
            }
        </div>
        </>
    );
}