import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import ApiKeysValueModal from "../../../components/ApiKeys/ApiKeysValueModal";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCog,
    faEdit,
    faEye,
    faPlus,
    faToggleOff,
    faToggleOn,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";

import { 
    useGetApiKeysQuery,
    useDeleteApiKeyMutation,
    useToggleApiKeyMutation,
} from "../../../services/mip";

export default function ApiKeys(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionResources") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - API Keys";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: apiKeysData,
        error: apiKeysError,
        isFetching: apiKeysIsFetching
    } = useGetApiKeysQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const [deleteApiKey] = useDeleteApiKeyMutation();
    const confirmDelete = async (identifier) => {
        try {
            await deleteApiKey(encodeURIComponent(identifier)).unwrap();
            setConfirmDeleteModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [putApiKey] = useToggleApiKeyMutation();
    const toggleApiKey = async (apiKeyId, enabled) => {
        setToggling(true);
        setToggleError(undefined);
        const dataToPost = {
            id: apiKeyId,
            body: {
                Enabled: enabled
            }
        };
        try {
            await putApiKey(dataToPost).unwrap();
        } catch (err) {
            setToggleError(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
        }
        setToggling(false);
    };

    const [showValueModal, setShowValueModal] = useState();
    const [confirmDeleteModalDisplayed, setConfirmDeleteModalDisplayed] = useState();
    const [toggleError, setToggleError] = useState();
    const [toggling, setToggling] = useState(false);

    useEffect(() => {
        if (apiKeysError && apiKeysError.status === 401) {
            window.location.href="/login";
        }
    }, [apiKeysError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="api-keys" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faCog} /> API Keys</div>
                        <div className="flex-initial text-right">
                            <button data-test-id="api-key-plus-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/settings/api-keys/new");}}><FontAwesomeIcon className="w-auto" icon={faPlus} /></button>
                        </div>
                    </div>
                    {
                        apiKeysError
                            ? <ErrorTile message={apiKeysError} />
                            : apiKeysIsFetching
                                ? <LoadingSpinner text={"Loading API Keys"} />
                                : toggling
                                    ? <LoadingSpinner text={"Updating API Key"} />
                                    : !apiKeysData || !apiKeysData.hasOwnProperty("Data") || !Array.isArray(apiKeysData.Data) || apiKeysData.Data.length === 0
                                        ?   <div className="text-center">No API Keys configured.</div>
                                        :   toggleError
                                            ?   <ErrorTile message={toggleError} />
                                            :   <div className="grid" style={{gridTemplateColumns: "repeat(2, auto) repeat(2, 9rem) repeat(2, min-content)"}}>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Description</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Identifier</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Usage Plan</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Enabled</div>
                                                    {
                                                        apiKeysData.Data.map((key, index) => (
                                                            <div className="contents group" key={`key-${  index }`}>
                                                                <div className="p-2 border group-odd:bg-gray-100">{key.Description}</div>
                                                                <div className="p-2 border group-odd:bg-gray-100">{key.Name}</div>
                                                                <div className="p-2 border group-odd:bg-gray-100">{key.Id}</div>
                                                                <div className="p-2 border group-odd:bg-gray-100">{key.UsagePlan.Name}</div>
                                                                <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                                    <button title="View API Key Value" id={`view-${  key.Id }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {setShowValueModal(key.Id);}}><FontAwesomeIcon className="w-auto" icon={faEye} /></button>
                                                                    <button title="Edit" id={`edit-${  key.Id }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigate(`/settings/api-keys/${  key.Id }`);}}><FontAwesomeIcon className="w-auto" icon={faEdit} /></button>
                                                                    <button title="Delete" id={`delete-${  key.Id }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteModalDisplayed(key.Id)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                </div>
                                                                <div className="px-2 border group-odd:bg-gray-100">
                                                                    {
                                                                        key.Enabled
                                                                            ? <button data-test-id={`disable-${  key.Id }`} disabled={toggling} onClick={() => toggleApiKey(key.Id, false)} className="hover:text-red-700 text-green-700 font-bold py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOn} /></button> 
                                                                            : <button data-test-id={`enable-${  key.Id }`} disabled={toggling} onClick={() => toggleApiKey(key.Id, true)} className="hover:text-green-700 py-1 px-4 text-3xl align-middle"><FontAwesomeIcon icon={faToggleOff} /></button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                    }
                </div>
                {
                    apiKeysData?.Data && Array.isArray(apiKeysData.Data) && confirmDeleteModalDisplayed !== undefined
                        ? apiKeysData.Data.map((key, index) => (
                            confirmDeleteModalDisplayed === key.Id
                                ?   <ConfirmModal
                                        key={index}
                                        title={`Confirm Delete of ${  key.Name  } API Key`}
                                        submitButtonTitle="Delete"
                                        identifier={key.Id}
                                        confirmationMessage={`Are you sure you want to delete ${  key.Name  }?`} 
                                        successMessage="API Key deleted successfully."
                                        failureMessage="API Key failed to delete:"
                                        closeModal={setConfirmDeleteModalDisplayed}
                                        confirmFunction={confirmDelete}
                                    />
                                : null
                        ))
                        : null
                }
                {
                    apiKeysData?.Data && Array.isArray(apiKeysData.Data) && showValueModal !== undefined
                        ? apiKeysData.Data.map((key, index) => (
                            showValueModal === key.Id
                                ?   <ApiKeysValueModal 
                                        key={index}
                                        title={`${ key.Name  } API Key Value`}
                                        identifier={key.Id}
                                        closeModal={setShowValueModal}
                                    />
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );

}