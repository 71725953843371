import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faNetworkWired,
    faPlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";

import { 
    useGetExternalEntitiesSystemsQuery,
    useDeleteExternalEntitiesSystemsMutation
} from "../../../services/mip";

export default function ExternalEntities(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - External Entities";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: externalEntitiesSystemsData,
        error: externalEntitiesSystemsError,
        isFetching: externalEntitiesSystemsIsFetching
    } = useGetExternalEntitiesSystemsQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const [confirmDeleteModalDisplayed, setConfirmDeleteModalDisplayed] = useState();
    const [deleteSystemMutation] = useDeleteExternalEntitiesSystemsMutation();
    const confirmDelete = async (identifier) => {
        try {
            await deleteSystemMutation(encodeURIComponent(identifier)).unwrap();
            setConfirmDeleteModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    useEffect(() => {
        if (externalEntitiesSystemsError && externalEntitiesSystemsError.status === 401) {
            window.location.href="/login";
        }
    }, [externalEntitiesSystemsError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="external-entities" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faNetworkWired} /> External Entities</div>
                        <div className="flex-initial text-right">
                            <button data-test-id="external-entities-system-plus-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/settings/external-entities/systems/new");}}><FontAwesomeIcon className="w-auto" icon={faPlus} /></button>
                        </div>
                    </div>
                    {
                        externalEntitiesSystemsError
                            ? <ErrorTile message={externalEntitiesSystemsError} />
                            : externalEntitiesSystemsIsFetching
                                ? <LoadingSpinner text={"Loading External Entities Systems"} />
                                : !externalEntitiesSystemsData || !Array.isArray(externalEntitiesSystemsData) || externalEntitiesSystemsData.length === 0
                                    ?   <div className="text-center">No External Entities Systems configured.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "auto min-content"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            {
                                                externalEntitiesSystemsData.map((system, index) => (
                                                    <div className="contents group" key={`system-${ index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100">{system.Name}</div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            <button title="View Entities" id={`view-${ system.Identifier }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigate(`/settings/external-entities/${ system.Identifier }`);}}><FontAwesomeIcon className="w-auto" icon={faNetworkWired} /></button>
                                                            <button title="Edit" id={`edit-${ system.Identifier }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigate(`/settings/external-entities/systems/${ system.Identifier }`);}}><FontAwesomeIcon className="w-auto" icon={faEdit} /></button>
                                                            <button title="Delete" id={`delete-${ system.Identifier }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteModalDisplayed(system.Identifier)}><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                    }
                </div>
                {
                    externalEntitiesSystemsData && Array.isArray(externalEntitiesSystemsData) && confirmDeleteModalDisplayed !== undefined
                        ? externalEntitiesSystemsData.map((system, index) => (
                            confirmDeleteModalDisplayed === system.Identifier
                                ?   <ConfirmModal
                                        key={index}
                                        title={`Confirm Delete of ${ system.Name  } External Entities System`}
                                        submitButtonTitle="Delete"
                                        identifier={system.Identifier}
                                        confirmationMessage={`Are you sure you want to delete ${  system.Name  }?`} 
                                        successMessage="External Entities System deleted successfully."
                                        failureMessage="External Entities System failed to delete:"
                                        closeModal={setConfirmDeleteModalDisplayed}
                                        confirmFunction={confirmDelete}
                                    />
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}