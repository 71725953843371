import { useLayoutEffect, useState } from "react";

export default function Footer(props) {
    const [title, setTitle] = useState("Baxter Software Solutions");
    useLayoutEffect(() => {
        if (window?.location?.href && window.location.href.includes("app.dop.omnitracs.com"))
        {
            setTitle("");
        }
    }, []);

    return (
        <footer className="print:hidden h-24 w-full pt-12 px-8 text-right bg-black text-white absolute bottom-0">
            &copy; {new Date().getFullYear()} {title}
        </footer>
    );
}