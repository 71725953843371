import { useLayoutEffect } from "react";
import ExportDataSelectorFieldContainer from "../ExportDataSelectorFieldContainer";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ErrorTile from "../../Common/ErrorTile";
import { geValuesApiUrl } from "../../../midgard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { useGetExternalDataQuery } from "../../../services/mip";

export default function ExportDataSelector(props) {   

    const externalDataApiUrl = geValuesApiUrl(props.field, props.formState);
    const { 
        data,
        error: apiErrorMessage,
        isFetching: isLoadingFromAPI,
        refetch: fetchValuesFromAPI
    } = useGetExternalDataQuery(externalDataApiUrl);

    const entityPropertyOptions = [];
    const entityValueByName = {};
    if (data !== undefined) {
        for (const dataObject of data) {
            entityPropertyOptions.push({value: dataObject.Name, label: dataObject.Name});
            entityValueByName[dataObject.Name] = dataObject;
        }
    }

    // anytime data changes and is not undefined put it in the external data state
    const {formStateDispatch} = props;
    useLayoutEffect(() => {
        formStateDispatch({type: "setExternalData", payload: { fieldIdentifier: props.field.Parameter.Identifier, data}});
    }, [data, formStateDispatch, props.field.Parameter.Identifier]);

    // if we are dealing with a flat file, find all array's and make sure
    // the array data aligns, add things that are missing and remove things that 
    // are no longer mapped.
    useLayoutEffect(() => {
        const isFlatFile = ["Delimited", "Excel", "FixedWidth"].includes(props.formState[props.field.ResultFileTypeProvisioningIdentifier].value);
        const flattenChecked = props.field.FlattenProvisioningIdentifier && props.formState[props.field.FlattenProvisioningIdentifier].value;
        if (isFlatFile || flattenChecked) {
            let updatedDataMappings = false;
            const mappingDataCopy = Object.assign({}, props.formState[props.field.Parameter.Identifier].value);
            // get unique arrays from mappingData.fields
            const mappingDataArrayIdentifiers = [];
            for (const field of mappingDataCopy.fields) {
                if (field.entityProperty.includes("]")) {
                    mappingDataArrayIdentifiers.push(field.entityProperty.substring(0, field.entityProperty.lastIndexOf("]") + 1));
                }
            }

            // make sure each unique identifier is in the array info section.
            for (const arrayIdentifier of mappingDataArrayIdentifiers) {
                if (!Object.keys(mappingDataCopy.arrayData).includes(arrayIdentifier)) {
                    mappingDataCopy.arrayData[arrayIdentifier] = {
                        NumberOfColumns: 1,
                        VerticalOrHorizontal: "vertical"
                    };
                    updatedDataMappings = true;
                }
            }

            // any that are in the array info section that aren't in the identifiers list
            // need to be removed.
            for (const arrayIdentifier of Object.keys(mappingDataCopy.arrayData)) {
                if (!mappingDataArrayIdentifiers.includes(arrayIdentifier)) {
                    delete mappingDataCopy.arrayData[arrayIdentifier];
                    updatedDataMappings = true;
                }
            }

            if (updatedDataMappings) {
                formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: mappingDataCopy}});
            }
        }
    }, [
        formStateDispatch,
        props.formState, props.field.Parameter.Identifier, props.field.ResultFileTypeProvisioningIdentifier, props.field.FlattenProvisioningIdentifier]);

    // remove any data mappings that no longer exist in the external data mapping
    // this is in case they go back and change the entity type being mapped.
    useLayoutEffect(() => {
        const {externalData} = props.formState[props.field.Parameter.Identifier];
        const fields = props.formState[props.field.Parameter.Identifier].value?.fields ?? null;
        if (externalData !== null && externalData !== undefined && fields !== null && fields !== undefined) {
            const allEntityProperties = [];
            for (const entityPropertyObject of externalData) {
                allEntityProperties.push(entityPropertyObject.Name);
            }
            const indexesToDelete = [];
            for (const index in fields) {
                if (fields[index].source === "Entity" && !allEntityProperties.includes(fields[index].entityProperty)) {
                    indexesToDelete.push(index);
                }
            }
            if (indexesToDelete.length > 0) {
                const formStateValueCopy = {...props.formState[props.field.Parameter.Identifier].value};
                for (const index of indexesToDelete.reverse()) {
                    formStateValueCopy.fields.splice(index, 1);
                }
                formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: formStateValueCopy}});
            }
        }
    }, [formStateDispatch, props.formState, props.field.Parameter.Identifier]);

    const thisSelectorIsFetching = props.formState[props.field.Parameter.Identifier].isFetching;
    useLayoutEffect(() => {
        if (thisSelectorIsFetching !== isLoadingFromAPI) {
            formStateDispatch({type: "setIsFetching", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: isLoadingFromAPI}});
        }
    }, [formStateDispatch, isLoadingFromAPI, thisSelectorIsFetching, props.field.Parameter.Identifier]);


    const handleAddFieldClick = () => {
        const mappingDataCopy = Object.assign({}, props.formState[props.field.Parameter.Identifier].value);
        mappingDataCopy.fields.push({
            destinationProperty: "", // required when using a constant
            destinationPropertyOverride: "", // used when specifying an override for entity property
            customPropertyKey: "",
            source: "Entity",
            entityProperty: entityPropertyOptions !== null && entityPropertyOptions.length > 0 ? entityPropertyOptions[0].value : "",
            constantValue: "",
            formatOption: "None",
            formatString: "",
            formatStringFunctions: [],
            parseString: "",
            destinationTimeZoneId: "",
            sourceTimeZoneId: "",
            applyCalculation: false,
            calculations: [],
            applyStringConcatenations: false,
            concatenations: [], // string concatenations
            useDataMaps: false,
            dataMapName: ""
        });
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: mappingDataCopy}});
    };

    const handleDeleteButtonClick = (index) => {
        const mappingDataCopy = Object.assign({}, props.formState[props.field.Parameter.Identifier].value);
        mappingDataCopy.fields.splice(index, 1);
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: mappingDataCopy}});
    };

    const handleUpButtonClick = (index) => {
        const mappingDataCopy = Object.assign({}, props.formState[props.field.Parameter.Identifier].value);
        const removedElements = mappingDataCopy.fields.splice(index, 1);
        mappingDataCopy.fields.splice(index - 1, 0, removedElements[0]);
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: mappingDataCopy}});
    };

    const handleDownButtonClick = (index) => {
        const mappingDataCopy = Object.assign({}, props.formState[props.field.Parameter.Identifier].value);
        const removedElements = mappingDataCopy.fields.splice(index, 1);
        mappingDataCopy.fields.splice(index + 1, 0, removedElements[0]);
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: mappingDataCopy}});
    };

    const addKeyToDataMappingField = (index, key, value) => {
        const mappingDataCopy = Object.assign({}, props.formState[props.field.Parameter.Identifier].value);
        mappingDataCopy.fields[index][key] = value;

        // Unchecking some things needs to reset other values.
        if (key === "source" && value === "Entity") {
            mappingDataCopy.fields[index]["destinationPropertyOverride"] = "";
            mappingDataCopy.fields[index]["destinationProperty"] = "";
            mappingDataCopy.fields[index]["constantValue"] = "";
            mappingDataCopy.fields[index]["entityProperty"] = entityPropertyOptions !== null && entityPropertyOptions.length > 0 ? entityPropertyOptions[0].value : "";
        } else if (key === "source" && value === "Constant") {
            mappingDataCopy.fields[index]["destinationPropertyOverride"] = "";
            mappingDataCopy.fields[index]["destinationProperty"] = "";
            mappingDataCopy.fields[index]["entityProperty"] = "";
        } else if (key === "formatOption" && value === "None") {
            mappingDataCopy.fields[index]["formatString"] = "";
            mappingDataCopy.fields[index]["formatStringFunctions"] = [];
            mappingDataCopy.fields[index]["parseString"] = "";
            mappingDataCopy.fields[index]["sourceTimeZoneId"] = "";
            mappingDataCopy.fields[index]["destinationTimeZoneId"] = "";
        } else if (key === "formatOption" && value === "Format") {
            // if we change to Format only, clear both Parse and FormatString
            // this is to account for format strings being different between
            // format and parseThenFormat
            mappingDataCopy.fields[index]["formatString"] = "";
            mappingDataCopy.fields[index]["formatStringFunctions"] = [];
            mappingDataCopy.fields[index]["parseString"] = "";
        } else if (key === "formatOption") {
            // if we are changing the format option and it hasn't already been caught
            // by the None or Format options above, then we are changing to
            // Parse or ParseThenFormat in which case we leave Parse alone
            // and only zero out the format string.
            mappingDataCopy.fields[index]["formatString"] = "";
            mappingDataCopy.fields[index]["formatStringFunctions"] = [];
        } else if (key === "applyCalculation" && value === true) {
            // when setting applyCalculation to true create the first default calculation.
            mappingDataCopy.fields[index]["calculations"] = [{
                mathOperation: "Add",
                source: "Constant",
                mathOperand: "0",
                roundOperation: "" // none
            }];
        } else if (key === "applyCalculation" && value === false) {
            mappingDataCopy.fields[index]["calculations"] = [];
        } else if (key === "applyStringConcatenations" && value === true) {
            // when setting applyStringConcatenations to true create the first default calculation.
            mappingDataCopy.fields[index]["concatenations"] = [{
                source: "Constant",
                value: ""
            }];
        } else if (key === "applyStringConcatenations" && value === false) {
            mappingDataCopy.fields[index]["concatenations"] = [];
        } else if (key === "useDataMaps" && value === false) {
            mappingDataCopy.fields[index]["dataMapName"] = "";
        }


        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: mappingDataCopy}});
    };

    const addKeyToArrayDataField = (arrayIdentifier, key, value) => {
        const mappingDataCopy = Object.assign({}, props.formState[props.field.Parameter.Identifier].value);
        mappingDataCopy.arrayData[arrayIdentifier][key] = value;
        props.formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: props.field.Parameter.Identifier, data: mappingDataCopy}});
    };

    return (
        <>
        {
            apiErrorMessage
            ? <div>
                <ErrorTile message={apiErrorMessage} />
                <div className="flex justify-evenly">
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={fetchValuesFromAPI}><FontAwesomeIcon icon={faRedo} /> Refresh</button>
                </div>
              </div>
            : isLoadingFromAPI 
                ?   <LoadingSpinner text="Loading Options" />
                :   <ExportDataSelectorFieldContainer 
                        mappingData={props.formState[props.field.Parameter.Identifier].value}
                        showArrayInfo={["Delimited", "Excel", "FixedWidth"].includes(props.formState[props.field.ResultFileTypeProvisioningIdentifier].value) || (props.field.ResultFileTypeProvisioningIdentifier && props.formState[props.field.ResultFileTypeProvisioningIdentifier].value)}
                        onAddFieldClick={handleAddFieldClick}
                        onDeleteClick={handleDeleteButtonClick}
                        onDownButtonClick={handleDownButtonClick}
                        onUpButtonClick={handleUpButtonClick}
                        setFieldValue={addKeyToDataMappingField}
                        setArrayDataValue={addKeyToArrayDataField}
                        entityPropertyOptions={entityPropertyOptions}
                        entityValueByName={entityValueByName}
                    />
        }
        </>
    );
}