import GeneralDataSelectorStringConcatInfoField from "../GeneralDataSelectorStringConcatInfoField";

export default function GeneralDataSelectorStringConcatInfo(props) {  

    // Import and Export use different setFieldValue signatures so this calls the right one
    const setFieldValue = (fieldIndex, fieldKey, fieldValue) => {
        const newConcatenations = [...props.concatenations];
        newConcatenations[fieldIndex][fieldKey] = fieldValue;

        if (props.property !== undefined) { // Import
            props.setFieldValue(props.property.Name, props.propertyIndex, "concatenations", newConcatenations);
        } else if (props.field !== undefined) { // Export
            props.setFieldValue(props.index, "concatenations", newConcatenations);
        }
    };

    // If the entityProperty is an array, limit the entityProperty options to things that the same array level
    // if it's not an array, limit the entityProperty options to things that are also not in an array
    let optionFilterFunction;
    if (props.field) { // this will only be applied on exports as imports have props.property not props.field
        if (props.field.entityProperty && props.field.entityProperty.lastIndexOf("]") !== -1) {
            const optionPrefixFilter = props.field.entityProperty.substring(0, props.field.entityProperty.lastIndexOf("]")+1);
            optionFilterFunction = (option) => option.startsWith(optionPrefixFilter) && option.substring(optionPrefixFilter.length, option.length).indexOf("[") === -1;
        } else {
            optionFilterFunction = (option) => option.indexOf("[") === -1;
        }
    }

    const addConcatenation = () => {
        const newConcatenations = [...props.concatenations];
        newConcatenations.push({
            source: "Constant",
            value: ""
        });
        if (props.property !== undefined) { // Import
            props.setFieldValue(props.property.Name, props.propertyIndex, "concatenations", newConcatenations);
        } else if (props.field !== undefined) { // Export
            props.setFieldValue(props.index, "concatenations", newConcatenations);
        }
    };

    const removeConcatenation = () => {
        if (props.concatenations.length > 1) {
            const newCalculations = [...props.concatenations.slice(0,-1)];
            if (props.property !== undefined) { // Import
                props.setFieldValue(props.property.Name, props.propertyIndex, "concatenations", newCalculations);
            } else if (props.field !== undefined) { // Export
                props.setFieldValue(props.index, "concatenations", newCalculations);
            }
        }
    };

    return (
        <>
            {
                props.concatenations.map((concatenation, index) => (
                    <GeneralDataSelectorStringConcatInfoField
                        key={`stringConcatInfo-${ props.htmlFieldName }-${ index }`}
                        index={index}
                        concatenation={concatenation}
                        htmlFieldName={props.htmlFieldName}
                        sourceOptions={!optionFilterFunction ? props.sourceOptions : props.sourceOptions.filter(optionFilterFunction)}
                        setFieldValue={setFieldValue}
                    />
                ))
            }
            <div className="text-right">
                <span className="hover:cursor-pointer" data-test-id={`concat-string-control-${ props.htmlFieldName }-add-button`} onClick={addConcatenation}>+</span> / <span className="hover:cursor-pointer" onClick={removeConcatenation}>-</span>
            </div>
        </>
    );
}