import { useReducer, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
    faTimesCircle,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../ErrorTile";
import QueueManualProcessModalPlaceholderValue from "../QueueManualProcessModalPlaceholderValue";

export default function QueueManualProcessModal(props) {   

    const {manualProcess} = props;

    const [errorMessage, setErrorMessage] = useState("");
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const setInitialPlaceholderValuesState = () => {
        const initialState = [];
        for (const placeholderSettingKey of Object.keys(manualProcess.PlaceholderSettings)) {
            if (manualProcess.PlaceholderSettings[placeholderSettingKey].Required) {
                initialState.push({
                    key: placeholderSettingKey,
                    placeholderSetting: manualProcess.PlaceholderSettings[placeholderSettingKey],
                    value: ""
                });
            }
        }
        return initialState;
    };

    const placeholderValuesReducer = (state, action) => {
        const stateCopy = [...state];
        switch (action.type) {
            case "addPlaceholder":
                stateCopy.push({
                    key: action.payload.key,
                    placeholderSetting: action.payload.settingData,
                    value: ""
                });
                break;
            case "setPlaceholderValue":
                stateCopy[action.payload.index].value = action.payload.value;
                break;
            case "deletePlaceholder":
                stateCopy.splice(action.payload.index, 1);
                break;
            default:
                throw new Error("unknown action.type in placeholderSettingReducer");
        }
        return stateCopy;
    };
    
    const [placeholderValues, placeholderValuesDispatch] = useReducer(placeholderValuesReducer, [], setInitialPlaceholderValuesState);

    const placeholderSettingsByKey = {};
    for (const placeholderSettingKey of Object.keys(manualProcess.PlaceholderSettings)) {
        placeholderSettingsByKey[placeholderSettingKey] = manualProcess.PlaceholderSettings[placeholderSettingKey];
    }

    const getPlaceholderSettingsThatCanBeAdded = (justAdded) => {
        const settingsAllowedToBeAdded = [];
        for (const placeholderSettingKey of Object.keys(manualProcess.PlaceholderSettings)) {
            if (manualProcess.PlaceholderSettings[placeholderSettingKey].ConstantValue === null || manualProcess.PlaceholderSettings[placeholderSettingKey].ConstantValue === undefined || manualProcess.PlaceholderSettings[placeholderSettingKey].ConstantValue === "") {
                if (manualProcess.PlaceholderSettings[placeholderSettingKey].AllowMultiple) {
                    settingsAllowedToBeAdded.push(placeholderSettingKey);
                } else {
                    let alreadyExists = false;
                    for (const placeholderValue of placeholderValues) {
                        if (placeholderValue.key === placeholderSettingKey) {
                            alreadyExists = true;
                        }
                    }
                    if (placeholderSettingKey === justAdded) {
                        alreadyExists = true;
                    }
                    if (!alreadyExists) {
                        settingsAllowedToBeAdded.push(placeholderSettingKey);
                    }
                }
            }
        }
        return settingsAllowedToBeAdded;
    };
    const placeholderSettingsThatCanBeAdded = getPlaceholderSettingsThatCanBeAdded();

    const [placeholderSettingKeyToAdd, setPlaceholderSettingKeyToAdd] = useState(placeholderSettingsThatCanBeAdded.length > 0 ? placeholderSettingsThatCanBeAdded[0] : "");
    const [httpBody, setHttpBody] = useState("");

    const queueProcess = async(identifier) => {
        setSubmittingToAPI(true);
        setErrorMessage(null);

        const bodyObj = {};
        if (manualProcess.AllowBody) {
            bodyObj.Body = httpBody;
        }
        if (placeholderValues.length > 0) {
            bodyObj.PlaceholderReplacements = [];
        }
        for (const placeholderValue of placeholderValues) {
            bodyObj.PlaceholderReplacements.push({
                Key: placeholderValue.key,
                Value: placeholderValue.value
            });
        }
        const [success, msg] = await props.queueProcessFunction(identifier, bodyObj);
        if (!success) {
            setErrorMessage(msg);
            setSubmittingToAPI(false);
        }
    };

    const addPlaceholderSettingAndResetValue = () => {
        placeholderValuesDispatch({type: "addPlaceholder", payload: {key: placeholderSettingKeyToAdd, settingData: placeholderSettingsByKey[placeholderSettingKeyToAdd]}});
        const currentPlacedholdersThatCanBeAdded = getPlaceholderSettingsThatCanBeAdded(placeholderSettingKeyToAdd);
        setPlaceholderSettingKeyToAdd(currentPlacedholdersThatCanBeAdded.length > 0 ? currentPlacedholdersThatCanBeAdded[0] : "");
    };

    return (
        <div className="opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-500 opacity-95"></div>
            <div className="fixed w-full h-full z-50 overflow-y-auto">
                <div className="container mx-auto w-1/2 h-auto text-left m-4 bg-white rounded-lg">
                    <div className="bg-purple-800 text-white text-2xl rounded-t-lg ">
                        <div className="flex">
                            <div className="flex-1 p-2">{props.title}</div>
                            <div className="flex-initial p-2"><FontAwesomeIcon className="hover:cursor-pointer" icon={faTimesCircle} onClick={props.closeModal} /></div>
                        </div>
                    </div>
                    { 
                        errorMessage 
                            ? <ErrorTile title={props.failureMessage} message={errorMessage} additionalClasses={["mx-4"]} />
                            : null
                    }
                    <div className="p-4">
                        {
                            placeholderValues && placeholderValues.length > 0
                                ?   <div className="grid mb-4" style={{gridTemplateColumns: "auto 15rem"}}>
                                    {
                                        placeholderValues.map((placeholderValue, index) => (
                                            <QueueManualProcessModalPlaceholderValue
                                                key={index}
                                                placeholderValueIndex={index}
                                                placeholderValue={placeholderValue}
                                                placeholderValuesDispatch={placeholderValuesDispatch}
                                            />
                                        ))
                                    }
                                    </div>
                                : null
                        }
                        {
                            placeholderSettingsThatCanBeAdded.length > 0
                                ?   <div className="flex">
                                        <select name="placeholderSettingToAdd" id="placeholderSettingToAdd" value={placeholderSettingKeyToAdd} onChange={(e) => {setPlaceholderSettingKeyToAdd(e.target.value);}} className="flex-1 shadow border rounded py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline mr-2">
                                            {placeholderSettingsThatCanBeAdded.map((key) => (<option key={key} value={key}>{placeholderSettingsByKey[key].DisplayName}</option>))}
                                        </select>
                                        <button className="bg-green-500 hover:bg-green-700 text-white font-bold px-2 rounded text-sm" type="button" onClick={() => {addPlaceholderSettingAndResetValue();}}><FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                : null
                        }
                        {
                            manualProcess.AllowBody
                                ?   <div className="my-3">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="httpBody">
                                            Body
                                        </label> 
                                        <textarea id="httpBody" name="httpBody" value={httpBody ?? ""} onChange={(e) => setHttpBody(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3 h-32"></textarea>
                                    </div>
                                : null
                        }
                    </div>
                    <div className="flex justify-evenly p-4">
                        <button data-test-id={`confirm-queue-manual-process-button-${ props.identifier }`} onClick={() => {queueProcess(props.identifier);}} disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : props.submitButtonTitle}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}