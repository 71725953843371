import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy,
    faFilter,
    faEye
} from "@fortawesome/free-solid-svg-icons";

export default function LogsTableRowDetailsRow(props) {
    return (
        <div className="flex">
            <div className="flex-1"><span htmltitle={props.longName}>{props.shortName}</span>: {props.displayValue}</div>
            <div className="flex-none">
                <div className="flex justify-evenly pt-1">
                    {
                        props.queryByLambdaRequestIdentifier !== undefined
                            ? <FontAwesomeIcon title="Query by Lambda Request Identifier" className="hover:cursor-pointer text-blue-500 hover:text-blue-700 ml-1" icon={faEye} onClick={() => {props.queryByLambdaRequestIdentifier(props.fullValue);}} />
                            : null
                    }
                    <FontAwesomeIcon title={`Copy ${ props.longName }`} className="hover:cursor-pointer text-blue-500 hover:text-blue-700 ml-1" icon={faCopy} onClick={() => {navigator.clipboard.writeText(props.fullValue).then(() => alert(`Copied ${ props.longName }: ${ props.fullValue }`));}} />
                    <FontAwesomeIcon title={`Filter on ${ props.longName }`} className="hover:cursor-pointer text-blue-500 hover:text-blue-700 ml-1" icon={faFilter} onClick={() => props.filterFunction(props.fullValue)} />
                </div>
            </div>
        </div>
    );
}