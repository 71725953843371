import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug } from "@fortawesome/free-solid-svg-icons";

export default function NotFound(props) {
    return (
        <div className="mx-auto my-20">
            <p className="text-center text-4xl"><FontAwesomeIcon icon={faBug} /></p>
            <p className="text-center text-4xl">{props.message ? props.message : "How'd you get here?"}</p>
        </div>
    );
}