import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCopy,
    faPencil,
    faEye,
    faTrash,
    faRefresh,
    faInfoCircle,
    faWarning,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { useDeleteRNACacheMutation } from "../../../services/mip";

export default function ProvsionedResourcesTableRow(props) {
    const navigate = useNavigate();

    const [showDetails, setShowDetails] = useState(false);
    const [cacheDeleteIsLoading, setCacheDeleteIsLoading] = useState(false);
    const [rowLevelErrorMessage, setRowLevelErrorMessage] = useState();

    const details = [];
    if (props.item.DisplayProperties) {
        for (const propertyKey of Object.keys(props.item.DisplayProperties)) {
            details.push(
                <li className="text-sm" key={propertyKey}><span className="font-bold">{propertyKey}</span>: {props.item.DisplayProperties[propertyKey]}</li>
            );
        }
    }
    const [deleteRNACache] = useDeleteRNACacheMutation();
    const deleteCache = async (identifier) => {
        setRowLevelErrorMessage(undefined);
        setCacheDeleteIsLoading(true);
        try {
            await deleteRNACache(identifier).unwrap();
        } catch (err) {
            setRowLevelErrorMessage(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
        }
        setCacheDeleteIsLoading(false);
    };

    return (
        <>
            <div className="border px-2 group-odd:bg-gray-100">
                <div className="flex p-2">
                    <div className="flex-1">
                        <div className="flex">
                            <div className="flex-1" data-test-id="provisioned-resource-name">{props.item.Name}</div>
                            {
                                props.isAdmin && props.item.ProvisionableItemIdentifier === "5b0f2fd1-1adc-4674-b270-26dd9ac70aaf"
                                    ? <div><button disabled={cacheDeleteIsLoading} onClick={() => {deleteCache(props.item.Identifier);}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm mx-2">{ cacheDeleteIsLoading ? <FontAwesomeIcon icon={faSpinner} className="spinner"/> : <FontAwesomeIcon icon={faRefresh} /> }</button></div>
                                    : null
                            }
                            {
                                details.length > 0
                                    ? <div><button onClick={() => setShowDetails(!showDetails)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm mx-2"><FontAwesomeIcon icon={faInfoCircle} /></button></div>
                                    : null
                            }
                        </div>
                        {
                            rowLevelErrorMessage
                                ? <div className="ml-4 text-sm text-orange-500"><FontAwesomeIcon icon={faWarning} /> {rowLevelErrorMessage}</div>
                                : null
                        }
                        {
                            showDetails
                                ? <ul className="list-disc ml-8">{details}</ul>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                <button title="Copy Resource Identifier" onClick={() => {navigator.clipboard.writeText(props.item.Identifier).then(() => alert(`Copied identifier: ${  props.item.Identifier }`));}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2"><FontAwesomeIcon icon={faCopy} /></button>
                <button title="View" data-test-id={`available-provisionable-item-view-${  props.item.Identifier }`} onClick={() => navigate(`/provisionableitem/resource/view/${ props.item.Identifier }`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faEye} /></button>
                <button title="Edit" data-test-id={`available-provisionable-item-edit-${  props.item.Identifier }`} onClick={() => navigate(`/provisionableitem/resource/edit/${ props.item.Identifier }`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faPencil} /></button>
                <button title="Deprovision" data-test-id={`provisioned-provisionable-item-deprovision-${  props.item.Identifier }`} onClick={() => props.deprovisionModal(props.item.Identifier)} className="bg-red-500 hover:bg-red-700 t text-white font-bold py-1 px-4 rounded text-sm m-2"><FontAwesomeIcon icon={faTrash} /></button>
            </div>
        </>
    );
}
