import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import ConfirmModal from "../../components/Common/ConfirmModal";
import QueueProcessModal from "../../components/Common/QueueProcessModal";
import CreateManualProcessModal from "../../components/Common/CreateManualProcessModal";
import ConfirmUndeployModal from "../../components/Processes/ConfirmUndeployModal";
import NotFound from "../../components/Common/NotFound";
import AvailableProcessesTable from "../../components/Processes/AvailableProcessesTable";
import ProvisionedProcessesTable from "../../components/Processes/ProvisionedProcessesTable";
import { 
    useGetProcessesQuery, 
    useGetProvisionedProcessesQuery,
    useDeprovisionProcessMutation,
    useDeployProcessMutation,
    useUndeployProcessMutation,
    useQueueProcessMutation,
    useCreateManualProcessMutation
 } from "../../services/mip";


export default function Processes(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");
    const hasLogsAccess = privilege.includes("Logs") || privilege.includes("All");
    const hasAdminPrivilege = privilege.includes("Admin") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        document.title = "Processes";
    }, []);

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: availableProcessData,
        error: availableProcessAPIError,
        isFetching: availableProcessDataFetching,
    } = useGetProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const [deployProcessModalDisplayed, setDeployProcessModalDisplayed] = useState(null);
    const [confirmQueueModalDisplayed, setConfirmQueueModalDisplayed] = useState(null);
    const [confirmQueueAPIModalDisplayed, setConfirmQueueAPIModalDisplayed] = useState(null);
    const [confirmCreateManualProcessModalDisplayed, setConfirmCreateManualProcessModalDisplayed] = useState(null);
    const [confirmDeprovisionModalDisplayed, setConfirmDeprovisionModalDisplayed] = useState(null);
    const [confirmUndeployModalDisplayed, setConfirmUndeployModalDisplayed] = useState(null);

    const chainedProcesses = provisionedProcessData === undefined ? [] : provisionedProcessData.map((p) => p.ChainedProcessIdentifier);

    const [deployProcess] = useDeployProcessMutation();
    const postDeployProcess = async (provisionableItemIdentifier, dataToPost) => {
        try {
            await deployProcess(provisionableItemIdentifier, dataToPost).unwrap();
            setDeployProcessModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [deleteProcess] = useDeprovisionProcessMutation();
    const deleteProvisionedProcess = async (provisionableItemIdentifier) => {
        try {
            await deleteProcess(provisionableItemIdentifier).unwrap();
            setConfirmDeprovisionModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [undeployProcess] = useUndeployProcessMutation();
    const deleteDeployedProcess = async (provisionableItemIdentifier) => {
        try {
            await undeployProcess(provisionableItemIdentifier).unwrap();
            setConfirmUndeployModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [queueProcessMutation] = useQueueProcessMutation();
    const queueProcess = async (provisionableItemIdentifier) => {
        try {
            await queueProcessMutation({id: provisionableItemIdentifier}).unwrap();
            setConfirmQueueModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const queueAPIProcess = async (provisionableItemIdentifier, verb, path, body) => {
        try {
            const bodyObj = {
                Verb: verb,
                Path: path,
                Body: body ? body : null
            };
            await queueProcessMutation({
                id: provisionableItemIdentifier,
                body: bodyObj
            }).unwrap();
            setConfirmQueueAPIModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [createManualprocessMutation] = useCreateManualProcessMutation();
    const createManualProcess = async (provisionableItemIdentifier, verb, name) => {
        try {
            await createManualprocessMutation({
                HttpVerb: verb,
                Name: name,
                ProvisionedProcessIdentifier: provisionableItemIdentifier
            }).unwrap();
            setConfirmCreateManualProcessModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const sortProcesses = (a, b) => {
        if (a.IsDeployed === b.IsDeployed) {
            if (a.Name.toUpperCase() < b.Name.toUpperCase()) {
                return -1;
            } else if (a.Name.toUpperCase() > b.Name.toUpperCase()) {
                return 1;
            } else {
                return 0;
            }
        } if (a.IsDeployed && !b.IsDeployed) {
            return -1;
        } else {
            return 1;
        }
    };

    let sortedProvisionedProcessData = [];
    if (provisionedProcessData !== null && provisionedProcessData !== undefined && Array.isArray(provisionedProcessData) && provisionedProcessData.length !== 0) {
        sortedProvisionedProcessData = [...provisionedProcessData].sort(sortProcesses);
    }

    useEffect(() => {
        let redirectToLogin = false;
        if (provisionedProcessAPIError && provisionedProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (availableProcessAPIError && availableProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        provisionedProcessAPIError,
        availableProcessAPIError
    ]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white">
            <ProvisionedProcessesTable
                error={provisionedProcessAPIError}
                fetching={provisionedProcessDataFetching}
                data={sortedProvisionedProcessData}
                deprovisionModal={setConfirmDeprovisionModalDisplayed} 
                deployModal={setDeployProcessModalDisplayed}
                undeployModal={setConfirmUndeployModalDisplayed}
                queueModal={setConfirmQueueModalDisplayed}
                queueAPIModal={setConfirmQueueAPIModalDisplayed}
                createManualProcessModal={setConfirmCreateManualProcessModalDisplayed}
                chainedProcesses={chainedProcesses}
                hasAdminPrivilege={hasAdminPrivilege}
                hasLogsAccess={hasLogsAccess}
            />
            <AvailableProcessesTable
                error={availableProcessAPIError}
                fetching={availableProcessDataFetching}
                data={availableProcessData}
            />
            {
                provisionedProcessData !== null && deployProcessModalDisplayed != null
                    ? provisionedProcessData.filter((process) => !process.IsDeployed).map((process, index) => (
                        deployProcessModalDisplayed === process.Identifier
                            ?   <ConfirmModal 
                                    key={index} 
                                    title={`Confirm Deploy of ${ process.Name }`}
                                    submitButtonTitle="Deploy"
                                    identifier={process.Identifier}
                                    confirmationMessage={`Are you sure you want to deploy ${ process.Name  }?`} 
                                    successMessage="Process deployed successfully."
                                    failureMessage="Process failed to deploy:"
                                    closeModal={() => setDeployProcessModalDisplayed(null)}
                                    confirmFunction={postDeployProcess}
                                /> 
                            : null
                      ))
                    : null
            }
            {
                provisionedProcessData !== null && confirmDeprovisionModalDisplayed !== null
                    ? provisionedProcessData.filter((process) => !process.IsDeployed).map((item, index) => (
                        confirmDeprovisionModalDisplayed === item.Identifier 
                            ? <ConfirmModal 
                                    key={index} 
                                    title={`Confirm Deprovision of ${ item.Name }`}
                                    submitButtonTitle="Deprovision"
                                    identifier={item.Identifier}
                                    confirmationMessage={`Are you sure you want to deprovision (delete) ${ item.Name  }? This cannot be undone.`} 
                                    showWarning={true}
                                    successMessage="Process deprovisioned successfully."
                                    failureMessage="Process failed to deprovision:"
                                    closeModal={() => setConfirmDeprovisionModalDisplayed(null)}
                                    confirmFunction={deleteProvisionedProcess}
                                /> 
                            : null
                      ))
                    : null
            }
            {
                provisionedProcessData !== null && confirmUndeployModalDisplayed !== null
                    ? provisionedProcessData.filter((process) => process.IsDeployed).map((item, index) => (
                        confirmUndeployModalDisplayed === item.Identifier 
                            ?   <ConfirmUndeployModal
                                    key={index} 
                                    submitButtonTitle="Undeploy"
                                    title={`Confirm Undeploy of ${ item.Name }`}
                                    identifier={item.Identifier}
                                    confirmationMessage={`Are you sure you want to undeploy ${ item.Name  }?`} 
                                    successMessage="Process undeployed successfully."
                                    failureMessage="Process failed to undeploy:"
                                    closeModal={() => setConfirmUndeployModalDisplayed(null)} 
                                    confirmFunction={deleteDeployedProcess}
                                />
                            : null
                      ))      
                    : null
            }
            {
                provisionedProcessData !== null && confirmQueueModalDisplayed !== null
                    ? provisionedProcessData.filter((process) => process.IsDeployed).map((item, index) => (
                        confirmQueueModalDisplayed === item.Identifier 
                            ? <ConfirmModal 
                                    key={index} 
                                    title={`Confirm Queue of ${ item.Name }`}
                                    submitButtonTitle="Queue Process"
                                    identifier={item.Identifier}
                                    confirmationMessage={`Are you sure you want to queue ${ item.Name  }?`}
                                    successMessage="Process queued successfully."
                                    failureMessage="Process failed to queue:"
                                    closeModal={() => setConfirmQueueModalDisplayed(null)}
                                    confirmFunction={queueProcess}
                                /> 
                            : null
                      ))
                    : null
            }
            {
                provisionedProcessData !== null && confirmQueueAPIModalDisplayed !== null
                    ? provisionedProcessData.filter((process) => process.IsDeployed).map((item, index) => (
                        confirmQueueAPIModalDisplayed === item.Identifier 
                            ? <QueueProcessModal 
                                    key={index} 
                                    title={`${ item.Name }`}
                                    submitButtonTitle="Run"
                                    identifier={item.Identifier}
                                    process={item}
                                    successMessage="Process queued successfully."
                                    failureMessage="Process failed to queue:"
                                    closeModal={() => setConfirmQueueAPIModalDisplayed(null)}
                                    queueProcessFunction={queueAPIProcess}
                                />
                            : null
                      ))
                    : null
            }
            {
                provisionedProcessData !== null && confirmCreateManualProcessModalDisplayed !== null
                    ? provisionedProcessData.map((item, index) => (
                        confirmCreateManualProcessModalDisplayed === item.Identifier 
                            ? <CreateManualProcessModal 
                                    key={index} 
                                    title={`${ item.Name }`}
                                    submitButtonTitle="Create"
                                    identifier={item.Identifier}
                                    process={item}
                                    successMessage="Manual process created successfully."
                                    failureMessage="Failed to create manual process."
                                    closeModal={() => setConfirmCreateManualProcessModalDisplayed(null)}
                                    createManualProcessFunction={createManualProcess}
                                />
                            : null
                      ))
                    : null
            }
        </div>
        </>
    );
}