import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
    faTimesCircle,
    faWarning
} from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../ErrorTile";
import "./spinner.css";

export default function ConfirmModal(props) {   

    const [errorMessage, setErrorMessage] = useState("");
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const executeConfirmationFunction = async (identifier) => {
        setSubmittingToAPI(true);
        setErrorMessage(null);
        const [success, msg] = await props.confirmFunction(identifier);
        if (!success) {
            setErrorMessage(msg);
            setSubmittingToAPI(false);
        }
    };

    return (
        <div className="opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-500 opacity-95"></div>
            <div className="fixed w-full h-full z-50 overflow-y-auto">
                <div className="container mx-auto w-1/2 h-auto text-left m-4 bg-white rounded-lg">
                    <div className="bg-purple-800 text-white text-2xl rounded-t-lg ">
                        <div className="flex">
                            <div className="flex-1 p-2">{props.title}</div>
                            <div className="flex-initial p-2"><FontAwesomeIcon className="hover:cursor-pointer" icon={faTimesCircle} onClick={props.closeModal} /></div>
                        </div>
                    </div>
                    {
                        props.showWarning === true
                            ? <div className="text-center m-4"><FontAwesomeIcon icon={faWarning} size="6x" className="text-orange-500" /></div>
                            : null
                    }
                    <div className="p-4">{props.confirmationMessage}</div>
                    { 
                        errorMessage 
                            ? <ErrorTile title={props.failureMessage} message={errorMessage} additionalClasses={["mx-4"]} />
                            : null
                    }
                    <div className="flex justify-evenly p-4">
                        <button data-test-id={`confirm-delete-button-${  props.identifier }`} onClick={() => {executeConfirmationFunction(props.identifier);}} disabled={submittingToAPI} className={`${ props.showWarning ? "bg-red-500 hover:bg-red-700" : "bg-blue-500 hover:bg-blue-700" } text-white font-bold py-2 px-4 rounded`} type="button">{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : props.submitButtonTitle}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}