import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faNetworkWired,
    faPlus,
    faSpinner,
    faTrash
} from "@fortawesome/free-solid-svg-icons";

import { 
    useGetExternalEntitiesSystemQuery,
    useGetExternalEntitiesQuery,
    useDeleteExternalEntitiesMutation
} from "../../../services/mip";

export default function ExternalEntities(props) {
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - External Entities";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: externalEntitiesSystemData,
        error: externalEntitiesSystemError,
        isFetching: externalEntitiesSystemIsFetching
    } = useGetExternalEntitiesSystemQuery(params.systemId, { skip: !isLoggedIn || !hasPrivilege});

    const { 
        data: externalEntitiesData,
        error: externalEntitiesError,
        isFetching: externalEntitiesIsFetching
    } = useGetExternalEntitiesQuery(params.systemId, { skip: !isLoggedIn || !hasPrivilege});

    const [confirmDeleteModalDisplayed, setConfirmDeleteModalDisplayed] = useState();
    const [deleteEntityMutation] = useDeleteExternalEntitiesMutation();
    const confirmDelete = async (identifier) => {
        try {
            await deleteEntityMutation(encodeURIComponent(identifier)).unwrap();
            setConfirmDeleteModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    useEffect(() => {
        if (externalEntitiesData && externalEntitiesData.status === 401) {
            window.location.href="/login";
        }
    }, [externalEntitiesData]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="external-entities" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1">
                            <FontAwesomeIcon className="mr-2" icon={faNetworkWired} />
                            <span>External Entities</span>
                            {
                                externalEntitiesSystemIsFetching
                                    ? <FontAwesomeIcon icon={faSpinner} className="spinner ml-2" />
                                    : externalEntitiesSystemData
                                        ? <span> - { externalEntitiesSystemData.Name }</span>
                                        : null
                            }
                        </div>       
                        <div className="flex-initial text-right">
                            <button data-test-id="external-entities-plus-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate(`/settings/external-entities/${ params.systemId }/entity/new`);}}><FontAwesomeIcon className="w-auto" icon={faPlus} /></button>
                        </div>
                    </div>
                    {
                        externalEntitiesSystemError || externalEntitiesError
                            ? <ErrorTile message={externalEntitiesSystemError || externalEntitiesError} />
                            : externalEntitiesSystemIsFetching || externalEntitiesIsFetching
                                ? <LoadingSpinner text={"Loading External Entities"} />
                                : !externalEntitiesData || !Array.isArray(externalEntitiesData) || externalEntitiesData.length === 0
                                    ?   <div className="text-center">No External Entities configured for this system.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "auto min-content"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            {
                                                externalEntitiesData.map((entity, index) => (
                                                    <div className="contents group" key={`entity-${ index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100">{entity.Name}</div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            <button title="Edit" id={`edit-${ entity.Identifier }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigate(`/settings/external-entities/${ params.systemId }/entity/${ entity.Identifier }`);}}><FontAwesomeIcon className="w-auto" icon={faEdit} /></button>
                                                            <button title="Delete" id={`delete-${ entity.Identifier }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteModalDisplayed(entity.Identifier)}><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                    }
                    <div className="m-4 flex justify-evenly">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/external-entities/systems")}>Back to External Entities</button>
                    </div>
                </div>
                {
                    externalEntitiesData && Array.isArray(externalEntitiesData) && confirmDeleteModalDisplayed !== undefined
                        ? externalEntitiesData.map((entity, index) => (
                            confirmDeleteModalDisplayed === entity.Identifier
                                ?   <ConfirmModal
                                        key={index}
                                        title={`Confirm Delete of ${ entity.Name  } External Entity`}
                                        submitButtonTitle="Delete"
                                        identifier={entity.Identifier}
                                        confirmationMessage={`Are you sure you want to delete ${  entity.Name  }?`} 
                                        successMessage="External Entity deleted successfully."
                                        failureMessage="External Entity failed to delete:"
                                        closeModal={setConfirmDeleteModalDisplayed}
                                        confirmFunction={confirmDelete}
                                    />
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}