import { useEffect, useLayoutEffect, useState, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import NotFound from "../../components/Common/NotFound";
import ManualProcessPlaceholderSetting from "../../components/ManualProcesses/ManualProcessPlaceholderSetting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserGear,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {
    useGetManualProcessQuery,
    useGetProvisionedProcessQuery,
    useUpdateManualProcessMutation
} from "../../services/mip";

export default function ManualProcessForm(props) {   
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Deploy") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: manualProcessData,
        error: manualProcessError,
        isFetching: manualProcessIsFetching
    } = useGetManualProcessQuery(params.manualProcessIdentifier, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: provisionedProcessData,
        error: provisionedProcessError,
        isFetching: provisionedProcessIsFetching
    } = useGetProvisionedProcessQuery(manualProcessData?.ProvisionedProcessIdentifier, { skip: !isLoggedIn || !hasPrivilege || !manualProcessData });

    const [updateManualProcessError, setUpdateManualProcessError] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);
    const [manualProcessName, setManualProcessName] = useState();
    const [httpVerb, setHttpVerb] = useState();
    const [allowBody, setAllowBody] = useState();
    
    const [updateManualProcessMutation] = useUpdateManualProcessMutation();
    const updateManualProcess = async () => {
        setSubmittingToAPI(true);
        setUpdateManualProcessError(undefined);
        let hasValidDisplayNames = true;
        for (const placeholderKey of Object.keys(placeholderState)) {
            if (placeholderState[placeholderKey].DisplayName === "") {
                hasValidDisplayNames = false;
            }
        }
        if (manualProcessName.length === 0) {
            setUpdateManualProcessError("Please provide a name.");
        } else if (!hasValidDisplayNames) {
            setUpdateManualProcessError("Please ensure all Placeholder Settings have valid DisplayName values.");
        } else {
            try {
                const bodyObj = {
                    AllowBody: allowBody,
                    ApiPath: provisionedProcessData.HttpPathPrefix,
                    HttpVerb: httpVerb,
                    Name: manualProcessName
                };
                if (Object.keys(placeholderState).length) {
                    bodyObj.PlaceholderSettings = placeholderState;
                }
                await updateManualProcessMutation({
                    identifier: params.manualProcessIdentifier,
                    body: bodyObj
                }).unwrap();
                navigate("/execute");
            } catch (err) {
                if (err.data?.message) {
                    setUpdateManualProcessError(err.data.message);
                } else if (err.data?.Message) {
                    setUpdateManualProcessError(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setUpdateManualProcessError(err.value.error.data.Message);
                } else {
                    setUpdateManualProcessError(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    const placeholderSettingReducer = (state, action) => {
        let stateCopy = {...state};
        switch (action.type) {
            case "setPlaceholderValue":
                stateCopy[action.payload.key][action.payload.property] = action.payload.value;
                break;
            case "deletePlaceholder":
                delete stateCopy[action.payload.key];
                break;
            case "setInitialPlaceholders":
                stateCopy = JSON.parse(JSON.stringify(action.payload));
                break;
            default:
                throw new Error("unknown action.type in placeholderSettingReducer");
        }
        return stateCopy;
    };

    const [placeholderState, placeholderStateDispatch] = useReducer(placeholderSettingReducer, undefined);

    
    useLayoutEffect(() => {
        if (manualProcessData) {
            if (manualProcessName === undefined) {
                setManualProcessName(manualProcessData.Name);
            }
            if (httpVerb === undefined) {
                setHttpVerb(manualProcessData.HttpVerb);
            }
            if (allowBody === undefined) {
                setAllowBody(manualProcessData.AllowBody === true);
            }
            if (placeholderState === undefined) {
                placeholderStateDispatch({type: "setInitialPlaceholders", payload: manualProcessData.PlaceholderSettings});
            }
        }
    }, [
        manualProcessData,
        manualProcessName,
        httpVerb,
        allowBody,
        placeholderState
    ]);

    const pageTitle = `Edit Manual Process${ manualProcessData?.Name ? ` ${ manualProcessData?.Name }`: "" }`;
    document.title = pageTitle;

    useEffect(() => {
        if (
                (manualProcessError && manualProcessError.status === 401) ||
                (provisionedProcessError && provisionedProcessError.status === 401)
           ) {
            window.location.href="/login";
        }
    }, [manualProcessError, provisionedProcessError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4">
            <div className="text-2xl p-1 pb-4">
                <FontAwesomeIcon icon={faUserGear} /> {pageTitle}
            </div>
            {
                updateManualProcessError && <ErrorTile message={updateManualProcessError} />
            }
            {
                manualProcessError || provisionedProcessError
                        ? <ErrorTile message={manualProcessError || provisionedProcessError} />
                        : manualProcessIsFetching || provisionedProcessIsFetching
                            ?   <LoadingSpinner text={"Loading Manual Process Data"} />
                            :   <div>
                                    <div className="my-3">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="manualProcessName">
                                            Name
                                        </label>
                                        <input type="text" name="manualProcessName" value={manualProcessName ?? ""} onChange={(e) => setManualProcessName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                    </div>
                                    <div className="my-3">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="baseProcess">
                                            Base Process
                                        </label>
                                        <input disabled={true} type="text" name="baseProcess" value={provisionedProcessData?.Name ?? ""} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                    </div>
                                    <div className="my-3">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="httpPath">
                                            Process HTTP Path
                                        </label>
                                        <input disabled={true} type="text" name="httpPath" value={provisionedProcessData?.HttpPathPrefix ?? ""} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                    </div>
                                    <div className="my-3">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="httpVerb">
                                            Verb
                                        </label>
                                        {
                                            Array.isArray(provisionedProcessData?.HttpVerbs)
                                                ?   <select id="httpVerb" name="httpVerb" value={httpVerb ?? "POST"} onChange={(e) => setHttpVerb(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                        {
                                                            provisionedProcessData.HttpVerbs.map((verb) => <option value={verb} key={verb}>{verb}</option>)
                                                        }
                                                    </select>
                                                : null
                                        }
                                    </div>
                                    <div className="my-3">
                                        <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="allowBody">
                                            <input type="checkbox" value={allowBody || false} name="allowBody" id="allowBody" checked={allowBody ? "checked" : ""} onChange={(e) => {setAllowBody(e.target.checked);}} className="mr-2 align-middle" />
                                            <span className="text-grey-800 mb-4 align-middle">Allow Body</span>
                                        </label>
                                    </div>
                                    <div className="my-3">
                                            <span className="block text-grey-800 text-sm font-bold align-middle mb-2">
                                                Placeholder Settings
                                            </span>
                                            <div>
                                                {
                                                    placeholderState && Object.keys(placeholderState).length > 0
                                                        ? Object.keys(placeholderState).map((placeholderKey) => <ManualProcessPlaceholderSetting key={placeholderKey} placeholderKey={placeholderKey} placeholderSetting={placeholderState[placeholderKey]} placeholderStateDispatch={placeholderStateDispatch} />)
                                                        : <div>No Placeholder Settings</div>
                                                }
                                            </div>
                                        </div>
                                    <div className="flex justify-evenly">
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/execute")}>Back to Manual Processes</button>
                                        <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={updateManualProcess}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update Manual Process"}</button>
                                    </div>
                                </div>
            }
        </div>
        </>
    );
}