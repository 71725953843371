import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHatWizard } from "@fortawesome/free-solid-svg-icons";


export default function AvailableResourcesTableRow(props) {
    const navigate = useNavigate();

    return (
        <>
            <div data-test-id="available-resource-name" className="p-2 border group-odd:bg-gray-100">{props.item.Name}</div>
            <div className="p-2 border group-odd:bg-gray-100">{props.item.Description}</div>
            <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                <button data-test-id={`available-provisionable-item-provision-${ props.item.Identifier }`} onClick={() => navigate(`/provisionableitem/resource/new/${  props.item.Identifier }`)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button"><FontAwesomeIcon icon={faHatWizard} /></button>
            </div>
        </>
    );
}