import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";

export default function TaskResultActionsFormCondition(props) {

    const [showTaskIdentifierChangeWarning, setShowTaskIdentifierChangeWarning] = useState(false);
    const [initialTaskIdentifierLabel, setInitialTaskIdentifierLabel] = useState();
    const {process, conditionIndex, condition, conditionsStateDispatch} = props;

    useLayoutEffect(() => {
        if (
            process &&
            condition.propertyName === "TaskIdentifier" && 
            condition.truthyValue && 
            !Object.values(process.TaskIdentifiers).includes(condition.truthyValue)
        ) {
            setShowTaskIdentifierChangeWarning(true);
            conditionsStateDispatch({type: "setConditionValue", payload: {index: conditionIndex, property: "truthyValue", value: Object.keys(process.TaskIdentifiers)[0]}});
        }
    }, [
        process,
        condition.propertyName,
        condition.truthyValue,
        conditionsStateDispatch,
        conditionIndex
    ]);

    const taskLabelByIdentifier = useMemo(() => {
        const data = {};
        if (props.process) {
            for (const taskLabel of Object.keys(props.process.TaskIdentifiers)) {
                data[props.process.TaskIdentifiers[taskLabel]] = taskLabel;
            }
        }
        return data;
    }, [
        props.process
    ]);

    useEffect(() => {
        if (condition.propertyName === "TaskIdentifier" && condition.truthyValue && initialTaskIdentifierLabel === undefined) {
            // only on the first render set this value to be used as in the warning when it changes
            setInitialTaskIdentifierLabel(taskLabelByIdentifier[condition.truthyValue]);
        }
    }, [
        condition.propertyName,
        condition.truthyValue,
        initialTaskIdentifierLabel,
        taskLabelByIdentifier
    ]);

    const handleTaskIdentifierChange = (taskIdentifier) => {
        setShowTaskIdentifierChangeWarning(false);
        conditionsStateDispatch({type: "setConditionValue", payload: {index: props.conditionIndex, property: "truthyValue", value: taskIdentifier}});
    };

    return (
        <>
        <div className="shadow border rounded mb-4">
            <div className="flex bg-gray-300 rounded-t">
                <div className="flex-1 text-xl p-2 font-bold">Condition #{parseInt(props.conditionIndex + 1)}</div>
                <div>
                    <button id={`delete-${ props.conditionIndex }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => conditionsStateDispatch({type: "deleteCondition", payload: {index: props.conditionIndex}})}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>      
            <div className={`p-3 ${ showTaskIdentifierChangeWarning && "bg-orange-200" }`}>
                <div className="my-3">
                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor={`propertyName-${ props.index }`}>
                        Task Result Property Name
                    </label>
                    <select id={`propertyName-${ props.index }`} name={`propertyName-${ props.index }`} value={props.condition.propertyName} onChange={(e) => conditionsStateDispatch({type: "setConditionValue", payload: {index: props.conditionIndex, property: "propertyName", value: e.target.value}})} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                        {
                            ["Success","HasFailedRecords","ErrorMessage","TaskIdentifier","StartTime","EndTime","Metadata"].map((allowedProperty, index) => <option value={allowedProperty} key={index}>{allowedProperty}</option>)
                        }
                    </select>
                </div>
                {
                    props.condition.propertyName === "Metadata"
                        ?   <>
                                <div className="my-3">
                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor={`metadataKey-${ props.index }`}>
                                        Metadata Key
                                    </label>
                                    {
                                        Array.isArray(props.settingsData?.TaskResultMetadataKeys)
                                            ?   <select id={`metadataKey-${ props.index }`} name={`metadataKey-${ props.index }`} value={props.condition.metadataKey} onChange={(e) => conditionsStateDispatch({type: "setConditionValue", payload: {index: props.conditionIndex, property: "metadataKey", value: e.target.value}})} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                    {
                                                        props.settingsData.TaskResultMetadataKeys.map((key) => <option value={key} key={key}>{key}</option>)
                                                    }
                                                </select>
                                            :   <div className="text-grey-800 text-sm px-2">No Metadata keys defined.</div>
                                    }
                                </div>
                            </>
                        : null
                }
                <div className="my-3">
                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor={`truthyValue-${ props.index }`}>
                        Truthy Value
                        {
                            showTaskIdentifierChangeWarning
                                ? <> - was { initialTaskIdentifierLabel }</>
                                : null
                        }
                    </label>
                    {
                        ["Success", "HasFailedRecords"].includes(props.condition.propertyName)
                            ?   <select id={`truthyValue-${ props.index }`} name={`truthyValue-${ props.index }`} value={props.condition.truthyValue} onChange={(e) => conditionsStateDispatch({type: "setConditionValue", payload: {index: props.conditionIndex, property: "truthyValue", value: e.target.value}})} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                    <option>True</option>
                                    <option>False</option>
                                </select>
                            : props.condition.propertyName === "TaskIdentifier"
                                ?   <select id={`truthyValue-${ props.index }`} name={`truthyValue-${ props.index }`} value={props.condition.truthyValue} onChange={(e) => handleTaskIdentifierChange(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                        {
                                            props.process && Object.keys(props.process.TaskIdentifiers).map((key) => <option value={props.process.TaskIdentifiers[key]} key={props.process.TaskIdentifiers[key]}>{key}</option>)
                                        }
                                    </select>
                                :   <input type="text" id={`truthyValue-${ props.index }`} name={`truthyValue-${ props.index }`} value={props.condition.truthyValue} onChange={(e) => conditionsStateDispatch({type: "setConditionValue", payload: {index: props.conditionIndex, property: "truthyValue", value: e.target.value}})} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                    }
                </div>
                <div className="my-3">
                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor={`conditionNot-${ props.index }`}>
                        <input type="checkbox" value={props.condition.not} name={`conditionNot-${ props.index }`} id={`conditionNot-${ props.index }`} checked={props.condition.not ? "checked" : ""} onChange={(e) => {conditionsStateDispatch({type: "setConditionValue", payload: {index: props.conditionIndex, property: "not", value: e.target.checked}});}} className="mr-2 align-middle" />
                        <span className="text-grey-800 mb-4 align-middle">Condition Not</span>
                    </label>
                </div>
            </div>
        </div>
        </>
    );
}