import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import { 
    useGetOrderReservationWindowsQuery,
    useDeleteOrderReservationWindowMutation
} from "../../../services/mip";

export default function OrderReservationWindows(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - Order Reservation Windows";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: orderReservationWindowsData,
        error: orderReservationWindowsError,
        isFetching: orderReservationWindowsIsFetching
    } = useGetOrderReservationWindowsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const [deleteOrderReservationWindow] = useDeleteOrderReservationWindowMutation();
    const confirmDelete = async (name) => {
        try {
            await deleteOrderReservationWindow(encodeURIComponent(name)).unwrap();
            setConfirmDeleteModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [confirmDeleteModalDisplayed, setConfirmDeleteModalDisplayed] = useState();

    useEffect(() => {
        if (orderReservationWindowsError && orderReservationWindowsError.status === 401) {
            window.location.href="/login";
        }
    }, [orderReservationWindowsError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="order-reservation-windows" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faCog} /> Order Reservation Windows</div>
                        <div className="flex-initial text-right">
                            <button data-test-id="order-reservation-window-plus-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/settings/order-reservation-windows/new");}}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>    
                    </div>
                    {
                        orderReservationWindowsError
                            ? <ErrorTile message={orderReservationWindowsError} />
                            : orderReservationWindowsIsFetching
                                ? <LoadingSpinner text={"Loading Order Reservation Windows"} />
                                : !orderReservationWindowsData || !orderReservationWindowsData.hasOwnProperty("Data") || !Array.isArray(orderReservationWindowsData.Data) || orderReservationWindowsData.Data.length === 0
                                    ?   <div className="text-center">No windows configured.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "repeat(2, 10rem) auto min-content"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Start</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">End</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Days of Week</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            {
                                                orderReservationWindowsData.Data.map((orderWindow, index) => (
                                                    <div className="contents group" key={`window-${  index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100">{orderWindow.StartTime}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{orderWindow.EndTime}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{orderWindow.DaysOfWeek}</div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            <button title="Edit" id={`edit-${ orderWindow.Identifier }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigate(`/settings/order-reservation-windows/${ orderWindow.Identifier }`);}}><FontAwesomeIcon className="w-auto" icon={faEdit} /></button>
                                                            <button title="Delete" id={`delete-${ orderWindow.Identifier }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteModalDisplayed(orderWindow.Identifier)}><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                    }
                </div>
                {
                    orderReservationWindowsData?.Data && Array.isArray(orderReservationWindowsData.Data) && confirmDeleteModalDisplayed !== undefined
                        ? orderReservationWindowsData.Data.map((orderWindow, index) => (
                            confirmDeleteModalDisplayed === orderWindow.Identifier 
                                ?   <ConfirmModal 
                                        key={index} 
                                        title={`Confirm Delete of ${ orderWindow.StartTime } - ${ orderWindow.EndTime } Window`}
                                        submitButtonTitle="Delete"
                                        identifier={orderWindow.Identifier}
                                        confirmationMessage={`Are you sure you want to delete the ${ orderWindow.StartTime } - ${ orderWindow.EndTime } Window?`} 
                                        successMessage="Window deleted successfully."
                                        failureMessage="Window failed to delete:"
                                        closeModal={setConfirmDeleteModalDisplayed}
                                        confirmFunction={confirmDelete}
                                    /> 
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}