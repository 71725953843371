import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Select, { createFilter } from "react-select";

export default function ArrayOfStrings(props) {

    const [stringToAdd, setStringToAdd] = useState(Array.isArray(props.selectOptions) ? props.selectOptions[0] : "");

    const deleteAtIndex = (index) => {
        const arrayCopy = [...props.arrayOfStrings];
        arrayCopy.splice(index, 1);
        props.setArrayOfStrings(arrayCopy);
    };

    const addString = () => {
        const arrayCopy = [...props.arrayOfStrings];
        if (!arrayCopy.includes(stringToAdd)) {
            arrayCopy.push(stringToAdd);
            props.setArrayOfStrings(arrayCopy);
            // if we are not using a select box, set the text box back to empty
            if (!Array.isArray(props.selectOptions)) {
                setStringToAdd("");
            }
        }
    };

    const selectOptions = useMemo(() => {
        const data = [];
        if (Array.isArray(props.selectOptions)) {
            for (const option of props.selectOptions) {
                data.push({value: option, label: option});
            }
        }
        return data;
    }, [
        props.selectOptions
    ]);
    
    return (
        <div className="grid" style={{gridTemplateColumns: "auto 7rem"}}>
            {
                Array.isArray(props.arrayOfStrings)
                    ? props.arrayOfStrings.map((key, index) => (
                        <div className="contents group" key={`string-${ index }`}>
                            <div className="p-2 border group-odd:bg-gray-100" key={index}>{key}</div>
                            <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                <button id={`delete-${ index }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => deleteAtIndex(index)}><FontAwesomeIcon icon={faTrash} /></button>
                            </div>
                        </div>
                      ))
                    : null
            }
            <div className="contents group" key="add">
                <div className="p-2 border">
                    {
                        Array.isArray(props.selectOptions)
                            ?   <Select filterOption={createFilter({ ignoreAccents: false })} isSearchable={true} options={selectOptions} defaultValue={selectOptions[0]} onChange={(e) => setStringToAdd(e.value)} />
                            :   <input id="stringToAdd" name="stringToAdd" value={stringToAdd} onChange={(e) => setStringToAdd(e.target.value)} className="shadow appearance-none border rounded w-full py-1 pl-3 text-grey-800" />
                    }
                </div>
                <div className="px-2 border flex justify-evenly items-start">
                    <button id="add-string" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm mt-3 mx-2" type="button" onClick={() => addString()}><FontAwesomeIcon icon={faPlus} /></button>
                </div>
            </div>
        </div>
    );
}