import { useLayoutEffect } from "react";

export default function GeneralDataSelectorStringConcatInfoField(props) {  

    useLayoutEffect(() => {
        // TODO: What if the source (other than "Constant") isn't in the available sources anymore?
        // that would be a use layout effect to set it, eh?
    }, []);

    const htmlFiledName = `${ props.htmlFieldName  }-${  props.index }`;

    return (
        <>
            <div className="flex p-1">
                <select name={`stringConcatSource-${ htmlFiledName }`} id={`stringConcatSource-${ htmlFiledName }`} value={props.concatenation.source} onChange={(e) => {props.setFieldValue(props.index, "source", e.target.value);}} className="flex-1 shadow border rounded w-1/4 py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                    <option value="Constant">Constant</option>
                    {props.sourceOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                </select>
                {
                    props.concatenation.source === "Constant"
                        ?   <input name={`stringConcatValue-${ htmlFiledName }`} id={`stringConcatValue-${  htmlFiledName }`} required="required" value={props.concatenation.value} onChange={(e) => {props.setFieldValue(props.index, "value", e.target.value);}} className="flex-1 shadow appearance-none border rounded w-1/4 py-2 pl-3 pr-6 text-grey-800 text-right" placeholder="Constant String" />
                        :   null
                }
            </div>
        </>
    );
}