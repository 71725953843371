import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function MetadataFilter(props) {

    return (
        <>
        <div className="shadow border rounded mb-4">
            <div className="flex p-2">
                <div className="flex w-5/12 pr-2">
                    {
                        Array.isArray(props.settingsData?.TaskResultMetadataKeys) && props.settingsData.TaskResultMetadataKeys.length > 1
                            ?   <select id={`metadataKey-${ props.index }`} name={`metadataKey-${ props.index }`} value={props.filter.metadataKey} onChange={(e) => props.metadataFiltersDispatch({type: "setFilterValue", payload: {index: props.filterIndex, property: "metadataKey", value: e.target.value}})} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 text-base focus:shadow-outline">
                                    {
                                        props.settingsData.TaskResultMetadataKeys.map((metadataKey, index) => <option value={metadataKey} key={index}>{metadataKey}</option>)
                                    }
                                </select>  
                            :   <div>Settings Data does not contain Task Result Metadata Keys</div>

                    }  
                </div>
                <div className="flex w-7/12">
                    <input type="text" id={`value-${ props.index }`} name={`value-${ props.index }`} value={props.filter.value} onChange={(e) => props.metadataFiltersDispatch({type: "setFilterValue", payload: {index: props.filterIndex, property: "value", value: e.target.value}})} className="shadow appearance-none border rounded flex-1 py-2 pl-3 pr-6 text-grey-800 mr-2" />
                    <button id={`delete-${ props.filterIndex }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-sm m-1" type="button" onClick={() => props.metadataFiltersDispatch({type: "deleteFilter", payload: {index: props.filterIndex}})}><FontAwesomeIcon icon={faTrash} /></button>
                </div>
            </div>      
        </div>
        </>
    );
}