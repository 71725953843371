import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import NotFound from "../../components/Common/NotFound";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
    faFile
} from "@fortawesome/free-solid-svg-icons";
import {
    useGetSettingsQuery,
    useGetProvisionedProcessesQuery,
    useSeedProcessTrackingMetadataMutation
} from "../../services/mip";

export default function SeedMetadata(props) {   
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Admin") || privilege.includes("All");

    const [metadataKey, setMetadataKey] = useState();
    const [seedingDataApiWorking, setSeedingDataApiWorking] = useState(false);
    const [seedingDataResults, setSeedingDataResults] = useState();
    const [provisionedProcessIdentifier, setProvisionedProcessIdentifier] = useState("");
    const [previouslySeededMetaKeys, setPreviouslySeededMetaKeys] = useState([]);

    const {
        data: settingsData,
        error: settingsError,
        isFetching: settingsIsFetching
    } = useGetSettingsQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const processesByProcessId = useMemo(() => {
        const processes = {};
        if (Array.isArray(provisionedProcessData)) {
            for (const process of provisionedProcessData) {
                processes[process.Identifier] = process;
            }
        }
        return processes;
    }, [provisionedProcessData]);

    const [seedMetadata] = useSeedProcessTrackingMetadataMutation();
    const handledSeedButton = async () => {
        setSeedingDataApiWorking(true);
        const previouslySeededMetaKeysCopy = [...previouslySeededMetaKeys];
        const duplicateKey = `${ metadataKey } for ${ provisionedProcessIdentifier === "" ? "All" : processesByProcessId[provisionedProcessIdentifier].Name }`;
        if (previouslySeededMetaKeysCopy.includes(duplicateKey)) {
            setSeedingDataResults({
                success: false,
                errorMessage: ["Sorry, this is a check to make sure you don't accidentaly overdo this expensive process.", `You have already seeded the following metadata keys: ${ previouslySeededMetaKeysCopy.join("; ") }`, "Hard refresh if you really need to reseed again."]
            });
        } else {
            try {
                const response = await seedMetadata({
                    metadataKey,
                    provisionedProcessIdentifier
                });
                if (response.error) {
                    setSeedingDataApiWorking(false);
                    setSeedingDataResults({
                        success: false,
                        errorMessage: response.error
                    });
                } else {
                    previouslySeededMetaKeysCopy.push(duplicateKey);
                    setPreviouslySeededMetaKeys(previouslySeededMetaKeysCopy);
                    setSeedingDataResults({
                        success: true
                    });
                }
                setSeedingDataApiWorking(false); 
            } catch (ex) {
                setSeedingDataApiWorking(false);
                setSeedingDataResults({
                    success: false,
                    errorMessage: ex.toString()
                });
            }
        }
        setSeedingDataApiWorking(false);
    };

    useEffect(() => {
        if (Array.isArray(settingsData?.TaskResultMetadataKeys) && metadataKey === undefined) {
            setMetadataKey(settingsData.TaskResultMetadataKeys[0]);
        }
    }, [settingsData, metadataKey]);

    useEffect(() => {
        if (settingsError && settingsError.status === 401) {
            window.location.href="/login";
        }
    }, [settingsError]);

    useEffect(() => {
        document.title = "Administration - Process Tracking Metadata Seeding";
    }, []);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white">
            <div className="p-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faFile} /> Process Tracking Metadata Seeding</div>
                    </div>
                    {
                        provisionedProcessAPIError || settingsError
                            ? <ErrorTile message={ provisionedProcessAPIError || settingsError } />
                            : provisionedProcessDataFetching || settingsIsFetching
                                ? <LoadingSpinner text={"Loading Prerequisite Data"} />
                                : <>
                                    <div className="border p-2">
                                        <div>
                                            <div className="my-2">
                                                <div className="mb-4">
                                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="fileType">
                                                        Metadata Key to Seed
                                                    </label>
                                                </div>
                                                <select name="metadataKey" id="metadataKey" value={metadataKey} onChange={(e) => {setMetadataKey(e.target.value);}} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                    {
                                                        Array.isArray(settingsData?.TaskResultMetadataKeys) && settingsData.TaskResultMetadataKeys.map((key) => <option key={key} value={key}>{key}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="my-2">
                                                <div className="mb-4">
                                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="fileType">
                                                        Process
                                                    </label>
                                                </div>
                                                <select id="provisionedProcessIdentifier" name="provisionedProcessIdentifier" value={provisionedProcessIdentifier} onChange={(e) => setProvisionedProcessIdentifier(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                    <option key="none" value="">All</option>
                                                    {
                                                        provisionedProcessData.map((process) => <option value={process.Identifier} key={process.Identifier}>{process.Name}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="flex justify-evenly">
                                            <button className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/processtracking/search")}>Back to Process Tracking</button>
                                                <button data-test-id="seed-process-tracking-files-button" disabled={seedingDataApiWorking} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => handledSeedButton()}>{seedingDataApiWorking? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span>Seed Metadata</span>}</button>
                                            </div>
                                        </div>
                                    </div>
                                    { 
                                        seedingDataResults?.success === false
                                            ? <ErrorTile message={seedingDataResults.errorMessage} />
                                            : seedingDataResults?.success === true
                                                ? <div className="border text-center border-green-500 rounded-lg mt-2 mb-4 p-4 bg-green-200">Metadata Seeding job created for {metadataKey}</div>
                                                : null
                                    }
                                </>
                    }
                </div>
            </div>
        </div>
        </>
    );
}