import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import AdminLeftNav from "../../../components/Admin/AdminLeftNav";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
    useGetProfileQuery,
    useGetCustomersQuery,
    useAddCustomerMutation,
    useUpdateCustomerMutation
} from "../../../services/mip";

export default function CustomersForm(props) {   
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("CreateCustomer") || privilege.includes("All");

    const newMode = params.customerIdentifier.toLowerCase() === "new";

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: profileData,
        error: profileError,
        isFetching: profileIsFetching
    } = useGetProfileQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: customersData,
        error: customersError,
        isFetching: customersIsFetching
    } = useGetCustomersQuery(null, { skip: !isLoggedIn || !hasPrivilege || newMode });

    const [addCustomerError, setAddCustomerError] = useState();
    const [customerName, setCustomerName] = useState(newMode ? "" : undefined);
    const [customerGroup, setCustomerGroup] = useState(undefined);
    const [customerGroups, setCustomerGroups] = useState(undefined);
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const [addCustomer] = useAddCustomerMutation();
    const postCustomer = async () => {
        setSubmittingToAPI(true);
        setAddCustomerError(undefined);
        if (customerName.length === 0) {
            setAddCustomerError("Please provide a name.");
        } else {
            const objectToPost = {
                Name: customerName,
                Group: customerGroup
            };
            try {
                await addCustomer(objectToPost).unwrap();
                navigate("/admin/customers");
            } catch (err) {
                if (err.data?.message) {
                    setAddCustomerError(err.data.message);
                } else if (err.data?.Message) {
                    setAddCustomerError(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setAddCustomerError(err.value.error.data.Message);
                } else {
                    setAddCustomerError(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    const [updateCustomerMutation] = useUpdateCustomerMutation();
    const updateCustomer = async () => {
        setSubmittingToAPI(true);
        setAddCustomerError(undefined);
        if (customerName.length === 0) {
            setAddCustomerError("Please provide a name.");
        } else {
            try {
                await updateCustomerMutation({identifier: params.customerIdentifier, body: {Name: customerName}}).unwrap();
                navigate("/admin/customers");
            } catch (err) {
                if (err.data?.message) {
                    setAddCustomerError(err.data.message);
                } else if (err.data?.Message) {
                    setAddCustomerError(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setAddCustomerError(err.value.error.data.Message);
                } else {
                    setAddCustomerError(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    useLayoutEffect(() => {
        if (profileData && Array.isArray(profileData?.Data?.CustomerGroups)) {
            setCustomerGroups(profileData.Data.CustomerGroups);
            if (newMode && customerGroup === undefined) {
                setCustomerGroup(profileData.Data.CustomerGroups[0].Value);
            }
        }
    }, [profileData, customerGroup, newMode]);

    useLayoutEffect(() => {
        if (!newMode && profileData && Array.isArray(profileData?.Data?.CustomerGroups) && customersData && Array.isArray(customersData?.Data)) {
            let foundCustomer;
            for (const customer of customersData.Data) {
                if (customer.Identifier === params.customerIdentifier) {
                    foundCustomer = customer;
                    break;
                }
            }

            if (customerName === undefined) {
                setCustomerName(foundCustomer.Name.replace("Test: ", ""));
            }

            if (customerGroup === undefined) {
                let customerGroupFound;
                for (const customerGroup of profileData.Data.CustomerGroups) {
                    if (foundCustomer.Group === customerGroup.Value) {
                        customerGroupFound = true;
                    }
                }
                setCustomerGroup(customerGroupFound ? foundCustomer.Group : profileData.Data.CustomerGroups[0].Value);
            }
        }
    }, [profileData, customerGroup, customersData, newMode, customerName, params.customerIdentifier]);

    let originalCustomerName;
    if (Array.isArray(customersData?.Data)) {
        for (const customer of customersData.Data) {
            if (customer.Identifier === params.customerIdentifier) {
                originalCustomerName = customer.Name.replace("Test: ", "");
            }
        }
    }

    const pageTitle = newMode
    ? "Create Customer"
    : `Edit Customer ${ originalCustomerName }`;

    useEffect(() => {
        document.title = `Administration - ${ pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (
                (profileError && profileError.status === 401) ||
                (customersError && customersError.status === 401)
           ) {
            window.location.href="/login";
        }
    }, [profileError, customersError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <AdminLeftNav selected="customers" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl p-1 pb-4">
                        <FontAwesomeIcon icon={faIdCard} /> {pageTitle}
                    </div>
                    {
                        addCustomerError && <ErrorTile message={addCustomerError} />
                    }
                    {
                        profileError || customersError
                                ? <ErrorTile message={profileError || customersError} />
                                : profileIsFetching || customersIsFetching
                                    ?   <LoadingSpinner text={"Loading Customer Data"} />
                                    :   <div>
                                            <div className="my-3">
                                                <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="customerName">
                                                    Name
                                                </label>
                                                <input type="text" name="customerName" value={customerName ?? ""} onChange={(e) => setCustomerName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                            </div>
                                            <div className="my-3">
                                                <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="customerGroup">
                                                    Group
                                                </label>
                                                {
                                                    customerGroups !== undefined
                                                        ? newMode && customerGroups.length > 1
                                                            ?   <select name="customerGroup" value={customerGroup} onChange={(e) => setCustomerGroup(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                                    {
                                                                        customerGroups.map((group) => <option value={group.Value} key={group.Value}>{group.Key}</option>)
                                                                    }
                                                                </select>
                                                            :   <div>{customerGroup}</div>
                                                        : <div>No Customer Groups Defined</div>
                                                }
                                            </div>
                                            <div className="flex justify-evenly">
                                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/admin/customers")}>Back to Customers</button>
                                                {
                                                    newMode
                                                        ? <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postCustomer}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add Customer"}</button>
                                                        : <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={updateCustomer}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update Customer"}</button>
                                                }
                                                
                                            </div>
                                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    );
}