import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import "./api-map-custom-grid.css";

import { 
    useGetProvisionedProcessesQuery,
    useGetSettingsQuery
} from "../../../services/mip";

export default function ApiMap(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - API Map";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: settingsData,
        error: settingsError,
        isFetching: settingsIsFetching
    } = useGetSettingsQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const processNames = useMemo(() => {
        const data = {};
        if (provisionedProcessData) {
            for (const process of provisionedProcessData) {
                data[process.Identifier] = process.Name;
            }
        }
        return data;
    }, [provisionedProcessData]);


    const apisToDisplay = useMemo(() => {
        const data = [];
        if (settingsData && settingsData.hasOwnProperty("ApiMap")) {
            for (const verb of Object.keys(settingsData.ApiMap)) {
                for (const endpoint of Object.keys(settingsData.ApiMap[verb])) {
                    const processId = settingsData.ApiMap[verb][endpoint];
                    const processName = processNames.hasOwnProperty(processId) ? processNames[processId] : "Unknown";
                    data.push({
                      verb,
                      endpoint,
                      processName
                    });
                }
            }
        }
        return data;
    }, [
        settingsData,
        processNames
    ]);

    const [sortedProperty, setSortedProperty] = useState({property: "verb", direction: 1});

    const sortedApisToDisplay = useMemo(() => {
        const data = [...apisToDisplay];
        data.sort((a, b) => {
            if (a[sortedProperty.property] < b[sortedProperty.property]) {
              return -1 * sortedProperty.direction;
            }
            if (a[sortedProperty.property] > b[sortedProperty.property]) {
              return 1 * sortedProperty.direction;
            }
            return 0;
        });
        return data;
    }, [
        apisToDisplay,
        sortedProperty
    ]);
    
    const toggleSort = (property) => {
        if (property !== sortedProperty.property) {
            setSortedProperty({property, direction: 1});
        } else {
            setSortedProperty({property, direction: sortedProperty.direction * -1});
        }
    };

    useEffect(() => {
        let redirectToLogin = false;
        if (settingsIsFetching && settingsIsFetching.status === 401) {
            redirectToLogin = true;
        }
        if (provisionedProcessAPIError && provisionedProcessAPIError.status === 401) {
            redirectToLogin = true;
        }
        if (redirectToLogin) {
            window.location.href="/login";
        }
    }, [
        settingsIsFetching,
        provisionedProcessAPIError
    ]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="api-map" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faCog} /> API Map</div>
                    </div>
                    {
                        provisionedProcessAPIError || settingsError
                            ? <ErrorTile message={provisionedProcessAPIError || settingsError} />
                            : provisionedProcessDataFetching || settingsIsFetching
                                ? <LoadingSpinner text={"Loading Settings"} />
                                : !settingsData || !settingsData.hasOwnProperty("ApiMap") || sortedApisToDisplay.length === 0
                                    ?   <div className="text-center">No API Maps.</div>
                                    :   <div className="grid api-map-custom-grid">
                                            <div className="border text-center font-bold px-2 border hover:cursor-pointer" onClick={() => toggleSort("verb")}>HTTP Verb {sortedProperty.property === "verb" ? sortedProperty.direction === 1 ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon className="text-gray-300" icon={faSort} />}</div>
                                            <div className="border text-center font-bold px-2 hover:cursor-pointer" onClick={() => toggleSort("endpoint")}>Path {sortedProperty.property === "endpoint" ? sortedProperty.direction === 1 ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon className="text-gray-300" icon={faSort} />}</div>
                                            <div className="border text-center font-bold px-2 hover:cursor-pointer" onClick={() => toggleSort("processName")}>Process Executed {sortedProperty.property === "processName" ? sortedProperty.direction === 1 ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon className="text-gray-300" icon={faSort} />}</div>
                                            {sortedApisToDisplay.map((api) => (
                                                <div className="contents api-map-custom-grid-row" key={api.verb + api.endpoint}>
                                                    <div className="border p-2">{api.verb}</div>
                                                    <div className="border p-2">{api.endpoint}</div>
                                                    <div className="border p-2">{api.processName}</div>
                                                </div>
                                            ))}
                                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    );

}