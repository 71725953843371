import { useReducer, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
    faTimesCircle,
    faFileUpload
} from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../ErrorTile";
import {
    midgardFetch
} from "../../../services/mip";

export default function UploadFilesModal(props) {   

    const [errorMessage, setErrorMessage] = useState("");
    const [submittingToAPI, setSubmittingToAPI] = useState(false);
    const [files, setFiles] = useState([]);
    const [refreshOnClose, setRefreshOnClose] = useState(false);
    
    const fileUploadStatusReducer = (state, action) => {
        const stateCopy = {...state};
        switch (action.type) {
            case "setStatus":
                stateCopy[action.filename] = action.status;
                break;
            case "resetStatus":
                Object.keys(stateCopy).forEach((key) => {
                    delete stateCopy[key];
                });
                break;
            default:
                throw new Error("unknown action.type in fileUploadStatusReducer");
        }
        return stateCopy;
    };
    
    const [fileUploadStatus, fileUploadStatusDispatch] = useReducer(fileUploadStatusReducer, {});

    const fileInputRef = useRef(null);

    const handleUpload = async () => {
        setSubmittingToAPI(true);
        setErrorMessage(null);
        if (files.length === 0) {
            setErrorMessage("Please select files to upload.");
        } else {
            for (const fileInput of files) {
                fileUploadStatusDispatch({type: "setStatus", filename: fileInput.name, status: "Uploading..."});
                try {
                    const encodedFullPath = encodeURIComponent(`${ props.prefix }${ fileInput.name }`);
                    await midgardFetch(`files/${ encodedFullPath }`, "PUT", {"Content-Type": fileInput.type}, fileInput).then((response) => {
                        if (!response.ok) {
                            return response.json().then((err) => {
                                throw new Error(err.Message);
                            }).catch(() => {
                                throw new Error(`${ response.status } ${ response.statusText }`);
                            });
                        }
                        return response.blob();
                    });
                    setRefreshOnClose(true);
                    fileUploadStatusDispatch({type: "setStatus", filename: fileInput.name, status: "Successfully Uploaded"});
                } catch (err) {
                    fileUploadStatusDispatch({type: "setStatus", filename: fileInput.name, status: err.toString()});
                }
            }
            setSubmittingToAPI(false);
        }
    };
    
    const handleAddFiles = (event) => {
        const filesToAdd = Array.from(event.target.files);
        const filesCopy = [...files];
        const fileNamesAlreadyAdded = filesCopy.map((file) => (file.name));
        for (const fileToAdd of filesToAdd) {
            if (!fileNamesAlreadyAdded.includes(fileToAdd.name)) {
                filesCopy.push(fileToAdd);
            }
        }
        setFiles(filesCopy);
        fileInputRef.current.value = "";
    };

    const chooseFiles = () => {
        fileInputRef.current.click();
    };

    const clearChosenFiles = () => {
        setFiles([]);
        fileUploadStatusDispatch({type: "resetStatus"});
        fileInputRef.current.value = "";
    };

    return (
        <div className="opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-500 opacity-95"></div>
            <div className="fixed w-full h-full z-50 overflow-y-auto">
                <div className="container mx-auto w-3/4 h-auto text-left m-4 bg-white rounded-lg">
                    <div className="bg-purple-800 text-white text-2xl rounded-t-lg ">
                        <div className="flex">
                            <div className="flex-1 p-2">Upload Files</div>
                            <div className="flex-initial p-2"><FontAwesomeIcon className="hover:cursor-pointer" icon={faTimesCircle} onClick={() => props.closeModal(refreshOnClose)} /></div>
                        </div>
                    </div>
                    { 
                        errorMessage 
                            ? <ErrorTile title="Failed to upload:" message={errorMessage} additionalClasses={["mx-4"]} />
                            : null
                    }
                    <div className="p-2">
                    {
                        files.length === 0
                            ?   <div>Select files below</div>
                            :   <div className="grid" style={{gridTemplateColumns: "auto auto"}}>
                                    <div className="p-2 border text-center font-bold">Filename</div>
                                    <div className="p-2 border text-center font-bold">Status</div>
                                    {
                                        files.map((file, index) => (
                                            <div className="contents group" key={`string-${ index }`}>
                                                <div className="p-2 border group-odd:bg-gray-100">{file.name}</div>
                                                <div className="p-2 border group-odd:bg-gray-100">{Object.keys(fileUploadStatus).includes(file.name) ? fileUploadStatus[file.name] : "Pending"}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                    }
                    </div>
                    <div className="flex justify-evenly p-4">
                        {
                            files.length > 0
                                ?   <>
                                        <button data-test-id="add-files-button" onClick={chooseFiles} disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">Add Files</button>
                                        <button data-test-id="clear-files-button" onClick={clearChosenFiles} disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Reset"}</button>
                                        <button data-test-id="upload-files-button" onClick={handleUpload} disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <><FontAwesomeIcon icon={faFileUpload} /> Upload</>}</button>
                                    </>
                                :   <>
                                        <button data-test-id="choose-files-button" onClick={chooseFiles} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">Choose Files</button>
                                    </>
                        }
                    </div>
                    <input type="file" multiple onChange={handleAddFiles} ref={fileInputRef} className="hidden" />
                </div>
            </div>
        </div>
    );
}