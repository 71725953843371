import { useState } from "react";
import { useSelector } from "react-redux";
import {
    selectPrivilege
} from "../../../features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../../Common/ErrorTile";
import "./spinner.css";

/** 
 * this is a copy of the generic confirm modal with an additional "Kill" checkbox if you are an admin.
 * */

export default function ConfirmUndeployModal(props) {   

    const [errorMessage, setErrorMessage] = useState("");
    const [submittingToAPI, setSubmittingToAPI] = useState(false);
    const [killRunningTasks, setKillRunningTasks] = useState(false);

    const privilege = useSelector(selectPrivilege);

    const executeConfirmationFunction = async (identifier) => {
        setSubmittingToAPI(true);
        setErrorMessage(null);
        const [success, msg] = await props.confirmFunction({identifier, killRunningTasks});
        if (!success) {
            setErrorMessage(msg);
            setSubmittingToAPI(false);
        }
    };

    return (
        <div className="opacity-100 fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-500 opacity-95"></div>
            <div className="fixed w-full h-full z-50 overflow-y-auto">
                <div className="container mx-auto w-1/3 h-auto text-left m-4 bg-white rounded-lg">
                    <div className="bg-purple-800 text-white text-2xl rounded-t-lg ">
                        <div className="flex">
                            <div className="flex-1 p-2">{props.title}</div>
                            <div className="flex-initial p-2"><FontAwesomeIcon className="hover:cursor-pointer" icon={faTimesCircle} onClick={props.closeModal} /></div>
                        </div>
                    </div>
                    <div className="p-4">{props.confirmationMessage}</div>
                    { 
                        errorMessage 
                            ? <ErrorTile title={props.failureMessage} message={errorMessage} additionalClasses={["mx-4"]} />
                            : null
                    }
                    {
                        privilege.includes("Admin") || privilege.includes("All")
                            ?   <div className="text-center">
                                    <label className="align-middle" htmlFor="killRunningTasks">
                                        <input type="checkbox" value={killRunningTasks} name="killRunningTasks" id="killRunningTasks" checked={killRunningTasks ? "checked" : ""} onChange={(e) => {setKillRunningTasks(e.target.checked);}} className="mr-2 align-middle" />
                                        <span className="text-grey-800 align-middle">Kill running tasks</span>
                                    </label>
                                </div>
                            : null
                    }
                    <div className="flex justify-evenly p-4">
                        <button data-test-id={`confirm-delete-button-${  props.identifier }`} onClick={() => {executeConfirmationFunction(props.identifier);}} disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button">{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : props.submitButtonTitle}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}