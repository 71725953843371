import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import { 
    useGetProvisionedProcessesQuery,
    useGetEmailDistributionListsQuery,
    useGetTaskResultActionsQuery,
    useDeleteTaskResultActionMutation
} from "../../../services/mip";

export default function TaskResultActions(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - Task Result Actions";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: taskResultActionsData,
        error: taskResultActionsError,
        isFetching: taskResultActionsIsFetching
    } = useGetTaskResultActionsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const { 
        data: emailDistributionListsData,
        error: emailDistributionListsError,
        isFetching: emailDistributionListsIsFetching
    } = useGetEmailDistributionListsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const {
        data: provisionedProcessData,
        error: provisionedProcessAPIError,
        isFetching: provisionedProcessDataFetching,
    } = useGetProvisionedProcessesQuery(undefined, { skip: !isLoggedIn || !hasPrivilege });

    const emailDistributionListsByIdentifier = {};
    if (Array.isArray(emailDistributionListsData?.Data)) {
        for (const distributionList of emailDistributionListsData.Data) {
            emailDistributionListsByIdentifier[distributionList.Identifier] = distributionList;
        }
    }

    const taskNameNamesByIdentifier = useMemo(() => {
        const nameToIdMap = {};
        if (Array.isArray(provisionedProcessData)) {
            for (const process of provisionedProcessData) {
                for (const taskName of Object.keys(process.TaskIdentifiers)) {
                    nameToIdMap[process.TaskIdentifiers[taskName]] = taskName;
                }
            }
        }
        return nameToIdMap;
    }, [provisionedProcessData]);

    const processNamesByProcessIdentifier = useMemo(() => {
        const nameToIdMap = {};
        if (Array.isArray(provisionedProcessData)) {
            for (const process of provisionedProcessData) {
                nameToIdMap[process.Identifier] = process.Name;
            }
        }
        return nameToIdMap;
    }, [provisionedProcessData]);

    const sortedProcesses = useMemo(() => {
        const data = [];
        if (Array.isArray(taskResultActionsData?.Data)) {
            const processesAlreadyAdded = [];
            for (const taskResultAction of taskResultActionsData?.Data) {
                if (!processesAlreadyAdded.includes(taskResultAction.ProvisionedProcessIdentifier)) {
                    processesAlreadyAdded.push(taskResultAction.ProvisionedProcessIdentifier);
                    data.push({
                        processIdentifier: taskResultAction.ProvisionedProcessIdentifier,
                        processName: processNamesByProcessIdentifier[taskResultAction.ProvisionedProcessIdentifier]
                    });
                }
            }
        }
        data.sort((a,b) => (a.processName > b.processName ? 1 : ((b.processName > a.processName) ? -1 : 0)));
        return data;
    }, [
        taskResultActionsData,
        processNamesByProcessIdentifier
    ]);
    
    const taskResultActionsByProcessIdentifier = useMemo(() => {
        const data = {};
        if (Array.isArray(taskResultActionsData?.Data)) {
            for (const taskResultAction of taskResultActionsData?.Data) {
                if (!Object.keys(data).includes(taskResultAction.ProvisionedProcessIdentifier)) {
                    data[taskResultAction.ProvisionedProcessIdentifier] = [];
                }
                const traCopy = {...taskResultAction};
                let sortKey = taskResultAction.$type;
                if (Array.isArray(traCopy.Conditions)) {
                    for (const condition of traCopy.Conditions) {
                        sortKey += condition.PropertyName === "Metadata" ? condition.MetadataKey : condition.PropertyName;
                        sortKey += condition.TruthyValue;
                    }
                }
                traCopy.sortKey = sortKey;
                data[taskResultAction.ProvisionedProcessIdentifier].push(traCopy);
            }
        }
        for (const processIdentifier of Object.keys(data)) {
            data[processIdentifier].sort((a,b) => (a.sortKey > b.sortKey ? 1 : ((b.sortKey > a.sortKey) ? -1 : 0)));
        }
        return data;
    }, [
        taskResultActionsData
    ]);

    const [deleteTaskResultAction] = useDeleteTaskResultActionMutation();
    const confirmDelete = async (name) => {
        try {
            await deleteTaskResultAction(encodeURIComponent(name)).unwrap();
            setConfirmDeleteModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [confirmDeleteModalDisplayed, setConfirmDeleteModalDisplayed] = useState();

    useEffect(() => {
        if (
            (taskResultActionsError && taskResultActionsError.status === 401) ||
            (emailDistributionListsError && emailDistributionListsError.status === 401) ||
            (provisionedProcessAPIError && provisionedProcessAPIError.status === 401)
        ) {
            window.location.href="/login";
        }
    }, [taskResultActionsError, emailDistributionListsError, provisionedProcessAPIError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="task-result-actions" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faCog} /> Task Result Actions</div>
                        <div className="flex-initial text-right">
                            <button data-test-id="task-result-actions-plus-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/settings/task-result-actions/new");}}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>    
                    </div>
                    {
                        taskResultActionsError || emailDistributionListsError || provisionedProcessAPIError
                            ? <ErrorTile message={taskResultActionsError || emailDistributionListsError || provisionedProcessAPIError} />
                            : taskResultActionsIsFetching || emailDistributionListsIsFetching || provisionedProcessDataFetching
                                ? <LoadingSpinner text={"Loading Task Result Actions"} />
                                : !taskResultActionsData || !taskResultActionsData.hasOwnProperty("Data") || !Array.isArray(taskResultActionsData.Data) || taskResultActionsData.Data.length === 0
                                    ?   <div className="text-center">No Task Result Actions configured.</div>
                                    :   sortedProcesses.map((processInfo, index) => (
                                            <div className="mb-4" key={processInfo.processIdentifier}>
                                                <div className="bg-gray-200 text-lg font-bold px-2 border">{processInfo.processName}</div>
                                                <div className="grid" style={{gridTemplateColumns: "repeat(3, auto) min-content"}}>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Type</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Conditions</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Info</div>
                                                    <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                                    {
                                                        taskResultActionsByProcessIdentifier[processInfo.processIdentifier].map((taskResultAction, index) => (
                                                            <div className="contents group" key={`task-result-actions-${ index }`}>
                                                                <div className="p-2 border group-odd:bg-gray-100">
                                                                    { taskResultAction.$type === "MIP.Provisioning.TaskResultActions.EmailTaskResultAction, Provisioning" && "Send Email" }
                                                                    { taskResultAction.$type === "MIP.Provisioning.TaskResultActions.CopyFileTaskResultAction, Provisioning" && "Copy File" }
                                                                    { taskResultAction.$type === "MIP.Provisioning.TaskResultActions.QueueProcessTaskResultAction, Provisioning" && "Queue Process" }
                                                                </div>
                                                                <div className="p-2 border group-odd:bg-gray-100">
                                                                    <ul>
                                                                    {
                                                                        !Array.isArray(taskResultAction.Conditions) || taskResultAction.Conditions.length === 0
                                                                            ?   <li>None</li>
                                                                            :   taskResultAction.Conditions.map((condition, index) => (
                                                                                    <li key={index}>
                                                                                        {
                                                                                            condition.PropertyName === "Metadata" 
                                                                                                ? <>{condition.MetadataKey}</>
                                                                                                : <>{condition.PropertyName}</>
                                                                                        }
                                                                                        {
                                                                                            condition.Not 
                                                                                                ? <> is not </>
                                                                                                : <> is </>
                                                                                        }
                                                                                        {
                                                                                            condition.PropertyName === "TaskIdentifier"
                                                                                            ? <>{taskNameNamesByIdentifier[condition.TruthyValue]}</>
                                                                                            : <>{condition.TruthyValue}</>
                                                                                        }
                                                                                    </li>
                                                                                ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="p-2 border group-odd:bg-gray-100">
                                                                    {
                                                                        taskResultAction.$type === "MIP.Provisioning.TaskResultActions.EmailTaskResultAction, Provisioning"
                                                                            ?   <ul>
                                                                                    <li>Subject: {taskResultAction.Subject}</li>
                                                                                    <li>Distribution List:  {emailDistributionListsByIdentifier[taskResultAction.EmailDistributionListIdentifier]?.Name ?? "Unknown"}</li>
                                                                                </ul>
                                                                            :   null
                                                                    }
                                                                    {
                                                                        taskResultAction.$type === "MIP.Provisioning.TaskResultActions.CopyFileTaskResultAction, Provisioning"
                                                                            ?   <ul>
                                                                                    <li>Process Filename Key: {taskResultAction.ProcessFilenameKey}</li>
                                                                                    <li>Destination: {taskResultAction.DestinationObjectKey}</li>
                                                                                </ul>
                                                                            :   null
                                                                    }
                                                                    {
                                                                        taskResultAction.$type === "MIP.Provisioning.TaskResultActions.QueueProcessTaskResultAction, Provisioning"
                                                                            ?   <ul>
                                                                                    <li>Queue: {processNamesByProcessIdentifier[taskResultAction.ProvisionedProcessIdentifierToQueue]}</li>
                                                                                </ul>
                                                                            :   null
                                                                    }
                                                                </div>
                                                                <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                                    <button title="Edit" id={`edit-${  taskResultAction.Identifier }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigate(`/settings/task-result-actions/${ taskResultAction.Identifier }`);}}><FontAwesomeIcon icon={faEdit} /></button>
                                                                    <button title="Delete" id={`delete-${  taskResultAction.Identifier }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteModalDisplayed(taskResultAction.Identifier)}><FontAwesomeIcon icon={faTrash} /></button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                    ))          
                    }
                </div>
                {
                    taskResultActionsData?.Data && Array.isArray(taskResultActionsData.Data) && confirmDeleteModalDisplayed !== undefined
                        ? taskResultActionsData.Data.map((taskResultAction, index) => (
                            confirmDeleteModalDisplayed === taskResultAction.Identifier 
                                ?   <ConfirmModal 
                                        key={index} 
                                        title={`Confirm Delete of ${ taskResultAction.Identifier }`}
                                        submitButtonTitle="Delete"
                                        identifier={taskResultAction.Identifier}
                                        confirmationMessage="Are you sure you want to delete this Task Result Action?"
                                        successMessage="Task Result Action deleted successfully."
                                        failureMessage="Task Result Action failed to delete:"
                                        closeModal={setConfirmDeleteModalDisplayed}
                                        confirmFunction={confirmDelete}
                                    /> 
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}