import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { 
    selectIsLoggedIn
} from "../../../features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faKey
} from "@fortawesome/free-solid-svg-icons";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ProfileLeftNav from "../../../components/Profile/ProfileLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";

export default function ChangePassword(props) {   
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const [amplifyChangePasswordErrorMessage, setAmplifyChangePasswordErrorMessage] = useState(null);
    const [amplifyChangePasswordSuccess, setAmplifyChangePasswordSuccess] = useState(false);
    
    const handleChangePasswordFormSubmit = async (values) => {
        setAmplifyChangePasswordSuccess(null);
        setAmplifyChangePasswordSuccess(false);
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(cognitoUser, values.oldPassword, values.passwordConfirmation);
            setAmplifyChangePasswordSuccess(true);
        } catch (err) {
            setAmplifyChangePasswordErrorMessage(err.message);
        }
    };

    useEffect(() => {
        document.title = "Profile - Change Password";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <ProfileLeftNav selected="change-password" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl p-1 pb-4"><FontAwesomeIcon icon={faKey} /> Change Password</div>
                    <Formik
                        initialValues={{
                            oldPassword: "",
                            password: "",
                            passwordConfirmation: ""
                        }}
                        onSubmit={handleChangePasswordFormSubmit}
                        validationSchema={Yup.object({
                            oldPassword: Yup.string().required("Required"),
                            password: Yup.string()
                                .required("Required")
                                .min(8, "Must be at least 8 characters")
                                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/, "Must contain at least one of each: lower case letter, upper case letter, number, special character."),
                            passwordConfirmation: Yup.string().required("Required").oneOf([Yup.ref("password"), null], "Passwords must match")
                        })}
                    >
                        <Form>
                            <div className="my-3">
                                <label htmlFor="oldPassword" className="block text-grey-800 text-sm font-bold mb-2">Current Password</label>
                                <Field name="oldPassword" type="password" placeholder="αααααααα" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                                <ErrorMessage name="oldPassword">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                            </div>
                            <div className="my-3">
                                <label htmlFor="password" className="block text-grey-800 text-sm font-bold mb-2">New Password</label>
                                <Field name="password" type="password" placeholder="αααααααα" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                                <ErrorMessage name="password">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                            </div>
                            <div className="my-3">
                                <label htmlFor="passwordConfirmation" className="block text-grey-800 text-sm font-bold mb-2">Confirm New Password</label>
                                <Field name="passwordConfirmation" type="password" placeholder="αααααααα" className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-800 mb-3" />
                                <ErrorMessage name="passwordConfirmation">{(msg) => <div className="text-red-600 text-sm font-bold ml-4">{msg}</div>}</ErrorMessage>
                            </div>
                            {amplifyChangePasswordErrorMessage ? <div className="text-red-500 my-3">{amplifyChangePasswordErrorMessage}</div> : null }
                            {amplifyChangePasswordSuccess ? <div className="text-green-400 my-3">Password changed.</div> : null}
                            <div className="flex justify-evenly">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit" >Change Password</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
        </>
    );
}