import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../../Common/ErrorTile";
import LoadingSpinner from "../../Common/LoadingSpinner";
import ProvsionedResourcesTableRow from "../ProvisionedResourcesTableRow";

export default function AvailableResourcesTable(props) {
    
    const dataToDisplay = Array.isArray(props.data) ? props.data : [];
    
    return (
        <div className="mx-auto bg-white rounded-lg p-4 mb-4">
        {
            props.error
                ? <ErrorTile message={props.error} />
                : props.fetching
                    ?   <LoadingSpinner text="Loading Provisioned Resources" />
                    :   <>
                            <div className="text-2xl pb-4"><FontAwesomeIcon icon={faServer} /> Provisioned Resources</div>
                            {
                                dataToDisplay.length === 0
                                    ?   <div className="text-center">No provisioned resources.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "minmax(20rem, auto) min-content"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            <div data-test-id="provisioned-resources" className="contents">
                                            {
                                                dataToDisplay.map((item, index) => (
                                                    <div data-test-id="provisioned-resource" className="contents group" key={`provisioned-process-${  index }`}>
                                                        <ProvsionedResourcesTableRow
                                                            key={index}
                                                            item={item}
                                                            deprovisionModal={props.deprovisionModal}
                                                            isAdmin={props.isAdmin}
                                                        />
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                            }
                        </>
        }
        </div>
    );
}
