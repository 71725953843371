import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import ErrorTile from "../../Common/ErrorTile";
import LoadingSpinner from "../../Common/LoadingSpinner";
import AvailableResourcesTableRow from "../AvailableResourcesTableRow";

export default function AvailableResourcesTable(props) {
    const dataToDisplay = Array.isArray(props.data) ? props.data : [];
    return (
        <div className="mx-auto bg-white rounded-lg p-4 mb-4">
        {
            props.error
                ? <ErrorTile message={props.error} />
                : props.fetching
                    ?   <LoadingSpinner text="Loading Available Resources" />
                    :   <>
                            <div className="text-2xl pb-4"><FontAwesomeIcon icon={faServer} /> Available Resources</div>
                            {
                                dataToDisplay.length === 0
                                    ?   <div className="border text-center">No resources available.</div>
                                    :   <div className="grid" style={{gridTemplateColumns: "repeat(2, minmax(14rem, auto)) 7rem"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Resource</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Description</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Provision</div>
                                            <div data-test-id="available-resources" className="contents">
                                            {
                                                dataToDisplay.map((item, index) => (
                                                    <div data-test-id="available-resource" className="contents group" key={`available-resource-${  index }`}>
                                                        <AvailableResourcesTableRow key={index} item={item} />
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                            }
                        </>
        }
        </div>
    );
}