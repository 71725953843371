import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCog, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import { 
    useGetDataMapsQuery,
    useDeleteDataMapMutation
} from "../../../services/mip";

export default function DataMaps(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - Data Maps";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: dataMapsData,
        error: dataMapsError,
        isFetching: dataMapsIsFetching
    } = useGetDataMapsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const [deleteDataMap] = useDeleteDataMapMutation();
    const confirmDelete = async (name) => {
        try {
            await deleteDataMap(encodeURIComponent(name)).unwrap();
            setConfirmDeleteModalDisplayed(null);
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [confirmDeleteModalDisplayed, setConfirmDeleteModalDisplayed] = useState();

    useEffect(() => {
        if (dataMapsError && dataMapsError.status === 401) {
            window.location.href="/login";
        }
    }, [dataMapsError]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="data-maps" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faCog} /> Data Maps</div>
                        <div className="flex-initial text-right">
                            <button data-test-id="data-map-plus-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm" type="button" onClick={() => {navigate("/settings/data-maps/new");}}><FontAwesomeIcon icon={faPlus} /></button>
                        </div>    
                    </div>
                    {
                        dataMapsError
                            ? <ErrorTile message={dataMapsError} />
                            : dataMapsIsFetching
                                ? <LoadingSpinner text={"Loading Data Maps"} />
                                : !dataMapsData || !dataMapsData.hasOwnProperty("Data") || !Array.isArray(dataMapsData.Data) || dataMapsData.Data.length === 0
                                    ?   <div className="text-center">No data maps configured.</div>
                                    :   <div data-test-id="data-maps" className="grid" style={{gridTemplateColumns: "auto repeat(2, 10rem) min-content"}}>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Name</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Value Type</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Info</div>
                                            <div className="text-center font-bold px-2 border whitespace-nowrap">Actions</div>
                                            {
                                                dataMapsData.Data.map((map, index) => (
                                                    <div className="contents group" key={`map-${  index }`}>
                                                        <div className="p-2 border group-odd:bg-gray-100">{map.Name}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{map.ValueType}</div>
                                                        <div className="p-2 border group-odd:bg-gray-100">{Object.keys(map.Map).length} Mappings</div>
                                                        <div className="px-2 border group-odd:bg-gray-100 flex justify-evenly items-start">
                                                            <button title="Copy Data Map contents to clipboard." className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigator.clipboard.writeText(JSON.stringify(map.Map)).then(() => alert("Copied map object to clipboard."));}}><FontAwesomeIcon className="w-auto" icon={faCopy} /></button>
                                                            <button title="Edit" id={`edit-${  map.Name }`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => {navigate(`/settings/data-maps/${  map.Name }`);}}><FontAwesomeIcon className="w-auto" icon={faEdit} /></button>
                                                            <button title="Delete" id={`delete-${  map.Name }`} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded text-sm m-2" type="button" onClick={() => setConfirmDeleteModalDisplayed(map.Name)}><FontAwesomeIcon icon={faTrash} /></button>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                    }
                </div>
                {
                    dataMapsData?.Data && Array.isArray(dataMapsData.Data) && confirmDeleteModalDisplayed !== undefined
                        ? dataMapsData.Data.map((map, index) => (
                            confirmDeleteModalDisplayed === map.Name 
                                ?   <ConfirmModal 
                                        key={index} 
                                        title={`Confirm Delete of ${  map.Name  } Data Map`}
                                        submitButtonTitle="Delete"
                                        identifier={map.Name}
                                        confirmationMessage={`Are you sure you want to delete ${  map.Name  }?`} 
                                        successMessage="Map deleted successfully."
                                        failureMessage="Map failed to delete:"
                                        closeModal={setConfirmDeleteModalDisplayed}
                                        confirmFunction={confirmDelete}
                                    /> 
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}