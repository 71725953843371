import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";

import {midgardFetch} from "../../../services/mip";

export default function DownloadFileButton(props) {  

    const [isFetching, setIsFetching] = useState(false);

    const downloadFile = (filename) => {
        props.setErrorMessage(undefined);
        setIsFetching(true);
        midgardFetch(`files/${ encodeURIComponent(filename) }`)
        .then((response) => {
            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error("File not found.");
                } else {
                    return response.json().then((err) => {
                        throw new Error(err.Message);
                    }).catch(() => {
                        throw new Error(`${ response.status } ${ response.statusText }`);
                    });
                }
            }
            return response.blob();
        })
        .then((blob) => {
            // Create a download link
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            // Set the filename
            link.download = props.filename.substring(props.filename.lastIndexOf("/")+1);

            // Append the link to the document
            document.body.appendChild(link);

            // Trigger the click event to start the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
        })
        .catch((err) => props.setErrorMessage(err))
        .finally(() => {
            setIsFetching(false);
        });
    };

    return (
        <button disabled={isFetching} onClick={() => { downloadFile(props.filename);}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm m-2">{isFetching ? <FontAwesomeIcon className="spinner" icon={faSpinner} /> : <FontAwesomeIcon icon={faDownload} /> }</button>
    );
}