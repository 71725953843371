import { useEffect, useLayoutEffect, useState, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import ErrorTile from "../../../components/Common/ErrorTile";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import NotFound from "../../../components/Common/NotFound";
import ExternalEntitiesPropertiesContainer from "../../../components/Settings/ExternalEntitiesPropertiesContainer";
import ExternalEntitiesConstantItemsContainer from "../../../components/Settings/ExternalEntitiesConstantItemsContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faNetworkWired,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {
    useGetExternalEntityQuery,
    useGetExternalEntitiesSystemQuery,
    useCreateExternalEntitiesMutation,
    useUpdateExternalEntitiesMutation
} from "../../../services/mip";

export default function ExternalEntitiesForm(props) {   
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const { 
        data: externalEntitiesSystemData,
        error: externalEntitiesSystemError,
        isFetching: externalEntitiesSystemIsFetching
    } = useGetExternalEntitiesSystemQuery(params.systemId, { skip: !isLoggedIn || !hasPrivilege});

    const newMode = params.entityId.toLowerCase() === "new";
    const { 
        data: externalEntityData,
        error: externalEntityError,
        isFetching: externalEntityIsFetching
    } = useGetExternalEntityQuery(params.entityId, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const [addEntityError, setAddEntityError] = useState();
    const [entityName, setEntityName] = useState("");
    const [entityJson, setEntityJson] = useState("");
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    const [createExternalEntity] = useCreateExternalEntitiesMutation();
    const postExternalEntities = async () => {
        setSubmittingToAPI(true);
        setAddEntityError(undefined);
        const objectToPost = {
            Name: entityName,
            EntityJson: entityJson,
            SystemIdentifier: params.systemId
        };
        try {
            await createExternalEntity(objectToPost).unwrap();
            navigate(`/settings/external-entities/${ params.systemId }`);
        } catch (err) {
            if (err.data?.message) {
                setAddEntityError(err.data.message);
            } else if (err.data?.Message) {
                setAddEntityError(err.data.Message);
            } else if (err.value?.error?.data?.Message) {
                setAddEntityError(err.value.error.data.Message);
            } else {
                setAddEntityError(err.toString());
            }
        }
        setSubmittingToAPI(false);
    };

    const [updateExternalEntity] = useUpdateExternalEntitiesMutation();
    const putExternalEntities = async (entityIdentifier) => {
        setSubmittingToAPI(true);
        setAddEntityError(undefined);
        const objectToPost = {
            id: entityIdentifier,
            body: {
                ConstantItems: null,
                KeyProperties: null,
                Name: entityName,
                Properties: [],
                SystemIdentifier: params.systemId
            }
        };
        const keyProperties = [];
        for (const entityProperty of entityProperties) {
            if (entityProperty.isKey) {
                keyProperties.push(entityProperty.name);
            }
            objectToPost.body.Properties.push({
                AllowConstantValueOnImport: entityProperty.allowConstantValueOnImport === true,
                AllowMultipleMappingOnImport: entityProperty.allowMultipleMappingOnImport === true,
                AllowMultipleValidConstantValuesOnImport: entityProperty.allowMultipleValidConstantValuesOnImport === true,
                AllowUrlSubstitution: entityProperty.allowUrlSubstitution === true,
                DisplayNameOverride: entityProperty.hasDisplayNameOverride && entityProperty.displayNameOverride !== "" ? entityProperty.displayNameOverride : null,
                IsRequired: entityProperty.isRequired === true,
                JsonType: entityProperty.jsonType,
                JsonTypeIsNullable: entityProperty.jsonTypeIsNullable === true,
                LinkedPropertyNames: entityProperty.hasLinkedProperties && Array.isArray(entityProperty.linkedPropertyNames) && entityProperty.linkedPropertyNames.length > 0 ? entityProperty.linkedPropertyNames : null,
                Name: entityProperty.name,
                PropertyKeyOptions: entityProperty.requiresPropertyKeys && Array.isArray(entityProperty.propertyKeyOptions) && entityProperty.propertyKeyOptions.length > 0 ? entityProperty.propertyKeyOptions : null,
                ValidConstantValuesOnImport: entityProperty.allowConstantValueOnImport && Array.isArray(entityProperty.validConstantValuesOnImport) && entityProperty.validConstantValuesOnImport.length > 0 ? entityProperty.validConstantValuesOnImport : null
            });
        }
        if (keyProperties.length !== 0) {
            objectToPost.body.KeyProperties = keyProperties;
        }
        if (constantItems.length > 0) {
            const constantItemObject = {};
            for (const constantItem of constantItems) {
                constantItemObject[constantItem.constantItemKey] = {
                    Type: constantItem.constantItemValueType,
                    ValueAsString: constantItem.constantItemValue
                };
            }
            objectToPost.body.ConstantItems = constantItemObject;
        }
        try {
            await updateExternalEntity(objectToPost).unwrap();
            navigate(`/settings/external-entities/${ params.systemId }`);
        } catch (err) {
            if (err.data?.message) {
                setAddEntityError(err.data.message);
            } else if (err.data?.Message) {
                setAddEntityError(err.data.Message);
            } else if (err.value?.error?.data?.Message) {
                setAddEntityError(err.value.error.data.Message);
            } else {
                setAddEntityError(err.toString());
            }
        }
        setSubmittingToAPI(false);
    };

    const pageTitle = newMode
        ? "Create External Entity"
        : "Edit External Entity";

    useEffect(() => {
        document.title = `Settings - ${ pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (externalEntitiesSystemError && externalEntitiesSystemError.status === 401) {
            window.location.href="/login";
        }
    }, [externalEntitiesSystemError]);

    const entityReducer = (state, action) => {
        const stateCopy = state.map((obj) => ({...obj}));
        switch (action.type) {
            case "setInitialState":
                const initialState = [];
                if (Array.isArray(action.payload?.Properties)) {
                    // first find all the property names that are keys
                    for (const entityProperty of action.payload.Properties) {
                        initialState.push({
                            allowConstantValueOnImport: entityProperty.AllowConstantValueOnImport,
                            allowMultipleMappingOnImport: entityProperty.AllowMultipleMappingOnImport,
                            allowMultipleValidConstantValuesOnImport: entityProperty.AllowMultipleValidConstantValuesOnImport,
                            allowUrlSubstitution: entityProperty.AllowUrlSubstitution,
                            displayNameOverride: entityProperty.DisplayNameOverride ?? "",
                            isKey: Array.isArray(action.payload.KeyProperties) && action.payload.KeyProperties.includes(entityProperty.Name),
                            isRequired: entityProperty.IsRequired,
                            jsonType: entityProperty.JsonType,
                            jsonTypeIsNullable: entityProperty.JsonTypeIsNullable,
                            linkedPropertyNames: Array.isArray(entityProperty.LinkedPropertyNames) ? [...entityProperty.LinkedPropertyNames] : [],
                            name: entityProperty.Name,
                            propertyKeyOptions: Array.isArray(entityProperty.PropertyKeyOptions) ? entityProperty.PropertyKeyOptions.map((obj) => ({...obj})) : [],
                            typeCode: entityProperty.TypeCode,
                            validConstantValuesOnImport: Array.isArray(entityProperty.ValidConstantValuesOnImport) ? entityProperty.ValidConstantValuesOnImport.map((obj) => ({...obj})) : [],
                            requiresPropertyKeys: Array.isArray(entityProperty.PropertyKeyOptions) && entityProperty.PropertyKeyOptions.length > 0,
                            hasLinkedProperties: Array.isArray(entityProperty.LinkedPropertyNames) && entityProperty.LinkedPropertyNames.length > 0,
                            hasDisplayNameOverride: entityProperty.DisplayNameOverride !== null && entityProperty.DisplayNameOverride !== undefined && entityProperty.DisplayNameOverride !== ""
                        });
                    }
                }
                return initialState;
            case "addEntitiesProperty":
                stateCopy.push({
                    allowConstantValueOnImport: false,
                    allowMultipleMappingOnImport: false,
                    allowMultipleValidConstantValuesOnImport: false,
                    allowUrlSubstitution: false,
                    displayNameOverride: "",
                    isKey: false,
                    isRequired: false,
                    jsonType: "String",
                    jsonTypeIsNullable: false,
                    linkedPropertyNames: [],
                    name: "UnknownProperty",
                    propertyKeyOptions: [],
                    typeCode: 3,
                    validConstantValuesOnImport: [],
                    requiresPropertyKeys: false,
                    hasLinkedProperties: false,
                    hasDisplayNameOverride: false
                });
                break;
            case "setEntitiesPropertyValue":
                if (action.payload.property === "linkedPropertyNames") {
                    // when setting a linked property also set the inverse
                    // determine what the new value is
                    if (stateCopy[action.payload.index].linkedPropertyNames.length > action.payload.value.length) {
                        // Removing an Item
                        const missingPropertyArray = stateCopy[action.payload.index].linkedPropertyNames.filter((x) => !action.payload.value.includes(x));
                        const missingProperty = missingPropertyArray.length > 0 ? missingPropertyArray[0] : "";
                        for (const index in stateCopy) {
                            if (stateCopy[index].name === missingProperty) {
                                const indexOfLinkRemoved = stateCopy[index].linkedPropertyNames.findIndex((str) => str === stateCopy[action.payload.index].name);
                                if (indexOfLinkRemoved !== -1) {
                                    stateCopy[index].linkedPropertyNames.splice(indexOfLinkRemoved,1);
                                }
                                if (stateCopy[index].linkedPropertyNames.length === 0) {
                                    stateCopy[index].hasLinkedProperties = false;
                                }
                            }
                        }
                    } else {
                        // Adding an Item
                        const newLinkedPropertyArray =  action.payload.value.filter((x) => !stateCopy[action.payload.index].linkedPropertyNames.includes(x));
                        const newLinkedProperty = newLinkedPropertyArray.length > 0 ? newLinkedPropertyArray[0] : "";
                        for (const index in stateCopy) {
                            if (stateCopy[index].name === newLinkedProperty) {
                                stateCopy[index].hasLinkedProperties = true;
                                if (!stateCopy[index].linkedPropertyNames.includes(stateCopy[action.payload.index].name)) {
                                    stateCopy[index].linkedPropertyNames.push(stateCopy[action.payload.index].name);
                                }
                            }
                        }
                    }
                    stateCopy[action.payload.index][action.payload.property] = action.payload.value;
                } else {
                    stateCopy[action.payload.index][action.payload.property] = action.payload.value;
                }
                break;
            case "deleteEntitiesProperty":
                stateCopy.splice(action.payload.index, 1);
                break;
            default:
                throw new Error("unknown action.type in entityReducer");
        }
        return stateCopy;
    };

    const [entityProperties, entitiesDispatch] = useReducer(entityReducer, []);

    const constantItemsReducer = (state, action) => {
        const stateCopy = state.map((obj) => ({...obj}));
        switch (action.type) {
            case "setInitialState":
                const initialState = [];
                if (action.payload?.ConstantItems !== null && action.payload?.ConstantItems !== undefined) {
                    for (const constantItemKey of Object.keys(action.payload.ConstantItems)) {
                        initialState.push({
                            constantItemKey,
                            constantItemValue: action.payload.ConstantItems[constantItemKey].ValueAsString,
                            constantItemValueType: action.payload.ConstantItems[constantItemKey].Type
                        });
                    }
                }
                return initialState;
            case "addConstantItem":
                stateCopy.push({
                    constantItemKey: "",
                    constantItemValue: "",
                    constantItemValueType: "String"
                });
                break;
            case "setConstantItemValue":
                stateCopy[action.payload.index][action.payload.property] = action.payload.value;
                break;
            case "deleteConstantItem":
                stateCopy.splice(action.payload.index, 1);
                break;
            default:
                throw new Error("unknown action.type in constantItemsReducer");
        }
        return stateCopy;
    };

    const [constantItems, constantItemsDispatch] = useReducer(constantItemsReducer, []);

    useLayoutEffect(() => {
        if (!newMode && externalEntityData) {
            setEntityName(externalEntityData.Name ?? "");
            entitiesDispatch({
                type: "setInitialState",
                payload: externalEntityData
            });
            constantItemsDispatch({
                type: "setInitialState",
                payload: externalEntityData
            });
        }
    }, [newMode, externalEntityData, entitiesDispatch]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="external-entities" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                {
                    externalEntityError || externalEntitiesSystemError
                        ? <ErrorTile message={externalEntityError || externalEntitiesSystemError} />
                        : externalEntityIsFetching || externalEntitiesSystemIsFetching
                            ?   <LoadingSpinner text={"Loading Data"} />
                            :   <>
                                    <div className="text-2xl p-1 pb-4">
                                        <FontAwesomeIcon icon={faNetworkWired} /> {pageTitle}
                                    </div>
                                    {
                                        addEntityError && <ErrorTile message={addEntityError} />
                                    }
                                    <div>
                                        <div className="my-3">
                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="systemName">
                                                System
                                            </label>
                                            <div className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3">
                                                {externalEntitiesSystemData?.Name}
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="entityName">
                                                Name
                                            </label>
                                            <input type="text" id="entityName" name="entityName" value={entityName ?? ""} onChange={(e) => setEntityName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                        </div>
                                        {
                                            newMode
                                                ?   <>
                                                        <div className="my-3">
                                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="entityJson">
                                                                Sample JSON
                                                            </label> 
                                                            <textarea id="entityJson" name="entityJson" value={entityJson ?? ""} onChange={(e) => setEntityJson(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3 h-32"></textarea>
                                                            <div className="text-sm text-grey-800">
                                                                Entities are created from a sample JSON object.
                                                            </div>
                                                        </div>
                                                    </>
                                                :   <>
                                                        <div className="my-3">
                                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="entityJson">
                                                                Entity Properties
                                                            </label> 
                                                            <ExternalEntitiesPropertiesContainer
                                                                entityProperties={entityProperties}
                                                                entitiesDispatch={entitiesDispatch}
                                                            />
                                                        </div>
                                                        <div className="my-3">
                                                            <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="entityJson">
                                                                Constant Items
                                                            </label> 
                                                            <ExternalEntitiesConstantItemsContainer
                                                                constantItems={constantItems}
                                                                constantItemsDispatch={constantItemsDispatch}
                                                            />
                                                        </div>
                                                        {/*<textarea className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-green-300 bg-black mb-3 h-64">{JSON.stringify(externalEntityData, null, " ")}</textarea>*/}
                                                    </>
                                        }
                                        <div className="mt-4 flex justify-evenly">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate(`/settings/external-entities/${ params.systemId }`)}>Back to {externalEntitiesSystemData?.Name ?? "External Entities"}</button>
                                            {
                                                newMode
                                                    ? <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postExternalEntities}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add External Entity"}</button>
                                                    : <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => putExternalEntities(params.entityId)}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update External Entity"}</button>
                                            }
                                        </div>
                                    </div>
                                </>
                }
                </div>
            </div>
        </div>
        </>
    );
}