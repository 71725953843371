import ExportDataSelectorArrayInfoField from "../ExportDataSelectorArrayInfoField";

export default function ExportDataSelectorArrayInfo(props) {
    return (
        <div className="my-2">
            <div className="mb-4">
                <div className="text-grey-800 text-sm font-bold mb-2">Required Array Information</div>
                <span>Arrays can be flattened horizontally or vertically. Vertical flattening will create individual rows for each unique record and has no limit on the number of items in the array. This is applicable for a hierarchical structure when you want one child item per row with any parent values duplicated. Horizontal flattening instead creates a fixed number of columns for the data in the array. This is more applicable for arrays where you want “Child 1” and “Child 2” columns instead of individual rows for each individual child. Be aware that horizontal flattening may not capture all the data in the set - if there are 3 children, the last one would be absent from the result.</span>
            </div>
            <div data-test-id="export-data-selector-array-info-container-div">
            {
                Object.keys(props.mappingData.arrayData).sort().map((arrayIdentifier, index) => (
                    <ExportDataSelectorArrayInfoField
                        key={index} 
                        arrayIdentifier={arrayIdentifier}
                        mappingData={props.mappingData}
                        setArrayDataValue={props.setArrayDataValue}
                    />
                ))
            }
            </div>
        </div>
    );
}