import "./loadingSpinner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


export default function LoadingSpinner(props) {  

    const classes = ["loadingGradient", "p-2"];
    classes.push(props.width ? props.width : "w-11/12");
    classes.push(props.margin ? props.margin : "mx-auto my-0");

    return (
        <div className={classes.join(" ")}>
            {props.spinner !== false ? <FontAwesomeIcon icon={faSpinner} className="mx-2 spinner" /> : null}
            {props.text ? props.text : null}
        </div>
    );
}