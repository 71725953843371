import { useState, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ArrayOfStrings from "../../../components/Common/ArrayOfStrings";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";

import {
    useGetEmailDistributionListsQuery,
    useGetExtendedSettingsQuery,
    useUpdateExtendedSettingsMutation
} from "../../../services/mip";

export default function ExtendedSettings(props) {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Admin") || privilege.includes("All");

    useEffect(() => {
        document.title = "Settings - Extended";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const {
        data: extendedSettingsData,
        error: extendedSettingsError,
        isFetching: extendedSettingsIsFetching
    } = useGetExtendedSettingsQuery(null, { skip: !isLoggedIn || !hasPrivilege });

    const { 
        data: emailDistributionListsData,
        error: emailDistributionListsError,
        isFetching: emailDistributionListsIsFetching
    } = useGetEmailDistributionListsQuery(null, { skip: !isLoggedIn || !hasPrivilege});

    const [resourceLicenses, setResourceLicenses] = useState([]);
    const [defaultEmailDistributionList, setDefaultEmailDistributionList] = useState();
    const [directoriesToPersist, setDirectoriesToPersist] = useState([]);
    const [staticConfigFiles, setStaticConfigFiles] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [validationMessage, setValidationmessage] = useState(null);
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    useLayoutEffect(() => {
        if (extendedSettingsData) {
            setResourceLicenses(extendedSettingsData.ResourceLicenses ?? []);
            setDirectoriesToPersist(extendedSettingsData.DirectoriesToPersist ?? []);
            setStaticConfigFiles(extendedSettingsData.StaticConfigFiles ?? []);
        }
    }, [extendedSettingsData]);

    const [updateSettings] = useUpdateExtendedSettingsMutation();
    const patchUpdate = async () => {
        setSubmittingToAPI(true);
        setValidationmessage(null);
        setSuccessMessage(null);
        const dataToPost = {
            "DirectoriesToPersist": directoriesToPersist,
            "StaticConfigFiles": staticConfigFiles,
            "ResourceLicenses": resourceLicenses
        };
        if (defaultEmailDistributionList && defaultEmailDistributionList !== "") {
            dataToPost["DefaultEmailDistributionListIdentifier"] = defaultEmailDistributionList;
        }
        try {
            await updateSettings(dataToPost).unwrap();
            setSuccessMessage("Settings updated.");
        } catch (err) {
            setValidationmessage(err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error");
        }
        setSubmittingToAPI(false);
    };

    useEffect(() => {
        if (extendedSettingsError && extendedSettingsError.status === 401) {
            window.location.href="/login";
        }
    }, [extendedSettingsError]);

    useLayoutEffect(() => {
        if (extendedSettingsData?.DefaultEmailDistributionListIdentifier && defaultEmailDistributionList === undefined) {
            setDefaultEmailDistributionList(extendedSettingsData.DefaultEmailDistributionListIdentifier);
        }
    }, [extendedSettingsData, defaultEmailDistributionList]);
    

    const handleResourceSelectionChange = (e) => {
        const selectedOptions = [...resourceLicenses];
        if (e.target.checked) {
            selectedOptions.push(e.target.value);
        } else {
            const currentIndex = selectedOptions.indexOf(e.target.value);
            selectedOptions.splice(currentIndex, 1);
        }
        setResourceLicenses(selectedOptions);
    };
    
    const licenseOptions = [];
    if (extendedSettingsData && extendedSettingsData.LicensableResources) {
        for (const possibleLicenseIdentifier of Object.keys(extendedSettingsData.LicensableResources)) {
            licenseOptions.push(
                <div key={possibleLicenseIdentifier} className="block ml-4">
                    <label>
                        <input type="checkbox" name="selectedResourceLicenses" value={possibleLicenseIdentifier} checked={resourceLicenses.includes(possibleLicenseIdentifier)} className="align-middle" onChange={handleResourceSelectionChange}/>
                        <span className="text-grey-800 text-sm mb-2 ml-2 align-middle">{extendedSettingsData.LicensableResources[possibleLicenseIdentifier]}</span>
                    </label>
                </div>
            );
        }
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="extended" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faCog} /> Extended Settings</div>
                    </div>
                    {
                        validationMessage
                            ? <ErrorTile message={validationMessage} />
                            : null
                    }
                    {
                        successMessage
                            ? <div className="border text-center border-green-500 rounded-lg mt-2 mb-4 p-4 bg-green-200">{successMessage}</div>
                            : null
                    }
                    {
                        extendedSettingsError || emailDistributionListsError
                            ? <ErrorTile message={extendedSettingsError || emailDistributionListsError} />
                            : extendedSettingsIsFetching || emailDistributionListsIsFetching
                                ?   <LoadingSpinner text={"Loading Settings"} />
                                :   <>
                                    {
                                        Array.isArray(licenseOptions) && licenseOptions.length > 0
                                            ?   <>
                                                    <div className="mb-3">
                                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="selectedResourceLicenses">
                                                            Licensed Resources
                                                        </label>
                                                        <div>
                                                            {licenseOptions}
                                                        </div>
                                                    </div>
                                                </>
                                            : null
                                    }
                                    <div className="my-6">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="defaultEmailDistributionList">
                                            Default Email Distribution List
                                        </label>
                                        {
                                            Array.isArray(emailDistributionListsData?.Data)
                                                ?   <select name="LogLevel" id="LogLevel" value={defaultEmailDistributionList} onChange={(e) => {setDefaultEmailDistributionList(e.target.value);}} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                        <option key="none" value="">None</option>
                                                        {
                                                            emailDistributionListsData.Data.map((listData) => (
                                                                <option key={listData.Identifier} value={listData.Identifier}>{listData.Name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                : null
                                        }
                                    </div>
                                    <div className="my-6">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="directoriesToPersist">
                                            Directories to Persist
                                        </label>
                                        <ArrayOfStrings
                                            arrayOfStrings={directoriesToPersist}
                                            setArrayOfStrings={setDirectoriesToPersist}
                                        />
                                    </div>
                                    <div className="my-6">
                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="staticConfigFiles">
                                            Static Config Files
                                        </label>
                                        <ArrayOfStrings
                                            arrayOfStrings={staticConfigFiles}
                                            setArrayOfStrings={setStaticConfigFiles}
                                        />
                                    </div>
                                    <div className="flex justify-evenly">
                                        <button disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={patchUpdate}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Update Settings"}</button>
                                    </div>
                                    </>
                    }
                </div>
            </div>
        </div>
        </>
    );
}