import LogsTableRow from "../LogsTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClose,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { useMemo, useState } from "react";
import {
    formatOffset
} from "../../../midgard.js";

export default function LogsTable(props) {
    const [logLevel, setLogLevel] = useState("DEBUG");
    const [lambdaFnFilter, setLambdaFnFilter] = useState(undefined);
    const [taskIdentifierFilter, setTaskIdentifierFilter] = useState(undefined);
    const [lambdaRequestIdentifierFilter, setLambdaRequestIdentifierFilter] = useState(undefined);
    const [taskChainInstanceFilter, setTaskChainInstanceFilter] = useState(undefined);

    const addLambdaFnFilter = (filterValue) => {
        props.setPageIndex(0);
        setLambdaFnFilter(filterValue);
    };

    const addTaskIdentifierFilter = (filterValue) => {
        props.setPageIndex(0);
        setTaskIdentifierFilter(filterValue);
    };

    const addLambdaRequestIdentifierFilter = (filterValue) => {
        props.setPageIndex(0);
        setLambdaRequestIdentifierFilter(filterValue);
    };

    const addTaskChainInstanceFilter = (filterValue) => {
        props.setPageIndex(0);
        setTaskChainInstanceFilter(filterValue);
    };

    const clearAllFilters = () => {
        props.setPageIndex(0);
        setLambdaFnFilter(undefined);
        setTaskIdentifierFilter(undefined);
        setLambdaRequestIdentifierFilter(undefined);
        setTaskChainInstanceFilter(undefined);
    };

    const logEntriesToDisplay = useMemo(() => {
        let levelsThatPass;
        switch (logLevel) {
            case "INFO":
                levelsThatPass = ["INFO", "WARN", "ERROR", "FATAL"];
                break;
            case "WARN":
                levelsThatPass = ["WARN", "ERROR", "FATAL"];
                break;
            case "ERROR":
                levelsThatPass = ["ERROR", "FATAL"];
                break;
            case "FATAL":
                levelsThatPass = ["FATAL"];
                break;
            default:
                levelsThatPass = ["DEBUG", "INFO", "WARN", "ERROR", "FATAL"];
                break;
        }
        return Object.values(props.cachedLogEntries.logEntries)
            .filter((logEntry) =>
                levelsThatPass.includes(logEntry.LogLevel.toUpperCase()) &&
                (lambdaFnFilter === undefined || logEntry.LambdaFunctionName === lambdaFnFilter) &&
                (taskChainInstanceFilter === undefined || logEntry.TaskChainInstance === taskChainInstanceFilter) &&
                (taskIdentifierFilter === undefined || logEntry.TaskIdentifier === taskIdentifierFilter) &&
                (lambdaRequestIdentifierFilter === undefined || logEntry.LambdaRequestIdentifier === lambdaRequestIdentifierFilter)
            )
            .sort((a,b) => (a.Timestamp > b.Timestamp ? 1 : ((a.Timestamp < b.Timestamp) ? -1 : 0)));
    }, [props.cachedLogEntries, logLevel, lambdaFnFilter, taskChainInstanceFilter, taskIdentifierFilter, lambdaRequestIdentifierFilter]);

    const logEntryCount = Object.keys(props.cachedLogEntries.logEntries).length;
    const totalPages = Math.ceil(logEntriesToDisplay.length / props.pageSize);
    const pagedData = logEntriesToDisplay.slice(props.pageIndex*props.pageSize, (props.pageIndex+1)*props.pageSize);
    const displayedEntriesCount = Math.min(pagedData.length, logEntriesToDisplay.length);

    const handleLoadAdditionalLink = (loadOlder) => {
        if (!props.isFetching) {
            props.fetchAdditionalLogs(loadOlder);
        }
    };

    return (
        <>
        <div className="border my-4 p-2">
            <div className="font-bold">Filters</div>
            <div className="ml-4">
                <span>Min Log Level:
                    <select name="LogLevel" id="LogLevel" value={logLevel} onChange={(e) => {setLogLevel(e.target.value);}} className="shadow border rounded ml-2 px-2 text-grey-800 mb-3 text-base focus:shadow-outline">
                        <option value="DEBUG">DEBUG</option>
                        <option value="INFO">INFO</option>
                        <option value="WARN">WARN</option>
                        <option value="ERROR">ERROR</option>
                        <option value="FATAL">FATAL</option>
                    </select>
                </span>
            </div>
            {
                lambdaFnFilter !== undefined
                    ?   <div className="ml-4">
                            <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => addLambdaFnFilter(undefined)} /></span>
                            <span>Filtering on Lambda Function Name: {lambdaFnFilter}</span>
                        </div>
                    : null
            }
            {
                taskChainInstanceFilter !== undefined
                    ?   <div className="ml-4">
                            <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => addTaskChainInstanceFilter(undefined)} /></span>
                            <span>Filtering on Task Chain Identifier: {taskChainInstanceFilter}</span>
                        </div>
                    : null
            }
            {
                taskIdentifierFilter !== undefined
                    ?   <div className="ml-4">
                            <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => addTaskIdentifierFilter(undefined)} /></span>
                            <span>Filtering on Task Identifier: {taskIdentifierFilter}</span>
                        </div>
                    : null
            }
            {
                lambdaRequestIdentifierFilter !== undefined
                    ?   <div className="ml-4">
                            <span className="hover:cursor-pointer text-red-500 pr-2"><FontAwesomeIcon icon={faClose} onClick={() => addLambdaRequestIdentifierFilter(undefined)} /></span>
                            <span>Filtering on Lambda Request Identifier: {lambdaRequestIdentifierFilter}</span>
                        </div>
                    : null
            }
            <div className="text-right"><span onClick={clearAllFilters} className="hover:cursor-pointer text-sm">Reset Filters</span></div>
        </div>
        {
            props.isFetching
                ?   <FontAwesomeIcon icon={faSpinner} className="spinner text-sm" />
                :   <div className="text-sm">
                        {
                            !props.hideLoadButtons
                                ?   <>
                                        Page {props.pageIndex + 1} of {totalPages}. 
                                        {
                                            props.pageIndex === 0
                                                ? <><span className="ml-1 hover:cursor-pointer text-blue-300" onClick={() => {handleLoadAdditionalLink(true);}}>Query older entries</span>.</>
                                                : <><span className="ml-1 hover:cursor-pointer text-blue-300" onClick={() => {props.setPageIndex(props.pageIndex-1);}}>Go to previous page</span>.</>
                                        }
                                    </>
                                : null
                        }
                        <span className="ml-1">Displaying {displayedEntriesCount} of {logEntryCount} cached log entries.</span>
                        {
                            props.cachedLogEntries.lastLoadCount !== undefined
                                ? <span className="ml-1">{`Loaded ${ props.cachedLogEntries.lastLoadCount } ${ props.cachedLogEntries.lastQueryDetails }.`}</span>
                                : null
                        }
                    </div>
        }
        <div className="grid" style={{gridTemplateColumns: "8rem 4rem 28rem auto"}}>
            <div className="text-center font-bold px-1 border whitespace-nowrap">
                { props.useUTC ? "UTC" : `UTC${ formatOffset(new Date().getTimezoneOffset()) }` }
            </div>
            <div className="text-center font-bold px-1 border whitespace-nowrap">Level</div>
            <div className="text-center font-bold px-1 border whitespace-nowrap">Info</div>
            <div className="text-center font-bold px-1 border whitespace-nowrap">Message</div>
            {
                pagedData.map((logInfo) => (
                    <LogsTableRow
                        key={logInfo.Identifier}
                        entry={logInfo}
                        expandAll={props.expandAll}
                        useUTC={props.useUTC}
                        setTaskChainInstanceFilter={addTaskChainInstanceFilter}
                        setLambdaFnFilter={addLambdaFnFilter}
                        setTaskIdentifierFilter={addTaskIdentifierFilter}
                        setLambdaRequestIdentifierFilter={addLambdaRequestIdentifierFilter}
                        queryByLambdaRequestIdentifier={props.queryByLambdaRequestIdentifier}
                    />
                ))
            }
        </div>
        {
            props.isFetching
                ?   <FontAwesomeIcon icon={faSpinner} className="spinner text-sm" />
                :   <div className="text-sm">
                        {
                            !props.hideLoadButtons
                                ?   <>
                                    Page {props.pageIndex + 1} of {totalPages}. 
                                    {
                                        props.pageIndex+1 >= totalPages
                                            ? <><span className="ml-1 hover:cursor-pointer text-blue-300" onClick={() => {handleLoadAdditionalLink(false);}}>Query newer entries</span>.</>
                                            : <><span className="ml-1 hover:cursor-pointer text-blue-300" onClick={() => {props.setPageIndex(props.pageIndex+1);}}>Go to next page</span>.</>
                                    }
                                    </>
                                : null
                        }
                        <span className="ml-1">Displaying {displayedEntriesCount} of {logEntryCount} cached log entries.</span>
                        {
                            props.cachedLogEntries.lastLoadCount !== undefined
                                ? <span className="ml-1">{`Loaded ${ props.cachedLogEntries.lastLoadCount } ${ props.cachedLogEntries.lastQueryDetails }.`}</span>
                                : null
                        }
                    </div>
        }
        </>
    );
}