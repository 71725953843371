import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../../features/auth/authSlice";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import ErrorTile from "../../../components/Common/ErrorTile";
import NotFound from "../../../components/Common/NotFound";
import ConfirmModal from "../../../components/Common/ConfirmModal";
import SettingsLeftNav from "../../../components/Settings/SettingsLeftNav";
import UserInformationBanner from "../../../components/Common/UserInformationBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { JsonType } from "../../../midgard.js";

import { 
    useGetDataMapsQuery,
    useCreateDataMapMutation,
    useUpdateDataMapMutation
} from "../../../services/mip";

export default function DataMapsForm(props) {
    const navigate = useNavigate();
    const params = useParams();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("ProvisionProcesses") || privilege.includes("All");

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);
    
    const newMode = params.mapName.toLowerCase() === "new";
    const { 
        data: dataMapsData,
        error: dataMapsError,
        isFetching: dataMapsIsFetching
    } = useGetDataMapsQuery(null, { skip: !isLoggedIn || !hasPrivilege || newMode});

    const [createDataMap, createDataMapState] = useCreateDataMapMutation();
    const postDataMap = async () => {
        setSubmittingToAPI(true);
        setInvalidDataMapOptions(undefined);
        if (dataMapName.length === 0) {
            setInvalidDataMapOptions("Please provide a name.");
        } else {
            const objectToPost = {
                Map: null,
                Name: dataMapName,
                ValueType: "String",
                FallbackValue: useFallbackValue ? dataMapFallbackValue : null
            };
            try {
                objectToPost.Map = JSON.parse(dataMapJson);
                objectToPost.ValueType = dataMapType;
                await createDataMap(objectToPost).unwrap();
                navigate("/settings/data-maps");
            } catch (err) {
                if (err.data?.message) {
                    setInvalidDataMapOptions(err.data.message);
                } else if (err.data?.Message) {
                    setInvalidDataMapOptions(err.data.Message);
                } else if (err.value?.error?.data?.Message) {
                    setInvalidDataMapOptions(err.value.error.data.Message);
                } else {
                    setInvalidDataMapOptions(err.toString());
                }
            }
        }
        setSubmittingToAPI(false);
    };

    const validateJsonAndShowConfirmModal = (name) => {
        const objectToPost = {
            Map: null,
            Name: dataMapName,
            ValueType: "String",
            FallbackValue: useFallbackValue ? dataMapFallbackValue : null
        };
        try {
            objectToPost.Map = JSON.parse(dataMapJson);
            objectToPost.ValueType = dataMapType;
            setConfirmUpdateModalDisplayed(name);
        } catch (err) {
            if (err.data?.message) {
                setInvalidDataMapOptions(err.data.message);
            } else if (err.data?.Message) {
                setInvalidDataMapOptions(err.data.Message);
            } else if (err.value?.error?.data?.Message) {
                setInvalidDataMapOptions(err.value.error.data.Message);
            } else {
                setInvalidDataMapOptions(err.toString());
            }
        }
    };

    const [updateDataMap] = useUpdateDataMapMutation();
    const confirmUpdate = async (name) => {
        setInvalidDataMapOptions(undefined);
        const objectToPost = {
            Map: null,
            Name: dataMapName,
            ValueType: dataMapType,
            FallbackValue: useFallbackValue ? dataMapFallbackValue : null
        };
        try {
            objectToPost.Map = JSON.parse(dataMapJson);
            objectToPost.ValueType = dataMapType;
            await updateDataMap({id: name, body: objectToPost}).unwrap();
            setConfirmUpdateModalDisplayed(null);
            navigate("/settings/data-maps");
            return [true, null];
        } catch (err) {
            return [false, err?.data?.Message || err?.data?.message || err?.value?.error?.error || err ||  "Unknown Error"];
        }
    };

    const [dataMapName, setDataMapName] = useState(newMode ? "" : undefined);
    const [dataMapType, setDataMapType] = useState(newMode ? "String" : undefined);
    const [useFallbackValue, setUseFallbackValue] = useState(newMode ? false : undefined);
    const [dataMapFallbackValue, setDataMapFallbackValue] = useState(newMode ? "" : undefined);
    const [dataMapJson, setDataMapJson] = useState(newMode ? '{"key1": "value1", "key2": "value2"}' : undefined);
    const [invalidDataMapOptions, setInvalidDataMapOptions] = useState();
    const [confirmUpdateModalDisplayed, setConfirmUpdateModalDisplayed] = useState();
    const [submittingToAPI, setSubmittingToAPI] = useState(false);

    useLayoutEffect(() => {
        let dataMapObject;
        if (Array.isArray(dataMapsData?.Data)) {
            for (const dataMap of dataMapsData.Data) {
                if (dataMap.Name === params.mapName) {
                    dataMapObject = dataMap;
                    break;
                }
            }
            // set defaults on edit
            if (dataMapObject) {
                if (dataMapName === undefined) {
                    setDataMapName(dataMapObject.Name);
                }
                if (dataMapType === undefined) {
                    setDataMapType(dataMapObject.ValueType);
                }
                if (dataMapJson === undefined) {
                    setDataMapJson(JSON.stringify(dataMapObject.Map, null, " "));
                }
                if (dataMapFallbackValue === undefined) {
                    setUseFallbackValue(dataMapObject.FallbackValue !== undefined && dataMapObject.FallbackValue !== null ? true : false);
                    setDataMapFallbackValue(dataMapObject.FallbackValue !== undefined && dataMapObject.FallbackValue !== null ? dataMapObject.FallbackValue : "");
                }
            }
        }
    }, [params.mapName, dataMapsData, dataMapName, dataMapType, dataMapJson, dataMapFallbackValue]);

    const pageTitle = newMode
        ? "Create Data Map"
        : `Edit ${  params.mapName  } Data Map`;

    useEffect(() => {
        document.title = `Settings - ${  pageTitle }`;
    }, [pageTitle]);

    useEffect(() => {
        if (dataMapsError && dataMapsError.status === 401) {
            window.location.href="/login";
        }
    }, [dataMapsError]);

    if (!newMode && Array.isArray(dataMapsData?.Data)) {
        let foundInData = false;
        for (const dataMap of dataMapsData.Data) {
            if (dataMap.Name === params.mapName) {
                foundInData = true;
                break;
            }
        }
        if (!foundInData) {
            return <NotFound message="DataMap Not Found" />;
        }
    }

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white p-4 grid md:grid-cols-5 grid-cols-1 gap-4">
            <SettingsLeftNav selected="data-maps" />
            <div className="col-span-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1">
                            <FontAwesomeIcon icon={faCog}/> {pageTitle}
                        </div>
                    </div>
                    {
                        dataMapsError
                            ? <ErrorTile message={dataMapsError} />
                            : dataMapsIsFetching
                                ? <LoadingSpinner text={"Loading Data Maps"} />
                                : !newMode && (!dataMapsData || !dataMapsData.hasOwnProperty("Data") || !Array.isArray(dataMapsData.Data) || dataMapsData.Data.length === 0)
                                    ?   <div>Map not found</div>
                                    :   <>
                                            <div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="dataMapName">
                                                        Name
                                                    </label>
                                                    <input type="text" id="dataMapName" name="dataMapName" value={dataMapName ?? ""} onChange={(e) => setDataMapName(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                </div>
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="dataMapType">
                                                        Value Type Code
                                                    </label>
                                                    <select id="dataMapType" name="dataMapType" value={dataMapType ?? "String"} onChange={(e) => setDataMapType(e.target.value)} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                        {
                                                            JsonType.map((jsonType) => <option value={jsonType} key={jsonType}>{jsonType}</option>)
                                                        }
                                                    </select>
                                                </div>
                                                <div className="my-3">
                                                    <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="useFallbackValue">
                                                        <input type="checkbox" value={useFallbackValue || false} name="useFallbackValue" id="useFallbackValue" checked={useFallbackValue ? "checked" : ""} onChange={(e) => {setUseFallbackValue(e.target.checked);}} className="mr-2 align-middle" />
                                                        <span className="text-grey-800 mb-4 align-middle">Use Fallback Value</span>
                                                    </label>
                                                </div>
                                                {
                                                    useFallbackValue && <div className="my-3">
                                                        <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="fallbackValue">
                                                            Fallback Value
                                                        </label>
                                                        <input type="text" id="fallbackValue" name="fallbackValue" value={dataMapFallbackValue ?? ""} onChange={(e) => setDataMapFallbackValue(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                                    </div>
                                                }
                                                <div className="my-3">
                                                    <label className="block text-grey-800 text-sm font-bold align-middle mb-2" htmlFor="dataMapJson">
                                                        Map Object
                                                    </label> 
                                                    <textarea id="dataMapJson" name="dataMapJson" value={dataMapJson ?? ""} onChange={(e) => setDataMapJson(e.target.value)} className="shadow appearance-none border rounded w-full py-2 pl-3 pr-6 text-grey-800 mb-3 h-32"></textarea>
                                                </div>
                                                {
                                                    invalidDataMapOptions 
                                                        ? <ErrorTile message={invalidDataMapOptions} />
                                                        : createDataMapState && createDataMapState.isError
                                                            ? <ErrorTile message={createDataMapState.error?.data?.Message} />
                                                            : null
                                                }
                                                <div className="flex justify-evenly">
                                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => navigate("/settings/data-maps")}>Back to Data Maps</button>
                                                    {
                                                        newMode
                                                            ?   <button data-test-id="add-data-map-button" disabled={submittingToAPI} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={postDataMap}>{submittingToAPI ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : "Add Data Map"}</button>
                                                            :   <button data-test-id="update-data-map-button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => validateJsonAndShowConfirmModal(params.mapName)}>Update Data Map</button>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </>
                    }
                </div>
                {
                    dataMapsData?.Data && Array.isArray(dataMapsData.Data) && confirmUpdateModalDisplayed
                        ? dataMapsData.Data.map((map, index) => (
                            confirmUpdateModalDisplayed === map.Name 
                                ?   <ConfirmModal 
                                        key={index} 
                                        title={`Confirm Update of ${  map.Name  } Data Map`}
                                        submitButtonTitle="Update"
                                        identifier={map.Name}
                                        confirmationMessage={`Are you sure you want to update ${  map.Name  }? Updating an active map will break processes using it.`} 
                                        successMessage="Map updated successfully."
                                        failureMessage="Map failed to update:"
                                        closeModal={setConfirmUpdateModalDisplayed}
                                        confirmFunction={confirmUpdate}
                                    /> 
                                : null
                        ))
                        : null
                }
            </div>
        </div>
        </>
    );
}